import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProjectDetailBase {

//#region projectDetailsId Prop
        @prop()
        projectDetailsId : number;
//#endregion projectDetailsId Prop


//#region projectId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        projectId : number;
//#endregion projectId Prop


//#region projectQuestionId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        projectQuestionId : number;
//#endregion projectQuestionId Prop


//#region projectOptionDetailId Prop
        @prop()
        projectOptionDetailId : number;
//#endregion projectOptionDetailId Prop


//#region projectQuestionType Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        projectQuestionType : number;
//#endregion projectQuestionType Prop


//#region projectOptionDetailDescription Prop
        @prop()
        projectOptionDetailDescription : string;
//#endregion projectOptionDetailDescription Prop


//#region projectDocumentUrl Prop
        @prop()
        projectDocumentUrl : string;
//#endregion projectDocumentUrl Prop


//#region projectFileSize Prop
        @prop()
        projectFileSize : number;
//#endregion projectFileSize Prop







}