import {vExpertieUserDetailBase,} from '@app/database-models'
//Generated Imports
export class vExpertieUserDetail extends vExpertieUserDetailBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties

























}