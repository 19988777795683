import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHROBase {

        //#region userId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'userId', keyColumn: true })
        userId: number;
        //#endregion userId Prop


        //#region name Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'name', keyColumn: false, headerTitle: 'Name' })
        name: string;
        //#endregion name Prop


        //#region companyName Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'companyName', keyColumn: false, headerTitle: 'Company Name' })
        companyName: string;
        //#endregion companyName Prop


        //#region email Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'email', keyColumn: false, headerTitle: 'Email' })
        email: string;
        //#endregion email Prop


        //#region country Prop
        @gridColumn({ visible: true, columnIndex: 6, allowSorting: true, headerKey: 'country', keyColumn: false, headerTitle: 'Country' })
        country: string;
        //#endregion country Prop


        //#region state Prop
        @gridColumn({ visible: true, columnIndex: 7, allowSorting: true, headerKey: 'state', keyColumn: false, headerTitle: 'State' })
        state: string;
        //#endregion state Prop


        //#region city Prop
        @gridColumn({ visible: true, columnIndex: 8, allowSorting: true, headerKey: 'city', keyColumn: false, headerTitle: 'City' })
        city: string;
        //#endregion city Prop


        //#region comapnySize Prop
        @gridColumn({ visible: false, columnIndex: 4, allowSorting: true, headerKey: 'comapnySize', keyColumn: false, headerTitle: 'Company Size' })
        comapnySize: string;
        //#endregion comapnySize Prop


        //#region roleId Prop
        @gridColumn({ visible: false, columnIndex: 8, allowSorting: true, headerKey: 'roleId', keyColumn: false })
        roleId: any;
        //#endregion roleId Prop


        //#region status Prop
        @gridColumn({ visible: false, columnIndex: 9, allowSorting: true, headerKey: 'status', keyColumn: false, headerTitle: 'Status', configuredTemplate: { templateName: "recordActive" } })
        status: string;
        //#endregion status Prop


        //#region linkedInUser Prop
        @gridColumn({ visible: true, columnIndex: 10, allowSorting: false, headerKey: 'linkedInUser', keyColumn: false, headerTitle: 'User Type', configuredTemplate: { templateName: "userTypeText" } })
        linkedInUser: string;
        //#endregion linkedInUser Prop


        //#region isDocumentPayment Prop
        @gridColumn({ visible: false, columnIndex: 11, allowSorting: true, headerKey: 'isDocumentPayment', keyColumn: false })
        isDocumentPayment: any;
        //#endregion isDocumentPayment Prop

        //#region joinDate Prop
        @gridColumn({ visible: true, columnIndex: 12, allowSorting: true, headerKey: 'joiningDate', keyColumn: false, headerTitle: 'Joining Date', configuredTemplate: { templateName: "dateFormat" } })
        joiningDate: any;
        //#endregion joinDate Prop

}