import {vQuestionOptionListBase} from '../database-models/v-question-option-list-base';
//Generated Imports
export class vQuestionOptionList extends vQuestionOptionListBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties














































































}