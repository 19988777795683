import {RegisterExpertizeSubCategoryLookupBase} from '../database-models/register-expertize-sub-category-lookup-base';
//Generated Imports
export class RegisterExpertizeSubCategoryLookup extends RegisterExpertizeSubCategoryLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties

}