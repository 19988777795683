import {CategoryLookupBase} from '../database-models/category-lookup-base';
//Generated Imports
export class CategoryLookup extends CategoryLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties

}