import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSubscriptionPlanRecordBase {

//#region subscriptionPlanId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'subscriptionPlanId', keyColumn: true})
        subscriptionPlanId : number;
//#endregion subscriptionPlanId Prop


//#region subscriptionPlanName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'subscriptionPlanName', keyColumn: false})
        subscriptionPlanName : string;
//#endregion subscriptionPlanName Prop


//#region subscriptionDetails Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'subscriptionDetails', keyColumn: false})
        subscriptionDetails : string;
//#endregion subscriptionDetails Prop


//#region subscriptionPrice Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'subscriptionPrice', keyColumn: false})
        subscriptionPrice : number;
//#endregion subscriptionPrice Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'createdBy', keyColumn: false})
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop


//#region subscriptionTypeId Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'subscriptionTypeId', keyColumn: false})
        subscriptionTypeId : number;
//#endregion subscriptionTypeId Prop


//#region subscriptionTypeName Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'subscriptionTypeName', keyColumn: false})
        subscriptionTypeName : string;
//#endregion subscriptionTypeName Prop

}