import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEmailTemplateBase {

//#region emailTemplateName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'emailTemplateName', keyColumn: false,headerTitle:'Email Templates'})
        emailTemplateName : string;
//#endregion emailTemplateName Prop


//#region emailTemplateId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'emailTemplateId', keyColumn: true})
        emailTemplateId : number;
//#endregion emailTemplateId Prop

}