import {HRConsultantLookupBase,} from '@app/database-models'
//Generated Imports
export class HRConsultantLookup extends HRConsultantLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties



























}