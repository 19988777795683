import {StaticPageBase} from '../database-models/static-page-base';
import {StaticPageImageBase} from '../database-models/static-page-image-base';
//Generated Imports
export class StaticPage extends StaticPageBase 
{




//#region Generated Reference Properties
//#region staticPageImages Prop
staticPageImages : StaticPageImageBase[];
//#endregion staticPageImages Prop

//#endregion Generated Reference Properties







}