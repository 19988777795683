import { TemplateConfig } from '@rxweb/grid';

export const URL_LINK_GRID_TEMPLATE: TemplateConfig = {
    div: {
        class: [
            function (e) {
                return "divTableCell".split(" ");
            }],
        attributes: {
            'data-head': function (e) { return e.headerTitle; }
        }
        ,
        childrens: [{
            div: {
                childrens: [{
                    a: {
                        event: {
                            click: "urlLink"
                        },
                        childrens: [{
                            div: {
                                style: {
                                    // "text-decoration":"underline",
                                    "cursor": "pointer"
                                },
                                //class:["mb-0 badge sucess"],
                                childrens: [{
                                    text: {
                                        text: function (e) {
                                            if (this[e.name] != null) {
                                              return this[e.name];
                                            } else {
                                              return ''
                                            }
                                          }
                                    }
                                }]
                            }
                        }]
                    }
                }],
            }
        }]
    }
};
