import {CustomerProfileIssueBase} from '../database-models/customer-profile-issue-base';
//Generated Imports
export class CustomerProfileIssue extends CustomerProfileIssueBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties


}