import {MessageAttachmentBase} from '../database-models/message-attachment-base';
import {MessageBase} from '../database-models/message-base';
//Generated Imports
export class MessageAttachment extends MessageAttachmentBase 
{




//#region Generated Reference Properties
//#region message Prop
message : MessageBase;
//#endregion message Prop
attachmentString:string;
contentType:string;
//#endregion Generated Reference Properties






}