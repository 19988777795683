import {vUserBase} from '../database-models/v-user-base';
//Generated Imports
export class vUser extends vUserBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties


































}