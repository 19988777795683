// import { AbstractControl } from '@angular/forms';
// import { actionColumn } from '@rxweb/grid';
// import { disable } from '@rxweb/reactive-form-validators/decorators/non-validation-decorators/disable.decorator';
// import { RolesEnum } from 'src/app/enums/roles-enum';

//Generated Imports
import {vUSCategoryBase} from '../models/database-models/v-u-s-category-base';
// @disable({conditionalExpression:function(control:AbstractControl){return this.roleId == RolesEnum.HRO; }})
// @actionColumn({
//     name: 'action',
//     allowSorting: false,
//     configuredTemplate: { templateName: 'action' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
// })
export class USCategoryViewModel extends vUSCategoryBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties



}