import {ProjectDetailBase} from '../database-models/project-detail-base';
import {OptionDetailBase} from '../database-models/option-detail-base';
import {ProjectBase} from '../database-models/project-base';
//Generated Imports
export class ProjectDetail extends ProjectDetailBase 
{
//#region Generated Reference Properties
//#region optionDetail Prop
optionDetail : OptionDetailBase;
//#endregion optionDetail Prop
//#region project Prop
project : ProjectBase;
//#endregion project Prop
//#region checked Prop
checked : boolean;
//#endregion checked Prop
//#region imageName Prop
imageName : string;
//#endregion imageName Prop
//#region fileSize Prop
// fileSize : number;
//#endregion fileSize Prop
//#region contentType Prop
contentType : string;
//#endregion contentType Prop
//#endregion Generated Reference Properties

}