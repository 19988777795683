import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { Contract } from '@app/models';
import { AbstractContract } from '../domain/abstract-contract';
import { ContractViewModel } from 'src/app/viewModel/contract-view-model';
import { StatusEnum } from 'src/app/enums/status.enum';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { RoutingKeys } from 'src/app/components/shared/constant/commonConstants';
import { Router } from '@angular/router';
import { ProjectLookupGroup } from 'src/app/viewModel/project-lookup-group';
import { PROJECT_LOOKUPS } from 'src/app/uris/project-lookups.uri';
import { RxSelectComponent } from '@rxweb/angular/select/rx-select.component';
import { ContractStatusEnum } from 'src/app/enums/contract-status.enum';
import { ContractAttachment } from "src/app/models/extended-models/contract-attachment";
import { access } from "@rxweb/angular-router";
import { ApplicationModules, PermissionNames } from "src/app/enums/application-modules.enum";
import { DownloadFile } from "src/app/components/shared/constant/download-file";

@access({ accessLevel: ApplicationModules.Contracts, action: PermissionNames.Post })

@Component({
    selector: "app-contract-add",
    templateUrl: './contract-add.component.html'
})
export class ContractAddComponent extends AbstractContract implements OnInit, OnDestroy {
    @ViewChild("rxselect") rxSelect: RxSelectComponent;
    contract: ContractViewModel;
    subscription: Subscription;
    projectLookupGroup: ProjectLookupGroup;
    fileType: string;
    fileList: fileViewModel[] = [];
    startDateError: boolean = false;
    endDateError: boolean = false;
    startDateErrorMessage: string;
    endDateErrorMessage: string;
    // attachmentMessage: string = "";
    constructor(private formBuilder: RxFormBuilder, private route: Router) {
        super();
    }

    ngOnInit(): void {
        this.spin = true;
        this.contract = new ContractViewModel();
        this.lookup<ProjectLookupGroup>(
            [
                { path: PROJECT_LOOKUPS.projectLookup, propName: 'projectLookup' },
                { path: PROJECT_LOOKUPS.hrConsultantLookup, propName: 'hrConsultantLookup' },
            ]).subscribe((response: any) => {
                this.spin = false;
                this.contractFormGroup = this.formBuilder.formGroup(this.contract) as IFormGroup<ContractViewModel>;
                this.projectLookupGroup = response;
                this.setDefaultValues();
                this.showComponent = true;
            })
    }

    changeHRCLookup(contract: any) {
        if (contract.sourceItem != undefined) {
            this.lookup<ProjectLookupGroup>([{ path: PROJECT_LOOKUPS["hrConsultantLookup"], propName: "hrConsultantLookup", queryParams: { "projectId": [contract.sourceItem.projectId] } }])
                .subscribe((resp: any) => {
                    this.spin = false;
                    this.rxSelect.resetSource(resp.hrConsultantLookup);
                });
            this.contractFormGroup.controls.hRConsultantId.markAsUntouched();
        }
    }

    setDefaultValues() {
        this.contractFormGroup.patchValue({
            "status": true,
            "createdBy": this.user.userId,
            "createdOn": new Date(),
            "contractId": 0,
            "statusId": StatusEnum.Active
        });
    }
    handleFileSelect(evt) {
        this.fileType = "";
        // this.fileList = [];
        var selectedFiles = evt.target.files;
        // if (selectedFiles.length > 0 && (this.fileList.length + selectedFiles.length) < 6) {
        //     const max_size = 1e+7;
        //     const file_sixe = 5e+6; //size filter in bytes
        // let selectedFilesSize = this.getTotalFileSize();
        //     var totalSize = 0;
        //     for (var index = 0; index < selectedFiles.length; index++) {
        //         totalSize = selectedFilesSize + selectedFiles[index].size;
        //     }
        //     if (totalSize > max_size) {
        //         this.toastr.error(CustomMessage.fileSizeValidation);
        //     }
        //     else {
        var downloadFileObj = new DownloadFile();
        var isFilesValid = downloadFileObj.mutipleFileValidation(selectedFiles, this.fileList.length)
        if (isFilesValid) {
            for (var index = 0; index < selectedFiles.length; index++) {
                var selectedFilename = selectedFiles[index].name
                let findFile = this.fileList.find(t => t.fileName == selectedFilename)
                if (!findFile) {
                    let ext = selectedFiles[index].name.split('.').pop(),
                        fileType = selectedFiles[index].type;
                    ext = ext.toLowerCase();
                    if (selectedFiles[index]) {
                        this.fileType = ext;
                        var reader = new FileReader();
                        reader.readAsDataURL(selectedFiles[index]);
                        // reader.onload = this._handleReaderLoaded.bind(this, selectedFilename, selectedFiles[index].size);
                        reader.onload = this._handleReaderLoaded.bind(this, selectedFilename);

                        // reader.readAsBinaryString(selectedFiles[index]);
                    }
                }
                else
                    this.toastr.error(CustomMessage.duplicateFileValidation);
            }
        }
        // }
        // else
        //     this.toastr.error(CustomMessage.fileCountValidation);

        // this.checkImageValidation();

    }

    // _handleReaderLoaded(fileName, fileSize, readerEvt) {
    _handleReaderLoaded(fileName, readerEvt) {
        var binaryString = readerEvt.target.result;
        // this.binaryString = btoa(binaryString)
        var fileModel = new fileViewModel();
        fileModel.fileName = fileName;
        fileModel.attachmentBase64String = binaryString.split(";base64,")[1];
        fileModel.fileSize = readerEvt.loaded;
        var firstIndex = binaryString.indexOf("data:") + 5;
        var lastIndex = binaryString.indexOf(";base64,");
        var contentType = binaryString.substring(firstIndex, lastIndex);
        fileModel.contentType = contentType;
        this.fileList.push(fileModel);
        // this.checkImageValidation();
    }

    removeAttachment(fileIndex: number) {
        this.fileList.splice(fileIndex, 1);
        // this.checkImageValidation();
    }

    // getTotalFileSize() {
    //     let totalSize = 0;
    //     this.fileList.forEach(element => {
    //         totalSize = totalSize + element.fileSize;
    //     });
    //     return totalSize;
    // }
    // checkImageValidation() {
    //     if (this.fileList.length > 0) {
    //         this.attachmentMessage = '';
    //     }
    //     else {
    //         this.attachmentMessage = "You have to upload atleast one attachment";
    //     }
    // }
    addContract() {
        this.setDefaultValues();
        let contractAttachmentList: ContractAttachment[] = [];
        if (this.fileList.length > 0) {
            for (let index = 0; index < this.fileList.length; index++) {
                let contractAttachment = new ContractAttachment();
                contractAttachment.contractAttachmentId = 0;
                contractAttachment.attachmentString = this.fileList[index].attachmentBase64String;
                contractAttachment.attachment = this.fileList[index].fileName;
                contractAttachment.attachmentSize = this.fileList[index].fileSize;
                contractAttachment.contractId = this.contractFormGroup.value.contractId;
                contractAttachment.createdBy = this.user.userId;
                contractAttachment.createdOn = new Date();
                contractAttachment.contentType = this.fileList[index].contentType;
                contractAttachmentList.push(contractAttachment);
            }
        }
        this.contractFormGroup.value.contractAttachments = contractAttachmentList;
        // if (this.contractFormGroup.value.contractAttachments.length > 0) {
        //     this.attachmentMessage = '';
        // }
        // else {
        //     this.attachmentMessage = "You have to upload atleast one attachment";
        // }
        // if (this.contractFormGroup.valid && this.contractFormGroup.value.contractAttachments.length > 0) {
        if (this.contractFormGroup.valid) {
            let checkStartDate = this.checkDate(this.contractFormGroup.value.contractStartDate);
            let checkEndDate = this.checkDate(this.contractFormGroup.value.contractEndDate);
            let endDateValidation = this.endDateValidation(this.contractFormGroup.value.contractEndDate)

            // if (checkStartDate && checkEndDate && this.attachmentMessage == '') {
            if (checkStartDate && checkEndDate) {
                if (endDateValidation) {
                    this.contractFormGroup.value.contractStatus = ContractStatusEnum.Pending;
                    this.spin = true;
                    this.post({ body: this.contractFormGroup.value }).subscribe(response => {
                        this.spin = false;
                        this.toastr.success(CustomMessage.dataAdded);
                        this.spin = false;
                        this.route.navigate([RoutingKeys.Contract]);
                    })
                }
                else
                    this.toastr.error(CustomMessage.ValidateEndDate);
            }
        }
    }

    checkDate(selectedItem: any) {
        if (selectedItem) {
            let sdate = new Date(selectedItem);
            let tdate = new Date();

            // if (sdate < tdate) {
                if (selectedItem == this.contractFormGroup.value.contractStartDate) {
                    this.startDateError = false;
                    if (sdate < tdate) {
                        // this.toastr.error(CustomMessage.InvalidStartDate);
                        this.startDateError = true;
                        this.startDateErrorMessage = CustomMessage.InvalidStartDate;
                        return false;
                    }
                    return true;
                }

                 if (selectedItem == this.contractFormGroup.value.contractEndDate) {
                    this.endDateError = false;
                    if(sdate<=tdate){
                        this.endDateError = true;
                        this.endDateErrorMessage = CustomMessage.InvalidEndDate;
                        return false;
                    }
                    // this.toastr.error(CustomMessage.InvalidEndDate);
                    if (selectedItem <= this.contractFormGroup.value.contractStartDate) {
                        this.endDateError = true;
                        this.endDateErrorMessage = CustomMessage.ValidateEndDate;
                        return false;
                    }
                    
                    return true;
                }
        }
    }

    endDateValidation(selectedItem: any) {
        if (selectedItem) {
            let endDate = new Date(selectedItem);
            let startDate = this.contractFormGroup.value.contractStartDate;

            if (endDate <= startDate) {
                return false;
            }
            else
                return true;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}
class fileViewModel {
    fileName: string;
    attachmentBase64String: string;
    fileSize: number;
    contentType: string;
}
