import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUSComplianceBase {

//#region title Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true,isAscending:true, headerKey: 'title', keyColumn: false , headerTitle: "Title"})
        title : string;
//#endregion title Prop


//#region documentName Prop
        @gridColumn({visible: false, columnIndex:1, allowSorting: true, headerKey: 'documentName', keyColumn: false, headerTitle:"Document Name"})
        documentName : string;
//#endregion documentName Prop


//#region category Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'category', keyColumn: false , headerTitle:'Category'})
        category : string;
//#endregion category Prop


//#region hrCompliance Prop
        @gridColumn({visible: false, columnIndex:3, allowSorting: true, headerKey: 'hrCompliance', keyColumn: false, headerTitle : 'HR Compliance'})
        hrCompliance : string;
//#endregion hrCompliance Prop


//#region stateName Prop
        @gridColumn({visible: false, columnIndex:4, allowSorting: true, headerKey: 'stateName', keyColumn: false,headerTitle:'State'})
        stateName : string;
//#endregion stateName Prop


//#region uRLLink Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: false, headerKey: 'urlLink', keyColumn: false , headerTitle:'URL Link' , configuredTemplate:{templateName:'urlLink'}})
        urlLink : string;
//#endregion uRLLink Prop


//#region uSComplianceId Prop
        @gridColumn({visible: false, columnIndex:6, allowSorting: true, headerKey: 'uSComplianceId', keyColumn: true})
        uSComplianceId : number;
//#endregion uSComplianceId Prop


//#region isDocument Prop
        @gridColumn({visible: false, columnIndex:7, allowSorting: true, headerKey: 'isDocument', keyColumn: false})
        isDocument : any;
//#endregion isDocument Prop


//#region hRComplianceId Prop
        @gridColumn({visible: false, columnIndex:8, allowSorting: true, headerKey: 'hRComplianceId', keyColumn: false})
        hRComplianceId : any;
//#endregion hRComplianceId Prop


//#region stateId Prop
        @gridColumn({visible: false, columnIndex:9, allowSorting: true, headerKey: 'stateId', keyColumn: false})
        stateId : any;
//#endregion stateId Prop


//#region categoryId Prop
        @gridColumn({visible: false, columnIndex:10, allowSorting: true, headerKey: 'categoryId', keyColumn: false})
        categoryId : any;
//#endregion categoryId Prop


//#region uSComplianceDocumentId Prop
        @gridColumn({visible: false, columnIndex:11, allowSorting: true, headerKey: 'uSComplianceDocumentId', keyColumn: false})
        uSComplianceDocumentId : any;
//#endregion uSComplianceDocumentId Prop


//#region documentUrl Prop
        @gridColumn({visible: false, columnIndex:12, allowSorting: true, headerKey: 'documentUrl', keyColumn: false})
        documentUrl : string;
//#endregion documentUrl Prop

}