import { TemplateConfig } from '@rxweb/grid';


export const PAYMENT_REPORT_ACTION_GRID_TEMPLATE: TemplateConfig = {
    div: {
        class: 'formAction list-unstyled mb-0'.split(' '),
        childrens: [{
            a: {
                class: ["gridPinkbtn"],
                authorize:"get",
                event: {
                    click: 'onView'
                },
                childrens: [{
                    a: {
                        childrens: [{
                            i: { class: 'fa fa-eye'.split(' ') }
                        }],
                    }
                }]
            }
        
        }]
    }
};
