import {vProjectRecordBase} from '../database-models/v-project-record-base';
//Generated Imports
export class vProjectRecord extends vProjectRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties











































}