import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vExpertizeSubCategoryBase {

//#region status Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'status', keyColumn: false, headerTitle:'Status', configuredTemplate: { templateName: "recordActive" }})
        status : string;
//#endregion status Prop


//#region expertizeName Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true,isAscending:true, headerKey: 'expertizeName', keyColumn: false, headerTitle:'Expertise'})
        expertizeName : string;
//#endregion expertizeName Prop


//#region expertizeSubCategoryDescription Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'expertizeSubCategoryDescription', keyColumn: false, headerTitle:'Sub Category Description'})
        expertizeSubCategoryDescription : string;
//#endregion expertizeSubCategoryDescription Prop


//#region expertizeSubCategoryName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'expertizeSubCategoryName', keyColumn: false, headerTitle:'Expertise Sub Category'})
        expertizeSubCategoryName : string;
//#endregion expertizeSubCategoryName Prop


//#region expertizeSubCategoryId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'expertizeSubCategoryId', keyColumn: true, headerTitle:'Sub-Category Id'})
        expertizeSubCategoryId : number;
//#endregion expertizeSubCategoryId Prop

}