import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class UserSubscriptionDetailBase {

//#region userSubscriptionDetailsId Prop
        @prop()
        userSubscriptionDetailsId : number;
//#endregion userSubscriptionDetailsId Prop


//#region userId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        userId : number;
//#endregion userId Prop


//#region subscriptionType Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        subscriptionType : number;
//#endregion subscriptionType Prop


//#region subscriptionStartDate Prop
        @required()
        subscriptionStartDate : Date;
//#endregion subscriptionStartDate Prop


//#region subscriptionEndDate Prop
        @required()
        subscriptionEndDate : Date;
//#endregion subscriptionEndDate Prop


//#region paymentSuccessful Prop
        @required()
        paymentSuccessful : boolean;
//#endregion paymentSuccessful Prop



}