import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class MonthlyRetainedOptionBase {

//#region monthlyRetainedOptionId Prop
        @prop()
        monthlyRetainedOptionId : number;
//#endregion monthlyRetainedOptionId Prop


//#region monthlyRetainedOptionName Prop
        @required()
        @maxLength({value:100})
        monthlyRetainedOptionName : string;
//#endregion monthlyRetainedOptionName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop





}