import { Component, OnInit } from '@angular/core';
declare const $: any;
@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.css']
})
export class FooterBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(function() {
      resizeHeightOnWindowResize();
			winSize(); // Call first time resize
    });
  
    function resizeHeightOnWindowResize(){
      $(window).resize(function(){
        winSize();
      });
    }
    function winSize(){
      var navigationHeight = $('header').outerHeight();
      $('.pcoded-main-container').css('padding-top',navigationHeight+15);
    }
  }

  
 
}
