import {QuestionBase} from '../database-models/question-base';
import {OptionDetailBase} from '../database-models/option-detail-base';
import {ProjectDetailBase} from '../database-models/project-detail-base';
//Generated Imports
import { prop } from '@rxweb/reactive-form-validators';
export class Question extends QuestionBase 
{




//#region Generated Reference Properties
//#region optionDetails Prop
optionDetails : OptionDetailBase[];
//#endregion optionDetails Prop
//#region projectDetails Prop
projectDetails : ProjectDetailBase[];
//#endregion projectDetails Prop

//#endregion Generated Reference Properties


































//#region status Prop
@prop()
status : string;
//#endregion status Prop








































}