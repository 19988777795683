import {CountryBase} from '../database-models/country-base';
import {StateBase} from '../database-models/state-base';
import {CityBase} from '../database-models/city-base';
import {UserBase} from '../database-models/user-base';
//Generated Imports
export class Country extends CountryBase 
{

//#region Generated Reference Properties
//#region state Prop
state : StateBase[];
//#endregion state Prop
//#region city Prop
city : CityBase[];
//#endregion city Prop
//#region users Prop
users : UserBase[];
//#endregion users Prop

//#endregion Generated Reference Properties









}