import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CategoryLookupBase {

//#region category Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'category', keyColumn: false})
        category : string;
//#endregion category Prop


//#region applicationObjectId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'applicationObjectId', keyColumn: true})
        applicationObjectId : number;
//#endregion applicationObjectId Prop

}