import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";

import { ROUTING } from './thank-you.routing'
import { ConfirmationModalModule } from '../../shared/confirmation-modal/confirmation-modal.module';
import { SharedModule } from '../../shared/shared.module';
import { SelectionHeaderComponentModule } from '../../shared/section-header/selection-header-module';
import { ThankYouComponent } from './thank-you.component';
import { anonymous } from '@rxweb/angular-router';

@anonymous()
@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule, ConfirmationModalModule,SharedModule,SelectionHeaderComponentModule,
        ROUTING
    ],
    declarations: [ThankYouComponent],
    exports: [RouterModule],
    providers: []
})
export class ThankyouModule { }


