import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class FeedbackDetailBase {

//#region feedbackDetailsId Prop
        @prop()
        feedbackDetailsId : number;
//#endregion feedbackDetailsId Prop


//#region feedbackId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        feedbackId : number;
//#endregion feedbackId Prop


//#region feedbackQuestion Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        feedbackQuestion : number;
//#endregion feedbackQuestion Prop


//#region monthlyRetainedServiceId Prop
        @prop()
        monthlyRetainedServiceId : number;
//#endregion monthlyRetainedServiceId Prop





}