// import { Component, OnInit, Input, OnDestroy } from '@angular/core';
// import { RxFormBuilder } from '@rxweb/reactive-form-validators';
// import { Subscription } from 'rxjs';

// @Component({
//   selector: 'note-modalview',
//   templateUrl: './note-modalview.component.html'  
// })
// export class NoteModalViewComponent implements OnInit, OnDestroy  {
//   subscription: Subscription;
//   @Input() vendorId: number;
//   constructor(private formBuilder: RxFormBuilder) {
//     //console.log('Modal constructor called');
//   }

//   ngOnInit() {
//   }

//   ngOnDestroy(): void {
//     if (this.subscription) {
//         this.subscription.unsubscribe();
//     }
//   }

//   addNote(){
    
//   }

// }
import { Component, ElementRef, ViewContainerRef } from '@angular/core';
import { ModalView } from 'src/app/domain/customize-design/modal';

@Component({
    selector: 'app-popup',
    template: '',
})
export class AppPopupComponent {
    constructor(
        public viewContainerRef: ViewContainerRef,
        public modalView: ModalView,
        elementRef: ElementRef
    ) {
        this.modalView.viewContainerRef = viewContainerRef;
        this.modalView.element = elementRef.nativeElement;
    }
}

