import {ApplicationLocaleBase} from '../database-models/application-locale-base';
//Generated Imports
export class ApplicationLocale extends ApplicationLocaleBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties



























}