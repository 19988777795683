import { TemplateConfig } from '@rxweb/grid';

export const HEADER_NAME_BACKGROUND: TemplateConfig = {
    // div: {
    //     class: [
    //         function (e) {
    //             return e.name ? "table-collapse" : ' ';
    //         }],
    //     childrens: [{
    //         text: {
    //             text: function (e) { return e.name }
    //         },
    //     }]
    // },
    // label: {
    //     class: [
    //         function (e) {
    //             return this[e.name] ? "mb-0 badge sucess".split(" ") : 'mb-0 badge sucess'.split(" ");
    //         }],
    //     childrens: [{
    //         text: {
    //             text: function (e) { return this[e.name] }
    //         }
    //     }]

    // }
    // div: {
    //     class: [
    //         function (e) {
    //             return "divTableHeading".split(" ");
    //         }],
    //     childrens: [{
    //         div: {
    //             class: [
    //                 function (e) {
    //                     return "divTableHead".split(" ");
    //                 }],
    //             attributes: {
    //                 'data-head': 'status'
    //             },
    //             childrens: [{
    //                 div: {
    //                     class: [
    //                         function (e) {
    //                             return "d-flex justify-content-between".split(" ");
    //                         }]
    //                     ,
    //                     childrens: [{
    //                         span: {
    //                             childrens: [{
    //                                 text: {
    //                                     text: function (e) { return e.name }
    //                                 }
    //                             }]
    //                         }
    //                     }]
    //                 }
    //             }]
    //         }

    //     }]
    // }
    // div: {
    //     class: [
    //         function (e) {
    //             return "divTableCell".split(" ");
    //         }],
    //     attributes: {
    //         'data-head': 'status'
    //     }
    //     ,
    //     childrens: [{
    //         label: {
    //             class: [
    //                 function (e) {
    //                     return this[e.name] ? "mb-0 badge sucess".split(" ") : 'mb-0 badge sucess'.split(" ");
    //                 }],
    //             childrens: [{
    //                 text: {
    //                     text: function (e) { return this[e.name] }
    //                 }
    //             }]
    //         }
    //     }]
    // }
    div: {
        class: [
            function (e) {
                return "divTableCell".split(" ");
            }],
        attributes: {
            'data-head': function (e) { return e.headerTitle; }
        }
        ,
        childrens: [{
            label: {
                class: [
                    function (e) {
                        return this[e.name] ? "mb-0 badge sucess".split(" ") : 'mb-0 badge sucess'.split(" ");
                    }],
                childrens: [{
                    text: {
                        text: function (e) { return this[e.name] }
                    }
                }]
            }
        }]
    }

}
