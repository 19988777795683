import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSubscriptionTypeBase {

//#region subscriptionTypeId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'subscriptionTypeId', keyColumn: true})
        subscriptionTypeId : number;
//#endregion subscriptionTypeId Prop


//#region subscriptionTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'subscriptionTypeName', keyColumn: false, headerTitle: "Subscription Type"})
        subscriptionTypeName : string;
//#endregion subscriptionTypeName Prop


//#region subscriptionTypeDescription Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: false, headerKey: 'subscriptionTypeDescription', keyColumn: false, headerTitle: "Description"})
        subscriptionTypeDescription : string;
//#endregion subscriptionTypeDescription Prop


//#region validMonths Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'validMonths', keyColumn: false, headerTitle: "Valid Months"})
        validMonths : any;
//#endregion validMonths Prop


//#region status Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'status', keyColumn: false, headerTitle: "Status", configuredTemplate: { templateName: "recordActive" }})
        status : string;
//#endregion status Prop

}