import {vCityBase} from '../database-models/v-city-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';

@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'action' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vCity extends vCityBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}