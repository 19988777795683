import {vExpertisePlanBase} from '../database-models/v-expertise-plan-base';
//Generated Imports
export class vExpertisePlan extends vExpertisePlanBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties


}