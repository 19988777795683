import { TemplateConfig } from '@rxweb/grid';

export const SORTING_BY_HEADER_GRID_TEMPLATE: TemplateConfig = {
    // div: {
    //     childrens: [
    //         // {
    //         //     a: {
    //         //         event: {
    //         //             click: "onFilterClick"
    //         //         },
    //         //         childrens: [
    //         //             {
    //         //                 span: {
    //         //                     childrens: [
    //         //                         {
    //         //                             i: {
    //         //                                 class: [
    //         //                                     function (e) {
    //         //                                         return 'icon-filter icon'.split(" ");
    //         //                                     }],
    //         //                             }
    //         //                         }
    //         //                     ]
    //         //                 }
    //         //             }]
    //         //     },
    //         // },
    //         {
    //             a: {
    //                 event: {
    //                     click: "onSortingClick"
    //                 },
    //                 childrens: [
    //                     {
    //                         i: {
    //                             class: [
    //                                 function (e) {
    //                                     return 'fa fa-sort'.split(" ");
    //                                 }],
    //                         }
    //                     }]
    //             },
    //         }
    //     ],
    // }
    div: {
        class: [
            function (e) {
                return "divTableCell".split(" ");
            }],
        attributes: {
            'data-head': function (e) { return e.headerTitle; }
        }
        ,
        childrens: [{
            div: {
                childrens: [
                    // {
                    //     a: {
                    //         event: {
                    //             click: "onFilterClick"
                    //         },
                    //         childrens: [
                    //             {
                    //                 span: {
                    //                     childrens: [
                    //                         {
                    //                             i: {
                    //                                 class: [
                    //                                     function (e) {
                    //                                         return 'icon-filter icon'.split(" ");
                    //                                     }],
                    //                             }
                    //                         }
                    //                     ]
                    //                 }
                    //             }]
                    //     },
                    // },
                    {
                        a: {
                            event: {
                                click: "onSortingClick"
                            },
                            childrens: [
                                {
                                    i: {
                                        class: [
                                            function (e) {
                                                return 'fa fa-sort'.split(" ");
                                            }],
                                    }
                                }]
                        },
                    }
                ],
            }
        }]
    }

};
