import { TemplateConfig } from '@rxweb/grid';
import { PipeConstants } from 'src/app/components/shared/constant/commonConstants';


export const ACCEPT_ACTION_RFQ_GRID_TEMPLATE: TemplateConfig = {
    div: {
        class: 'formAction list-unstyled mb-0'.split(' '),
        childrens: [{
            a: {
                class: [function (e) {
                    
                    if (this[e.name] != 0 && this[e.name] != "") {
                        return "message_notify";
                    }
                    else{
                        return "gridBluebtn";
                    }
                }],
                event: {
                    click: 'onMesaageView'
                },
                childrens: [{
                    i: { class: 'fas fa-comment-dots'.split(' ') },
                    span:{
                      childrens:[{
                        text: {
                          text: function (e) {               
                            return (this[e.name] == 0 ? "" : this[e.name]);
                          }}
                      }]
                    }
                },
                ]
            }
        },
        {
            a: {
                class: [
                    function (e) {
                        if (this.projectRFQStatus != "Pending" && this.projectRFQStatus!="Interested") {
                            return "disabledgridbtn";
                        }
                        else {
                            return "gridEditbtn";
                        }
                    }
                ],
                event: {
                    click: 'onApprove'
                },
                childrens: [{
                    a: {

                        childrens: [{
                            i: { class: 'fas fa-check'.split(' ') }
                        }],
                    }
                },
                ]
            }

        },
        {
            a: {
                class: [
                    function (e) {
                        if (this.projectRFQStatus != "Pending" && this.projectRFQStatus!="Interested") {
                            return "disabledgridbtn";
                        }
                        else {
                            return "gridDeletebtn";
                        }
                    }
                ],
                event: {
                    click: 'onReject'
                },
                childrens: [{
                    a: {
                        childrens: [{
                            i: { class: 'fas fa-times'.split(' ') }
                        }],
                    }
                }]
            }

        }
        ]
    }
};
