import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LinkedInConfigurationBase {

//#region linkedInConfigurationId Prop
        @prop()
        linkedInConfigurationId : number;
//#endregion linkedInConfigurationId Prop


//#region clientID Prop
        @required()
        @maxLength({value:50})
        clientID : string;
//#endregion clientID Prop


//#region secretKey Prop
        @required()
        @maxLength({value:50})
        secretKey : string;
//#endregion secretKey Prop


//#region redirectUrl Prop
        @required()
        redirectUrl : string;
//#endregion redirectUrl Prop


//#region isActive Prop
        @required()
        isActive : boolean;
//#endregion isActive Prop

}