import {UploadAttachmentBase} from '../database-models/upload-attachment-base';
//import { States } from 'src/app/components/master/upload-attachments/edit/upload-attachment-edit.component';
//Generated Imports
export class UploadAttachment extends UploadAttachmentBase 
{




//#region Generated Reference Properties
stateArray: any;
attachments: any;
//#endregion Generated Reference Properties

}