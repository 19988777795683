import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractPublicRFP } from '../domain/abstract-public-r-f-p';
import { PublicRFP, vPublicRFP } from "@app/models";
import { Subscription } from 'rxjs';
import { Router } from "@angular/router";
import { CommonHelperService } from "src/app/components/shared/common-helpers/helper.service";
import { AppGrid } from "src/app/domain/app-grid";
import { ModalView } from "src/app/domain/customize-design/modal";
import { RoutingKeys, ToolTipText } from "src/app/components/shared/constant/commonConstants";
import { ConfirmationModalComponent } from "src/app/components/shared/confirmation-modal/confirmation-modal.component";
import { CustomMessage } from "src/app/components/shared/constant/custom-message";
import { RolesEnum } from "src/app/enums/roles-enum";
import { access } from "@rxweb/angular-router";
import { ApplicationModules, PermissionNames } from "src/app/enums/application-modules.enum";
import { PublicRFPAddComponent } from "../add/public-r-f-p-add.component";
import { vPublicRfpHrc } from "src/app/models/extended-models/v-public-rfp-hrc";
import { SpParameter, PageDataQuery, SearchQuery, Query } from 'src/app/viewModel/sp-parameter';
import { ModelComponentType, ModelResponse } from "src/app/components/shared/constant/common-constants";
declare const $: any;

@access({ accessLevel: ApplicationModules.PublicRFP, action: PermissionNames.Get })

@Component({
    selector: "app-public-r-f-p-list",
    templateUrl: './public-r-f-p-list.component.html'
})
export class PublicRFPListComponent extends AbstractPublicRFP implements OnInit, OnDestroy {
    publicRFPAdd: any = PublicRFPAddComponent;
    publicRFP: List<PublicRFP>;
    subscription: Subscription;
    publicRFPGrid: AppGrid;
    publicRFPGridColumns: any[];
    searchText = '';
    modalView: ModalView;
    isAdmin: boolean = false;
    hRCRole = RolesEnum.HRConsultant;
    totalRecordsPerPage = 10;
    pageIndex = 1;
    isGridBound = true;
    orderByColumn = 'publicRFPId';
    sortOrder = 'desc';
    constructor(
        private route: Router,
        private commonHelperService: CommonHelperService,
        modalView: ModalView
    ) {
        super();
        this.modalView = modalView;
    }

    ngOnInit(): void {
        this.initializeGrid();
    }
    

    initializeGrid(page: Number = 0) {
        page = page == 0 ? 0 : this.pageIndex;
        const jsonToSend = this.getJsonToSend();
        let sendJson = new Query();
        sendJson.query = JSON.stringify(jsonToSend);
        if (this.isGridBound && !this.commonHelperService.isObjectNullOrUndefined(this.publicRFPGrid)) {
            this.publicRFPGrid.clearGrid('publicRFP');
        }
        this.spin = true;
        this.showComponent = true;
        if (this.user.roleId == RolesEnum.SuperAdmin) {
            this.isAdmin = true;
        }


        this.subscription = this.getRFP(sendJson).subscribe((publicRFPList: any) => {

            // publicRFPList.forEach(element => {
            //     if (element.publicRFPCategory == null) {
            //         element.publicRFPCategory = "NA";
            //     }
            // });
            var data = JSON.parse(publicRFPList[0].result);

            const parsedData =data[0].result;
            const gridData = parsedData.data;
            const totalRecords = parsedData.footer[0].totalCount;
            if (page == 0) {
                if (this.user.roleId == RolesEnum.SuperAdmin) {
                    
                    this.publicRFPGrid = new AppGrid(gridData, vPublicRFP
                        , {
                            actions: {
                                onEdit: this.onEdit.bind(this),
                                onDelete: this.onDelete.bind(this),
                                // onPublicRFPLink: this.onPublicRFPLink.bind(this)
                            }
                        }
                    );
                }
                else if (this.user.roleId == RolesEnum.HRConsultant) {
                    this.publicRFPGrid = new AppGrid(gridData, vPublicRfpHrc
                        , {
                            actions: {
                                onPublicRFP: this.onPublicRFP.bind(this),
                                onPublicRFPLink: this.onPublicRFPLink.bind(this)
                            }
                        }
                    );
                }
                this.publicRFPGrid.authorization = {
                    'put': { accessLevel: ApplicationModules.PublicRFP, action: PermissionNames.Put },
                    'delete': { accessLevel: ApplicationModules.PublicRFP, action: PermissionNames.Delete }
                }
                this.publicRFPGrid.storeProcedure = {
                    length: totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    onPageSorting: this.onPageSorting.bind(this),
                    nextPage: this.pageIndex
                };
                if (this.isGridBound) {
                    this.publicRFPGrid.design(document.getElementById('publicRFP'));
                    this.isGridBound = false;
                }
                // this.publicRFPGrid.design(document.getElementById('publicRFP'));
                // this.publicRFPGridColumns = this.publicRFPGrid.gridColumns.filter(x => x.keyColumn === false);
                // this.publicRFPGrid.search = this.searchText;
            }
            else {
                if (this.isGridBound) {
                    this.publicRFPGrid.design(document.getElementById('feedbacks'));
                    this.isGridBound = false;
                }
                this.publicRFPGrid.storeProcedure = {
                    length: totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: this.pageIndex,
                    onPageSorting: this.onPageSorting.bind(this)
                }
                this.publicRFPGrid.updateSource(gridData);
    
            }
            this.spin = false;
            this.addTooltip();
        });

    }

    getJsonToSend(): SpParameter {
        const pageQuery = new PageDataQuery();
        pageQuery.PageRow = this.totalRecordsPerPage;
        pageQuery.PageIndex = this.pageIndex;
        pageQuery.OrderColumn = this.orderByColumn;
        pageQuery.OrderType = this.sortOrder;

        const jsonObject = new SpParameter();
        jsonObject.searchQuery = new SearchQuery();
        jsonObject.searchQuery.searchText = this.searchText;
        jsonObject.pageQuery = JSON.stringify(pageQuery);
        jsonObject.searchQuery = JSON.stringify(jsonObject.searchQuery);
        return jsonObject;
    }

    addTooltip() {
        $(document).ready(function () {
            $(".gridEditbtn").hover(function () {
                $(this).attr("title", ToolTipText.EditPublicRFP);
            })
            $(".gridDeletebtn").hover(function () {
                $(this).attr("title", ToolTipText.DeletePublicRFP);
            })
            $(".urlLinkClass").hover(function () {
                $(this).attr("title", ToolTipText.VisitPublicRFPUrl);
            })
        })
    }

    onEdit(publicRFP: PublicRFP) {
        this.route.navigate([RoutingKeys.PublicRFP, publicRFP.publicRFPId]);
    }

    onDelete(publicRFP: PublicRFP) {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Delete }).then(t => {
            if (t == ModelResponse.Yes) {
                this.spin = true;
                this.delete({ params: [publicRFP.publicRFPId], body: null }).subscribe(response => {
                    this.spin = false;
                    this.toastr.success(CustomMessage.dataDeleted);
                    this.isGridBound = true;
                    this.initializeGrid();
                })
            }
        })
    }

    onPublicRFPLink(publicRFP: PublicRFP) {
        let url: string = '';
        if (!/^http[s]?:\/\//.test(publicRFP.url)) {
            url += 'http://';
        }
        url += publicRFP.url
        window.open(url, "_blank")
    }
    onPublicRFP(publicRFP: PublicRFP) {
        let url: string = '';
        if (!/^http[s]?:\/\//.test(publicRFP.url)) {
            url += 'http://';
        }
        url += publicRFP.url
        window.open(url, "_blank")
    }
    search(value) {
        this.publicRFPGrid.search = value;
        this.searchText = this.publicRFPGrid.search;
    }

    onPageChanging(nextPage: number) {
        this.totalRecordsPerPage = this.publicRFPGrid.maxPerPage;
        this.pageIndex = nextPage;
        //this.isGridBound = true;
        this.initializeGrid(this.pageIndex);
    }

    onPageSorting(columnName: string, isAscendingOrder: boolean, currentPage: number) {
        this.orderByColumn = columnName;
        this.sortOrder = isAscendingOrder ? 'asc' : 'desc';
        //this.isGridBound = true;
        this.pageIndex = 1;
        this.initializeGrid(1);
    }

   
    addPublicRFP() {
        this.route.navigate([RoutingKeys.AddPublicRFP]);
    }

    openTips() {
        let msg = new Array<any>();
        msg[0] = 'Public RFPs that match your skills are listed here, select the link to view and participate in the RFP.'
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.PopupMessage, params: msg }).then(t => {
        })
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

}
