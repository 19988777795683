import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractRole } from '../domain/abstract-role';
import { Role, vRole } from "@app/models";
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CommonHelperService } from 'src/app/components/shared/common-helpers/helper.service';
import { AppGrid } from 'src/app/domain/app-grid';
import { RoutingKeys, ToolTipText } from 'src/app/components/shared/constant/commonConstants';
import { ApplicationModules, PermissionNames } from 'src/app/enums/application-modules.enum';
import { access } from '@rxweb/angular-router';
declare const $: any;

@access({ accessLevel: ApplicationModules.RoleList, action: PermissionNames.Get })

@Component({
    selector: "app-role-list",
    templateUrl: './role-list.component.html'
})
export class RoleListComponent extends AbstractRole implements OnInit, OnDestroy {
    roles: List<Role>;
    subscription: Subscription;
    roleGrid: AppGrid;
    roleGridColumns: any[];
    searchText = '';

    constructor(
        private route: Router,
        private commonHelperService: CommonHelperService
    ) {
        super();
    }

    ngOnInit(): void {
        this.initializeGrid();
    }

    initializeGrid() {
        if (!this.commonHelperService.isObjectNullOrUndefined(this.roleGrid)) {
            this.roleGrid.clearGrid('roles');
        }
        this.spin = true;
        this.showComponent = true;
        this.subscription = this.get().subscribe((roleList: any) => {
            this.spin = false;
            this.roleGrid = new AppGrid(roleList, vRole
                , {
                    actions: {
                        onEdit: this.onEdit.bind(this),
                    }
                }
            );
            this.roleGrid.authorization={
                'put':{accessLevel: ApplicationModules.RoleList, action: PermissionNames.Put}
            }
            this.roleGrid.design(document.getElementById('roles'));
            this.roleGridColumns = this.roleGrid.gridColumns.filter(x => x.keyColumn === false);
            this.roleGrid.search = this.searchText;
            this.addTooltip();
        });

    }

    search(value) {
        this.roleGrid.search = value;
        this.searchText = this.roleGrid.search;
    }

    addRole(){
        this.route.navigate([RoutingKeys.AddRole]);
    }

    onEdit(role: Role) {
        this.route.navigate([RoutingKeys.RolesPermission, role.roleId]);
    }

    addTooltip() {
        $(document).ready(function () {
            $(".gridEditbtn").hover(function () {
                $(this).attr("title", ToolTipText.EditRole);
            })         
        })
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

}
