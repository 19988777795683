import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractEmailNotification } from '../domain/abstract-email-notification';
import { EmailNotification, vEmailNotificationRecord } from "@app/models";
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CommonHelperService } from 'src/app/components/shared/common-helpers/helper.service';
import { AppGrid } from 'src/app/domain/app-grid';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { MessagePreviewModal } from 'src/app/components/project/messages/modal/message-preview.component';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { StatusEnum } from 'src/app/enums/status.enum';
import { ConfirmationModalModule } from 'src/app/components/shared/confirmation-modal/confirmation-modal.module';
import { RxHttp } from '@rxweb/http';
import { RoutingKeys, ToolTipText } from 'src/app/components/shared/constant/commonConstants';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { ApplicationBroadcaster } from "src/app/broadcast-services/application-broadcaster";
import { UserAuthenticateData } from "src/app/models/client-models/login.classes";
import { PageDataQuery, Query, SearchQuery, SpParameter } from "src/app/viewModel/sp-parameter";
import { ModelComponentType, ModelResponse } from "src/app/components/shared/constant/common-constants";
import { API } from "src/app/components/shared/constant/administrationAPIContants";
declare const $: any;

@Component({
    selector: "app-email-notification-list",
    templateUrl: './email-notification-list.component.html'
})
export class EmailNotificationListComponent extends AbstractEmailNotification implements OnInit, OnDestroy {
    emailNotification: List<EmailNotification>;
    subscription: Subscription;
    emailNotificationsGrid: AppGrid;
    emailNotificationsGridColumns: any[];
    searchText = '';
    modalView: ModalView;
    is0Notification: boolean;
    emailNotificationsCount: number;
    totalRecordsPerPage = 10;
    pageIndex = 1;
    orderByColumn = 'CreatedOn';
    sortOrder = 'desc';
    isGridBound = true;

    constructor(
        private route: Router,
        private commonHelperService: CommonHelperService,
        private applicationBroadCaster: ApplicationBroadcaster,
        modalView: ModalView,
        public formBuilder: RxFormBuilder,
        private http: RxHttp
    ) {
        super();
        this.modalView = modalView;
        this.applicationBroadCaster.emailNotificationsSubscriber.subscribe(t => {
            this.emailNotificationsCount = t;
        });
    }

    ngOnInit(): void {
        this.initializeGrid();
    }

    initializeGrid() {
        this.http.get<UserAuthenticateData>({ path: API.userDetails }).subscribe(response => {
            this.applicationBroadCaster.emailNotificationsCount(response.emailNotificationCount);
        });
        const jsonToSend = this.getJsonToSend();
        if (this.isGridBound && !this.commonHelperService.isObjectNullOrUndefined(this.emailNotificationsGrid)) {
            this.emailNotificationsGrid.clearGrid('emailNotifications');
        }
        this.spin = true;
        this.showComponent = true;
        let sendJson = new Query();
        sendJson.query = JSON.stringify(jsonToSend);
        this.subscription = this.getEmailNotifications(sendJson).subscribe((emailNotificationList: any) => {
            this.spin = false;
            this.bindGrid(emailNotificationList);
        }, () => {
            this.spin = false;
        });
        // if (!this.commonHelperService.isObjectNullOrUndefined(this.emailNotificationsGrid)) {
        //     this.emailNotificationsGrid.clearGrid('emailNotifications');
        // }
        // this.spin = true;
        // this.showComponent = true;
        // this.subscription = this.get().subscribe((emailNotificationList: any) => {
        //     this.spin = false;
        //     var emailNotificationResponseList = JSON.parse(emailNotificationList[0].result);
        //     emailNotificationResponseList = emailNotificationResponseList[0].result.data;
        //     if (emailNotificationResponseList.length == 0) this.is0Notification = true;
        //     else this.is0Notification = false;
        //     this.emailNotificationsGrid = new AppGrid(emailNotificationResponseList, vEmailNotificationRecord
        //         , {
        //             actions: {
        //                 onView: this.onView.bind(this),
        //                 onDelete: this.onDelete.bind(this),
        //                 onSubjectLink: this.onSubjectLink.bind(this)
        //                 //  onLink: this.onLink.bind(this)
        //             }
        //         }
        //     );
        //     this.emailNotificationsGrid.design(document.getElementById('emailNotifications'));
        //     this.emailNotificationsGridColumns = this.emailNotificationsGrid.gridColumns.filter(x => x.keyColumn === false);
        //     this.emailNotificationsGrid.search = this.searchText;
        // });
    }

    getJsonToSend(): SpParameter {
        const pageQuery = new PageDataQuery();
        pageQuery.PageRow = this.totalRecordsPerPage;
        pageQuery.PageIndex = this.pageIndex;
        pageQuery.OrderColumn = this.orderByColumn;
        pageQuery.OrderType = this.sortOrder;

        const jsonObject = new SpParameter();
        jsonObject.searchQuery = new SearchQuery();
        jsonObject.searchQuery.searchText = this.searchText;
        jsonObject.pageQuery = JSON.stringify(pageQuery);
        jsonObject.searchQuery = JSON.stringify(jsonObject.searchQuery);
        return jsonObject;
    }

    bindGrid(data: any) {
        const parsedData = JSON.parse(data)[0].result;
        const gridData = parsedData.data;
        if (gridData.length == 0) this.is0Notification = true;
        else this.is0Notification = false;
        const totalRecords = parsedData.footer[0].totalCount;
        if (gridData.length > 0) {
            if (this.isGridBound) {
                this.emailNotificationsGrid = new AppGrid(gridData, vEmailNotificationRecord
                    , {
                        actions: {
                            onView: this.onView.bind(this),
                            onDelete: this.onDelete.bind(this),
                            onSubjectLink: this.onSubjectLink.bind(this)
                            //  onLink: this.onLink.bind(this)
                        }
                    });
            }
            this.emailNotificationsGrid.storeProcedure = {
                length: totalRecords,
                onPageChanging: this.onPageChanging.bind(this),
                onPageSorting: this.onPageSorting.bind(this),
                nextPage: this.pageIndex
            };

            this.emailNotificationsGrid.updateSource([]);

            if (this.isGridBound) {
                this.emailNotificationsGrid.design(document.getElementById('emailNotifications'));
                this.isGridBound = false;
            }
            this.emailNotificationsGrid.updateSource(gridData);
            this.addTooltip();
        }
    }

    onPageChanging(nextPage: number) {
        this.totalRecordsPerPage = this.emailNotificationsGrid.maxPerPage;
        this.pageIndex = nextPage;
        this.initializeGrid();
    }

    onPageSorting(columnName: string, isAscendingOrder: boolean, currentPage: number) {
        this.orderByColumn = columnName;
        this.sortOrder = isAscendingOrder ? 'asc' : 'desc';
        this.pageIndex = 1;
        this.initializeGrid();
    }

    addTooltip() {
        $(document).ready(function () {
            $(".gridEditbtn").hover(function () {
                $(this).attr("title", ToolTipText.ViewEmailNotification);
            })
            $(".gridinfobttn").hover(function () {
                $(this).attr("title", ToolTipText.ViewEmailNotification);
            })
            $(".gridDeletebtn").hover(function () {
                $(this).attr("title", ToolTipText.DeleteEmailNotification);
           })           
        })
    }

    onView(emailNotification: EmailNotification) {
        this.get({ params: [emailNotification.emailNotificationId] }).subscribe((t: any) => {
            this.emailNotificationFormGroup = this.formBuilder.formGroup(EmailNotification, t[0]) as IFormGroup<EmailNotification>;

            this.emailNotificationFormGroup.controls.statusId.setValue(StatusEnum.Active);
            this.emailNotificationFormGroup.controls.isPreview.setValue(true);
            this.emailNotificationFormGroup.controls.createdBy.setValue(t[0].userId);
            this.emailNotificationFormGroup.controls.createdOn.setValue(t[0].createdOn);
            this.put({ body: this.emailNotificationFormGroup.value, params: [t[0].emailNotificationId] }).subscribe(data => {
                let date = new Date(t[0].createdOn);
                let createdDate = (date.toLocaleString('default', { month: 'short' })) + ' ' + ('0' + date.getDate()).slice(-2) + ', ' + date.getFullYear() + ' | ' + (((date.getHours() < 10 ? '0' : '') + date.getHours()) + ':' + ((date.getMinutes() < 10 ? '0' : '') + date.getMinutes()) + ':' + ((date.getSeconds() < 10 ? '0' : '') + date.getSeconds()));
                let stringArray = new Array<string>();
                stringArray.push(createdDate)
                stringArray.push(t[0].subject)
                stringArray.push(t[0].templateBody)
                this.initializeGrid();
                this.modalView.show(MessagePreviewModal, { componentType: ModelComponentType.EmailNotificationModal, params: stringArray }).then(t => {
                });
            })
        })
    }

    onSubjectLink(emailNotification: any) {
        if (emailNotification.emailDetailId == 24)
            this.route.navigateByUrl("contracts");

        else if (emailNotification.emailDetailId == 25)
            this.route.navigateByUrl("project-r-f-q-response");

    }

    onDelete(emailNotification: EmailNotification) {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Delete }).then(t => {
            this.spin = true
            if (t == ModelResponse.Yes) {
                this.delete({ body: null, params: [emailNotification.emailNotificationId] }).subscribe(data => {
                    this.toastr.success("Email notification deleted successfully.");
                    this.isGridBound = true;
                    this.initializeGrid();
                    this.spin = false;
                })
            }
            this.spin = false;
        });
    }

    markAsRead() {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.MarkAsRead }).then(t => {
            this.spin = true;
            if (t == ModelResponse.Yes) {

                let userId = this.user.userId;
                this.spin = true;
                this.http.post<any>({ path: API.markAsReadEmailNotification, body: userId }).subscribe((t: any) => {
                    this.toastr.success(CustomMessage.MarkAllNotificationAsRead);
                    this.isGridBound = true;
                    this.initializeGrid();
                    this.spin = false;
                });
            }
            this.spin = false;
        });
    }

    deleteAll() {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.DeleteAll }).then(t => {
            this.spin = true;
            if (t == ModelResponse.Yes) {
                this.spin = true;
                let userId = this.user.userId;

                this.http.post<any>({ path: API.deleteAllEmailNotification, body: userId }).subscribe((t: any) => {
                    this.toastr.success(CustomMessage.DeleteAll);
                    this.isGridBound = true;
                    this.initializeGrid();
                    this.spin = false;
                });
            }
            this.spin = false;
        });
    }

    search(value) {
        this.emailNotificationsGrid.search = value;
        this.searchText = this.emailNotificationsGrid.search;
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

}
