import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class RegisterExpertizeSubCategoryLookupBase {

//#region expertizeSubCategoryId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'expertizeSubCategoryId', keyColumn: true})
        expertizeSubCategoryId : number;
//#endregion expertizeSubCategoryId Prop


//#region expertizeSubCategoryName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'expertizeSubCategoryName', keyColumn: false})
        expertizeSubCategoryName : string;
//#endregion expertizeSubCategoryName Prop


//#region expertizeId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'expertizeId', keyColumn: false})
        expertizeId : number;
//#endregion expertizeId Prop

}