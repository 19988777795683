import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHrcProjectDetailBase {

//#region userId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'userId', keyColumn: true})
        userId : number;
//#endregion userId Prop


//#region projectId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'projectId', keyColumn: false})
        projectId : number;
//#endregion projectId Prop


//#region projectName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'projectName', keyColumn: false})
        projectName : string;
//#endregion projectName Prop


//#region projectOptions Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'projectOptions', keyColumn: false})
        projectOptions : string;
//#endregion projectOptions Prop


//#region expertize Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'expertize', keyColumn: false})
        expertize : string;
//#endregion expertize Prop

}