import { Injectable } from '@angular/core';
import { IMiddleware } from '@rxweb/angular-router'
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserStorage } from '../../services/browser-storage';
import { RoutingKeys, StorageKeys } from 'src/app/components/shared/constant/commonConstants';

@Injectable({
    providedIn: 'root',
})
export class LoggedInMiddleware implements IMiddleware {
    constructor(private router: Router, private storage: BrowserStorage) { }
    invoke(user: { [key: string]: any; }, activateRouteSnapshot: any): boolean | Promise<boolean> {
        var auth = this.storage.local.get(StorageKeys.Authentication,false);
        if (auth)
            this.router.navigate([RoutingKeys.Dashboard]);
        return true;
    }
}
