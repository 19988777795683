import {vPublicRFPSendMailBase} from '../database-models/v-public-r-f-p-send-mail-base';
//Generated Imports
export class vPublicRFPSendMail extends vPublicRFPSendMailBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}