import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vQuestionOptionListBase {

//#region questionId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'questionId', keyColumn: true})
        questionId : number;
//#endregion questionId Prop


//#region questionText Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'questionText', keyColumn: false})
        questionText : string;
//#endregion questionText Prop


//#region questionType Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'questionType', keyColumn: false})
        questionType : number;
//#endregion questionType Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region options Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'options', keyColumn: false})
        options : string;
//#endregion options Prop

}