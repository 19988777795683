import { Injectable } from '@angular/core';
import { IAuthResolver, CoreComponent } from '@rxweb/angular-router'
import { RxHttp } from '@rxweb/http';
import { BrowserStorage } from '../services/browser-storage';
import { UserAuthenticateData } from 'src/app/models/client-models/login.classes';
import { ApplicationBroadcaster } from 'src/app/broadcast-services/application-broadcaster';
import { API } from 'src/app/components/shared/constant/administrationAPIContants';

@Injectable({
    providedIn: 'root',
})
export class AuthResolver extends CoreComponent implements IAuthResolver {

    constructor(private http: RxHttp,private storagedata: BrowserStorage,private applicationBroadCaster : ApplicationBroadcaster) {
        super();
        this.storagedata = new BrowserStorage();
    }
    
    resolveAuth(): Promise<{ [key: string]: any; }> | { [key: string]: any; } {
        var promise = new Promise<{ [key: string]: any; }>((resolve, reject) => {
            var auth = this.storagedata.local.get("auth",false);
            if (auth) {
                this.http.get<UserAuthenticateData>({ path: API.userDetails}).subscribe(response => {
                    resolve(response);
                    this.applicationBroadCaster.emailNotificationsCount(response.emailNotificationCount);
                })
                this.get({ path: API.quoteMessageCount }).subscribe((t:any) => {
                    this.applicationBroadCaster.quoteMessagesCount(t);
                })
            }
        })
        return promise;
    }
}
