import {MonthlyRetainedServiceBase} from '../database-models/monthly-retained-service-base';
import {MonthlyRetainedOptionBase} from '../database-models/monthly-retained-option-base';
import {FeedbackDetailBase} from '../database-models/feedback-detail-base';
//Generated Imports
export class MonthlyRetainedService extends MonthlyRetainedServiceBase 
{




//#region Generated Reference Properties
//#region monthlyRetainedOption Prop
monthlyRetainedOption : MonthlyRetainedOptionBase;
//#endregion monthlyRetainedOption Prop
//#region feedbackDetails Prop
feedbackDetails : FeedbackDetailBase[];
//#endregion feedbackDetails Prop

//#endregion Generated Reference Properties














































































}