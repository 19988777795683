import { vAllProject } from './../../../../../models/extended-models/v-all-project';
import { RolesEnum } from 'src/app/enums/roles-enum';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { Component, OnInit, OnDestroy } from "@angular/core";
import { OptionDetail, Project, vProjectList } from "@app/models";
import { Subscription } from 'rxjs';
import { RoutingKeys, StorageKeys, ToolTipText } from 'src/app/components/shared/constant/commonConstants';
import { Router } from '@angular/router';
import { AppGrid } from 'src/app/domain/app-grid';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { CommonHelperService } from 'src/app/components/shared/common-helpers/helper.service';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { ApplicationModules, PermissionNames } from 'src/app/enums/application-modules.enum';
import { access } from '@rxweb/angular-router';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { AbstractHRConsultant } from '../../domain/abstract-hr-consultant';
import { PageDataQuery, Query, SearchQuery, SpParameter } from "src/app/viewModel/sp-parameter";
import { API } from 'src/app/components/shared/constant/administrationAPIContants';

declare const $: any;

@access({ accessLevel: ApplicationModules.AllProjects, action: PermissionNames.Get })

@Component({
    selector: "app-all-project-list",
    templateUrl: './all-project-list.component.html'
})
export class AllProjectListComponent extends AbstractHRConsultant implements OnInit, OnDestroy {
    subscription: Subscription;
    optionArray: OptionDetail[];
    projectGrid: AppGrid;
    projectGridColumns: any[];
    searchText = '';
    modalView: ModalView;
    roleId: number;
    storage: BrowserStorage;
    isRole: boolean = true;
    totalRecordsPerPage = 10;
    pageIndex = 1;
    orderByColumn = 'CreatedOn';
    sortOrder = 'desc';
    isGridBound = true;

    constructor(private route: Router,
        private commonHelperService: CommonHelperService) {
        super()
        this.storage = new BrowserStorage();
    }

    ngOnInit(): void {
        this.roleId = this.storage.local.get(StorageKeys.UserRole);
        if (RolesEnum.HRO == this.roleId) {
            this.isRole = false;
        }
        this.initializeGrid();
    }
    initializeGrid() {

        if (this.isGridBound && !this.commonHelperService.isObjectNullOrUndefined(this.projectGrid)) {
            this.projectGrid.clearGrid('project');
        }

        const jsonToSend = this.getJsonToSend();
        this.spin = true;
        this.showComponent = true;
        
        let sendJson = new Query();
        sendJson.query = JSON.stringify(jsonToSend);
        this.subscription = this.post({ body: sendJson,path: API.allProjectList }).subscribe((projectList: any) => {
            
            
            this.spin = false;
            this.bindGrid(projectList);
        }, () => {
            this.spin = false;
        });
            
    }

    bindGrid(data: any){
        const parsedData = JSON.parse(data)[0].result;
        const gridData = parsedData.data;
        const totalRecords = parsedData.footer[0].totalCount;

        if (this.isGridBound) {
            this.projectGrid = new AppGrid(gridData, vAllProject, {
                actions: {
                    viewDetails: this.viewDetails.bind(this),
                }
            });
            this.projectGrid.authorization = {
                'get': { accessLevel: ApplicationModules.AllProjects, action: PermissionNames.Get }
            }
        }

        this.projectGrid.storeProcedure = {
            length: totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            onPageSorting: this.onPageSorting.bind(this),
            nextPage: this.pageIndex
        };

        this.projectGrid.updateSource([]);

        if (this.isGridBound) {
            this.projectGrid.design(document.getElementById('project'));
            this.isGridBound = false;
        }
        
        this.projectGrid.updateSource(gridData);
        

        this.spin = false;
        this.addTooltip();


    }
    getJsonToSend(): SpParameter {
        const pageQuery = new PageDataQuery();
        pageQuery.PageRow = this.totalRecordsPerPage;
        pageQuery.PageIndex = this.pageIndex;
        pageQuery.OrderColumn = this.orderByColumn;
        pageQuery.OrderType = this.sortOrder;

        const jsonObject = new SpParameter();
        jsonObject.searchQuery = new SearchQuery();
        jsonObject.searchQuery.searchText = this.searchText;
        jsonObject.pageQuery = JSON.stringify(pageQuery);
        jsonObject.searchQuery = JSON.stringify(jsonObject.searchQuery);
        return jsonObject;
    }

    onPageChanging(nextPage: number) {
        
        this.totalRecordsPerPage = this.projectGrid.maxPerPage;
        this.pageIndex = nextPage;
        this.initializeGrid();
    }

    onPageSorting(columnName: string, isAscendingOrder: boolean, currentPage: number) {
        this.orderByColumn = columnName;
        this.sortOrder = isAscendingOrder ? 'asc' : 'desc';
        this.pageIndex = 1;
        if (!this.isGridBound) {
            this.initializeGrid();
        }
    }
    search(value) {
        this.searchText = value;
        this.pageIndex = 1
        this.initializeGrid();
    }

    addTooltip() {
        $(document).ready(function () {
            $(".gridinfobttn").hover(function () {
                $(this).attr("title", ToolTipText.ViewProjectDetails);
            })
        })
    }

    viewDetails(project: any) {
        
        this.subscription = this.get({ queryParams: { 'ListPage': ["chkProjectRFQ"], 'Id': project.projectId } }).subscribe((t: any) => {
            if (t.result != null) {
                this.route.navigateByUrl("projects/allProjectDetailsHRC/" + project.projectId);
            } else {
                this.route.navigate([RoutingKeys.HRCProjectDetails, project.projectId]);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

}
