import { Router } from '@angular/router';
import { AbstractRequestFilter, HttpResponse, ResponseFilter, XhrContext } from '@rxweb/http';
import { StorageKeys, RoutingKeys, RequestHeaderKeys } from 'src/app/components/shared/constant/commonConstants';
import { API } from '../components/shared/constant/administrationAPIContants';
import { BaseToastr } from '../domain/customize-design/toastr';
import { BrowserStorage } from '../domain/services/browser-storage';
export class AuthFilter extends AbstractRequestFilter implements ResponseFilter {

    byPassApiList: any[] = [API.authorize, API.staticPage, API.linkedInlogin, API.roleLookup, API.getLinkedInCredential, API.resetPassword, API.searchPaymentDetails]; //,"services/getProductTemplates/json/?lang_id=EN&product_id=UJA-EP"

    constructor(
        private baseToastr: BaseToastr,
        private storageHelper: BrowserStorage) {
        super();
        this.storageHelper = new BrowserStorage();
        this.baseToastr = new BaseToastr();
    }

    onRequest = (context: XhrContext) => {
        const lastRequestOn = this.storageHelper.local.get(StorageKeys.LastRequestOn);
        if (lastRequestOn) {
            const diff = Math.abs(new Date().getTime() - new Date(lastRequestOn).getTime());
            const diffHours = Math.ceil(diff / (1000 * 3600));
            if (diffHours > 3) {
                this.storageHelper.local.clearAll();
                this.storageHelper.session.clearAll();
                this.baseToastr.error('Session timeout, please login again');
                window.setTimeout(() => {
                    window.location.href = RoutingKeys.Login;
                }, 500);
                return;
            }
        }

        if (this.byPassApiList.indexOf(context.request.path) < 0) {
            const authorization = this.storageHelper.local.get(StorageKeys.Authentication, false);
            const request_identity = this.storageHelper.local.get(StorageKeys.Request_identity, false);
            if (authorization !== null && authorization !== undefined) {
                context.request.headers[RequestHeaderKeys.Authorization] = authorization;
                context.request.headers[RequestHeaderKeys.Request_identity] = request_identity;
            }
        }
        this.onRequestExecuting(context);
    }

    onResponse = (response: HttpResponse) => {
        // if user is logged in then only set last request on
        this.setLastRequestOn();
        return response;
    }

    setLastRequestOn() {
        const isLoggedIn = this.storageHelper.local.get(StorageKeys.IsLoggedIn) as boolean;
        if (isLoggedIn) {
            this.storageHelper.local.save(StorageKeys.LastRequestOn, new Date());
        }
    }
}

export enum HttpResponseCode {
    InvalidStatusCode = 0,
    OK = 200,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    InternalServerError = 500,
    TokenNotFound = 600,

}
