import { TemplateConfig } from '@rxweb/grid';

export const RECORD_ACTIVE_GRID_TEMPLATE: TemplateConfig = {
    span: {        
        class: [function (e) {
            return this[e.name] == 'Active' ? "label f-12 text-white activebadge-bg".split(" ") : 'label f-12 text-white inactivebadge-bg'.split(" ");
        }],
        childrens: [{
            text: {
                
                text: function (e) { 
                    return this[e.name] == 'Active' ? "Active" : "Inactive" }
            }
        }]
    }
}
