import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHRORecordBase {

//#region userId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'userId', keyColumn: true})
        userId : number;
//#endregion userId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region companyName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'companyName', keyColumn: false})
        companyName : string;
//#endregion companyName Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'email', keyColumn: false})
        email : string;
//#endregion email Prop


//#region companySizeId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'companySizeId', keyColumn: false})
        companySizeId : any;
//#endregion companySizeId Prop


//#region cityId Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'cityId', keyColumn: false})
        cityId : any;
//#endregion cityId Prop


//#region stateId Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'stateId', keyColumn: false})
        stateId : any;
//#endregion stateId Prop


//#region countryId Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'countryId', keyColumn: false})
        countryId : any;
//#endregion countryId Prop


//#region createdBy Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'createdBy', keyColumn: false})
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop


//#region applicationLocaleId Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'applicationLocaleId', keyColumn: false})
        applicationLocaleId : number;
//#endregion applicationLocaleId Prop


//#region applicationTimeZoneId Prop
        @gridColumn({visible: true, columnIndex:11, allowSorting: true, headerKey: 'applicationTimeZoneId', keyColumn: false})
        applicationTimeZoneId : number;
//#endregion applicationTimeZoneId Prop


//#region languageCode Prop
        @gridColumn({visible: true, columnIndex:12, allowSorting: true, headerKey: 'languageCode', keyColumn: false})
        languageCode : string;
//#endregion languageCode Prop


//#region loginBlocked Prop
        @gridColumn({visible: true, columnIndex:13, allowSorting: true, headerKey: 'loginBlocked', keyColumn: false})
        loginBlocked : boolean;
//#endregion loginBlocked Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:14, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region roleId Prop
        @gridColumn({visible: true, columnIndex:15, allowSorting: true, headerKey: 'roleId', keyColumn: false})
        roleId : any;
//#endregion roleId Prop


//#region linkedInUser Prop
        @gridColumn({visible: true, columnIndex:16, allowSorting: true, headerKey: 'linkedInUser', keyColumn: false})
        linkedInUser : any;
//#endregion linkedInUser Prop

}