import {vCompanySizeDetailBase} from '../database-models/v-company-size-detail-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';



@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'action' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vCompanySizeDetail extends vCompanySizeDetailBase 
{


//#region Generated Reference Properties

//#endregion Generated Reference Properties

















}