import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class YearsOfExperienceLookupBase {

//#region yearsOfExperienceId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'yearsOfExperienceId', keyColumn: true})
        yearsOfExperienceId : number;
//#endregion yearsOfExperienceId Prop


//#region yearsOfExperience Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'yearsOfExperience', keyColumn: false})
        yearsOfExperience : string;
//#endregion yearsOfExperience Prop

}