import {DocumentPlanBase} from '../database-models/document-plan-base';
//Generated Imports
export class DocumentPlan extends DocumentPlanBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties


}