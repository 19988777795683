import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractContract } from '../domain/abstract-contract';
import { Contract, vContract, UserRole } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { RoutingKeys, ToolTipText, UserRoles } from 'src/app/components/shared/constant/commonConstants';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { CommonHelperService } from 'src/app/components/shared/common-helpers/helper.service';
import { Router } from '@angular/router';
import { vContractHRO } from 'src/app/models/extended-models/v-contract-hro';
import { vContractHRC } from 'src/app/models/extended-models/v-contract-hrc';
import { RolesEnum } from 'src/app/enums/roles-enum';
import { ContractsEnum } from 'src/app/enums/contracts.enum';
import { PageDataQuery, SpParameter, SearchQuery, Query } from 'src/app/viewModel/sp-parameter';
import { debug } from 'console';
import { access } from "@rxweb/angular-router";
import { ApplicationModules, PermissionNames } from "src/app/enums/application-modules.enum";
import { ContractAddComponent } from "../add/contract-add.component";
import { ModelComponentType, ModelResponse } from "src/app/components/shared/constant/common-constants";
declare const $: any;

@access({ accessLevel: ApplicationModules.Contracts, action: PermissionNames.Get })

@Component({
    selector: "app-contract-list",
    templateUrl: './contract-list.component.html'
})
export class ContractListComponent extends AbstractContract implements OnInit, OnDestroy {
    contractAdd: any = ContractAddComponent;

    contract: List<Contract>;
    subscription: Subscription;
    contractGrid: AppGrid;
    contractGridColumns: any[];
    searchText = '';
    modalView: ModalView;
    loggedInUserRoleId: number;
    hRORoleId = RolesEnum.HRO;
    hRCRoleId = RolesEnum.HRConsultant;
    totalRecordsPerPage = 10;
    pageIndex = 1;
    orderByColumn = 'ContractEndDate';
    sortOrder = 'desc';
    isGridBound = true;
    isApproved: boolean = false;

    constructor(
        private route: Router,
        private commonHelperService: CommonHelperService,
        modalView: ModalView

    ) {
        super();
        this.modalView = modalView;
    }

    ngOnInit(): void {
        this.initializeGridforSP();
        this.isApproved = false;
    }

    initializeGridforSP() {
        const jsonToSend = this.getJsonToSend();
        if (this.isGridBound && !this.commonHelperService.isObjectNullOrUndefined(this.contractGrid)) {
            this.contractGrid.clearGrid('contract');
        }
        this.spin = true;
        this.showComponent = true;
        let sendJson = new Query();
        sendJson.query = JSON.stringify(jsonToSend);
        this.subscription = this.getContracts(sendJson).subscribe((contractList: any) => {
            this.spin = false;
            this.bindGrid(contractList);
        }, () => {
            this.spin = false;
        });
    }

    getJsonToSend(): SpParameter {
        const pageQuery = new PageDataQuery();
        pageQuery.PageRow = this.totalRecordsPerPage;
        pageQuery.PageIndex = this.pageIndex;
        pageQuery.OrderColumn = this.orderByColumn;
        pageQuery.OrderType = this.sortOrder;

        const jsonObject = new SpParameter();
        jsonObject.searchQuery = new SearchQuery();
        jsonObject.searchQuery.searchText = this.searchText;
        jsonObject.pageQuery = JSON.stringify(pageQuery);
        jsonObject.searchQuery = JSON.stringify(jsonObject.searchQuery);
        jsonObject["isApproved"] = this.isApproved;
        return jsonObject;
    }

    bindGrid(data: any) {
        const parsedData = JSON.parse(data)[0].result;
        const gridData = parsedData.data;
        const totalRecords = parsedData.footer[0].totalCount;
        this.loggedInUserRoleId = this.user.roleId;

        if (this.isGridBound) {
            if (this.loggedInUserRoleId == RolesEnum.SuperAdmin)
                this.contractGrid = new AppGrid(gridData, vContract
                    , {
                        actions: {
                            viewDetails: this.viewDetails.bind(this),
                            onEdit: this.onEdit.bind(this),
                            onDelete: this.onDelete.bind(this),
                            onHROLink: this.onHROLink.bind(this),
                            onHRCLink: this.onHRCLink.bind(this),
                            onProjectLink: this.onProjectLink.bind(this),
                        }
                    }
                )
            else if (this.loggedInUserRoleId == RolesEnum.HRO) {
                this.contractGrid = new AppGrid(gridData, vContractHRO
                    , {
                        actions: {
                            onEdit: this.onEdit.bind(this),
                            viewDetails: this.viewDetails.bind(this),
                            onHROLink: this.onHROLink.bind(this),
                            onHRCLink: this.onHRCLink.bind(this),
                            onProjectLink: this.onProjectLink.bind(this),
                        }
                    }
                )
            }

            else if (this.loggedInUserRoleId == RolesEnum.HRConsultant)
                this.contractGrid = new AppGrid(gridData, vContractHRC
                    , {
                        actions: {
                            viewDetails: this.viewDetails.bind(this),
                            onApprove: this.onApprove.bind(this),
                            onReject: this.onReject.bind(this),
                            onHROLink: this.onHROLink.bind(this),
                            onHRCLink: this.onHRCLink.bind(this),
                            onProjectLink: this.onProjectLink.bind(this),
                        }
                    }
                );
        }

        this.contractGrid.authorization = {
            'put': { accessLevel: ApplicationModules.Contracts, action: PermissionNames.Put },
            'delete': { accessLevel: ApplicationModules.Contracts, action: PermissionNames.Delete },
            'get': { accessLevel: ApplicationModules.Contracts, action: PermissionNames.Get },
        };
        this.contractGrid.storeProcedure = {
            length: totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            onPageSorting: this.onPageSorting.bind(this),
            nextPage: this.pageIndex
        };

        this.contractGrid.updateSource([]);

        if (this.isGridBound) {
            this.isGridBound = false;
            this.contractGrid.design(document.getElementById('contract'));
            // this.contractFormGroup.submitted = true;
        }
        this.contractGrid.updateSource(gridData);
        this.addTooltip();
    }

    onPageChanging(nextPage: number) {
        this.totalRecordsPerPage = this.contractGrid.maxPerPage;
        this.pageIndex = nextPage;
        this.initializeGridforSP();
    }

    onPageSorting(columnName: string, isAscendingOrder: boolean, currentPage: number) {
        this.orderByColumn = columnName;
        this.sortOrder = isAscendingOrder ? 'asc' : 'desc';
        this.pageIndex = 1;
        this.initializeGridforSP();
    }

    search(value) {
        this.searchText = value;
        this.pageIndex = 1;
        this.initializeGridforSP();
    }

    onEdit(contract: Contract) {
        this.route.navigate([RoutingKeys.Contract, contract.contractId]);
    }

    onDelete(contract: Contract) {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Delete }).then(t => {
            if (t == ModelResponse.Yes) {
                this.spin = true;
                this.delete({ params: [contract.contractId], body: null }).subscribe(response => {
                    this.spin = false;
                    this.toastr.success(CustomMessage.dataDeleted);
                    this.initializeGridforSP();
                })
            }
        });
    }

    onHROLink(contract: any) {
        if (contract.hROName != "*******") {
            this.route.navigateByUrl("hro-management/contractHRODetails/" + contract.hroId);
        }
    }

    onHRCLink(contract: any) {
        this.route.navigateByUrl("hrc/contractHRCDetails/" + contract.hrConsultantId);
    }

    onProjectLink(contract: any) {
        this.route.navigateByUrl("projects/userDetails/" + contract.ProjectId)
    }

    onApprove(contract: Contract) {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Approve }).then(t => {
            if (t == ModelResponse.Yes) {
                this.spin = true;
                this.get({ params: [contract.contractId] }).subscribe((c: Contract) => {
                    c.contractStatus = ContractsEnum.Approved;
                    this.put({ params: [contract.contractId], body: c }).subscribe(response => {
                        this.spin = false;
                        this.toastr.success(CustomMessage.contractApproved);
                        this.initializeGridforSP();
                    })
                })
            }
        });
    }

    onReject(contract: Contract) {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Reject }).then(t => {
            if (t == ModelResponse.Yes) {
                this.spin = true;
                this.get({ params: [contract.contractId] }).subscribe((c: Contract) => {
                    c.contractStatus = ContractsEnum.Rejected;
                    this.put({ params: [contract.contractId], body: c }).subscribe(response => {
                        this.spin = false;
                        this.toastr.success(CustomMessage.contractRejected);
                        this.initializeGridforSP();
                    })
                })
            }
        });
    }

    addContract() {
        this.route.navigate([RoutingKeys.AddContract]);
    }

    viewDetails(contract: Contract) {
        this.route.navigate([RoutingKeys.ContractView, contract.contractId]);
    }

    addTooltip() {
        $(document).ready(function () {
            $(".gridEditbtn").hover(function () {
                $(this).attr("title", ToolTipText.EditContract);
            })
            $(".gridDeletebtn").hover(function () {
                $(this).attr("title", ToolTipText.DeleteContract);
            })
            $(".acceptContract").hover(function () {
                $(this).attr("title", ToolTipText.AcceptContract);
            })
            $(".rejectContract").hover(function () {
                $(this).attr("title", ToolTipText.RejectContract);
            })
            $(".gridinfobttn").hover(function () {
                $(this).attr("title", ToolTipText.ViewContract);
            })
        })
    }
    
    openTips() {
        let msg = new Array<any>();
        if(this.loggedInUserRoleId==this.hRCRoleId)
            msg[0] = 'Submit your contract for HRO review here. If the contract is to be created by the HRO, you will receive a message when complete.'
        else if(this.loggedInUserRoleId==this.hRORoleId)
            msg[0] = 'Manage and Create your contracts here after selecting an HRC. If you want the HRC to send their contract, select add then message instruction in the contract details box.'
            this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.PopupMessage, params: msg }).then(t => {
        })
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    approvedContractsList(value) {
        this.isApproved = value;
        this.pageIndex = 1;
        this.initializeGridforSP();
    }
}