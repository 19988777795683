import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SubscriptionPlanLookupBase {

//#region subscriptionPlanId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'subscriptionPlanId', keyColumn: true})
        subscriptionPlanId : number;
//#endregion subscriptionPlanId Prop


//#region subscriptionPlanName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'subscriptionPlanName', keyColumn: false})
        subscriptionPlanName : string;
//#endregion subscriptionPlanName Prop

}