import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCancelledCategoryBase {

        //#region isPlanCancel Prop
        @gridColumn({ visible: false, columnIndex: 4, allowSorting: true, headerKey: 'isPlanCancel', keyColumn: false })
        isPlanCancel: boolean;
        //#endregion isPlanCancel Prop


        //#region expertizeName Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'expertizeName', keyColumn: false, headerTitle: 'Expertise' })
        expertizeName: string;
        //#endregion expertizeName Prop


        //#region name Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'name', keyColumn: false, headerTitle: 'User Name' })
        name: string;
        //#endregion name Prop


        //#region cancelledAt Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, isAscending: true, headerKey: 'cancelledAt', keyColumn: false, headerTitle: 'Cancelled Date', configuredTemplate: { templateName: "dateFormat" } })
        cancelledAt: any;
        //#endregion cancelledAt Prop

        //#region expertisePlanId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'expertisePlanId', keyColumn: true })
        expertisePlanId: number;
        //#endregion expertisePlanId Prop

}