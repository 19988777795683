import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUserBase {

//#region userId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'userId', keyColumn: true})
        userId : number;
//#endregion userId Prop


//#region applicationTimeZoneName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'applicationTimeZoneName', keyColumn: false})
        applicationTimeZoneName : string;
//#endregion applicationTimeZoneName Prop


//#region languageCode Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'languageCode', keyColumn: false})
        languageCode : string;
//#endregion languageCode Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'email', keyColumn: false})
        email : string;
//#endregion email Prop


//#region password Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'password', keyColumn: false})
        password : any;
//#endregion password Prop


//#region salt Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'salt', keyColumn: false})
        salt : any;
//#endregion salt Prop


//#region loginBlocked Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'loginBlocked', keyColumn: false})
        loginBlocked : boolean;
//#endregion loginBlocked Prop


//#region localeCode Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'localeCode', keyColumn: false})
        localeCode : string;
//#endregion localeCode Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region activationKey Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'activationKey', keyColumn: false})
        activationKey : string;
//#endregion activationKey Prop


//#region linkedInUser Prop
        @gridColumn({visible: true, columnIndex:11, allowSorting: true, headerKey: 'linkedInUser', keyColumn: false})
        linkedInUser : any;
//#endregion linkedInUser Prop


//#region linkedInUserId Prop
        @gridColumn({visible: true, columnIndex:12, allowSorting: true, headerKey: 'linkedInUserId', keyColumn: false})
        linkedInUserId : string;
//#endregion linkedInUserId Prop


//#region roleId Prop
        @gridColumn({visible: true, columnIndex:12, allowSorting: true, headerKey: 'roleId', keyColumn: false})
        roleId : any;
//#endregion roleId Prop

}