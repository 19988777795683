import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vExpertizeBase {

//#region status Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'status', keyColumn: false, headerTitle:"Status", configuredTemplate: { templateName: "recordActive" }})
        status : string;
//#endregion status Prop


//#region expertizeDetails Prop
        @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerKey: 'expertizeDetails', keyColumn: false, headerTitle: "Expertise Details" })
        expertizeDetails : string;
//#endregion expertizeDetails Prop


//#region expertizeName Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'expertizeName', keyColumn: false, headerTitle: "Expertise", isAscending: true })
        expertizeName : string;
//#endregion expertizeName Prop


//#region expertizeId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'expertizeId', keyColumn: true })
        expertizeId : number;
//#endregion expertizeId Prop

}