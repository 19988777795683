import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractProjectRFQHRO } from '../domain/abstract-project-r-f-q-hro';
import { ProjectRFQ } from "@app/models";
import { Subscription } from 'rxjs';
import { vProjectRFQHRO } from 'src/app/models/extended-models/v-project-r-f-q-hro';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { Router } from '@angular/router';
import { CommonHelperService } from 'src/app/components/shared/common-helpers/helper.service';
import { AppGrid } from 'src/app/domain/app-grid';
import { Query, SpParameter, PageDataQuery, SearchQuery } from 'src/app/viewModel/sp-parameter';
import { RoutingKeys, ToolTipText } from 'src/app/components/shared/constant/commonConstants';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { access } from "@rxweb/angular-router";
import { ApplicationModules, PermissionNames } from "src/app/enums/application-modules.enum";
import { ModelComponentType, ModelResponse, ProjectRFQDeadlineDateText } from "src/app/components/shared/constant/common-constants";
import { ProjectRFQStatusEnum } from 'src/app/enums/project-r-f-q-status.enum';
import { StatusEnum } from 'src/app/enums/status.enum';
declare const $: any;

@access({ accessLevel: ApplicationModules.QuoteMessage, action: PermissionNames.Get })

@Component({
    selector: "app-project-r-f-q-hro-list",
    templateUrl: './project-r-f-q-hro-list.component.html'
})
export class ProjectRFQHROListComponent extends AbstractProjectRFQHRO implements OnInit, OnDestroy {
    projectRFQHRO: List<ProjectRFQ>;
    subscription: Subscription;
    projectRFQHROGrid: AppGrid;
    modalView: ModalView;
    searchText = '';
    totalRecordsPerPage = 10;
    pageIndex = 1;
    orderByColumn = 'projectRFQId';
    sortOrder = 'desc';
    isGridBound = true;
    deadlineText = ProjectRFQDeadlineDateText.DeadlineText;

    constructor(
        private route: Router,
        private commonHelperService: CommonHelperService,
        modalView: ModalView

    ) {
        super();
        this.modalView = modalView;
    }

    ngOnInit(): void {
        this.initializeGridforSP()
    }

    initializeGridforSP() {
        const jsonToSend = this.getJsonToSend();
        if (this.isGridBound && !this.commonHelperService.isObjectNullOrUndefined(this.projectRFQHROGrid)) {
            this.projectRFQHROGrid.clearGrid('projectRFQHRO');
        }
        this.spin = true;
        this.showComponent = true;
        let sendJson = new Query();
        sendJson.query = JSON.stringify(jsonToSend);
        this.subscription = this.getProjectRFQList(sendJson).subscribe((projectRFQHROList: any) => {
            this.spin = false;
            this.bindGrid(projectRFQHROList);
        }, () => {
            this.spin = false;
        });
    }

    getJsonToSend(): SpParameter {
        const pageQuery = new PageDataQuery();
        pageQuery.PageRow = this.totalRecordsPerPage;
        pageQuery.PageIndex = this.pageIndex;
        pageQuery.OrderColumn = this.orderByColumn;
        pageQuery.OrderType = this.sortOrder;

        const jsonObject = new SpParameter();
        jsonObject.searchQuery = new SearchQuery();
        jsonObject.searchQuery.searchText = this.searchText;
        jsonObject.pageQuery = JSON.stringify(pageQuery);
        jsonObject.searchQuery = JSON.stringify(jsonObject.searchQuery);
        return jsonObject;
    }

    bindGrid(data: any) {
        const parsedData = JSON.parse(data)[0].result;
        const gridData = parsedData.data;
        const totalRecords = parsedData.footer[0].totalCount;

        if (this.isGridBound) {
            this.projectRFQHROGrid = new AppGrid(gridData, vProjectRFQHRO
                , {
                    actions: {
                        onMesaageView: this.onMesaageView.bind(this),
                        onHRCLink: this.onHRCLink.bind(this),
                        onApprove: this.onApprove.bind(this),
                        onReject: this.onReject.bind(this),
                        onProjectLink: this.onProjectLink.bind(this),
                    }
                }
            )
        }
        // this.projectRFQHROGrid.authorization = {
        //     'put': { accessLevel: ApplicationModules.QuoteMessage, action: PermissionNames.Put }
        // }
        this.projectRFQHROGrid.storeProcedure = {
            length: totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            onPageSorting: this.onPageSorting.bind(this),
            nextPage: this.pageIndex
        };

        this.projectRFQHROGrid.updateSource([]);

        if (this.isGridBound) {
            this.projectRFQHROGrid.design(document.getElementById('projectRFQHRO'));
            this.isGridBound = false;
        }
        this.projectRFQHROGrid.updateSource(gridData);
        this.addTooltip();
    }

    onMesaageView(projectRFQHRO: any) {
        this.route.navigate([RoutingKeys.Messages, projectRFQHRO.projectRFQId]);
    }

    onHRCLink(projectRFQHRO: any) {
        this.route.navigateByUrl("hrc/rfqHRCDetails/" + projectRFQHRO.hrConsultant);
    }

    onProjectLink(projectRFQHRO: any) {
        this.route.navigateByUrl("projects/projectDetailsHRO/" + projectRFQHRO.projectId);
    }

    onPageChanging(nextPage: number) {
        this.totalRecordsPerPage = this.projectRFQHROGrid.maxPerPage;
        this.pageIndex = nextPage;
        this.initializeGridforSP();
    }

    onPageSorting(columnName: string, isAscendingOrder: boolean, currentPage: number) {
        this.orderByColumn = columnName;
        this.sortOrder = isAscendingOrder ? 'asc' : 'desc';
        this.pageIndex = 1;
        this.initializeGridforSP();
    }

    onApprove(projectRFQHRC: ProjectRFQ) {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Approve }).then(t => {
            if (t == ModelResponse.Yes) {
                this.spin = true;
                this.get({ params: [projectRFQHRC.projectRFQId] }).subscribe((p: ProjectRFQ) => {
                    p.projectRFQStatus = ProjectRFQStatusEnum.Approved;
                    p.statusId = StatusEnum.Active;
                    this.put({ params: [projectRFQHRC.projectRFQId], body: p }).subscribe(response => {
                        this.spin = false;
                        this.toastr.success(CustomMessage.rfqApproved);
                        this.initializeGridforSP();
                    })
                })
            }
        });
    }

    onReject(projectRFQHRC: ProjectRFQ) {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Reject }).then(t => {
            if (t == ModelResponse.Yes) {
                this.spin = true;
                this.get({ params: [projectRFQHRC.projectRFQId] }).subscribe((p: ProjectRFQ) => {
                    p.projectRFQStatus = ProjectRFQStatusEnum.Rejected;
                    p.statusId = StatusEnum.Active;
                    this.put({ params: [projectRFQHRC.projectRFQId], body: p }).subscribe(response => {
                        this.spin = false;
                        this.toastr.success(CustomMessage.rfqRejected);
                        this.initializeGridforSP();
                    })
                })
            }
        });
    }

    search(value) {
        this.searchText = value;
        this.pageIndex = 1;
        this.initializeGridforSP();
    }

    addTooltip() {
        $(document).ready(function () {

            $(".message_notify").hover(function () {
                $(this).attr("title", ToolTipText.MessageHRC);
            })
            $(".gridBluebtn").hover(function () {
                $(this).attr("title", ToolTipText.MessageHRC);
            })
            $(".gridEditbtn").hover(function () {
                $(this).attr("title", ToolTipText.ApproveRFQ);
            })
            $(".gridDeletebtn").hover(function () {
                $(this).attr("title", ToolTipText.RejectRFQ);
            })
        })
    }

    openTips() {
        let msg = new Array<any>();
        msg[0] = 'Message Human Resources Consultants directly from here. Once the HRCs approve, the status will be updated.'
        msg[1] = 'Once you choose and accept an HRCs proposal, either send your contract to the HRC or ask the HRC to send their contract agreement.'
        msg[2]='Make sure and reject the HRCs proposals that were not chosen for closure.'
        msg[3] = this.deadlineText;
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.PopupMessage, params: msg }).then(t => {
        })
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

}
