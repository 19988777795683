import {ProjectQuestionTypeLookupBase} from '../database-models/project-question-type-lookup-base';
//Generated Imports
export class ProjectQuestionTypeLookup extends ProjectQuestionTypeLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties



















}