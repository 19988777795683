import { TemplateConfig } from '@rxweb/grid';

export const MESSAGE_GRID_TEMPLATE: TemplateConfig = {
    div: {
        class: 'formAction list-unstyled mb-0'.split(' '),
        childrens: [{
            a: {
                class:[function (e) {
                    return this.isPreview ? "gridinfobttn" : "gridEditbtn";
                }],
                event: {
                    click: 'onView'
                },
                childrens: [{
                    a: {
                        childrens: [{
                            i: { class: 'fa fa-eye'.split(' ') }
                        }],
                    }
                }]
            }
        },

        ]
    }
  
}