import {RegisterExpertizeLookupBase} from '../database-models/register-expertize-lookup-base';
//Generated Imports
export class RegisterExpertizeLookup extends RegisterExpertizeLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties

}