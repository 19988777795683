
//Generated Imports
//import { actionColumn } from '@rxweb/grid';
import { vContractHRCBase } from '../database-models/v-contract-hrc-base';

import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name: 'accept',
    allowSorting: false,
    configuredTemplate: { templateName: 'accept' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"], headerCellClass: ["pl-4"]
})
export class vContractHRC extends vContractHRCBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties














}