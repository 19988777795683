import { prop,propObject,propArray,required,maxLength,range ,numeric, NumericValueType } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ContractBase {

//#region contractId Prop
        @prop()
        contractId : number;
//#endregion contractId Prop


//#region hRConsultantId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        hRConsultantId : number;
//#endregion hRConsultantId Prop


//#region projectId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        projectId : number;
//#endregion projectId Prop


//#region contractDetails Prop
        @prop()
        @required()
        @maxLength({value:500})
        contractDetails : string;
//#endregion contractDetails Prop


//#region contractStartDate Prop
        @required()
        contractStartDate : Date;
//#endregion contractStartDate Prop


//#region contractEndDate Prop
        @required()
        contractEndDate : Date;
//#endregion contractEndDate Prop


//#region pricing Prop       
        @required()
        @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })
        @range({ minimumNumber: 1, maximumNumber: 10000000 })
        pricing : number;
//#endregion pricing Prop


//#region contractStatus Prop
        @prop()
        contractStatus : number;
//#endregion contractStatus Prop


//#region statusId Prop
        @prop()
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @prop()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop





}