import {vExpertizeRecordBase} from '../database-models/v-expertize-record-base';
//Generated Imports
export class vExpertizeRecord extends vExpertizeRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





























}