export enum ApplicationModules {
    HROList = 1,
    HRCList = 2,
    RoleList = 3,
    ProjectRFQRequestResponseManagement=4,  
    Contracts=5,  
    Feedbacks = 6,
    SubscriptionPlanManagement=7,
    SubscriptionPlanCancellationResponses=8,
    PaymentResponseSubscriptions=9,
    ExpertiseCategoryManagement=10,
    ExpertiseSubCategoryManagement=11,
    QuestionsManagement=12,
    SubscriptionTypeManagement=13,
    YearsofExperienceManagement=14,              
    CityManagement=15,  
    StateManagement=16,
    CountryManagement=17,
    CompanySizeManagement=18,
    MonthlyRetainedServiceOptionManagement=19,
    MonthlyRetainedServiceManagement=20,
    EmailTemplateManagement=21,             
    StaticPageManagement=22,   
    Reports  =23,   
    MyProject = 24,
    QuoteMessage=25,
    ProjectRFQResponse=28,    
    MyTestimonials=31,  
    PublicRFP=32,
    HRComplianceForms=33,
    PaymentReport=34,
    UserReport=35,
    ImportedUserList=36,
    MatchingHRCList=37,
    AllProjects=38,
}

export class PermissionNames {
    public static Get = 'get';
    public static Post = 'post';
    public static Put = 'put';
    public static Patch = 'patch';
    public static Delete = 'delete';
}
