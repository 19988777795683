import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEmailNotificationRecordBase {

//#region emailNotificationId Prop
        @gridColumn({visible: false, columnIndex:0, isAscending:false, allowSorting: true, headerKey: 'emailNotificationId', keyColumn: true})
        emailNotificationId : number;
//#endregion emailNotificationId Prop


//#region emailDetailId Prop
        @gridColumn({visible: false, columnIndex:1, allowSorting: true, headerKey: 'emailDetailId', keyColumn: false})
        emailDetailId : number;
//#endregion emailDetailId Prop


//#region userId Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'userId', keyColumn: false})
        userId : number;
//#endregion userId Prop


//#region from Prop
        @gridColumn({visible: false, columnIndex:3, allowSorting: true, headerKey: 'from', keyColumn: false,headerTitle:"From"})
        from : string;
//#endregion from Prop


//#region to Prop
        @gridColumn({visible: false, columnIndex:4, allowSorting: true, headerKey: 'to', keyColumn: false,headerTitle:"To"})
        to : string;
//#endregion to Prop


//#region cC Prop
        @gridColumn({visible: false, columnIndex:5, allowSorting: true, headerKey: 'cC', keyColumn: false})
        cC : string;
//#endregion cC Prop


//#region subject Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'subject', keyColumn: false,headerTitle:"Subject"})
        subject : string;
//#endregion subject Prop


//#region templateBody Prop
        @gridColumn({visible: false, columnIndex:7, allowSorting: true, headerKey: 'templateBody', keyColumn: false})
        templateBody : string;
//#endregion templateBody Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'createdOn', keyColumn: false, headerTitle:"Date & Time",configuredTemplate: { templateName: "dateTimeFormat" }})
        createdOn : any;
//#endregion createdOn Prop

}