import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CustomerProfileIssueBase {

//#region customerProfileIssueId Prop
        @prop()
        customerProfileIssueId : number;
//#endregion customerProfileIssueId Prop


//#region errorCode Prop
        @required()
        errorCode : string;
//#endregion errorCode Prop


//#region errorMessage Prop
        @required()
        errorMessage : string;
//#endregion errorMessage Prop


//#region errorDate Prop
        @required()
        errorDate : Date;
//#endregion errorDate Prop


//#region userId Prop
        @prop()
        userId : number;
//#endregion userId Prop


//#region responseErrorId Prop
        @prop()
        responseErrorId : number;
//#endregion responseErrorId Prop

}