import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vQuestionRecordBase {

//#region questionId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'questionId', keyColumn: true})
        questionId : number;
//#endregion questionId Prop


//#region questionText Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'questionText', keyColumn: false})
        questionText : string;
//#endregion questionText Prop


//#region createdBy Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'createdBy', keyColumn: false})
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop


//#region questionType Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'questionType', keyColumn: false})
        questionType : number;
//#endregion questionType Prop


//#region options Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'options', keyColumn: false})
        options : string;
//#endregion options Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop

}