import {vStateRecordBase} from '../database-models/v-state-record-base';
//Generated Imports
export class vStateRecord extends vStateRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties

























}