import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { MessagePreviewModal } from './modal/message-preview.component';



@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule],
    declarations: [MessagePreviewModal],
    providers: [],
    entryComponents:[MessagePreviewModal]
})
export class MessageSharedModule { }

