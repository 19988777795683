import {vMonthlyRetainedOptionRecordBase} from '../database-models/v-monthly-retained-option-record-base';
//Generated Imports
export class vMonthlyRetainedOptionRecord extends vMonthlyRetainedOptionRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties































































}