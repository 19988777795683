import {UserSubscriptionDetailBase} from '../database-models/user-subscription-detail-base';
import {UserBase} from '../database-models/user-base';
//Generated Imports
export class UserSubscriptionDetail extends UserSubscriptionDetailBase 
{




//#region Generated Reference Properties
//#region user Prop
user : UserBase;
//#endregion user Prop

//#endregion Generated Reference Properties



























}