import {EmailTemplateBase} from '../database-models/email-template-base';
//Generated Imports
export class EmailTemplate extends EmailTemplateBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties















































}