export const MASTER_LOOKUPS :{ [key:string] : string } = {
        cityLookup : 'api/masterlookups/citylookup',
        countryLookup : 'api/masterlookups/countrylookup',
        expertizeLookup : 'api/masterlookups/expertizelookup',
        registerExpertizeLookup : 'api/masterlookups/registerExpertizelookup',
        stateLookup : 'api/masterlookups/statelookup',
        companySizeLookup : 'api/masterlookups/companysizelookup',
        roleLookup : 'api/masterlookups/rolelookup',
        monthlyRetainedOptionLookup:'api/masterlookups/MonthlyRetainedOptionLookup',
        expertizeSubCategoryLookup:'api/masterlookups/expertizeSubCategoryLookup',
        registerExpertizeSubCategoryLookup:'api/masterlookups/registerExpertizeSubCategoryLookup',
        yearsOfExperienceLookup:'api/masterlookups/yearsOfExperienceLookup',
        subscriptionTypeLookup:'api/masterlookups/SubscriptionTypeLookup',
        projectQuestionTypeLookup : 'api/masterlookups/projectquestiontypelookup',
        subscriptionPlanLookup:'api/masterlookups/subscriptionPlanLookup',
        complianceCategoryLookup:'api/masterlookups/complianceCategoryLookup',
        hRLookup:'api/masterlookups/HRLookup',
        roleNameLookup : 'api/masterlookups/RoleNamelookup',
        userTypeLookup : 'api/masterlookups/UserTypeLookup',
        expertisePlanList:'api/SearchContracts/getExpertisePlan'

}