
import { gridColumn } from "@rxweb/grid"
import { actionColumn } from '@rxweb/grid';
@actionColumn({
        name: 'feedbackAction',
        allowSorting: false,
        configuredTemplate: { templateName: 'feedbackAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
    })

export class vFeedbackDetailHRO {

//#region feedbackId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'feedbackId', keyColumn: true})
        feedbackId : number;
//#endregion feedbackId Prop


//#region hRConsultantName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'hRConsultantName', keyColumn: false,headerTitle:'HR Consultant', configuredTemplate: { templateName: "redirectHRCLink" }})
        hRConsultantName : string;
//#endregion hRConsultantName Prop


//#region projectName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'projectName', keyColumn: false,headerTitle:'Project', configuredTemplate: { templateName: "redirectProjectLink" }})
        projectName : string;
//#endregion projectName Prop


// //#region hROName Prop
//         @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'hROName', keyColumn: false,headerTitle:'HRO'})
//         hROName : string;
// //#endregion hROName Prop


//#region feedbackDetailsId Prop
        @gridColumn({visible: false, columnIndex:3, allowSorting: true, headerKey: 'feedbackDetailsId', keyColumn: false})
        feedbackDetailsId : number;
//#endregion feedbackDetailsId Prop


//#region feedbackQuestion Prop
        @gridColumn({visible: false, columnIndex:4, allowSorting: true, headerKey: 'feedbackQuestion', keyColumn: false})
        feedbackQuestion : number;
//#endregion feedbackQuestion Prop


//#region monthlyRetainedServiceId Prop
        @gridColumn({visible: false, columnIndex:6, allowSorting: true, headerKey: 'monthlyRetainedServiceId', keyColumn: false})
        monthlyRetainedServiceId : string;
//#endregion monthlyRetainedServiceId Prop


//#region comments Prop
        @gridColumn({visible: false, columnIndex:7, allowSorting: true, headerKey: 'comments', keyColumn: false})
        comments : string;
//#endregion comments Prop


//#region ratings Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: false, headerKey: 'ratings', keyColumn: false,headerTitle:'Ratings', configuredTemplate: { templateName: "ratings" }})
        ratings : any;
//#endregion ratings Prop

}