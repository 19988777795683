import { TemplateConfig } from '@rxweb/grid';
import { ContractsEnum } from 'src/app/enums/contracts.enum';

export const MATCHING_HRC_RATINGS_GRID_TEMPLATE: TemplateConfig = {
    div: {
        class: "rate-area".split(" ")
        ,
        childrens: [
            {
                input: {
                    attributes: {
                        id: "5-star",
                        name: function (e) {
                            return "fbRating" + this.UserId;
                        },
                        type: "radio",
                        checked: function (e) {
                            if (this.Ratings == 5) {
                                return true;
                            }
                        },
                        value: 5
                    }
                }
            },
            {
                label: {
                    // class: "rating5".split(" "),
                    text: "5-star".split(" ")
                },

            },
            {
                input: {
                    attributes: {
                        id: "4-star",
                        name: function (e) {
                            return "fbRating" + this.UserId;
                        },
                        type: "radio",
                        checked: function (e) {
                            
                            if (this.Ratings == 4) {
                                return true;
                            }
                        },
                        value: 4
                    }
                }
            },
            {
                label: {
                    // class: "rating4".split(" "),
                    text: "4-star".split(" ")
                }
            },
            {
                input: {
                    attributes: {
                        id: "3-star",
                        name: function (e) {
                            return "fbRating" + this.UserId;
                        },
                        type: "radio",
                        checked: function (e) {
                            
                            if (this.Ratings == 3) {
                                return true;
                            }
                        },
                        value: 3
                    }
                }
            },
            {
                label: {
                    // class: "rating3".split(" "),
                    text: "3-star".split(" ")
                }
            },
            {
                input: {
                    attributes: {
                        id: "2-star",
                        name: function (e) {
                            return "fbRating" + this.UserId;
                        },
                        type: "radio",
                        checked: function (e) {
                            
                            if (this.Ratings == 2) {
                                return true;
                            }
                        },
                        value: 2
                    }
                }
            },
            {
                label: {
                    // class: "rating2".split(" "),
                    text: "2-star".split(" ")
                }
            },
            {
                input: {
                    attributes: {
                        id: "1-star",
                        name: function (e) {
                            return "fbRating" + this.UserId;
                        },
                        type: "radio",
                        checked: function (e) {
                            
                            if (this.Ratings == 1) {
                                return true;
                            }
                        },
                        value: 1
                    }
                }
            },
            {
                label: {
                    // class: "rating1".split(" "),
                    text: "1-star".split(" ")
                }
            }
        ],
    }
}
