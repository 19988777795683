import {RoleLookupBase} from '../database-models/role-lookup-base';
//Generated Imports
export class RoleLookup extends RoleLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties











}