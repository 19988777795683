import { http, RxHttp } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { User, UserDetail } from '@app/models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { CoreComponent } from '@rxweb/angular-router';
import { HRConsultantViewModel } from 'src/app/viewModel/hr-consultant-view-model';
import { vHRCRecord } from 'src/app/models/extended-models/v-h-r-c-record';
import { RolesEnum } from 'src/app/enums/roles-enum';
import { UserCredentialModel } from "src/app/viewModel/user-credential-model";
import { Query } from 'src/app/viewModel/sp-parameter';
import { API } from "src/app/components/shared/constant/administrationAPIContants";


@http({
    path: "api/Users",
})
export class AbstractHRConsultant extends CoreComponent {
    userFormGroup: IFormGroup<HRConsultantViewModel>;
    userCredentialFormGroup: IFormGroup<UserCredentialModel>;
    userDetailFormGroup: IFormGroup<vHRCRecord>;
    toastr: BaseToastr;
    spin = false;
    breadCrumb: BreadCrumb;
    dialog: BaseDialog;
    showComponent: boolean = false;
    isContract: boolean;
    isDetailAdmin: boolean;
    isDetailHRO: boolean;
    isDetailHRC: boolean;
    isFeedback: boolean;
    isMatchingHRC: boolean;
    isHrcProjectList: boolean;
    isHrcProjectDetails: boolean;
    isPaymentReportPage:boolean=false;
    isPaymentResponsePage:boolean=false;
    constructor() {
        super();
        this.isContract = window.location.href.indexOf('/contractHRCDetails') !== -1;
        this.isDetailAdmin = window.location.href.indexOf('/rfqAdminDetails') !== -1;
        this.isDetailHRO = window.location.href.indexOf('/rfqHRODetails') !== -1;
        this.isDetailHRC = window.location.href.indexOf('/rfqHRCDetails') !== -1;
        this.isFeedback = window.location.href.indexOf('/feedbackHRCDetails') !== -1;
        this.isMatchingHRC = window.location.href.indexOf('/matchingHRC') !== -1;
        this.isHrcProjectList = window.location.href.indexOf('/hrc/hrcProjectList') !== -1;
        this.isHrcProjectDetails = window.location.href.indexOf('hrc/hrcProjectDetails') !== -1;
        this.isPaymentReportPage = window.location.href.indexOf('/paymentReport') !== -1;
        this.isPaymentResponsePage = window.location.href.indexOf('/paymentResponse') !== -1;

        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.badRequest;

        if (this.isContract) {
            this.contractBreadCrumb();
        }
        else if (this.isDetailAdmin) {
            this.projectRFQAdminBreadCrumb();
        }
        else if (this.isDetailHRO) {
            this.quoteBreadCrumb();
        }
        else if (this.isDetailHRC) {
            this.quoteBreadCrumb();
        }
        else if (this.isFeedback) {
            this.feedbackBreadCrumb();
        }
        else if (this.isMatchingHRC) {
            this.matchingHRCBreadCrumb();
        }
        else if (this.isHrcProjectList || this.isHrcProjectDetails) {
            this.hrcProjectBreadCrumb();
        }
        else if(this.isPaymentReportPage){
            this.paymentBreadCrumb();
        }
        else if(this.isPaymentResponsePage){
            this.paymentResponseBreadCrumb();
        }
        else {
            this.bindBreadCrumb();
        }
    }

    paymentResponseBreadCrumb(){
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'Payment Response Subscriptions';
        this.breadCrumb.pageName = "Payment Response Subscriptions";
        this.breadCrumb.pageRedirect = 'payment-response';
    }
    hrcProjectBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'My Projects';
        this.breadCrumb.pageName = "My Projects";
        this.breadCrumb.pageRedirect = 'hrc/hrcProjectList';
    }
    paymentBreadCrumb(){
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'Payment Report';
        this.breadCrumb.pageName = "Payment Report";
        this.breadCrumb.pageRedirect = 'payment-report';
    }

    quoteBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'Quote Messages';
        this.breadCrumb.pageName = "Quote Messages";
        this.breadCrumb.pageRedirect = 'quote-message';
    }

    contractBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home";
        var RoleId = this.user.roleId;

        if (RoleId == RolesEnum.SuperAdmin) {
            this.breadCrumb.title = 'Contracts Management'
            this.breadCrumb.pageName = 'Contracts Management'
        }
        else {
            this.breadCrumb.title = 'My Contracts'
            this.breadCrumb.pageName = 'My Contracts'
        }
        this.breadCrumb.pageRedirect = "contracts";
        this.breadCrumb.pageRedirect = 'contracts';
    }
    projectRFQAdminBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'Project RFQ Request & Response Management';
        this.breadCrumb.pageName = "Project RFQ Request & Response Management";
        this.breadCrumb.pageRedirect = 'project-r-f-q-admin';
    }
    projectRFQResponseBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'Project RFQ Request & Response Management';
        this.breadCrumb.pageName = "Project RFQ Request & Response Management";
        this.breadCrumb.pageRedirect = 'project-r-f-q-response';
    }

    feedbackBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";

        var RoleId = this.user.roleId;

        if (RoleId == RolesEnum.SuperAdmin) {
            this.breadCrumb.title = 'Feedback Management';
            this.breadCrumb.pageName = 'Feedback Management';
        }
        else {
            this.breadCrumb.title = 'Feedback';
            this.breadCrumb.pageName = 'Feedback';
        }
        this.breadCrumb.pageRedirect = "feedbacks";
    }


    matchingHRCBreadCrumb() {
        var url = location.pathname.split("/");
        var projectId = url[3];
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.link2 = 'projects';
        this.breadCrumb.name2 = "My Projects";
        this.breadCrumb.title = 'My Projects';
        this.breadCrumb.pageName = 'Matching HRC List';
        this.breadCrumb.pageRedirect = "projects/matchingHRC/" + projectId;
    }

    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home"
        this.breadCrumb.name2 = "User Management"
        this.breadCrumb.title = 'HR Consultant Management'
        this.breadCrumb.pageName = 'HR Consultant Management'
        this.breadCrumb.pageRedirect = 'hrc'
    }

    badRequest = (data) => {
        this.spin = false;
        let messages = JSON.parse(data);
        if (messages == "User already added") {
            this.toastr.warning(messages[0]);
        }
        else {
            this.toastr.error(messages[0]);
        }
    }

    getProjectDetails(projectId){
        return this.get({path: API.projects, params: [projectId], queryParams: { 'Page': 'projectDetailsPage', 'RoleId': RolesEnum.HRConsultant } })
    }
    
    checkProject(projectId: number) {
        return this.get({queryParams: {projectId}, path: API.checkDeadline });
    }
}
