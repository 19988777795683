import { TemplateConfig } from '@rxweb/grid';

export const TEXT_FORMAT_GRID_TEMPLATE: TemplateConfig = {

    // span: { 
    //     class:["pull-left"],
    //     childrens: [{
    //         text: {
    //             text: function (e) {

    //                 if(this[e.name] != null){
    //                   return this[e.name];
    //                 }else{
    //                     return ''
    //                 }
    //               }
    //     }}]
    // }

    div: {
        class: [
            function (e) {
                return "divTableCell".split(" ");
            }],
        attributes: {
            'data-head': function (e) { return e.headerTitle; }
        }
        ,
        childrens: [{
            span: {
                class: ["pull-left"],
                childrens: [{
                    text: {
                        text: function (e) {

                            if (this[e.name] != null) {
                                return this[e.name];
                            } else {
                                return ''
                            }
                        }
                    }
                }]
            }
        }]
    }

}