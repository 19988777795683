import { TemplateConfig } from '@rxweb/grid';

export const PAYMENT_GRID_TEMPLATE: TemplateConfig = {
    span: {        
        class: [function (e) {
            if (this[e.name] == "False")
            return 'subscriptionFailedPayment'.split(" ");
            else
                return 'subscriptionPayment'.split(" ");
        }],
        childrens: [{
            text: {
                
                text: function (e) { 
                    return this[e.name]}
            }
        }]
    }
}
