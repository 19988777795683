import { RxSelectComponent } from '@rxweb/angular/select';
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractUploadAttachment } from '../domain/abstract-upload-attachment';
import { Attachment, UploadAttachment, USCompliance, vUSCategory } from "@app/models";
import { Subscription } from 'rxjs';
import { Router } from "@angular/router";
import { CommonHelperService } from "src/app/components/shared/common-helpers/helper.service";
import { ModalView } from "src/app/domain/customize-design/modal";
import { AppGrid } from "src/app/domain/app-grid";
import { MasterLookupGroup } from "src/app/viewModel/master-lookup-group";
import { MASTER_LOOKUPS } from "src/app/uris/master-lookups.uri";
import { PageDataQuery, Query, SearchQuery, SpParameter } from "src/app/viewModel/sp-parameter";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { RoutingKeys, StorageKeys, ToolTipText } from "src/app/components/shared/constant/commonConstants";
import { vUSComplianceBase, vUSComplianceHRBase, vUSComplianceStateBase } from "@app/database-models";
import { ConfirmationModalComponent } from "src/app/components/shared/confirmation-modal/confirmation-modal.component";
import { CustomMessage } from "src/app/components/shared/constant/custom-message";
import { DownloadFile } from "src/app/components/shared/constant/download-file";
import { DownloadDocumentViewModel } from "src/app/viewModel/download-document-viewModel";
import { BlobTypeEnum } from "src/app/enums/blob-type.enum";
import { RolesEnum } from "src/app/enums/roles-enum";
import { USCategoryViewModel } from "src/app/viewModel/u-s-category-view-model";
import { access } from "@rxweb/angular-router";
import { ApplicationModules, PermissionNames } from "src/app/enums/application-modules.enum";
import { UploadAttachmentAddComponent } from "../add/upload-attachment-add.component";
import { ModelComponentType, ModelResponse } from 'src/app/components/shared/constant/common-constants';
import { API } from 'src/app/components/shared/constant/administrationAPIContants';
declare const $: any;

@access({ accessLevel: ApplicationModules.HRComplianceForms, action: PermissionNames.Get })

@Component({
    selector: "app-upload-attachment-list",
    templateUrl: './upload-attachment-list.component.html'
})
export class UploadAttachmentListComponent extends AbstractUploadAttachment implements OnInit, OnDestroy {
    usComplienceAdd: any = UploadAttachmentAddComponent
    uploadAttachments: List<UploadAttachment>;
    subscription: Subscription;
    complianceGrid: AppGrid;
    subcomplianceGrid:AppGrid;
    documentGrid: AppGrid;
    complianceGridColumns: any[];
    searchText = '';
    modalView: ModalView;
    totalRecordsPerPage = 10;
    pageIndex = 1;
    orderByColumn = 'Category';
    sortOrder = 'asc';
    isGridBound = true;
    isDocGridBound = true;
    category: string = "";
    sCategory: string = "";
    masterLookupGroup: MasterLookupGroup;
    storageHelper: BrowserStorage
    roleId: number;
    userId: number;
    subCategory: string;
    showGrid: boolean;
    isComplianceLookup: boolean = false;
    isStateLookup: boolean = false;
    isTitle: boolean = false;
    uSComplianceDocument: any;
    isURL: boolean = false;
    isAdmin: boolean = false;

    constructor(
        private route: Router,
        private commonHelperService: CommonHelperService,
        modalView: ModalView

    ) {
        super();
        this.modalView = modalView;
        this.storageHelper = new BrowserStorage();
    }

    ngOnInit(): void {
        this.roleId = parseInt(this.storageHelper.local.get(StorageKeys.UserRole));
        this.userId = parseInt(this.storageHelper.local.get(StorageKeys.UserId));
        if (this.roleId != RolesEnum.SuperAdmin) {
            this.checkPayment();
        }
        this.lookup<MasterLookupGroup>(
            [
                { path: MASTER_LOOKUPS.complianceCategoryLookup, propName: 'complianceCategoryLookup' },
                { path: MASTER_LOOKUPS.hRLookup, propName: 'hRLookup' },
                { path: MASTER_LOOKUPS.stateLookup, propName: 'stateLookup' }
            ]).subscribe((response: any) => {
                this.masterLookupGroup = response;
                this.initializeGrid();
            })
    }

    checkPayment(): void {
        this.rxHttp.get({ queryParams: { 'plan': 'docPayment', 'RoleId': this.roleId, 'Id': this.userId }, path: API.users }).subscribe(t => {
            if (t[0].isDocumentPayment != true) {
                this.route.navigate([RoutingKeys.DocumentPlan]);
            }
        });
    }

    openTips() {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Hrcomplianceformtips }).then(t => {
        })
    }

    selectCategory(value) {
        this.pageIndex = 1;
        if (value.text == "") {
            this.category = value.text;
            this.sCategory = value.text;
            this.isStateLookup = false;
            this.isComplianceLookup = false;
            // this.isComplianceLookup = false;
        }
        else {
            if (value.sourceItem.applicationObjectId == 20) {
                this.isStateLookup = false;
                this.isComplianceLookup = true;
                this.isURL = false;
                this.sCategory = "";
                this.category = value.item.value;
            }
            else if (value.sourceItem.applicationObjectId == 21) {
                this.isComplianceLookup = false;
                this.isStateLookup = true;
                this.isURL = false;
                this.category = value.item.value;
                this.sCategory = "";
                this.masterLookupGroup.stateLookup = this.masterLookupGroup.stateLookup.filter(t => t.countryId == 1);
            }
            else if (value.sourceItem.applicationObjectId == 22) {
                this.isStateLookup = false;
                this.isComplianceLookup = false;
                this.category = value.item.value;
                this.sCategory = "";
                this.isURL = true;
            }
        }
        this.initializeGrid();
    }

    selectSubCategory(value) {
        if (value.text == "") {
            this.sCategory = value.text;
        }
        else {
            this.sCategory = value.item.value;
        }
        this.pageIndex = 1;
        this.initializeGrid();
    }
    resetfilter(value: any) {
        
        this.category = value;
        this.sCategory = value;
        this.isStateLookup = false;
        this.isComplianceLookup = false;
        this.initializeGrid();
    }
    initializeGrid() {
        const jsonToSend = this.getJsonToSend();
        if (this.isGridBound && !this.commonHelperService.isObjectNullOrUndefined(this.complianceGrid)) {
            //this.complianceGrid.clearGrid('uploadAttachment');
        }
        this.spin = true;
        this.showGrid = true;
        this.showComponent = true;
        let sendJson = new Query();
        sendJson.query = JSON.stringify(jsonToSend);
        this.subscription = this.getUserData(sendJson).subscribe((documentList: any) => {
            this.spin = false;
            this.bindGrid(documentList);
        }, () => {
            this.spin = false;
        });
    }

    getJsonToSend(): SpParameter {
        const pageQuery = new PageDataQuery();
        pageQuery.PageRow = this.totalRecordsPerPage;
        pageQuery.PageIndex = this.pageIndex;
        pageQuery.OrderColumn = this.orderByColumn;
        pageQuery.OrderType = this.sortOrder;

        const jsonObject = new SpParameter();
        jsonObject.searchQuery = new SearchQuery();
        jsonObject.searchQuery.searchText = this.searchText;
        jsonObject.pageQuery = JSON.stringify(pageQuery);
        jsonObject.searchQuery = JSON.stringify(jsonObject.searchQuery);
        jsonObject["category"] = this.category;
        jsonObject["subCategory"] = this.sCategory;
        return jsonObject;
    }

    bindGrid(data: any) {
        const pageQuery = new PageDataQuery();
        pageQuery.PageRow = this.totalRecordsPerPage;
        pageQuery.PageIndex = this.pageIndex;
        pageQuery.OrderColumn = this.orderByColumn;
        pageQuery.OrderType = this.sortOrder;

        const parsedData = JSON.parse(data)[0].result;
        const gridData = parsedData.data;
        const totalRecords = parsedData.footer[0].totalCount;

        if (this.isGridBound) {
            if (this.roleId != RolesEnum.SuperAdmin) {
                this.complianceGrid = new AppGrid(gridData, USCategoryViewModel
                    , {
                        actions: {
                            onCategoryLink: this.onCategoryLink.bind(this),
                        }
                    }
                )
            }
            else {
                this.isAdmin = true;
                this.complianceGrid = new AppGrid(gridData, vUSCategory
                    ,{
                        actions: {
                            onCategoryLink: this.onCategoryLink.bind(this),
                            onDelete: this.onDelete.bind(this),
                            onEdit: this.onEdit.bind(this)
                        }
                    }
                )
            }
        }
        if (this.roleId != RolesEnum.SuperAdmin) {
            this.complianceGrid.authorization = {
                'put': { accessLevel: ApplicationModules.HRComplianceForms, action: PermissionNames.Put },
                'delete': { accessLevel: ApplicationModules.HRComplianceForms, action: PermissionNames.Delete },
                'get': { accessLevel: ApplicationModules.HRComplianceForms, action: PermissionNames.Get }
            }
        }
        this.complianceGrid.storeProcedure = {
            length: totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            onPageSorting: this.onPageSorting.bind(this),
            nextPage: this.pageIndex
        };

        this.complianceGrid.updateSource([]);

        if (this.isGridBound) {
            this.complianceGrid.design(document.getElementById('uploadAttachment'));
            this.isGridBound = false;
        }
        this.complianceGrid.updateSource(gridData);
        this.addTooltip();
    }

    onEdit(value) {
        this.route.navigate([RoutingKeys.UploadAttachments, value.USComplianceId]);
    }

    onDelete(category) {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Delete }).then(t => {
            if (t == ModelResponse.Yes) {
                this.spin = true;
                this.delete({ params: [category.USComplianceId], body: null }).subscribe(response => {
                    this.spin = false;
                    this.toastr.success(CustomMessage.dataDeleted);
                    this.initializeGrid();
                })
            }
        });
    }

    onCategoryLink(c) {
        this.bindDoc(c.category, c.subCategory, c.URLLink);
    }

    onPageChanging(nextPage: number) {
        this.totalRecordsPerPage = this.complianceGrid.maxPerPage;
        this.pageIndex = nextPage;
        this.initializeGrid();
    }

    onPageSorting(columnName: string, isAscendingOrder: boolean, currentPage: number) {
        this.orderByColumn = columnName;
        this.sortOrder = isAscendingOrder ? 'desc' : 'asc';
        this.pageIndex = 1;
        if (!this.isGridBound) {
            this.initializeGrid();
        }
    }

    searchCategory(value) {
        this.searchText = value;
        this.pageIndex = 1
        this.initializeGrid();
    }

    bindDoc(category, subcategory, url) {
        this.orderByColumn = 'title';
        this.sortOrder = 'asc';
        this.totalRecordsPerPage = 10;

        if (category == "Additional Online Forms And Posters Library") {
            this.subCategory = category;
            this.pageIndex = 1;
            this.showGrid = false;
            this.isDocGridBound = true;
            this.initializeDocumentGrid();

        }
        else {
            this.subCategory = subcategory;
            this.pageIndex = 1;
            this.showGrid = false;
            this.isDocGridBound = true;
            this.initializeDocumentGrid();
        }
    }

    initializeDocumentGrid() {
        const jsonToSend = this.getJsonToSendDocument();
        if (this.isDocGridBound && !this.commonHelperService.isObjectNullOrUndefined(this.documentGrid)) {
            //this.documentGrid.clearGrid('document');
        }
        this.spin = true;
        this.showGrid = false;
        this.showComponent = true;
        let sendJson = new Query();
        sendJson.query = JSON.stringify(jsonToSend);
        this.subscription = this.getDocument(sendJson).subscribe((documentList: any) => {
            this.spin = false;
            this.bindDocumentGrid(documentList);
        }, () => {
            this.spin = false;
        });
    }

    getJsonToSendDocument(): SpParameter {
        const pageQuery = new PageDataQuery();
        pageQuery.PageRow = this.totalRecordsPerPage;
        pageQuery.PageIndex = this.pageIndex;
        pageQuery.OrderColumn = this.orderByColumn;
        pageQuery.OrderType = this.sortOrder;

        const jsonObject = new SpParameter();
        jsonObject.searchQuery = new SearchQuery();
        jsonObject.searchQuery.searchText = this.searchText;
        jsonObject.pageQuery = JSON.stringify(pageQuery);
        jsonObject.searchQuery = JSON.stringify(jsonObject.searchQuery);
        jsonObject["subCategory"] = this.subCategory;
        return jsonObject;
    }

    bindDocumentGrid(data: any) {
        const pageQuery = new PageDataQuery();
        pageQuery.PageRow = this.totalRecordsPerPage;
        pageQuery.PageIndex = this.pageIndex;
        pageQuery.OrderColumn = this.orderByColumn;
        pageQuery.OrderType = this.sortOrder;

        const parsedData = JSON.parse(data)[0].result;
        const gridData = parsedData.data;
        const totalRecords = parsedData.footer[0].totalCount;
        
        if (this.isDocGridBound) {
            this.complianceGrid.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex align-items-center flex-wrap mt-3".split(' ');
            if (gridData[0].hrCompliance == null && gridData[0].documentName != null) {
                this.complianceGrid.clearGrid('document');
                this.subcomplianceGrid = new AppGrid(gridData, vUSComplianceStateBase
                    , {
                        actions: {
                            // onEdit: this.onEdit.bind(this),
                            // onDelete: this.onDelete.bind(this)
                            downloadLink: this.downloadDocument.bind(this)
                        }
                    }
                )
            }
            else if (gridData[0].stateName == null && gridData[0].documentName != null) {
                this.complianceGrid.clearGrid('document');
                this.subcomplianceGrid = new AppGrid(gridData, vUSComplianceHRBase
                    , {
                        actions: {
                            // onEdit: this.onEdit.bind(this),
                            // onDelete: this.onDelete.bind(this)
                            downloadLink: this.downloadDocument.bind(this)
                        }
                    }
                )
            }
            else {
                this.complianceGrid.clearGrid('document');
                this.subcomplianceGrid = new AppGrid(gridData, vUSComplianceBase
                    , {
                        actions: {
                            // onEdit: this.onEdit.bind(this),
                            // onDelete: this.onDelete.bind(this)
                            urlLink: this.urlLink.bind(this)
                        }
                    }
                )
            }
        }

        this.subcomplianceGrid.storeProcedure = {
            length: totalRecords,
            onPageChanging: this.onDocPageChanging.bind(this),
            onPageSorting: this.onDocPageSorting.bind(this),
            nextPage: this.pageIndex
        };

        this.subcomplianceGrid.updateSource([]);

        if (this.isDocGridBound) {
            this.subcomplianceGrid.design(document.getElementById('document'));
            this.isDocGridBound = false;
        }
        this.subcomplianceGrid.updateSource(gridData);
    }

    downloadDocument(fileObj: any) {
        this.spin = true;
        let fileModel = new fileViewModel;
        fileModel.fileName = fileObj.documentName;
        fileModel.fileType = this.selectFileType(fileObj.documentName);
        let download = new DownloadFile();
        let downloadViewModel = new DownloadDocumentViewModel();
        downloadViewModel.Id = fileObj.uSComplianceDocumentId;
        downloadViewModel.BlobTypeEnum = BlobTypeEnum.USComplianceDocument;
        download.downloadFileFromAzure(downloadViewModel, fileModel).then(() => {
            this.spin = false;
        }, () => {
            this.spin = false;
        });
    }
    selectFileType(fileName: string): string {
        let splitArray = fileName.split(".");
        switch (splitArray[(splitArray.length - 1)].toLocaleLowerCase()) {
            case "pdf":
                return "application/pdf";
            case "doc":
                return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            case "docx":
                return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            case "xlsx":
                return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            case "xls":
                return "application/vnd.ms-excel";
            case "pptx":
                return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
            case "ppt":
                return "application/vnd.ms-powerpoint";
            case "png":
                return "image/png";
            case "jpg":
                return "image/jpeg";
            case "jpeg":
                return "image/jpeg";
        }
    }

    urlLink(value) {
        window.open(value.urlLink, "_blank");
    }

    onDocPageChanging(nextPage: number) {
        this.totalRecordsPerPage = this.subcomplianceGrid.maxPerPage;
        this.pageIndex = nextPage;
        this.initializeDocumentGrid();
    }

    onDocPageSorting(columnName: string, isAscendingOrder: boolean, currentPage: number) {
        this.orderByColumn = columnName;
        this.sortOrder = isAscendingOrder ? 'desc' : 'asc';
        if(!this.isDocGridBound)
            this.initializeDocumentGrid();
    }

    search(value) {
        this.searchText = value;
        this.pageIndex = 1
        this.initializeDocumentGrid();
    }

    back() {
        this.orderByColumn = 'category';
        this.showGrid = true;
        this.isGridBound = true;
        this.category = "";
        this.sCategory = "";
        this.initializeGrid();
    }

    addDocument() {
        this.route.navigate([RoutingKeys.AddDocument]);
    }

    addTooltip() {
        $(document).ready(function () {
            $(".gridEditbtn").hover(function () {
                $(this).attr("title", ToolTipText.EditUSCompliance);
            })
            $(".gridDeletebtn").hover(function () {
                $(this).attr("title", ToolTipText.DeleteUSCompliance);
            })
        })
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}

class fileViewModel {
    fileName: string;
    fileType: string;
    attachmentBase64String: string;
}