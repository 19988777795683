import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSubscriptionPlanBase {

//#region subscriptionPlanId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'subscriptionPlanId', keyColumn: true})
        subscriptionPlanId : number;
//#endregion subscriptionPlanId Prop


//#region subscriptionPlanName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'subscriptionPlanName', keyColumn: false, headerTitle: "Subscription Plan"})
        subscriptionPlanName : string;
//#endregion subscriptionPlanName Prop


//#region subscriptionDetails Prop
        @gridColumn({visible: false, columnIndex:3, allowSorting: true, headerKey: 'subscriptionDetails', keyColumn: false, headerTitle: "Plan Details"})
        subscriptionDetails : string;
//#endregion subscriptionDetails Prop


//#region subscriptionPrice Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'subscriptionPrice', keyColumn: false, headerTitle: "Price", configuredTemplate: { templateName: "numberDisplay" }})
        subscriptionPrice : number;
//#endregion subscriptionPrice Prop


//#region status Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'status', keyColumn: false, headerTitle: "Status", configuredTemplate: { templateName: "recordActive" }})
        status : string;
//#endregion status Prop


//#region subscriptionTypeName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'subscriptionTypeName', keyColumn: false, headerTitle: "Subscription Type"})
        subscriptionTypeName : string;
//#endregion subscriptionTypeName Prop


//#region validMonths Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'validMonths', keyColumn: false, headerTitle: "Valid Months"})
        validMonths : any;
//#endregion validMonths Prop

}