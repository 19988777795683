import {vEmailNotificationRecordBase} from '../database-models/v-email-notification-record-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'emailNotificationAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vEmailNotificationRecord extends vEmailNotificationRecordBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}