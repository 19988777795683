import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vAllProjectBase {

//#region projectId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'projectId', keyColumn: true })
        projectId : number;
//#endregion projectId Prop


//#region projectDeadlineDate Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'projectDeadlineDate', headerTitle: 'Project Deadline Date', keyColumn: false, configuredTemplate: { templateName: "dateFormat" } })
        projectDeadlineDate : string;
//#endregion projectDeadlineDate Prop

//#region Budget Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'budget', headerTitle: 'Budget', keyColumn: false, configuredTemplate: { templateName: "numberDisplay" } })
        budget : string;
//#endregion Budget Prop

//#region YearsOfExpriance Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'yearsOfExpriance', headerTitle: 'Years Of Experience', keyColumn: false })
        yearsOfExpriance : string;
//#endregion YearsOfExpriance Prop

//#region createdOn Prop
        @gridColumn({ visible: false, columnIndex: 3, allowSorting: true, headerKey: 'createdOn', headerTitle: 'Created On', keyColumn: false })
        createdOn : any;
//#endregion createdOn Prop

}