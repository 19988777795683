import {vSubscriptionPlanRecordBase} from '../database-models/v-subscription-plan-record-base';
//Generated Imports
export class vSubscriptionPlanRecord extends vSubscriptionPlanRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties














}