import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SMTPConfigurationBase {

//#region sMTPConfigurationId Prop
        @prop()
        sMTPConfigurationId : number;
//#endregion sMTPConfigurationId Prop


//#region fromEmail Prop
        @required()
        @maxLength({value:200})
        fromEmail : string;
//#endregion fromEmail Prop


//#region defaultCredentials Prop
        @prop()
        defaultCredentials : boolean;
//#endregion defaultCredentials Prop


//#region enableSSL Prop
        @prop()
        enableSSL : boolean;
//#endregion enableSSL Prop


//#region host Prop
        @required()
        @maxLength({value:100})
        host : string;
//#endregion host Prop


//#region userName Prop
        @maxLength({value:200})
        userName : string;
//#endregion userName Prop


//#region password Prop
        @maxLength({value:100})
        password : string;
//#endregion password Prop


//#region port Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        port : number;
//#endregion port Prop


//#region deliveryMethod Prop
        @maxLength({value:100})
        deliveryMethod : string;
//#endregion deliveryMethod Prop


//#region sendIndividually Prop
        @required()
        sendIndividually : boolean;
//#endregion sendIndividually Prop


//#region isActive Prop
        @prop()
        isActive : boolean;
//#endregion isActive Prop

}