import { prop,propObject,propArray,required,maxLength,range, unique  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"
import { AbstractControl } from '@angular/forms';


export class USComplianceDocumentBase {

//#region uSComplianceDocumentId Prop
        @prop()
        usComplianceDocumentId : number;
//#endregion uSComplianceDocumentId Prop


//#region uSComplianceId Prop
        @prop()
        // @range({minimumNumber:1,maximumNumber:2147483647})
        // @required()
        usComplianceId : number;
//#endregion uSComplianceId Prop


//#region title Prop
        @prop()       
        @required()
        @unique({message:'This title is already added within this form'})
        title : string;
//#endregion title Prop


//#region documentName Prop
        @prop()      
        @required({ conditionalExpression: function (control: AbstractControl) { return this.isDocument == true; } })
        @unique({message:'This file is already added within this form'})
        documentName: string;
//#endregion documentName Prop


//#region documentUrl Prop
        @prop()
        documentUrl : string;
//#endregion documentUrl Prop


//#region uRLLink Prop
        @prop()      
        @required({ conditionalExpression: function (control: AbstractControl) {return this.isDocument == false; }})
        @unique({message:'This link is already added within this form'})
        urlLink: string;
//#endregion uRLLink Prop


//#region isDocument Prop
        @prop()
        isDocument : boolean;
//#endregion isDocument Prop





}