import {vExpertizeSubCategoryBase} from '../database-models/v-expertize-sub-category-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'expertiseAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vExpertizeSubCategory extends vExpertizeSubCategoryBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties















































}