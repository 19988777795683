import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUSCategoryBase {

//#region uSComplianceId Prop
        @gridColumn({visible: false, columnIndex:4, allowSorting: true, headerKey: 'uSComplianceId', keyColumn: true})
        uSComplianceId : number;
//#endregion uSComplianceId Prop


//#region category Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, isAscending:true, headerKey: 'category', keyColumn: false , headerTitle:'Category'})
        category : string;
//#endregion category Prop


//#region subCategory Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'subCategory', keyColumn: false , headerTitle:'Sub Category/Title URL' , configuredTemplate:{templateName:'redirectCategoryLink'}})
        subCategory : string;
//#endregion subCategory Prop

}