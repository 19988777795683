import {vContractBase} from '../database-models/v-contract-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';

@actionColumn({
    name: 'contractAdminAction',
    allowSorting: false,
    configuredTemplate: { templateName: 'contractAdminAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vContract extends vContractBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





















}