import {ExpertisePlanBase} from '../database-models/expertise-plan-base';
//Generated Imports
export class ExpertisePlan extends ExpertisePlanBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties


}