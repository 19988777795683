
<!-- <div class="pcoded-navbar navbar-content"> -->

<!-- 
<div *ngIf="!isLoggedIn">
    <router-outlet></router-outlet>
    <app-popup></app-popup> 
  </div>
   -->
  
  <div class="dashboard_content" >

    <app-side-bar *ngIf="isLoggedIn"></app-side-bar>
    <app-top-bar *ngIf="isLoggedIn"></app-top-bar>
    <div [class.wrapper]="isLoggedIn" [class.pcoded-main-container]="isLoggedIn" [class.top-padding-remove]="!isLoggedIn">
      <router-outlet></router-outlet>
      <app-popup></app-popup>
    </div>
    <app-footer-bar *ngIf="isLoggedIn"></app-footer-bar>
    
    
  </div>
<!-- </div> -->
  