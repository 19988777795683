import {ProjectLookupBase} from '../database-models/project-lookup-base';
//Generated Imports
export class ProjectLookup extends ProjectLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties















































}