<style>
    .max-w-800 {
        max-width: 800px;
    }
</style>
<div *ngIf="showMessageComponent" class="fade modal show" role="dialog" tabindex="-1" style="display: block;"
    aria-labelledby="id" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-w-800" role="document">
        <div class="modal-content">
            <div class="modal-header py-2">
                <h2 class="h2 modal-title text-blue">
                    Message Preview
                </h2>
                <button type="button" class="close text-blue mt-0 pt-1 mb-0 pb-1 mr-0" data-dismiss="modal"
                    aria-label="Close" (click)="hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-2">
                <table>
                    <tr>
                        <td class="w-25">
                            <label class=" col-form-label"> Project Name</label>
                        </td>
                        <td>:</td>
                        <td class="w-100">
                            <div class="col-sm-9">
                                <input type="text" readonly="" class="form-control-plaintext" value="{{projectName}}">
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="hroName=='HRO'?false:true">
                        <td class="w-25">
                            <label class=" col-form-label">HRO</label>
                        </td>
                        <td>:</td>
                        <td class="w-100">
                            <div class="col-sm-9">
                                <input type="text" readonly="" class="form-control-plaintext" value="{{hroName}}">
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="hrcName=='HRC'?false:true">
                        <td class="w-25">
                            <label class=" col-form-label">HR Consultant</label>
                        </td>
                        <td>:</td>
                        <td class="w-100">
                            <div class="col-sm-12">
                                <input type="text" readonly="" class="form-control-plaintext" value="{{hrcName}}">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-25">
                            <label class=" col-form-label"> Subject</label>
                        </td>
                        <td>:</td>
                        <td class="w-100">
                            <div class="col-12 col-sm-12 text-c-black-theme">
                                {{subject}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-25">
                            <label class=" col-form-label">Message</label>
                        </td>
                        <td>:</td>
                        <td class="w-100">
                            <!-- <div class="col-sm-9"> -->
                            <div class="pl-3 form-control-plaintext">
                                <p class="mb-0">{{messageDetail}}</p>

                                <!-- <textarea readonly="" class="form-control-plaintext" value="{{messageDetail}}"></textarea> -->
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="showAttachment">
                        <td class="w-25">
                            <label class=" col-form-label">Attachments</label>
                        </td>
                        <td>:</td>
                        <td class="w-100 pl-3">
                            <div *ngFor="let fileObj of attachmentList">
                                <div class="media d-flex m-b-15">
                                    <div class="m-r-20 file-attach">
                                        <i class="far f-28 text-muted" [ngClass]="fileObj.fileIconClass"></i>
                                    </div>
                                    <div class="pt-1">
                                        <a href="javascript:void(0)"
                                            class="m-b-5 d-block text-secondary">{{fileObj.fileName}}</a>
                                    </div>
                                    <div class="float-right pl-4 text-muted">
                                        <button class="btn p-0"> <i class="fas fa-download f-18"
                                                (click)="downloadDocument(fileObj)"></i></button>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="modal-footer p-0"></div>
        </div>
    </div>
</div>

<div *ngIf="showEmailNotificationComponent" class="fade modal show" role="dialog" tabindex="-1" style="display: block;"
    aria-labelledby="id" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header py-2">
                <h2 class="h2 modal-title text-blue">
                    Email Notifications
                </h2>
                <button type="button" class="close text-blue mt-0 pt-1 mb-0 pb-1 mr-0" data-dismiss="modal"
                    aria-label="Close" (click)="hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-2" style="max-height: 350px; overflow-y: auto;">
                <table>
                    <tr>
                        <td class="w-25">
                            <label class=" col-form-label">Date & Time</label>
                        </td>
                        <td>:</td>
                        <td>
                            <div class="col-12 col-sm-12 text-c-black-theme">
                                {{dateAndTime}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-25">
                            <label class=" col-form-label">Subject</label>
                        </td>
                        <td>:</td>
                        <td>
                            <div class="col-12 col-sm-12 text-c-black-theme">
                                {{subject}}
                            </div>
                        </td>
                    </tr>
                </table>
                <ul class="p-0">
                    <li class="background-light d-block p-2 text-center mt-3">
                        <div [innerHTML]="body"></div>
                    </li>
                </ul>
            </div>
            <div class="modal-footer p-0"></div>
        </div>
    </div>
</div>

<div *ngIf="showHRCPlanDetailComponent" class="fade modal show" role="dialog" tabindex="-1" style="display: block;"
    aria-labelledby="id" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header py-2">
                <h2 class="h2 modal-title text-blue">
                    Payment Details
                </h2>
                <button type="button" class="close text-blue mt-0 pt-1 mb-0 pb-1 mr-0" data-dismiss="modal"
                    aria-label="Close" (click)="hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-2" style="max-height: 350px; overflow-y: auto;">
                <table>
                    <tr>
                        <td class="" style="width: 40%!important;">
                            <label class=" col-form-label">No. of category selected</label>
                        </td>
                        <td>:</td>
                        <td>
                            <div class="col-sm-9">
                                <input type="text" readonly="" class="form-control-plaintext"
                                    value="{{selectedExpertiseCount}}">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="" style="width: 40%!important;">
                            <label class=" col-form-label">Months</label>
                        </td>
                        <td>:</td>
                        <td>
                            <div class="col-sm-9">
                                <input type="text" readonly="" class="form-control-plaintext"
                                    value="{{remainingMonths}}">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="" style="width: 40%!important;">
                            <label class=" col-form-label">Price</label>
                        </td>
                        <td>:</td>
                        <td>
                            <div class="col-sm-10 text-c-black-theme">
                                ${{pricePerExpertise}}/per month per category
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="" style="width: 40%!important;">
                            <label class=" col-form-label">Amount</label>
                        </td>
                        <td>:</td>
                        <td>
                            <div class="col-sm-9">
                                <input type="text" readonly="" class="form-control-plaintext"
                                    value="${{finalAmountToBePaid}}">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <div class="text-center pt-3">
                                <button class="btn btn-primary" (click)="payNow(finalAmountToBePaid)">Pay Now</button>
                                <button class="btn btn-secondary" (click)="hide()">Cancel</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="modal-footer p-0"></div>
        </div>
    </div>
</div>