import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class MessageAttachmentBase {

//#region messageAttachmentId Prop
        @prop()
        messageAttachmentId : number;
//#endregion messageAttachmentId Prop


//#region messageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        messageId : number;
//#endregion messageId Prop


//#region attachment Prop
        @required()
        @maxLength({value:500})
        attachment : string;
//#endregion attachment Prop


//#region attachmentURL Prop
        @prop()
        attachmentURL : string;
//#endregion attachmentURL Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @prop()
        createdOn : any;
//#endregion createdOn Prop



}