import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationModalComponent } from './confirmation-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AuthenticationSharedModule } from '../../authentication/authentication.shared.module';
import { SharedModule } from '../shared.module';
import { RxLocalizationModule } from '@rxweb/angular-localization';
import { RxRoutingModule } from '@rxweb/angular-router';


@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, RxReactiveFormsModule,
         RxRoutingModule, RxLocalizationModule, CommonModule, AuthenticationSharedModule,SharedModule
    ],
    declarations: [ConfirmationModalComponent],
    exports: [ConfirmationModalComponent],
    entryComponents:[ConfirmationModalComponent],

})
export class ConfirmationModalModule { }
