import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class UserDataBase {

//#region userId Prop
        @prop()
        userId : number;
//#endregion userId Prop


//#region name Prop
        @maxLength({value:50})
        name : string;
//#endregion name Prop


//#region email Prop
        @maxLength({value:50})
        email : string;
//#endregion email Prop


//#region companyName Prop
        @maxLength({value:100})
        companyName : string;
//#endregion companyName Prop


//#region title Prop
        @maxLength({value:100})
        title : string;
//#endregion title Prop


//#region isRegisteredUser Prop
        @prop()
        isRegisteredUser : boolean;
//#endregion isRegisteredUser Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop

}