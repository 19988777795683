import {EmailNotificationBase} from '../database-models/email-notification-base';
import {EmailDetailBase} from '../database-models/email-detail-base';
//Generated Imports
export class EmailNotification extends EmailNotificationBase 
{




//#region Generated Reference Properties
//#region emailDetail Prop
emailDetail : EmailDetailBase;
//#endregion emailDetail Prop

//#endregion Generated Reference Properties





}