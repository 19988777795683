import { TemplateConfig } from '@rxweb/grid';
import { PipeConstants } from 'src/app/components/shared/constant/commonConstants';


export const RFQ_ADMIN_ACTION_GRID_TEMPLATE: TemplateConfig = {
    div: {
        class: 'formAction list-unstyled mb-0'.split(' '),
        childrens: [
            {
                a: {
                    class: [
                        function (e) {
                            
                            if (this.projectRFQStatus != "Interested" && this.projectRFQStatus!="NotInterested") {
                                return "disabledgridbtn";
                            }
                            else {
                                return "gridEditbtn";
                            }
                        }
                    ],
                    // authorize: 'put',
                    event: {
                        click: 'onApprove'
                    },
                    childrens: [{
                        a: {

                            childrens: [{
                                i: { class: 'fas fa-check'.split(' ') }
                            }],
                        }
                    },
                    ]
                }
            },
            {
                a: {
                    class: [
                        function (e) {
                            if (this.projectRFQStatus != "Interested" && this.projectRFQStatus!="NotInterested") {
                                return "disabledgridbtn";
                            }
                            else {
                                return "gridDeletebtn rejectRFQ".split(" ");
                            }
                        }
                    ],
                    // authorize: 'delete',
                    event: {
                        click: 'onReject'
                    },
                    childrens: [{
                        a: {
                            childrens: [{
                                i: { class: 'fas fa-times'.split(' ') }
                            }],
                        }
                    }]
                }

            },
            {
                a: {
                    class: [
                        function (e) {
                            
                            if (this.projectRFQStatus != "Pending") {
                                return "disabledgridbtn";
                            }
                            else {
                                return "gridEditbtn interestedRFQ".split(" ");
                            }
                        }
                    ],
                    // authorize: 'put',
                    event: {
                        click: 'onInterested'
                    },
                    childrens: [{
                        a: {

                            childrens: [{
                                i: { class: 'fas fa-thumbs-up'.split(' ') }
                            }],
                        }
                    },
                    ]
                }
            },
            {
                a: {
                    class: [
                        function (e) {
                            if (this.projectRFQStatus != "Pending") {
                                return "disabledgridbtn";
                            }
                            else {
                                return "gridDeletebtn notinterestedRFQ".split(" ");
                            }
                        }
                    ],
                    // authorize: 'delete',
                    event: {
                        click: 'onNotInterested'
                    },
                    childrens: [{
                        a: {
                            childrens: [{
                                i: { class: 'fas fa-thumbs-down'.split(' ') }
                            }],
                        }
                    }]
                }

            },
            {
                a: {
                    class: [
                        function (e) {
                            if (this.projectRFQStatus != "Pending" && this.projectRFQStatus!="Interested") {
                                return "disabledgridbtn";
                            }
                            else {
                                return "gridDeletebtn deleteRFQ".split(" ");
                            }
                        }
                    ],
                    authorize: 'delete',
                    event: {
                        click: 'onDelete'
                    },
                    childrens: [{
                        a: {
                            childrens: [{
                                i: { class: 'feather icon-trash-2'.split(' ') }
                                // text:{ 
                                //     text : "Delete"
                                // }
                            }],
                        }
                    }]
                }

            }
           
        ]
    }
};
