import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class USComplianceBase {

//#region uSComplianceId Prop
        @prop()
        uSComplianceId : number;
//#endregion uSComplianceId Prop


//#region categoryId Prop
        @prop()
        categoryId : number;
//#endregion categoryId Prop


//#region hRComplianceId Prop
        @prop()
        hRComplianceId : number;
//#endregion hRComplianceId Prop


//#region stateId Prop
        @prop()
        stateId : number;
//#endregion stateId Prop


//#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId : number;
//#endregion statusId Prop







}