import { TemplateConfig } from '@rxweb/grid';

export const DEADLINE_DATE_GRID_TEMPLATE: TemplateConfig = {
    span: {
        class: [function (e) {
            return this[e.name] == 'Yes' ? "label f-12 text-white activebadge-bg".split(" ") : 'label f-12 text-white inactivebadge-bg'.split(" ");
        }],
        childrens: [{
            text: {
                text: function (e) {
                    return this[e.name] == 'Yes' ? "Yes" : "No"
                }
            }

        }]
    }
}
