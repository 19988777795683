import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EmailTemplateBase {

//#region emailTemplateId Prop
        @prop()
        emailTemplateId : number;
//#endregion emailTemplateId Prop


//#region emailTemplateName Prop
        @required()
        @maxLength({ value: 200 })
        emailTemplateName : string;
//#endregion emailTemplateName Prop


//#region templateBody Prop
        @required()
        templateBody : string;
//#endregion templateBody Prop


//#region mailFrom Prop
        @maxLength({ value: 200 })
        mailFrom : string;
//#endregion mailFrom Prop


//#region mailTo Prop
        @maxLength({ value: 200 })
        mailTo : string;
//#endregion mailTo Prop


//#region subject Prop
        @maxLength({ value: 500 })
        subject : string;
//#endregion subject Prop


//#region cC Prop
        @maxLength({ value: 200 })
        cC : string;
//#endregion cC Prop


//#region bCC Prop
        @maxLength({value:200})
        bCC : string;
//#endregion bCC Prop


//#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @prop()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop

}