import {UserTypeLookupBase} from '../database-models/user-type-lookup-base';
//Generated Imports
export class UserTypeLookup extends UserTypeLookupBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}