import {vEmailSenderBase} from '../database-models/v-email-sender-base';
//Generated Imports
export class vEmailSender extends vEmailSenderBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties

}