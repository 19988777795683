import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { CommonHelperService } from '../shared/common-helpers/helper.service';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule],//, SharedModule, FileModule],
    providers: [CommonHelperService]
})

export class AuthenticationSharedModule { }


