import { Component, OnInit, Input } from '@angular/core';
import { ApplicationBroadcaster } from 'src/app/broadcast-services/application-broadcaster';
import { CoreComponent } from '@rxweb/angular-router';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { StorageKeys, CommonConstants, RoutingKeys, UserRoles } from '../constant/commonConstants';
import { CommonHelperService } from '../common-helpers/helper.service';
import { Router } from '@angular/router';
import { HROManagementListComponent } from '../../user/hro-management/list/hro-management-list.component';
import { HRConsultantListComponent } from '../../user/hr-consultant/list/hr-consultant-list.component';
import { RoleListComponent } from '../../user/roles/list/role-list.component';
import { FeedbackListComponent } from '../../project/feedbacks/list/feedback-list.component';
import { ProjectListComponent } from '../../project/projects/list/project-list.component';
import { ProjectRFQHROListComponent } from '../../project/project-r-f-q/project-r-f-q-hro/list/project-r-f-q-hro-list.component';
import { ContractListComponent } from '../../project/contracts/list/contract-list.component';
import { UploadAttachmentListComponent } from '../../master/upload-attachments/list/upload-attachment-list.component';
import { AllProjectListComponent } from '../../user/hr-consultant/hrc-all-project/list/all-project-list.component';
import { ProjectRFQHRCListComponent } from '../../project/project-r-f-q/project-r-f-q-hrc/list/project-r-f-q-hrc-list.component';
import { TestimonialListComponent } from '../../project/testimonials/list/testimonial-list.component';
import { PublicRFPListComponent } from '../../master/public-r-f-p/list/public-r-f-p-list.component';
import { API } from '../constant/administrationAPIContants';

//import $ as 'jquery';
declare const $: any;

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent extends CoreComponent implements OnInit {

  isMenuHide: boolean;
  activeMenu: number = 1;
  activeSubMenu: number = 1;
  applyActive: boolean;
  dashboard = false;
  userManagement = false;
  hroManagement = false;
  hrcManagement = false;
  roleManagement = false;
  masterManagement = false;
  projectRFQRequestResponseManagement = false;
  contractsManagement = false;
  feedbackManagement = false;
  subscriptionPlanManagement = false;
  subscriptionPlanCancellationResponses = false;
  paymentResponseSubscriptions = false;
  expertiseCategoryManagement = false;
  expertiseSubCategoryManagement = false;
  questionsManagement = false;
  subscriptionTypeManagement = false;
  yearsOfExperienceManagement = false;
  cityManagement = false;
  stateManagement = false;
  countryManagement = false;
  companySizeManagement = false;
  monthlyRetainedServiceOptionManagement = false;
  monthlyRetainedServiceManagement = false;
  emailTemplateManagement = false;
  staticPageManagement = false;
  systemSettings = false;
  reports = false;
  myProjects = false;
  quoteMessages = false;
  myContracts = false;
  feedbacks = false;
  projectRFQResponse = false;
  myTestimonials = false;
  isSuperAdmin = false;
  isHRO = false;
  isHRC = false;
  isGuestHRC = false;
  quoteMessagesCount: number;

  hroList: any = HROManagementListComponent;
  hrcList: any = HRConsultantListComponent;
  roleList: any = RoleListComponent;
  feedbackList: any = FeedbackListComponent;
  projectList: any = ProjectListComponent;
  quoteMessageList: any = ProjectRFQHROListComponent;
  contractList: any = ContractListComponent;
  hrComplianceList: any = UploadAttachmentListComponent;
  allProjectList: any = AllProjectListComponent;
  pRFQResponseList: any = ProjectRFQHRCListComponent;
  testimonialList: any = TestimonialListComponent;
  publicRFPList: any = PublicRFPListComponent;
  

  constructor(
    private applicationBroadCaster: ApplicationBroadcaster,
    private commonHelperService: CommonHelperService,
    private storageData: BrowserStorage) {
    super();
    this.applicationBroadCaster.quoteMessagesSubscriber.subscribe(t => {
      this.quoteMessagesCount = t;
    });
  }

  ngOnInit() {
   let menu= this.storageData.local.get("activeMenu");
   if(menu!=undefined)
      this.activeMenu = menu;
    const userRole = this.storageData.local.get(StorageKeys.UserRole) as string;
    if (!this.commonHelperService.isStringNullEmptyOrWhiteSpace(userRole)) {
      switch (parseInt(userRole)) {
        case UserRoles.Admin:
          this.isSuperAdmin = true;
          break;
        case UserRoles.HRO:
          this.isHRO = true;
          break;
        case UserRoles.HRConsultant:
          this.isHRC = true;
          break;
        case UserRoles.GuestHRC:
          this.isGuestHRC = true;
          break;
      }
    }
    const userId = this.storageData.local.get(StorageKeys.UserId);
    this.get({ path: API.quoteMessageCount, queryParams: { "Id": [userId] } }).subscribe((t: any) => {
      this.quoteMessagesCount = t;
    })
  }

  activeMenuItem(menu) {
    this.activeMenu = menu;
    this.storageData.local.save("activeMenu", menu);
  }
 
  setMenu(activeMenu) {
    switch (activeMenu) {
      case 1:
        this.showHide('dashboard', !this.dashboard);
        break;
      case 2:
        this.showHide('userManagement', !this.userManagement);
        break;
      case 3:
        this.showHide('masterManagement', !this.masterManagement);
        break;
      case 4:
        this.showHide('projectRFQRequestResponseManagement', !this.projectRFQRequestResponseManagement);
        break;
      case 5:
        this.showHide('contractsManagement', !this.contractsManagement);
        break;
      case 6:
        this.showHide('feedbackManagement', !this.feedbackManagement);
        break;
      case 7:
        this.showHide('subscriptionPlanManagement', !this.subscriptionPlanManagement);
        break;
      case 8:
        this.showHide('subscriptionPlanCancellationResponses', !this.subscriptionPlanCancellationResponses);
        break;
      case 9:
        this.showHide('paymentResponseSubscriptions', !this.paymentResponseSubscriptions);
        break;
      case 10:
        this.showHide('expertiseCategoryManagement', !this.expertiseCategoryManagement);
        break;
      case 11:
        this.showHide('expertiseSubCategoryManagement', !this.expertiseSubCategoryManagement);
        break;
      case 12:
        this.showHide('questionsManagement', !this.questionsManagement);
        break;
      case 13:
        this.showHide('subscriptionTypeManagement', !this.subscriptionTypeManagement);
        break;
      case 14:
        this.showHide('yearsOfExperienceManagement', !this.yearsOfExperienceManagement);
        break;
      case 15:
        this.showHide('cityManagement', !this.cityManagement);
        break;
      case 16:
        this.showHide('stateManagement', !this.stateManagement);
        break;
      case 17:
        this.showHide('countryManagement', !this.countryManagement);
        break;
      case 18:
        this.showHide('companySizeManagement', !this.companySizeManagement);
        break;
      case 19:
        this.showHide('monthlyRetainedServiceOptionManagement', !this.monthlyRetainedServiceOptionManagement);
        break;
      case 20:
        this.showHide('monthlyRetainedServiceManagement', !this.monthlyRetainedServiceManagement);
        break;
      case 21:
        this.showHide('emailTemplateManagement', !this.emailTemplateManagement);
        break;
      case 22:
        this.showHide('staticPageManagement', !this.staticPageManagement);
        break;
      case 23:
        this.showHide('reports', !this.reports);
        break;
      case 24:
        this.showHide('myProjects', !this.myProjects);
        break;
      case 25:
        this.showHide('quoteMessages', !this.quoteMessages);
        break;
      case 26:
        this.showHide('myContracts', !this.myContracts);
        break;
      case 27:
        this.showHide('feedbacks', !this.feedbacks);
        break;
      case 28:
        this.showHide('projectRFQResponse', !this.projectRFQResponse);
        break;
      case 29:
        this.showHide('myTestimonials', !this.myTestimonials);
        break;
      case 30:
        this.showHide('hroManagement', !this.hroManagement);
        break;
      case 31:
        this.showHide('hrcManagement', !this.hrcManagement);
        break;
      case 32:
        this.showHide('roleManagement', !this.roleManagement);
        break;
    }
  }

  activeSubMenuItem(activeSubMenu: number): void {
    this.activeSubMenu = activeSubMenu;
    // this.setOthersFalse();
    // this.setMenu(this.activeSubMenu);
    // this.activeMenu=0;
    // this.storage.session.save("selectedSubModule", activeSubMenu);
  }

  showHide(itemClicked: string, status: boolean, isCalledFromUI: boolean = false): void {
    if (isCalledFromUI && !this.applyActive) return;

    switch (itemClicked) {
      case 'dashboard':
        this.dashboard = status;
        break;
      case 'userManagement':
        this.userManagement = status;
        break;
      case 'hroManagement':
        this.hroManagement = status;
        break;
      case 'hrcManagement':
        this.hrcManagement = status;
        break;
      case 'roleManagement':
        this.roleManagement = status;
        break;
      case 'masterManagement':
        this.masterManagement = status;
        break;
      case 'projectRFQRequestResponseManagement':
        this.projectRFQRequestResponseManagement = status;
        break;
      case 'contractsManagement':
        this.contractsManagement = status;
        break;
      case 'feedbackManagement':
        this.feedbackManagement = status;
        break;
      case 'subscriptionPlanManagement':
        this.subscriptionPlanManagement = status;
        break;
      case 'subscriptionPlanCancellationResponses':
        this.subscriptionPlanCancellationResponses = status;
        break;
      case 'paymentResponseSubscriptions':
        this.paymentResponseSubscriptions = status;
        break;
      case 'expertiseCategoryManagement':
        this.expertiseCategoryManagement = status;
        break;
      case 'expertiseSubCategoryManagement':
        this.expertiseSubCategoryManagement = status;
        break;
      case 'questionsManagement':
        this.questionsManagement = status;
        break;
      case 'subscriptionTypeManagement':
        this.subscriptionTypeManagement = status;
        break;
      case 'yearsOfExperienceManagement':
        this.yearsOfExperienceManagement = status;
        break;
      case 'cityManagement':
        this.cityManagement = status;
        break;
      case 'stateManagement':
        this.stateManagement = status;
        break;
      case 'countryManagement':
        this.countryManagement = status;
        break;
      case 'companySizeManagement':
        this.companySizeManagement = status;
        break;
      case 'monthlyRetainedServiceOptionManagement':
        this.monthlyRetainedServiceOptionManagement = status;
        break;
      case 'monthlyRetainedServiceManagement':
        this.monthlyRetainedServiceManagement = status;
        break;
      case 'emailTemplateManagement':
        this.emailTemplateManagement = status;
        break;
      case 'staticPageManagement':
        this.staticPageManagement = status;
        break;
      case 'reports':
        this.reports = status;
        break;
      case 'myProjects':
        this.myProjects = status;
        break;
      case 'quoteMessages':
        this.quoteMessages = status;
        break;
      case 'myContracts':
        this.myContracts = status;
        break;
      case 'feedbacks':
        this.feedbacks = status;
        break;
      case 'projectRFQResponse':
        this.projectRFQResponse = status;
        break;
      case 'myTestimonials':
        this.myTestimonials = status;
        break;
    }
  }

  setOthersFalse(): void {
    this.dashboard = false;
    this.userManagement = false;

    this.masterManagement = false;
    this.projectRFQRequestResponseManagement = false;
    this.contractsManagement = false;
    this.feedbackManagement = false;
    this.subscriptionPlanManagement = false;
    this.subscriptionPlanCancellationResponses = false;
    this.paymentResponseSubscriptions = false;
    this.expertiseCategoryManagement = false;
    this.expertiseSubCategoryManagement = false;
    this.questionsManagement = false;
    this.subscriptionTypeManagement = false;
    this.yearsOfExperienceManagement = false;
    this.cityManagement = false;
    this.stateManagement = false;
    this.countryManagement = false;
    this.companySizeManagement = false;
    this.monthlyRetainedServiceOptionManagement = false;
    this.monthlyRetainedServiceManagement = false;
    this.emailTemplateManagement = false;
    this.staticPageManagement = false;
    this.systemSettings = false;
    this.reports = false;
    this.myProjects = false;
    this.quoteMessages = false;
    this.myContracts = false;
    this.feedbacks = false;
    this.projectRFQResponse = false;
    this.myTestimonials = false;
  }

  ngAfterViewInit() {
    //Load JS after View
    $.getScript('../../../../assets/js/pcoded.js');
  }

}
