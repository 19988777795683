import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, date } from '@rxweb/reactive-form-validators';

import { Expertize, ExpertizeLookup, PublicRFP, PublicRFPCategory } from '@app/models';
import { AbstractPublicRFP } from '../domain/abstract-public-r-f-p';
import { Router } from "@angular/router";
import { StatusEnum } from "src/app/enums/status.enum";
import { RoutingKeys } from "src/app/components/shared/constant/commonConstants";
import { CustomMessage } from "src/app/components/shared/constant/custom-message";
import { MasterLookupGroup } from "src/app/viewModel/master-lookup-group";
import { MASTER_LOOKUPS } from "src/app/uris/master-lookups.uri";
import { access } from "@rxweb/angular-router";
import { ApplicationModules, PermissionNames } from "src/app/enums/application-modules.enum";

@access({ accessLevel: ApplicationModules.PublicRFP, action: PermissionNames.Post })

@Component({
    selector: "app-public-r-f-p-add",
    templateUrl: './public-r-f-p-add.component.html'
})
export class PublicRFPAddComponent extends AbstractPublicRFP implements OnInit, OnDestroy {
    publicRFP: PublicRFP;
    subscription: Subscription;
    expertizeList: ExpertizeLookup[] = [];
    selectedCategoryIds: any[] = [];
    publicRFPCategoryList: PublicRFPCategory[];


    constructor(private formBuilder: RxFormBuilder, private route: Router) {
        super();
    }

    ngOnInit(): void {
        this.spin = false;
        this.publicRFP = new PublicRFP();
        this.publicRFPCategoryList = new Array<PublicRFPCategory>();
        this.publicRFPFormGroup = this.formBuilder.formGroup(this.publicRFP) as IFormGroup<PublicRFP>;
        this.lookup<MasterLookupGroup>(
            [
                { path: MASTER_LOOKUPS.expertizeLookup, propName: 'expertizeLookup' },
            ]).subscribe((response: any) => {
                this.expertizeList = response.expertizeLookup;
            });

        this.setDefaultValues();
        this.showComponent = true;
        this.spin = false;

    }

    setDefaultValues() {
        this.publicRFPFormGroup.patchValue({
            "publicRFPId": 0,
            "statusId": StatusEnum.Active,
            "createdBy": this.user.userId,
            "createdOn": new Date(),
        });
    }

    selectPublicRFPCategory(isChecked: boolean, value: any) {
        if (isChecked) {
            let index = this.expertizeList.findIndex(t => t.expertizeId == value);
            this.expertizeList[index].checked = true;
        }
        else {
            let index = this.expertizeList.findIndex(t => t.expertizeId == value);
            this.expertizeList[index].checked = false;
        }
    }

    addPublicRFP() {
        this.expertizeList.forEach(element => {
            if (element.checked) {
                let publicRFPCategory = new PublicRFPCategory();
                publicRFPCategory.publicRFPCategoryId = 0;
                publicRFPCategory.publicRFPId = 0;
                publicRFPCategory.categoryId = element.expertizeId;
                publicRFPCategory.statusId = StatusEnum.Active;
                publicRFPCategory.createdBy = this.user.userId;
                publicRFPCategory.createdOn = new Date();
                this.publicRFPCategoryList.push(publicRFPCategory);
            }
        });
        this.publicRFPFormGroup.value.publicRFPCategories = this.publicRFPCategoryList;

        if (this.publicRFPFormGroup.valid) {
            this.spin = true;
            this.post({ body: this.publicRFPFormGroup.value }).subscribe(response => {
                this.spin = false;
                this.toastr.success(CustomMessage.dataAdded);
                this.route.navigate([RoutingKeys.PublicRFP]);
            })
        }
    }

    backToList() {
        this.route.navigate([RoutingKeys.PublicRFP]);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

}
