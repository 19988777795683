import {TransectionDetailBase} from '../database-models/transection-detail-base';
//Generated Imports
export class TransectionDetail extends TransectionDetailBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties

}