import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ExpertizeLookupBase {

//#region expertizeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'expertizeId', keyColumn: true})
        expertizeId : number;
//#endregion expertizeId Prop


//#region expertizeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'expertizeName', keyColumn: false})
        expertizeName : string;
//#endregion expertizeName Prop

}