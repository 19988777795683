import {UserDataBase} from '../database-models/user-data-base';
//Generated Imports
export class UserData extends UserDataBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






}