import {vFeedbackOptionBase} from '../database-models/v-feedback-option-base';
//Generated Imports
export class vFeedbackOption extends vFeedbackOptionBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





























}