import { TemplateConfig } from '@rxweb/grid';
import { PipeConstants } from 'src/app/components/shared/constant/commonConstants';


export const FEEDBACK_VIEW_GRID_TEMPLATE: TemplateConfig = {
    div: {
        class: 'formAction list-unstyled mb-0'.split(' '),
        childrens: [
        {
            a: {
                class: 'gridinfobttn'.split(' '),
                authorize: 'get',
                event: {
                    click: 'viewDetails'
                },
                childrens: [{
                    a: {
                        childrens: [{
                            i: { class: 'fas fa-address-card'.split(' ') }
                            // text: {
                            //     text: "View Details"
                            // }
                        }],
                    }
                }]
            }

        }
        ]
    }
};



