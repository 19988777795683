import { prop, propObject, propArray, required, maxLength, range, fileSize } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class UserDetailBase {

        //#region userDetailsId Prop
        @prop()
        userDetailsId: number;
        //#endregion userDetailsId Prop


        //#region userId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        userId: number;
        //#endregion userId Prop


        //#region headline Prop
        @maxLength({ value: 100 })
        headline: string;
        //#endregion headline Prop


        //#region photo Prop
        @prop()
        @fileSize({ maxSize: 5 })
        photo: string;
        //#endregion photo Prop


        //#region summary Prop
        //@maxLength({ value: 500 })
        @prop()
        summary: string;
        //#endregion summary Prop


        //#region achievements Prop
        //@maxLength({ value: 500 })
        @prop()
        achievements: string;
        //#endregion achievements Prop


        //#region yearsOfExperienceId Prop
        @prop()
        yearsOfExperienceId: number;
        //#endregion yearsOfExperienceId Prop


        //#region subscriptionPlanId Prop
        @prop()
        subscriptionPlanId: number;
        //#endregion subscriptionPlanId Prop


        //#region startDate Prop
        @prop()
        startDate: Date;
        //#endregion startDate Prop


        //#region endDate Prop
        @prop()
        endDate: Date;
        //#endregion endDate Prop


        //#region paymentStatusId Prop
        @prop()
        paymentStatusId: number;
        //#endregion paymentStatusId Prop


        //#region ratings Prop
        @range({ minimumNumber: 0, maximumNumber: 5 })
        ratings: number;
        //#endregion ratings Prop


        //#region customerProfileId Prop
        @maxLength({ value: 50 })
        customerProfileId: string;
        //#endregion customerProfileId Prop


        //#region customerPaymentProfileId Prop
        @maxLength({ value: 50 })
        customerPaymentProfileId: string;
        //#endregion customerPaymentProfileId Prop


        //#region photoUrl Prop
        @prop()
        photoUrl: string;
        //#endregion photoUrl Prop

        //#region customerSubscriptionId Prop
        @maxLength({ value: 50 })
        customerSubscriptionId: string;
        //#endregion customerSubscriptionId Prop

        //#region paymentAccessKey Prop
        @prop()
        paymentAccessKey: string;
        //#endregion paymentAccessKey Prop







}