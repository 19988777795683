import {vYearOfExperienceBase} from '../database-models/v-year-of-experience-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'action' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vYearOfExperience extends vYearOfExperienceBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




















}