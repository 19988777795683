import { CoreComponent } from '@rxweb/angular-router';
import { http, RxHttp } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { Project } from '@app/models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { Query } from 'src/app/viewModel/sp-parameter';
import { ProjectFilterValuesViewModel } from 'src/app/viewModel/project-filterValues-view-model';
import { RolesEnum } from 'src/app/enums/roles-enum';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { QuestionsEnum } from 'src/app/enums/questions.enum';
import { API } from 'src/app/components/shared/constant/administrationAPIContants';
@http({
    path: "api/Projects",
})
export class AbstractProject extends CoreComponent {
    projectFormGroup: IFormGroup<Project>
    searchFormGroup: IFormGroup<ProjectFilterValuesViewModel>;
    spin = false;
    showComponent: boolean = false;
    toastr: BaseToastr;
    breadCrumb: BreadCrumb;
    rxHttp: RxHttp;
    fileArray: Array<any>;

    file1size: number = 0;
    file2size: number = 0;

    file1ListCount: number = 0;
    file2ListCount: number = 0;

    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
        this.rxHttp = new RxHttp();
        this.badRequest;
    }

    upload(selectedFiles, questionId) {
        this.fileArray = new Array<any>();
        const max_size = 1.5e+7; //5mb size filter in bytes
        const file_size = 3e+6; //3mb size filter in bytes
        if (QuestionsEnum.Question5 == questionId) {
            this.file1ListCount = this.file1ListCount + selectedFiles.length;
            var value = this.checkFiles(this.file1ListCount, selectedFiles, file_size, max_size, questionId);
            if (value != undefined && value.length > 0) {
                return value;
            }
            else {
                return null;
            }
        }
        else if (QuestionsEnum.Question9 == questionId) {
            this.file2ListCount = this.file2ListCount + selectedFiles.length;
            var value = this.checkFiles(this.file2ListCount, selectedFiles, file_size, max_size, questionId);
            if (value != undefined && value.length > 0) {
                return value;
            }
            else {
                return null;
            }
        }
    }

    checkFiles(fileListCount, selectedFiles, file_size, max_size, questionId) {
        var istotalFileSize = false;
        if (fileListCount > 0 && fileListCount <= 5) {
            for (var index = 0; index < selectedFiles.length; index++) {
                if (selectedFiles[index].size <= file_size) {
                    if (QuestionsEnum.Question5 == questionId) {
                        this.file1size = this.file1size + selectedFiles[index].size;
                        // totalFileSize = this.file1size;
                        if (this.file1size >= max_size) {
                            istotalFileSize = true;
                        }
                    }
                    else if (QuestionsEnum.Question9 == questionId) {
                        this.file2size = this.file2size + selectedFiles[index].size;
                        // totalFileSize = this.file2size;
                        if (this.file2size >= max_size) {
                            istotalFileSize = true;

                        }
                    }
                    if (istotalFileSize) {
                        this.toastr.error(CustomMessage.fileSizeValidation15mb);
                    }
                    else {
                        // var selectedFilename = selectedFiles[index].name
                        let ext = selectedFiles[index].name.split('.').pop();
                        // fileType = selectedFiles[index].type;
                        ext = ext.toLowerCase();
                        if (selectedFiles[index]) {
                            if (["jpg", "png", "jpeg", "pdf", "doc", "docx", "pptx", "ppt", "xlsx", "xls"].indexOf(ext) != -1) {
                                this.fileArray.push(selectedFiles[index]);
                            }
                            else {
                                fileListCount = fileListCount - 1;
                                if (QuestionsEnum.Question5 == questionId) {
                                    this.file1ListCount = fileListCount;
                                    this.file1size = this.file1size - selectedFiles[index].size;
                                }
                                else if (QuestionsEnum.Question9 == questionId) {
                                    this.file2ListCount = fileListCount;
                                    this.file2size = this.file2size - selectedFiles[index].size;
                                }
                                this.toastr.error(CustomMessage.uploadFileErrorMsg);
                            }
                        }
                    }
                }
                else {
                    fileListCount = fileListCount - 1;
                    if (QuestionsEnum.Question5 == questionId) {
                        this.file1ListCount = fileListCount;
                        this.file1size = this.file1size - selectedFiles[index].size;
                    }
                    else if (QuestionsEnum.Question9 == questionId) {
                        this.file2ListCount = fileListCount;
                        this.file2size = this.file2size - selectedFiles[index].size;
                    }
                    this.toastr.error(CustomMessage.fileSizeValidation3mb);
                }
            }
            return this.fileArray;
        }
        else {
            fileListCount = fileListCount - selectedFiles.length;
            if (QuestionsEnum.Question5 == questionId) {
                this.file1ListCount = fileListCount;
            }
            else if (QuestionsEnum.Question9 == questionId) {
                this.file2ListCount = fileListCount;
            }
            this.toastr.error(CustomMessage.fileCountValidation);
        }
    }

    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'My Projects';
        this.breadCrumb.pageName = 'My Projects';
        this.breadCrumb.pageRedirect = "projects";
    }
    quoteBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'Quote Messages';
        this.breadCrumb.pageName = "Quote Messages";
        this.breadCrumb.pageRedirect = 'quote-message';
    }
    contractBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";
        var RoleId = this.user.roleId;
        if (RoleId == RolesEnum.SuperAdmin) {
            this.breadCrumb.title = 'Contracts Management'
            this.breadCrumb.pageName = 'Contracts Management'
        }
        else {
            this.breadCrumb.title = 'My Contracts'
            this.breadCrumb.pageName = 'My Contracts'
        }
        this.breadCrumb.pageRedirect = 'contracts';
    }
    projectRFQAdminBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'Project RFQ Request & Response Management';
        this.breadCrumb.pageName = "Project RFQ Request & Response Management";
        this.breadCrumb.pageRedirect = 'project-r-f-q-admin';
    }
    projectRFQResponseBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'My RFQ Response';
        this.breadCrumb.pageName = "My RFQ Response";
        this.breadCrumb.pageRedirect = 'project-r-f-q-response';
    }

    allProjectRFQResponseBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'My Projects';
        this.breadCrumb.pageName = "My Projects";
        this.breadCrumb.pageRedirect = 'hrc/hrcProjectList';
    }

    feedbackBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";
        var RoleId = this.user.roleId;

        if (RoleId == RolesEnum.SuperAdmin) {
            this.breadCrumb.title = 'Feedback Management';
            this.breadCrumb.pageName = 'Feedback Management';
        }
        else {
            this.breadCrumb.title = 'Feedback';
            this.breadCrumb.pageName = 'Feedback';
        }
        this.breadCrumb.pageRedirect = 'feedbacks';
    }
    badRequest = (data) => {
        this.spin = false;
        let messages = JSON.parse(data);
        this.toastr.warning(messages[0]);
    }
    getMatchingHRC(searchParams: Query) {
        return this.rxHttp.post({ body: searchParams, path: API.searchHRCMatching });
    }    
}