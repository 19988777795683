import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, ErrorMessageBindingStrategy, date } from '@rxweb/reactive-form-validators';
import { Feedback, FeedbackDetail, HRConsultantLookup, MonthlyRetainedOptionLookup, MonthlyRetainedService, ProjectLookup } from '@app/models';
import { AbstractFeedback } from '../domain/abstract-feedback';
import { Router } from '@angular/router';
import { RoutingKeys } from 'src/app/components/shared/constant/commonConstants';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { FeedbackListComponent } from '../list/feedback-list.component';
import { FeedbackLookupGroup } from 'src/app/viewModel/feedback-lookup-group';
import { FEEDBACK_LOOKUPS } from 'src/app/components/shared/constant/feedback-lookup-api-constants';
import { ProjectQuestionTypeEnum } from 'src/app/enums/project-question-type.enum';
import { StatusEnum } from 'src/app/enums/status.enum';
import { RxSelectComponent } from '@rxweb/angular/select/rx-select.component';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { ApplicationModules, PermissionNames } from 'src/app/enums/application-modules.enum';
import { access } from '@rxweb/angular-router';
import { API } from "src/app/components/shared/constant/administrationAPIContants";

@access({ accessLevel: ApplicationModules.Feedbacks, action: PermissionNames.Post })

@Component({
    selector: "app-feedback-add",
    templateUrl: './feedback-add.component.html'
})
export class FeedbackAddComponent extends AbstractFeedback implements OnInit, OnDestroy {
    @ViewChild("rxselectlookup") rxSelect: RxSelectComponent;
    feedback: Feedback;
    subscription: Subscription;
    feedbackLookupGroup: FeedbackLookupGroup;
    feedbackDetailFormGroup: IFormGroup<FeedbackDetail>;
    feedbackDetailsArray: FeedbackDetail[];
    feedbackDetail: FeedbackDetail;
    feedbackOPtionLookup: Array<MonthlyRetainedOptionLookup>;
    monthlyRetainedServiceList: Array<MonthlyRetainedService>;
    monthlyRetainedServiceType = ProjectQuestionTypeEnum.TextArea;
    tempArrayForReuse: FeedbackDetail[];
    modelView: ModalView;

    constructor(private formBuilder: RxFormBuilder, private route: Router,
        modalView: ModalView
    ) {
        super();
        this.modelView = modalView;
    }

    ngOnInit(): void {
        this.spin = true;
        this.feedback = new Feedback();
        this.feedbackFormGroup = this.formBuilder.formGroup(this.feedback) as IFormGroup<Feedback>;
        this.feedbackLookupGroup = new FeedbackLookupGroup();
        this.lookup<FeedbackLookupGroup>([
            { path: FEEDBACK_LOOKUPS["projectLookup"], propName: "projectLookup" },
            { path: FEEDBACK_LOOKUPS["monthlyRetainedOptionLookup"], propName: "monthlyRetainedOptionLookup" },
            { path: FEEDBACK_LOOKUPS["hrConsultantLookup"], propName: "hrConsultantLookup" },
        ]).subscribe(response => {
            this.spin = false;
            this.feedbackLookupGroup.monthlyRetainedOptionLookup = response.monthlyRetainedOptionLookup;
            this.feedbackLookupGroup.projectLookup = response.projectLookup.filter(t => t.userId == this.user.userId);
            this.feedbackLookupGroup.hrConsultantLookup = response.hrConsultantLookup;
            this.feedbackDetailsArray = new Array<FeedbackDetail>();
            this.tempArrayForReuse = new Array();
            this.feedbackDetail = new FeedbackDetail();
            this.feedbackLookupGroup.monthlyRetainedOptionLookup.forEach(element => {
                element.isSelected = false;
            });
            this.feedbackLookupGroup.monthlyRetainedOptionLookup[0].isSelected = true;
            this.feedbackFormGroup.controls.feedbackOptionId.setValue(this.feedbackLookupGroup.monthlyRetainedOptionLookup[0].monthlyRetainedOptionId);
            this.changeService(this.feedbackLookupGroup.monthlyRetainedOptionLookup[0].monthlyRetainedOptionId);
        });
        this.setDefaultValues();
        this.showComponent = true;
    }

    setDefaultValues() {
        this.feedbackFormGroup.patchValue({
            "createdBy": this.user.userId,
            "userId": this.user.userId,
            "createdOn": new Date(),
            "feedbackId": 0,
            "statusId": StatusEnum.Active,
            "ratings": 0
        });
    }

    changeHRCLookup(event: any) {
        if (event.sourceItem != undefined) {
            this.lookup<FeedbackLookupGroup>([{ path: FEEDBACK_LOOKUPS["hrConsultantLookup"], propName: "hrConsultantLookup", queryParams: { "projectId": [event.sourceItem.projectId] } }]).subscribe(resp => {
                this.spin = false;
                this.rxSelect.resetSource(resp.hrConsultantLookup);
            });
            this.feedbackFormGroup.controls.hRConsultantId.markAsUntouched();
        }
    }

    changeService(mroId: number) {
        this.spin = true;
        this.monthlyRetainedServiceList = new Array<MonthlyRetainedService>();
        this.feedbackDetailsArray = [];
        this.tempArrayForReuse = [];
        this.get({ path: API.services, queryParams: { 'Id': [mroId] } }).subscribe((t: any) => {
            this.spin = false;
            this.monthlyRetainedServiceList = JSON.parse(t[0].optionService);
            if (this.monthlyRetainedServiceList.length > 0) {
                this.monthlyRetainedServiceList.forEach((element: any) => {
                    var fd = new FeedbackDetail();
                    fd.feedbackId = this.feedbackFormGroup.value.feedbackId;
                    fd.feedbackDetailsId = 0;
                    fd.feedbackQuestion = mroId;
                    fd.questionText = element.monthlyRetainedServiceName;
                    // fd.isRequired = element.isRequired;
                    fd.monthlyRetainedServiceId = element.monthlyRetainedServiceId;
                    this.feedbackDetailsArray.push(fd);
                    this.tempArrayForReuse.push(fd);

                });
            }
        });
    }

    changeOption(id: number) {
        //     let isNotBlank = false;
        //     if (this.feedbackFormGroup.value.feedbackOptionId != null && this.feedbackFormGroup.value.feedbackOptionId != id) {
        //         for (let i = 0; i < this.feedbackDetailsArray.length; i++) {
        //             if (this.feedbackDetailsArray[i].feedbackAnswer != null && this.feedbackDetailsArray[i].feedbackAnswer != 0) {
        //                 isNotBlank = true;
        //                 break;
        //             }
        //         }
        //         if (!isNotBlank) {
        //             this.feedbackLookupGroup.monthlyRetainedOptionLookup.forEach(element => {
        //                 if (element.monthlyRetainedOptionId == id) {
        //                     element.isSelected = true;
        //                     this.feedbackFormGroup.controls.feedbackOptionId.setValue(id);
        //                     this.changeService(element.monthlyRetainedOptionId);
        //                 }
        //                 else
        //                     element.isSelected = false;
        //             });
        //         }
        //         else {
        //             this.modelView.show(ConfirmationModalComponent, { componentType: 'feedbackOptionChange' }).then(t => {
        //                 if (t == 'yes') {
        //                     this.feedbackLookupGroup.monthlyRetainedOptionLookup.forEach(element => {
        //                         if (element.monthlyRetainedOptionId == id) {
        //                             element.isSelected = true;
        //                             this.feedbackFormGroup.controls.feedbackOptionId.setValue(id);
        //                             this.changeService(element.monthlyRetainedOptionId);
        //                         }
        //                         else
        //                             element.isSelected = false;
        //                     });
        //                 }
        //             });
        //         }
        //     }
    }



    getRatings(value: any) {
        this.feedbackFormGroup.controls.ratings.setValue(value);
    }

    getAnswer(event: any, index: number) {
        if (event == true) {
            this.feedbackDetailsArray[index].isSelected = true;
            this.tempArrayForReuse[index].isSelected = true;
        }
        else if (event == false) {
            this.feedbackDetailsArray[index].isSelected = false;
            this.tempArrayForReuse[index].isSelected = false;
        }
    }

    AddFeedback() {
        this.spin = true;
        // let isError = false;
        // for (var item = 0; item < this.feedbackDetailsArray.length; item++) {
        //     if (this.feedbackDetailsArray[item].monthlyRetainedServiceId == 0) {
        //         if (this.feedbackDetailsArray[item].isRequired) {
        //             this.toastr.error(CustomMessage.isRequiredField);
        //             isError = true;
        //             break;
        //         }
        //     }
        // }
        //  if (isError == false) {
        let fdarray = new Array<FeedbackDetail>();

        this.feedbackDetailsArray.forEach(element => {
            if (element.isSelected)
                fdarray.push(element);
        });
        if (fdarray.length > 0) {
            this.feedbackFormGroup.value.feedbackDetails = fdarray;
            if (this.feedbackFormGroup.valid) {
                this.post({ body: this.feedbackFormGroup.value }).subscribe(response => {
                    this.spin = false;
                    this.toastr.success(CustomMessage.dataAdded);
                    this.route.navigate([RoutingKeys.FeedBacks]);
                })
            }
            else {
                this.feedbackDetailsArray = this.tempArrayForReuse;
            }
        }
        else {
            this.toastr.warning(CustomMessage.FeedbackDetailValidation);
            this.feedbackDetailsArray = this.tempArrayForReuse;
            this.spin = false;
        }
    }

    backToList() {
        this.route.navigate([RoutingKeys.FeedBacks]);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

}
