import { prop,propObject,propArray,required,maxLength,range ,numeric, NumericValueType } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SubscriptionTypeBase {

//#region subscriptionTypeId Prop
        @prop()
        subscriptionTypeId : number;
//#endregion subscriptionTypeId Prop


//#region subscriptionTypeName Prop
        @required()
        @maxLength({value:100})
        subscriptionTypeName : string;
//#endregion subscriptionTypeName Prop


//#region subscriptionTypeDescription Prop
        @maxLength({value:500})
        subscriptionTypeDescription : string;
//#endregion subscriptionTypeDescription Prop


//#region validMonths Prop
        @prop()
        @numeric({acceptValue:NumericValueType.PositiveNumber  ,allowDecimal:false })
        validMonths : number;
//#endregion validMonths Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @prop()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop



}