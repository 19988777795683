import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vContractRecordBase {

//#region contractId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'contractId', keyColumn: true})
        contractId : number;
//#endregion contractId Prop


//#region projectId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'projectId', keyColumn: false})
        projectId : number;
//#endregion projectId Prop


//#region contractDetails Prop
        @gridColumn({visible: false, columnIndex:3, allowSorting: true, headerKey: 'contractDetails', keyColumn: false})
        contractDetails : string;
//#endregion contractDetails Prop


//#region contractStartDate Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'contractStartDate', keyColumn: false})
        contractStartDate : any;
//#endregion contractStartDate Prop


//#region contractEndDate Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'contractEndDate', keyColumn: false})
        contractEndDate : any;
//#endregion contractEndDate Prop


//#region pricing Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'pricing', keyColumn: false})
        pricing : any;
//#endregion pricing Prop


//#region contractStatus Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'contractStatus', keyColumn: false})
        contractStatus : any;
//#endregion contractStatus Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : any;
//#endregion statusId Prop


//#region createdBy Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'createdBy', keyColumn: false})
        createdBy : any;
//#endregion createdBy Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop


//#region hRConsultantId Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'hRConsultantId', keyColumn: false})
        hRConsultantId : number;
//#endregion hRConsultantId Prop

}