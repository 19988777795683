import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CityLookupBase {

//#region cityId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'cityId', keyColumn: false})
        cityId : number;
//#endregion cityId Prop


//#region cityName Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'cityName', keyColumn: false})
        cityName : string;
//#endregion cityName Prop


//#region countryId Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'countryId', keyColumn: false})
        countryId : number;
//#endregion countryId Prop


//#region stateId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'stateId', keyColumn: true})
        stateId : number;
//#endregion stateId Prop

}