import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEmailTemplateRecordBase {

//#region bCC Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'bCC', keyColumn: false})
        bCC : string;
//#endregion bCC Prop


//#region cC Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'cC', keyColumn: false})
        cC : string;
//#endregion cC Prop


//#region templateBody Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'templateBody', keyColumn: false})
        templateBody : string;
//#endregion templateBody Prop


//#region mailTo Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'mailTo', keyColumn: false})
        mailTo : string;
//#endregion mailTo Prop


//#region mailFrom Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'mailFrom', keyColumn: false})
        mailFrom : string;
//#endregion mailFrom Prop


//#region subject Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'subject', keyColumn: false})
        subject : string;
//#endregion subject Prop


//#region emailTemplateName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'emailTemplateName', keyColumn: false})
        emailTemplateName : string;
//#endregion emailTemplateName Prop


//#region emailTemplateId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'emailTemplateId', keyColumn: true})
        emailTemplateId : number;
//#endregion emailTemplateId Prop

}