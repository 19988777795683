import { prop,propObject,propArray,required,maxLength,range ,email } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class UserBase {

//#region userId Prop
        @prop()
        userId : number;
//#endregion userId Prop


//#region applicationLocaleId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        applicationLocaleId : number;
//#endregion applicationLocaleId Prop


//#region applicationTimeZoneId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        applicationTimeZoneId : number;
//#endregion applicationTimeZoneId Prop


//#region languageCode Prop
        @required()
        @maxLength({ value: 3 })
        languageCode : string;
//#endregion languageCode Prop


//#region roleId Prop
        @prop()
        roleId : number;
//#endregion roleId Prop


//#region name Prop
        @required()
        @maxLength({ value: 50 })
        name : string;
//#endregion name Prop


//#region companyName Prop
        @required()
        @maxLength({ value: 100 })
        companyName : string;
//#endregion companyName Prop


//#region email Prop
        @maxLength({ value: 50 })
        @email()
        email : string;
//#endregion email Prop


//#region companySizeId Prop
        @prop()
        companySizeId : number;
//#endregion companySizeId Prop


//#region cityId Prop
        @prop()
        cityId : number;
//#endregion cityId Prop


//#region stateId Prop
        @prop()
        stateId : number;
//#endregion stateId Prop


//#region countryId Prop
        @prop()
        countryId : number;
//#endregion countryId Prop


//#region password Prop
        @maxLength({ value: 132 })
        password : any;
//#endregion password Prop


//#region salt Prop
        @maxLength({ value: 140 })
        salt : any;
//#endregion salt Prop


//#region activationKey Prop
        @prop()
        activationKey : string;
//#endregion activationKey Prop


//#region loginBlocked Prop
        @required()
        loginBlocked : boolean;
//#endregion loginBlocked Prop


//#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop


//#region linkedInUser Prop
        @prop()
        linkedInUser : boolean;
//#endregion linkedInUser Prop


//#region linkedInUserId Prop
        @maxLength({ value: 50 })
        linkedInUserId : string;
//#endregion linkedInUserId Prop


//#region isPlanCancelled Prop
        @prop()
        isPlanCancelled : boolean;
//#endregion isPlanCancelled Prop


//#region isDocumentPayment Prop
        @prop()
        isDocumentPayment : boolean;
//#endregion isDocumentPayment Prop





























}