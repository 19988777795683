import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class StaticPageImageBase {

//#region staticPageImageId Prop
        @prop()
        staticPageImageId : number;
//#endregion staticPageImageId Prop


//#region staticPageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        staticPageId : number;
//#endregion staticPageId Prop


//#region imageName Prop
        @required()
        @maxLength({value:50})
        imageName : string;
//#endregion imageName Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @prop()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop



}