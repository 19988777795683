import { http, RxHttp } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { Feedback } from '@app/models';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { CoreComponent } from '@rxweb/angular-router';
import { RolesEnum } from 'src/app/enums/roles-enum';
import { Query } from 'src/app/viewModel/sp-parameter';
import { API } from "src/app/components/shared/constant/administrationAPIContants";

@http({
    path: "api/Feedbacks",
})

export class AbstractFeedback extends CoreComponent {
    feedbackFormGroup: IFormGroup<Feedback>
    toastr: BaseToastr;
    spin = false;
    breadCrumb: BreadCrumb;
    dialog: BaseDialog;
    showComponent: boolean = false;
    rxHttp: RxHttp;

    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.rxHttp = new RxHttp();
        this.badRequest;
    }
    
    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";

        var RoleId = this.user.roleId;

        if (RoleId == RolesEnum.SuperAdmin) {
            this.breadCrumb.title = 'Feedback Management';
            this.breadCrumb.pageName = 'Feedback Management';
        }
        else {
            this.breadCrumb.title = 'Feedback';
            this.breadCrumb.pageName = 'Feedback';
        }         
        this.breadCrumb.pageRedirect = "feedbacks";       
    }
    
    badRequest = (data) => {
        this.spin = false;
        let messages = JSON.parse(data);
        this.toastr.warning(messages[0]);
    }

    getFeedbacks(searchParams: Query) {
        return this.rxHttp.post({ body: searchParams, path: API.searchFeedback });
    }

}
