import {vRoleRecordBase} from '../database-models/v-role-record-base';
//Generated Imports
export class vRoleRecord extends vRoleRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}