import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPublicRfpHrcBase {

        //#region publicRFPCategory Prop
        @gridColumn({class: ["word-wrap-category"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'publicRFPCategory', keyColumn: false, headerTitle: "Category" })
        publicRFPCategory: string;
        //#endregion publicRFPCategory Prop


        //#region createdOn Prop
        @gridColumn({ visible: false, columnIndex: 4, allowSorting: true, headerKey: 'createdOn', keyColumn: false })
        createdOn: any;
        //#endregion createdOn Prop


        //#region deadlineDate Prop
        @gridColumn({ visible: true, columnIndex: 5, allowSorting: true, headerKey: 'deadlineDate', keyColumn: false, headerTitle: "Deadline Date", configuredTemplate: { templateName: "dateFormat" } })
        deadlineDate: any;
        //#endregion deadlineDate Prop


        //#region url Prop
        @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerKey: 'url', keyColumn: false, headerTitle: "Url" })
        url: string;
        //#endregion url Prop


        //#region publicRFPTitle Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'publicRFPTitle', keyColumn: false, headerTitle: "Title", configuredTemplate: { templateName: "publicRFPRedirectLink" } })
        publicRFPTitle: string;
        //#endregion publicRFPTitle Prop


        //#region publicRFPId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'publicRFPId', keyColumn: true })
        publicRFPId: number;
        //#endregion publicRFPId Prop

}