import { TemplateConfig } from '@rxweb/grid';

export const SUBSCRIPTION_PLAN_GRID_TEMPLATE: TemplateConfig = {
    span: {        
        class: [function (e) {            
            if( this[e.name] != null )
            return 'subscriptionPayment'.split(" ");
        }],
        childrens: [{
            text: {                
                text: function (e) { 
                    return this[e.name] == null ? "NA" : this[e.name] }
            }
        }]
    }
}
