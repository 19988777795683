import {RoleNameLookupBase} from '../database-models/role-name-lookup-base';
//Generated Imports
export class RoleNameLookup extends RoleNameLookupBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}