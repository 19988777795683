import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProjectListBase {

//#region userId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'userId', keyColumn: true})
        userId : number;
//#endregion userId Prop


//#region projectId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'projectId', keyColumn: true})
        projectId : number;
//#endregion projectId Prop


//#region projectName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'projectName', keyColumn: false,headerTitle: "Project",configuredTemplate: { templateName: "redirectProjectLink" }})
        projectName : string;
//#endregion projectName Prop


//#region applicationObjectName Prop
        @gridColumn({visible: false, columnIndex:3, allowSorting: true, headerKey: 'applicationObjectName', keyColumn: false})
        applicationObjectName : string;
//#endregion applicationObjectName Prop


//#region createdOn Prop
        @gridColumn({visible: false, columnIndex:4, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop

}