import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { ProjectRFQ } from '@app/models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { Query } from 'src/app/viewModel/sp-parameter';
import { API } from "src/app/components/shared/constant/administrationAPIContants";

@http({
    path: "api/ProjectRFQ",
})

export class AbstractProjectRFQHRO extends RxHttp {
    projectRFQFormGroup: IFormGroup<ProjectRFQ>
    toastr: BaseToastr;
    spin = false;
    breadCrumb: BreadCrumb;
    dialog: BaseDialog;
    showComponent: boolean = false;
    rxHttp: RxHttp;

    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.badRequest;
        this.rxHttp = new RxHttp();
    }
    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home"
        this.breadCrumb.title = 'Quote Messages'
        this.breadCrumb.pageName = 'Quote Messages'
        this.breadCrumb.pageRedirect = "quote-message";
    }
    badRequest = (data) => {
        this.spin = false;       
        let messages = JSON.parse(data);
        this.toastr.warning(messages[0]);
    }

    getProjectRFQList(searchParams: Query) {
        return this.rxHttp.post({ body: searchParams, path: API.searchProjectRFQ });
    }
}
