import { http, RxHttp } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { Testimonial } from '@app/models';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { CoreComponent } from '@rxweb/angular-router';

@http({
    path: "api/Testimonials",
})

export class AbstractTestimonial extends CoreComponent {
    testimonialFormGroup: IFormGroup<Testimonial>
    toastr: BaseToastr;
    spin = false;
    breadCrumb: BreadCrumb;
    dialog: BaseDialog;
    showComponent: boolean = false;
    isMatchingHRCPage: boolean;

    constructor() {
        super();
        this.isMatchingHRCPage = window.location.href.indexOf('/matchingHRC') !== -1;
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.badRequest;
        if (this.isMatchingHRCPage) {
            this.bindBreadCrumbMatchingHRC();
        }
        else this.bindBreadCrumb();
    }

    bindBreadCrumbMatchingHRC() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'My Projects';
        this.breadCrumb.pageName = 'My Projects';
        this.breadCrumb.pageRedirect = "projects";
    }
    
    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";
        // this.breadCrumb.name2='Project Management';
        this.breadCrumb.title = 'Testimonials';
        this.breadCrumb.pageName = 'Testimonials';
        this.breadCrumb.pageRedirect = "testimonials";
        this.breadCrumb.title = 'Testimonials';
    }
    badRequest = (data) => {
        this.spin = false;
        let messages = JSON.parse(data);
        this.toastr.warning(messages[0]);
    }

}
