import { TemplateConfig } from '@rxweb/grid';
import { ContractsEnum } from 'src/app/enums/contracts.enum';

export const RFQ_STATUS_GRID_TEMPLATE: TemplateConfig = {
    span: {
        class: [function (e) {
            if (this[e.name] == "Approved")
                return this[e.name] = 'Approved', "label f-12 text-white greenbadge-bg".split(" ");

            else if (this[e.name] == "Interested")
                return this[e.name] = 'Interested', "label f-12 text-white approvedbadge-bg".split(" ");

            else if (this[e.name] == "Pending")
                return this[e.name] = 'Pending', "label f-12 text-white pendingbadge-bg".split(" ");

            else if (this[e.name] == "Rejected")
                return this[e.name] = 'Rejected', "label f-12 text-white radbadge-bg".split(" ");

            else if (this[e.name] == "Expired")
                return this[e.name] = 'Expired', "label f-12 text-white completedbadge-bg".split(" ");

            else
                return this[e.name] = 'NotInterested', "label f-12 text-white rejectedbadge-bg".split(" ");
        }],
        childrens: [{
            text: {
                text: function (e) {
                    if (this[e.name] == "Approved")
                        return this[e.name] = 'Approved'

                    else if (this[e.name] == "Interested")
                        return this[e.name] = 'Interested'

                    else if (this[e.name] == "NotInterested")
                        return this[e.name] = 'Not Interested'

                    else if (this[e.name] == "Pending")
                        return this[e.name] = 'Pending'

                    else if (this[e.name] == "Rejected")
                        return this[e.name] = 'Rejected'

                    else if (this[e.name] == "Expired")
                        return this[e.name] = 'Expired'
                }
            }
        }]
    }
}
