import { Injectable } from '@angular/core';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { Router } from '@angular/router';
import { StorageKeys, RoutingKeys } from '../../shared/constant/commonConstants'

@Injectable({
    providedIn: 'root',
})
export class CommonHelperService {

    storageHelper: BrowserStorage;

    constructor(private routers: Router,) {
        this.storageHelper = new BrowserStorage();
    }

    isStringNullEmptyOrWhiteSpace(stringToCheck: string): boolean {
        return stringToCheck === null || stringToCheck === undefined || stringToCheck.trim() === '';
    }

    isNumberNullOrUndefined(numberToCheck: number) {
        if (numberToCheck === null || numberToCheck === undefined) {
            return true;
        }
        else {
            return this.isStringNullEmptyOrWhiteSpace(numberToCheck.toString());
        }
    }

    isObjectNullOrUndefined(objectToCheck: object) {
        return objectToCheck === null || objectToCheck === undefined;
    }

    isArrayNullOrEmpty(recordToCheck: any) {
        if (this.isObjectNullOrUndefined(recordToCheck)) {
            return true;
        }
        else {
            return recordToCheck.length === 0;
        }
    }

    isNullOrUndefined(recordToCheck: any) {
        return recordToCheck === null || recordToCheck === undefined;
    }

    splitString(val: string, noOfCharacterToGet: number) {
        if (this.isStringNullEmptyOrWhiteSpace(val)) {
            return '';
        }
        else {
            if (val.length >= noOfCharacterToGet) {
                return val.substring(0, noOfCharacterToGet) + ' ...';
            }
            return val;
        }
    }

    navigateDashboard() {
        var currentService = this.storageHelper.local.get(StorageKeys.CurrentService, false);
        var userName = this.storageHelper.local.get(StorageKeys.Username, false);
    }

    /* Use method across all the components for sorting by field wise */
    predicateBy(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }


}