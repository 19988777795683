import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vStaticPageBase {

//#region staticPageId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'staticPageId', keyColumn: true})
        staticPageId : number;
//#endregion staticPageId Prop


//#region staticPageName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'staticPageName', keyColumn: false,headerTitle:'Page Name'})
        staticPageName : string;
//#endregion staticPageName Prop

}