import { SpinnerDirective } from "@rxweb/framework"
import { TemplateConfig } from '@rxweb/grid';
export class AppSpinner extends SpinnerDirective {
    constructor(element: HTMLElement) {
        super(element);
        this.inClass = ["sk-loading"];
        this.designClass.root = ["sk-spinner", "sk-spinner-wave"];
        this.designClass.childrens = ["sk-rect1", "sk-rect2", "sk-rect3"] 

    }

  
}
