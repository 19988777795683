import {CompanySizeDetailBase} from '../database-models/company-size-detail-base';
import {UserBase} from '../database-models/user-base';
//Generated Imports
export class CompanySizeDetail extends CompanySizeDetailBase 
{




//#region Generated Reference Properties
//#region users Prop
users : UserBase[];
//#endregion users Prop

//#endregion Generated Reference Properties



















}