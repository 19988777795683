import {ContractAttachmentBase} from '../database-models/contract-attachment-base';
import {ContractBase} from '../database-models/contract-base';
//Generated Imports
export class ContractAttachment extends ContractAttachmentBase 
{
//#region Generated Reference Properties
//#region contract Prop
contract : ContractBase;
//#endregion contract Prop
attachmentString:string;
contentType:string;
//#endregion Generated Reference Properties
}