import {vEmailTemplateBase} from '../database-models/v-email-template-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'editAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vEmailTemplate extends vEmailTemplateBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties















































}