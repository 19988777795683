import { TemplateConfig } from '@rxweb/grid';
import { RolesEnum } from 'src/app/enums/roles-enum';

export const USER_TYPE_TEXT_GRID_TEMPLATE: TemplateConfig = {
    span: {        
        class: [function (e) {
            
            return this[e.name] == "Guest" ? "label f-12 text-white guestUserbadge-bg".split(" ") : (this[e.name] == 'LinkedIn' ? "label f-12 text-white linkedInUserbadge-bg".split(" ") : 'label f-12 text-white inactivebadge-bg'.split(" "));
        }],
        childrens: [{
            text: {
                text: function (e) { 
                    return this[e.name] }
            }
        }]
    }
}