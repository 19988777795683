import { RxHttp } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { PublicRFP } from '@app/models';
import { http } from "@rxweb/http";
import { CoreComponent } from "@rxweb/angular-router";
import { BaseDialog } from "src/app/domain/customize-design/dialog";
import { BaseToastr } from "src/app/domain/customize-design/toastr";
import { BreadCrumb } from "src/app/viewModel/bread-crumb";
import { Query } from 'src/app/viewModel/sp-parameter';
import { API } from "src/app/components/shared/constant/administrationAPIContants";

@http({
    path: "api/PublicRFP",
})

export class AbstractPublicRFP extends CoreComponent {
    publicRFPFormGroup: IFormGroup<PublicRFP>
    toastr: BaseToastr;
    spin = false;
    breadCrumb: BreadCrumb;
    dialog: BaseDialog;
    showComponent: boolean = false;
    selectDateErrorMessage: string = '';
    rxHttp: RxHttp;

    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.rxHttp = new RxHttp();
        this.badRequest;
    }

    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home"
        // this.breadCrumb.name2 ='Master Management'
        this.breadCrumb.title = 'Public RFPs'
        this.breadCrumb.pageName = 'Public RFPs'
        this.breadCrumb.pageRedirect = "public-r-f-p";
    }

    badRequest = (data) => {
        this.spin = false;
        let messages = JSON.parse(data);
        this.toastr.warning(messages[0]);
    }
    deadlineDate(event) {
        if (event != null) {
            let deadlineDate = new Date(event);
            let todayDate = new Date();
            
            if (deadlineDate < todayDate) {
                this.selectDateErrorMessage = "Please select deadline date greater than today.";
            }
            else {
                this.selectDateErrorMessage = "";
            }
        }
    }
    getRFP(searchParams: Query) {
        return this.rxHttp.post({ body: searchParams, path: API.getRFP });
    }
}
