import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vRoleRecordBase {

//#region roleId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'roleId', keyColumn: true})
        roleId : number;
//#endregion roleId Prop


//#region roleName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'roleName', keyColumn: false})
        roleName : string;
//#endregion roleName Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region rolePermissionData Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'rolePermissionData', keyColumn: false})
        rolePermissionData : string;
//#endregion rolePermissionData Prop

}