import {vUSComplianceBase} from '../database-models/v-u-s-compliance-base';
//Generated Imports
export class vUSCompliance extends vUSComplianceBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties



}