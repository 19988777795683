import {LinkedInConfigurationBase} from '../database-models/linked-in-configuration-base';
//Generated Imports
export class LinkedInConfiguration extends LinkedInConfigurationBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties


}