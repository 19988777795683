import { Injectable } from '@angular/core';
import { IAuthorize, AuthorizeConfig } from '@rxweb/angular-router';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { RxHttp } from '@rxweb/http';
import { StorageKeys } from 'src/app/components/shared/constant/commonConstants';
import { BrowserStorage } from '../services/browser-storage';
import { API } from 'src/app/components/shared/constant/administrationAPIContants';

export var dbAccessModule: any;
export var dbUserId: any;

@Injectable({
    providedIn: 'root',
})
export class AuthorizationResolver implements IAuthorize {

    currentUserId
    constructor(private http: RxHttp, private router: Router, private localStorage: BrowserStorage) { }

    authorize(authorizeConfig: AuthorizeConfig): Promise<boolean> | boolean {
        var promise = new Promise<boolean>((resolve, reject) => {
            this.currentUserId = this.localStorage.local.get(StorageKeys.UserId, false);
            if (dbAccessModule == undefined || dbAccessModule == null || this.currentUserId != dbUserId) {
                this.http.get<string>({ path: API.access, }).subscribe(response => {
                    dbAccessModule = JSON.parse(response);
                    dbUserId = this.currentUserId;
                    let isAccess = this.verifyAuthorization(authorizeConfig);
                    resolve(isAccess);
                    if (!isAccess)
                        this.router.navigate(["/dashboard"])
                }, () => {
                    reject(false)
                })
            }
            else {
                let isAccess = this.verifyAuthorization(authorizeConfig);
                resolve(isAccess);
                if (!isAccess)
                    this.router.navigate(["/dashboard"])
            }

        })
        return promise;
    }

    verifyAuthorization(authorizeConfig: AuthorizeConfig): boolean {
        this.currentUserId = this.localStorage.local.get(StorageKeys.UserId, false);
        if (this.currentUserId != dbUserId) {
            this.authorize(authorizeConfig);
        }
        if (dbAccessModule) {
            if (dbAccessModule[authorizeConfig.accessLevel]){
                return dbAccessModule[authorizeConfig.accessLevel][authorizeConfig.action]
            }
            return false;
        }
        return false;
    }

    authorizeChildren(authorizeConfig: AuthorizeConfig, pageAuthorizeConfig: AuthorizeConfig) {
        if (dbAccessModule == undefined || dbAccessModule == null || this.currentUserId != dbUserId) {
            var promise = new Promise<boolean>((resolve, reject) => {
                this.http.get<string>({ path: API.access }).subscribe(response => {
                    dbAccessModule = JSON.parse(response);
                    var result = this.verifyAuthorization(authorizeConfig);
                    resolve(result);

                }, () => {
                    reject(false)
                })
            })
            return promise;
        }
        else {
            return this.verifyAuthorization(authorizeConfig);
        }
    }
}
