import {vSubscriptionPlanCancelListBase} from '../database-models/v-subscription-plan-cancel-list-base';
//Generated Imports
export class vSubscriptionPlanCancelList extends vSubscriptionPlanCancelListBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties








}