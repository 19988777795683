import {vEmailTemplateRecordBase} from '../database-models/v-email-template-record-base';
//Generated Imports
export class vEmailTemplateRecord extends vEmailTemplateRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties



















































}