import {vFeedbackDetailBase} from '../database-models/v-feedback-detail-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name: 'feedbackAction',
    allowSorting: false,
    configuredTemplate: { templateName: 'feedbackAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})

export class vFeedbackDetail extends vFeedbackDetailBase 
{




//#region Generated Reference Properties
feedbackQuestionName:string;
feedbackServiceName:string;
//#endregion Generated Reference Properties




































}