import {CityBase} from '../database-models/city-base';
import {CountryBase} from '../database-models/country-base';
import {StateBase} from '../database-models/state-base';
import {UserBase} from '../database-models/user-base';
//Generated Imports
export class City extends CityBase 
{




//#region Generated Reference Properties
//#region country Prop
country : CountryBase;
//#endregion country Prop
//#region state Prop
state : StateBase;
//#endregion state Prop
//#region users Prop
users : UserBase[];
//#endregion users Prop

//#endregion Generated Reference Properties


































}