import { TemplateConfig } from '@rxweb/grid';

export const USER_PAYMENT_GRID_TEMPLATE: TemplateConfig = {
    span: {        
        class: [function (e) {
             if(this[e.name] == true)  
             return "user-payment".split(" ") ;
        }],
        childrens: [{
            text: {
                
                text: function (e) { 
                    return this[e.name] == true ? "Payment Received" : "NA" }
            }
        }]
    }
}
