import { Routes, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { RouteProvider } from "@rxweb/angular-router"
import { Injectable } from '@angular/core';
import { AppComponent } from './app.component';
var routings = [
	{
		path: '', redirectTo: 'dashboard', pathMatch: 'full', data: { anonymous: true }
	},
	{
		path: 'dashboard', loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
		// canActivate: [LoginGuard]
	},
	{
		path: 'hrc-dashboard', loadChildren: () => import('../hrc-dashboard/hrc-dashboard.module').then(m => m.HRCDashboardModule),
	},
	{
		path: 'guest-hrc-dashboard', loadChildren: () => import('../hrc-dashboard/guest-hrc-dashboard/guest-hrc-dashboard.module').then(m => m.GuestHRCDashboardModule),
	},
	{
		path: "login",
		loadChildren: () => import("../authentication/login/login.module").then(m => m.LoginModule),
	},
	{
		path: "linkedInlogin",
		loadChildren: () => import("../authentication/login-with-linkedIn/login-with-linkedIn.module").then(m => m.LoginWithLinkedInModule),
	},
	{
		path: "city",
		loadChildren: () => import("../master/city/city.module").then(m => m.CityModule)
	},
	{
		path: "company-size",
		loadChildren: () => import("../master/company-size/company-size.module").then(m => m.CompanySizeModule)
	},
	{
		path: "country",
		loadChildren: () => import("../master/country/country.module").then(m => m.CountryModule)
	},
	{
		path: "emailtemplates",
		loadChildren: () => import("../master/emailtemplates/emailtemplate.module").then(m => m.EmailtemplateModule)
	},
	{
		path: "expertise",
		loadChildren: () => import("../master/expertize/expertize.module").then(m => m.ExpertizeModule)
	},
	{
		path: "expertise-sub-category",
		loadChildren: () => import("../master/expertize-sub-category/expertize-sub-category.module").then(m => m.ExpertizeSubCategoryModule)
	},
	{
		path: "monthly-retained-services",
		loadChildren: () => import("../master/monthly-retained-services/monthly-retained-service.module").then(m => m.MonthlyRetainedServiceModule)
	},
	{
		path: "options",
		loadChildren: () => import("../master/options/option.module").then(m => m.OptionModule)
	},
	{
		path: "questions",
		loadChildren: () => import("../master/questions/question.module").then(m => m.QuestionModule)
	},
	{
		path: "state",
		loadChildren: () => import("../master/state/state.module").then(m => m.StateModule)
	},
	{
		path: "years-of-experience",
		loadChildren: () => import("../master/years-of-experience/years-of-experience.module").then(m => m.YearsOfExperienceModule)
	},
	{
		path: "contracts",
		loadChildren: () => import("../project/contracts/contract.module").then(m => m.ContractModule)
	},
	{
		path: "feedbacks",
		loadChildren: () => import("../project/feedbacks/feedback.module").then(m => m.FeedbackModule)
	},
	{
		path: "messages",
		loadChildren: () => import("../project/messages/message.module").then(m => m.MessageModule)
	},
	{
		path: "project-r-f-q-admin",
		loadChildren: () => import("../project/project-r-f-q/project-r-f-q-admin/project-r-f-q-admin.module").then(m => m.ProjectRFQAdminModule)
	},
	{
		path: "quote-message",
		loadChildren: () => import("../project/project-r-f-q/project-r-f-q-hro/project-r-f-q-hro.module").then(m => m.ProjectRFQHROModule)
	},
	{
		path: "project-r-f-q-response",
		loadChildren: () => import("../project/project-r-f-q/project-r-f-q-hrc/project-r-f-q-hrc.module").then(m => m.ProjectRFQHRCModule)
	},
	{
		path: "projects",
		loadChildren: () => import("../project/projects/project.module").then(m => m.ProjectModule)
	},
	{
		path: "testimonials",
		loadChildren: () => import("../project/testimonials/testimonial.module").then(m => m.TestimonialModule)
	},
	{
		path: "roles",
		loadChildren: () => import("../user/roles/role.module").then(m => m.RoleModule)
	},
	{
		path: "user",
		loadChildren: () => import("../user/users/user.module").then(m => m.UserModule)
	},
	{
		path: "hro-management",
		loadChildren: () => import("../user/hro-management/hro-management.module").then(m => m.HROManagementModule)
	},
	{
		path: "hrc",
		loadChildren: () => import("../user/hr-consultant/hr-consultant.module").then(m => m.HRConsultantModule)
	},
	{
		path: "thank-you/:data", data: { anonymous: true },
		loadChildren: () => import("../master/thank-you/thank-you.module").then(m => m.ThankyouModule)
	},
	{
		path: "plan-cancellation",
		loadChildren: () => import("../master/subscription-plan-cancellation/subscription-plan-cancellation.module").then(m => m.SubscriptionPlanCancellationModule)
	},
	{
		path: "email-notification",
		loadChildren: () => import("../master/email-notification/email-notification.module").then(m => m.EmailNotificationModule)
	},
	{
		path: "us-compliance",
		loadChildren: () => import("../master/upload-attachments/upload-attachment.module").then(m => m.UploadAttachmentModule)
	},	
	// {
	// 	path: 'payment', loadChildren: () => import('../authentication/login/payment/payment.module').then(m => m.PaymentModule),
	// 	// canActivate: [LoginGuard]
	// },
	{
		path: "public-r-f-p",
		loadChildren: () => import("../master/public-r-f-p/public-r-f-p.module").then(m => m.PublicRFPModule)
	},
	{
		path: "hro-dashboard",
		loadChildren: () => import("../dashboard/hro-dashboard/hro-dashboard.module").then(m => m.HRODashboardModule)
	},	
	{
		path: "user-report",
		loadChildren: () => import("../user-report/user-report.module").then(m => m.UserReportModule)
	},
	{
		path: "payment-report",
		loadChildren: () => import("../payment-report/payment-report.module").then(m => m.PaymentReportModule)
	},
	{
		path: "payment-response",
		loadChildren: () => import("../reports/report.module").then(m => m.ReportModule)
	},
	{
		path: "system-setting",
		loadChildren: () => import("../master/system-setting/system-setting.module").then(m => m.SystemSettingModule)
	},
	{
		path: '**', redirectTo: 'login'
	}
]



// {
// 	path: "reset-password",
// 	loadChildren: () => import("../user/reset-password/reset-password.module").then(m => m.ResetPasswordModule)
// }

        
//generated code
//])


export const ROUTES: Routes = routings;








