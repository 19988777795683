import { Component, OnInit, Input } from "@angular/core";
import { AbstractMessage } from '../domain/abstract-message';
import { DownloadFile } from "src/app/components/shared/constant/download-file";
import { BlobTypeEnum } from "src/app/enums/blob-type.enum";
import { DownloadDocumentViewModel } from "src/app/viewModel/download-document-viewModel";

@Component({
    templateUrl: './message-preview.component.html'
})

export class MessagePreviewModal extends AbstractMessage implements OnInit {
    @Input() componentType: any;
    @Input() params: string[];
    @Input() hide: Function;

    showMessageComponent: boolean;
    showEmailNotificationComponent: boolean;
    showHRCPlanDetailComponent: boolean;

    projectName: string;
    hrcName: string;
    hroName: string;
    subject: string;
    messageDetail: string;
    attachments: any;
    attachmentList: Array<fileViewModel>;
    fileType: string;
    showAttachment: boolean = false;
    dateAndTime: string;
    body: string;
    // messageId: number;
    selectedExpertiseCount:number;
    remainingMonths:number;
    pricePerExpertise:number;
    finalAmountToBePaid:number;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.attachmentList = [];
        if (this.componentType == 'messageModal') {
            this.showMessageComponent = true;
            this.projectName = this.params[0];
            this.hrcName = this.params[1];
            this.hroName = this.params[2]
            this.subject = this.params[3];
            this.messageDetail = this.params[4];
            this.attachments = this.params[5];
            // this.messageId = parseInt(this.params[6]);
            // this.attachmentString = this.params[6];
        }
        else if (this.componentType == 'emailNotificationModal') {
            this.showEmailNotificationComponent = true;
            this.dateAndTime = this.params[0];
            this.subject = this.params[1]
            this.body = this.params[2];
        }
        else if (this.componentType == 'hrcPlanDetailModal') {
            this.showHRCPlanDetailComponent = true;
            this.selectedExpertiseCount=parseInt(this.params[0]);
            this.remainingMonths=parseInt(this.params[1]);
            this.pricePerExpertise=parseInt(this.params[2]);
            this.finalAmountToBePaid=parseInt(this.params[3]);
        }
        if (this.attachments != null && this.attachments.length > 0) {
            for (let index = 0; index < this.attachments.length; index++) {
                let fileViewModelObj = new fileViewModel();
                fileViewModelObj.fileAttachmentString = this.attachments[index].attachmentBase64String;
                fileViewModelObj.fileName = this.attachments[index].fileName;
                fileViewModelObj.messageAttachmentId = this.attachments[index].messageAttachmentId;

                var tempString = this.selectFileType(this.attachments[index].fileName).split(",");
                fileViewModelObj.fileIconClass = tempString[0];
                fileViewModelObj.fileType = tempString[1];
                this.attachmentList.push(fileViewModelObj);
            }
            this.showAttachment = true;
        }
    }

    // classForIcon: string;
    selectFileType(fileName: string): string {
        let splitArray = fileName.split(".");
        switch (splitArray[(splitArray.length - 1)].toLocaleLowerCase()) {
            case "pdf":
                return "fa-file-pdf,application/pdf";
            case "doc":
                return "fa-file-word,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            case "docx":
                return "fa-file-word,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            case "xlsx":
                return "fa-file-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            case "xls":
                return "fa-file-excel,application/vnd.ms-excel";
            case "pptx":
                return "fa-file-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation";
            case "ppt":
                return "fa-file-powerpoint,application/vnd.ms-powerpoint";
            case "png":
                return "fa-file-image,image/png";
            case "jpg":
                return "fa-file-image,image/jpeg";
            case "jpeg":
                return "fa-file-image,image/jpeg";
        }
    }
    payNow(value) {
        this.hide(value)
    }

    public downloadDocument(fileObj: fileViewModel) {
        this.spin = true;
        let download = new DownloadFile();
        let downloadViewModel = new DownloadDocumentViewModel();
        downloadViewModel.Id = fileObj.messageAttachmentId;
        downloadViewModel.BlobTypeEnum = BlobTypeEnum.MessageAttachments;
        download.downloadFileFromAzure(downloadViewModel, fileObj).then(()=> {
            this.spin = false;
        }, ()=>{
            this.spin = false;
        });

    }
    ngOnDestroy(): void {

    }
}
class fileViewModel {
    fileName: string;
    fileAttachmentString: string;
    fileIconClass: string;
    fileType: string;
    messageAttachmentId: number;
}