import {CountryLookupBase} from '../database-models/country-lookup-base';
//Generated Imports
export class CountryLookup extends CountryLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}