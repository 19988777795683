import {vProjectListBase} from '../database-models/v-project-list-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'actionProjectList' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vProjectList extends vProjectListBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties

















}