import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vStateBase {

//#region stateId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'stateId', keyColumn: true})
        stateId : number;
//#endregion stateId Prop


//#region stateName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'stateName', keyColumn: false, headerTitle: "State"})
        stateName : string;
//#endregion stateName Prop


//#region countryName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'countryName', keyColumn: false, headerTitle: "Country"})
        countryName : string;
//#endregion countryName Prop


//#region status Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'status', keyColumn: false, headerTitle: "Status", configuredTemplate: { templateName: "recordActive" }})
        status : string;
//#endregion status Prop

}