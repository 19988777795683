import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCountryBase {

//#region countryId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'countryId', keyColumn: true})
        countryId : number;
//#endregion countryId Prop


//#region countryName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'countryName', keyColumn: false,headerTitle: "Country"})
        countryName : string;
//#endregion countryName Prop


//#region status Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'status', keyColumn: false, headerTitle: "Status", configuredTemplate: { templateName: "recordActive" }})
        status : string;
//#endregion status Prop

}