import { actionColumn } from '@rxweb/grid';

//Generated Imports
import {vUSCategoryBase} from '../database-models/v-u-s-category-base';
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'action' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vUSCategory extends vUSCategoryBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties



}