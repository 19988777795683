import { http, RxHttp } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { User } from '@app/models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { CoreComponent } from '@rxweb/angular-router';
import { HROViewModel } from 'src/app/viewModel/hro-view-model';
import { UserCredentialModel } from 'src/app/viewModel/user-credential-model';
import { RolesEnum } from 'src/app/enums/roles-enum';

@http({
    path: "api/Users",
})
export class AbstractHROManagement extends CoreComponent {
    userFormGroup: IFormGroup<HROViewModel>;
    userCredentialFormGroup: IFormGroup<UserCredentialModel>;
    toastr: BaseToastr;
    spin = false;
    breadCrumb: BreadCrumb;
    dialog: BaseDialog;
    showComponent: boolean = false;
    isContract: boolean;
    isDetailAdmin: boolean;
    isDetailHRO: boolean;
    isDetailHRC: boolean;
    isFeedback:boolean;
    isPaymentReportPage:boolean=false;
    isPaymentResponsePage:boolean=false;
    constructor() {
        super();
        // this.bindBreadCrumb();

        this.isContract = window.location.href.indexOf('/contractHRODetails') !== -1;
        this.isDetailAdmin = window.location.href.indexOf('/rfqAdminDetails') !== -1;
        this.isDetailHRO = window.location.href.indexOf('/rfqHRCDetails') !== -1;
        this.isDetailHRC = window.location.href.indexOf('/rfqHRODetails') !== -1;
        this.isFeedback = window.location.href.indexOf('/feedbackHRODetails') !== -1;
        this.isPaymentReportPage = window.location.href.indexOf('/paymentReport') !== -1;
        this.isPaymentResponsePage = window.location.href.indexOf('/paymentResponse') !== -1;
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.badRequest;

        if (this.isContract) {
            this.contractBreadCrumb();
        }
        else if (this.isDetailAdmin) {
            this.projectRFQAdminBreadCrumb();
        }
        else if (this.isDetailHRC) {
            this.projectRFQResponseBreadCrumb();
        }
        else if (this.isDetailHRO) {
            this.quoteBreadCrumb();
        }
        else if(this.isFeedback) {
            this.feedbackBreadCrumb();
        }
        else if(this.isPaymentReportPage){
            this.paymentBreadCrumb();
        }
        else if(this.isPaymentResponsePage){
            this.paymentResponseBreadCrumb();
        }
        else {
            this.bindBreadCrumb()
        }
    }


    quoteBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'Quote Messages';
        this.breadCrumb.pageName = "Quote Messages";
        this.breadCrumb.pageRedirect = 'quote-message';
    }

    paymentResponseBreadCrumb(){
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'Payment Response Subscriptions';
        this.breadCrumb.pageName = "Payment Response Subscriptions";
        this.breadCrumb.pageRedirect = 'payment-response';
    }
    paymentBreadCrumb(){
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'Payment Report';
        this.breadCrumb.pageName = "Payment Report";
        this.breadCrumb.pageRedirect = 'payment-report';
    }
    contractBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home";
        var RoleId = this.user.roleId;

        if (RoleId == RolesEnum.SuperAdmin) {
            this.breadCrumb.title = 'Contracts Management';
            this.breadCrumb.pageName = "Contracts Management";
        }
        else {
            this.breadCrumb.title = 'My Contracts';
            this.breadCrumb.pageName = " My Contracts";
        }
        this.breadCrumb.pageRedirect = 'contracts';
    }

    projectRFQAdminBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'Project RFQ Request & Response Management';
        this.breadCrumb.pageName = "Project RFQ Request & Response Management";
        this.breadCrumb.pageRedirect = 'project-r-f-q-admin';
    }

    projectRFQResponseBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.title = 'My RFQ Response';
        this.breadCrumb.pageName = "My RFQ Response";
        this.breadCrumb.pageRedirect = 'project-r-f-q-response';
    }

    feedbackBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";

        var RoleId = this.user.roleId;

        if (RoleId == RolesEnum.SuperAdmin) {
            this.breadCrumb.title = 'Feedback Management';
            this.breadCrumb.pageName = 'Feedback Management';
        }
        else {
            this.breadCrumb.title = 'Feedback';
            this.breadCrumb.pageName = 'Feedback';
        }
        this.breadCrumb.pageRedirect = "feedbacks";
    }

    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home"
        this.breadCrumb.name2 = "User Management"
        this.breadCrumb.title = 'HRO Management'
        this.breadCrumb.pageName = 'HRO Management';
        this.breadCrumb.pageRedirect = "hro-management";
    }

    badRequest = (data) => {
        this.spin = false;
        let messages = JSON.parse(data);
        this.toastr.warning(messages[0]);
    }
}
