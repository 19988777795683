import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { User, vHRO } from "@app/models";
import { Subscription } from 'rxjs';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { AppGrid } from 'src/app/domain/app-grid';
import { Router } from '@angular/router';
import { CommonHelperService } from 'src/app/components/shared/common-helpers/helper.service';
import { RoutingKeys, ToolTipText } from 'src/app/components/shared/constant/commonConstants';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { AbstractHRConsultant } from '../domain/abstract-hr-consultant';
import { access } from '@rxweb/angular-router';
import { ApplicationModules, PermissionNames } from 'src/app/enums/application-modules.enum';
import { HRODashboardComponent } from "src/app/components/dashboard/hro-dashboard/hro-dashboard.component";
import { SideBarComponent } from "src/app/components/shared/side-bar/side-bar.component";
import { ModelComponentType, ModelResponse } from "src/app/components/shared/constant/common-constants";
declare const $: any;

@access({ accessLevel: ApplicationModules.HRCList, action: PermissionNames.Get })

@Component({
    selector: "app-hr-consultant-list",
    templateUrl: './hr-consultant-list.component.html'
})

export class HRConsultantListComponent extends AbstractHRConsultant implements OnInit, OnDestroy {
    users: List<User>;
    subscription: Subscription;
    userGrid: AppGrid;
    userGridColumns: any[];
    searchText = '';
    modalView: ModalView;
    roleId: number = 3;

    constructor(
        private route: Router,
        private commonHelperService: CommonHelperService,
        modalView: ModalView
    ) {
        super();
        this.modalView = modalView;
    }

    ngOnInit(): void {
        this.initializeGrid();
    }

    initializeGrid() {
        if (!this.commonHelperService.isObjectNullOrUndefined(this.userGrid)) {
            this.userGrid.clearGrid('user');
        }
        this.spin = true;
        this.showComponent = true;
        this.subscription = this.get({ queryParams: { 'RoleId': [this.roleId] } }).subscribe((userList: any) => {
            
            this.userGrid = new AppGrid(userList, vHRO
                , {
                    actions: {
                        onEdit: this.onEdit.bind(this),
                        onDelete: this.onDelete.bind(this),
                        viewDetails: this.viewDetails.bind(this)
                    }
                }
            );
            this.userGrid.authorization = {
                'put': { accessLevel: ApplicationModules.HRCList, action: PermissionNames.Put },
                'delete': { accessLevel: ApplicationModules.HRCList, action: PermissionNames.Delete },
                'get': { accessLevel: ApplicationModules.HRCList, action: PermissionNames.Get }
            };
            this.userGrid.design(document.getElementById('user'));
            this.userGridColumns = this.userGrid.gridColumns.filter(x => x.keyColumn === false);
            this.userGrid.search = this.searchText;
            this.spin = false;
            this.addTooltip();
        });
    }

    search(value) {
        this.userGrid.search = value;
        this.searchText = this.userGrid.search;
    }

    onEdit(user: User) {
        this.route.navigate([RoutingKeys.HRConsultant, user.userId]);
    }

    viewDetails(user: User) {
        this.route.navigate([RoutingKeys.HRConsultantDetails, user.userId]);
    }

    onDelete(user: User) {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Delete }).then(t => {
            if (t == ModelResponse.Yes) {
                this.spin = true;
                this.delete({ params: [user.userId], body: null }).subscribe(response => {
                    this.spin = false;
                    this.toastr.success(CustomMessage.dataDeleted);
                    this.initializeGrid();
                });
            }
        });
    }

    addTooltip() {
        $(document).ready(function () {
            $(".gridEditbtn").hover(function () {
                $(this).attr("title", ToolTipText.EditHRC);
            })
            $(".gridDeletebtn").hover(function () {
                $(this).attr("title", ToolTipText.DeleteHRC);
            })
            $(".gridinfobttn").hover(function () {
                $(this).attr("title", ToolTipText.ViewHRC);
            })
        })
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

}
