import {YearsOfExperienceLookupBase} from '../database-models/years-of-experience-lookup-base';
//Generated Imports
export class YearsOfExperienceLookup extends YearsOfExperienceLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties
























}