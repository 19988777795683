import {OptionDetailBase} from '../database-models/option-detail-base';
import {QuestionBase} from '../database-models/question-base';
import {ProjectDetailBase} from '../database-models/project-detail-base';
//Generated Imports
import { prop } from '@rxweb/reactive-form-validators';
export class OptionDetail extends OptionDetailBase 
{




//#region Generated Reference Properties
//#region question Prop
question : QuestionBase;
//#endregion question Prop
//#region projectDetails Prop
projectDetails : ProjectDetailBase[];
//#endregion projectDetails Prop

//#endregion Generated Reference Properties


































//#region arrayIndex Prop
@prop()
arrayIndex : number;
//#endregion arrayIndex Prop










































}