import { ContractBase, StateBase, UserBase, } from '@app/database-models'

//Generated Imports
import { prop, numeric, NumericValueType, required, propArray } from '@rxweb/reactive-form-validators';
import { ContractAttachmentBase } from '../models/database-models/contract-attachment-base';
import { ContractAttachment } from '../models/extended-models/contract-attachment';
export class ContractViewModel extends ContractBase {



    //#region Generated Reference Properties
    isDisabled: boolean = false;
    //#region contractAttachments Prop
    contractAttachments: ContractAttachmentBase[];
    //#endregion contractAttachments Prop
    RoleId: number;
    //#region ContractAttachmentBase Prop
    // @propArray(ContractAttachmentBase)
    // contractAttachments: ContractAttachmentBase[];
    //#endregion ContractAttachmentBase Prop  

    //#endregion Generated Reference Properties


}