import {vAllProjectBase} from '../database-models/v-all-project-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'feedbackViewAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vAllProject extends vAllProjectBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






}