import {vAdminRecordBase} from '../database-models/v-admin-record-base';
//Generated Imports
export class vAdminRecord extends vAdminRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







}