import { TemplateConfig } from '@rxweb/grid';

export const REDIRECT_HRC_LINK_GRID_TEMPLATE: TemplateConfig = {
  // div: {
  //   childrens: [{
  //     a: {
  //       event: {
  //         click: "onLink"
  //       },
  //       childrens: [{
  //         div: {
  //           style: {
  //             // "text-decoration":"underline",
  //             "cursor": "pointer",
  //           },
  //           //class:["mb-0 badge sucess"],
  //           childrens: [{
  //             text: {
  //               text: function (e) {
  //                 if (this[e.name] != null) {
  //                   return this[e.name];
  //                 } else {
  //                   return ''
  //                 }
  //               }
  //             }
  //           }]
  //         }
  //       }]
  //     }
  //   }],
  // }

  div: {
    class: [
      function (e) {
        return "divTableCell".split(" ");
      }],
    attributes: {
      'data-head': function (e) { return e.headerTitle; }
    }
    ,
    childrens: [{
      div: {
        childrens: [{
          a: {
            event: {
              click: "onHRCLink"
            },
            childrens: [{
              div: {
                style: {
                  // "text-decoration":"underline",
                  "cursor": "pointer",
                },
                //class:["mb-0 badge sucess"],
                childrens: [{
                  text: {
                    text: function (e) {
                      if (this[e.name] != null) {
                        return this[e.name];
                      } else {
                        return ''
                      }
                    }
                  }
                }]
              }
            }]
          }
        }],
      }
    }]
  }
};
