<div *ngIf="showDeleteComponent" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Delete</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">Are you sure you want to delete this?</p>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="deleteRecord()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showApproveComponent" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Approve</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">Are you sure you want to approve this?</p>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="deleteRecord()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>

<!-- <div *ngIf="interestedConfirmationComponent" class="modal fade show" id="clonepopup" tabindex="-1"
  aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Interested</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body pb-0">
        Are you sure you are interested in this project?
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="deleteRecord()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div> -->

<div *ngIf="showInterestedComponent" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Interested</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">Are you sure you are interested in this project?</p>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="deleteRecord()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>


<div *ngIf="showNotInterestedComponent" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Not Interested</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">Are you sure you are not interested in this project?</p>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="deleteRecord()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showRejectComponent" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Reject</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">Are you sure you want to reject this?</p>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="deleteRecord()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>


<div *ngIf="showCategoryChangeComponent" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Change Category</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">Your input data will be lost !
          Are you sure you want to change category?</p>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="deleteRecord()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>




<div *ngIf="showRolesComponent" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Roles</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <rx-select [(source)]="masterLookupGroup.roleLookup" 
          selectPlaceholder="Select Role"
          mainClass="chosen-select form-control" [keyValueProps]="['roleName','roleId']">
        </rx-select> -->
        <select class="form-control" [(ngModel)]="selectedValue">
          <option value="undefined" selected disabled>Select Role</option>
          <option *ngFor="let roles of masterLookupGroup.roleLookup" [value]="roles.roleId">{{roles.roleDescription}}
          </option>
        </select>
      </div>
      <div class="modal-footer primaryBttn-wrapper">
        <button class="bttn-primary bttn-icon" (click)="register()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showFeedbackOptionChangeComponent" class="modal fade show" id="clonepopup" appOutside
  (click)="ClickedOut($event)" tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Change Option</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">Data entered in previous option will be lost.</p>
        Are you sure you want to change option?
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="deleteRecord()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showPlanCancelComponent" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Delete</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">Are you sure you want to cancel this subscription plan?</p>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="deleteRecord()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showMarkAllAsReadComponent" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Mark as read all</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">Are you sure you want to mark as read all these email notifications?</p>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="markAsRead()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showDeleteAllComponent" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Delete All</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">Are you sure you want to delete all these email notifications?</p>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="markAsRead()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showMatchingHrc" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Make Selections Then Click Request Project RFQ</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body pb-0 tips-block">
        <h5 class="font-weight-bold">What should you look for when selecting the best HR Consultant for your business?
        </h5>
        <ol class="text-dark">
          <li> Is the 'HR Consultant' (HRC) a part of a team or a specialized individual?</li>
          <li>When corresponding with 'HR Consultants' look for someone you can communicate well with. Will this person
            assimilate well into your company’s culture? Does the consultant have the desire to build a strong
            relationship as a trusted adviser?</li>
          <li>A professional capable of understanding the nuances of your industry and organization.</li>
          <li>A strong track record, read our reviews, talk to their other clients, view their thought leadership in the
            HR industry or industry affiliations.</li>
          <li>Can the consultant provide the full life-cycle of HR functions or a specialized niche?</li>
          <ul>
            <li>Human Resources Employee Management </li>
            <li>HR Technology</li>
            <li>Workers Compensation</li>
            <li>Payroll</li>
            <li>Employee Benefits</li>
            <li>Recruitment</li>
            <li>Policy and Compliance</li>
            <li>Retained Services</li>
          </ul>
          <li>Demonstrated ability to understand business drivers, your measures of success and business goals, and
            previous experience contributing to those goals in similar organizations.</li>
          <li>Once you have narrowed down the list, before you choose and engage the consultant on an ongoing basis.
            Consider tasking your chosen consultant to initially complete a HR overview of your business in line with
            your business plan and then review or develop a HR strategy for your business. This is a great way for your
            consultant to gain a rapid understanding of your organization, the culture and its strengths and weaknesses
            in managing people and performance.</li>
        </ol>
        <div class="col-12">
          <hr />
        </div>
        <div style="text-align: center" class="mb-3">
          <button class="bttn-primary bttn-icon" (click)="hide()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showHrcomplianceformtips" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Notes For Selecting HR Compliance Forms</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body pb-0 tips-block">
        <ul class="text-dark">
          <li> The sample forms and policies featured in this section may be used for general reference only. All sample
            forms and policies should be modified to meet your company’s individual needs and applicable laws. Federal
            and state laws do change and, as a result, the featured forms and policies may not comply with current
            requirements. We strongly recommend consulting an employment law attorney or HR specialist for assistance
            with customizing any forms, policies, or the sample employee handbook. Any links that have been provided as
            an additional source of forms or guidelines are also for general information purposes and cannot be
            guaranteed for accuracy or compliance with any particular law or requirement.</li>
        </ul>
        <div class="col-12">
          <hr />
        </div>
        <div style="text-align: center" class="mb-3">
          <button class="bttn-primary bttn-icon" (click)="hide()">OK</button>
        </div>
      </div>

    </div>
  </div>
</div>

<div *ngIf="showSuccessComponent" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Success</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="markAsRead()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">You have subscribed <b>{{plan}}</b> plan with amount <b>{{price | currency}}</b>.<br>
          Payment successful.</p>
      </div>
      <div class="modal-footer">
        <!-- <a type="button" class="bttn-primary bttn-icon" (click)="markAsRead()">Yes</a> -->
        <button class="bttn-primary bttn-icon" (click)="markAsRead()">Close</button>
      </div>
    </div>
  </div>
</div>


<div *ngIf="showDocSuccessComponent" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Success</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="markAsRead()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- You have subscribed <b>{{plan}}</b> plan with amount <b>{{price | currency}}</b>.<br> -->
        <p class="mb-0"> <b>Payment Successful.</b><br>
          Congratulations!! You have subscribed for life time access to the documents.
        </p>
      </div>
      <div class="modal-footer">
        <!-- <a type="button" class="bttn-primary bttn-icon" (click)="markAsRead()">Yes</a> -->
        <button class="bttn-primary bttn-icon" (click)="markAsRead()">Close</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showResponseDescription" class="fade modal show" role="dialog" tabindex="-1" style="display: block;"
  aria-labelledby="id" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered max-w-800" role="document">
    <div class="modal-content">
      <div class="modal-header py-2">
        <h2 class="h2 modal-title text-blue">
          Payment Response
        </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body py-2">
        <table>
          <tr>
            <td class="w-25">
              <label class=" col-form-label">Payment details</label>
            </td>
            <td>:</td>
            <td class="w-100">
              <!-- <div class="col-sm-9"> -->
              <div class="pl-3 form-control-plaintext">
                <p class="mb-0">{{paymentAccessKey}}</p>
              </div>
            </td>
          </tr>
        </table>
        <hr class="mt-2">
        <div *ngIf="showExpertise">
          <div class="">
            <label class="text-10 font-bold lightgrey-color">Subscribed Expertise :
            </label>
          </div>
          <div *ngFor="let name of expertiseName">
            <ul class="list-group list-group-flush">
              <li class="list-group-item p-0 pb-2">
                <label class="form-check-label">{{name}}</label>
              </li>
            </ul>
          </div>
        </div>

        <!-- </div> -->
        <!-- <br> -->

        <!-- <tr *ngIf="showExpertise">
            <td class="w-25">
              <label class=" col-form-label">Subscribed Expertise</label>
            </td>
            <td>:</td>
            <td class="w-100">
              <div class="form-control-plaintext" *ngFor="let name of expertiseName">
                <ul style="line-height:10%">
                  <li class="mb-0">{{name}}</li>
                </ul>
              </div>
            </td>
          </tr> -->

      </div>
      <div class="modal-footer p-0"></div>
    </div>
  </div>
</div>

<div *ngIf="showDisableEnableExpertiseComponent" class="fade modal show" role="dialog" tabindex="-1"
  style="display: block;" aria-labelledby="id" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered max-w-800 modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Expertise </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="enableOrDisbale=='disable'" class="col">
          Inactivating <span class="font-bold text-dark">{{params[2]}}</span> expertise will not consider in receiving
          Project RFQ requests. <br>
          If you not activate before <b class="font-bold text-dark"> {{params[1] | date}}</b>, next occuring payment
          will not include <span class="font-bold text-dark">{{params[2]}}</span> expertise. <br>
          Are you sure you want to deactivate ?
        </div>
        <div *ngIf="enableOrDisbale=='enable'" class="col">
          Activating <span class="font-bold text-dark">{{params[2]}}</span> expertise will consider in receiving Project
          RFQ requests. <br>
          If you activate before <b class="font-bold text-dark"> {{params[1] | date}}</b>, next occuring payment will
          include <span class="font-bold text-dark">{{params[2]}}</span> expertise. <br>
          Are you sure you want to activate ?
        </div>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="deleteRecord()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showChangeExpertiseTabComponent" class="fade modal show" role="dialog" tabindex="-1" style="display: block;"
  aria-labelledby="id" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered max-w-800" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Change To Unpaid Expertise </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">The changes you made in Sub-Expertise category will be lost. <br>
          Are you sure you want to change to Unpaid Expertise ?</p>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="deleteRecord()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showUserEditComponent" class="fade modal show" role="dialog" tabindex="-1" style="display: block;"
  aria-labelledby="id" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered max-w-800" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Change Email Id</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">Are you sure you want to change email id ?</p>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="deleteRecord()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showGuestHRCComponent" class="fade modal show" role="dialog" tabindex="-1" style="display: block;"
  aria-labelledby="id" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered max-w-800" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title ml-auto font-bold" style="color: #148ab8;" id="clonepopuptilte">Upgrade Expertise
          Category
        </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body ">
        <label> By subscribing your account you can get below benefits to improvise your experience with us. </label>
        <ul type="circle" class="acc-selection">
          <li>Complete access to HRDoorway’s power matching technology.</li>
          <li>Bid on expertise matched projects in real time.</li>
          <li>Direct contact with RFP decision makers (HRO).</li>
          <li>Simple, inclusive process to receive contract awards.</li>
          <li>Real time feedback to enhance your ratings and win more work!</li>
          <li>Access to all federal and state human resources compliance forms.</li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="bttn-primary bttn-icon" (click)="deleteRecord()">Subscribe Now</button>
        <button type="button" class="bttn-secoundry bttn-icon" (click)="hide()">Later</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showExpertiseDetailsComponent" class="fade modal show" role="dialog" tabindex="-1" style="display: block;"
  aria-labelledby="id" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered max-w-800" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title font-bold" id="clonepopuptilte">Expertise Category Details
        </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body ">
        {{expertiseDetails}}
      </div>
      <div class="modal-footer">
        <button class="bttn-secoundry bttn-icon" (click)="hide()">Close</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showSubExpertiseDetailsComponent" class="fade modal show" role="dialog" tabindex="-1"
  style="display: block;" aria-labelledby="id" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered max-w-800" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title font-bold" id="clonepopuptilte">Expertise Sub Category Details
        </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body ">
        {{expertiseDetails}}
      </div>
      <div class="modal-footer">
        <button class="bttn-secoundry bttn-icon" (click)="hide()">Close</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showTipsForExpertiseInHRCProfileComponent" class="modal fade show" id="clonepopup" appOutside
  (click)="ClickedOut($event)" tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Role Of Expertise </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body pb-0 tips-block">
        <h5 class="font-weight-bold"> Expertise and sub Categories </h5>
        <ul class="text-dark">
          <li> The selected expertise category or expertise sub-category will help your industry to get more Project
            request
            (Project RFQ Request). </li>
          <li>Expertise shows capability of your industry and organization. </li>
          <li>A professional capable of understanding the nuances of your industry and organization.</li>
          <li>A strong track record, read our reviews, talk to their other clients, view their thought leadership in the
            HR industry or industry affiliations.</li>
        </ul>
        <hr />
        <h5 class="font-weight-bold"> Subscription Details :</h5>
        <ul class="text-dark">
          <li>As a part of HR Doorway you are charged once in a year for active expertise on the date you have signed
            up.</li>
          <!-- <li>Selected expertise will valid upto {{params[1] | date}}.</li> -->
          <li>You have freedom to subscribe other expertise any time from Unpaid Expertise section.</li>
        </ul>
        <!-- <hr /> -->
        <!-- <h5 class="font-weight-bold"> Notes :</h5> -->
        <!-- <ul class="text-dark">
          <li>
            <button class="btn p-0 pt-1 mr-0 disableExpertiseButton" Title="Disable Expertise" style="color: red;"><i
                class="fas fa-minus-square f-22"></i></button> &nbsp; : &nbsp; Disabling expertise will not consider in
            receiving Project RFQ requests.
          </li>
          <li>
            <button class="btn p-0 pt-1 mr-0 enableExpertiseButton" Title="Enable Expertise"
              style="color: rgb(64, 228, 119);"><i class="fas fa-plus-square f-22"></i></button>
            &nbsp; : &nbsp; Enabling expertise will consider in receiving Project RFQ requests.
          </li>
        </ul> -->
        <div class="col-12">
          <hr />
        </div>
        <div style="text-align: center" class="mb-3">
          <button class="bttn-primary bttn-icon" (click)="hide()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showPopupMessage" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Notes</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body pb-0 tips-block">
        <ul class="text-dark">
          <li *ngFor="let item of params"> {{item}}</li>
        </ul>
        <div class="col-12">
          <hr />
        </div>
        <div style="text-align: center" class="mb-3">
          <button class="bttn-primary bttn-icon" (click)="hide()">OK</button>
        </div>
      </div>

    </div>
  </div>
</div>

<div *ngIf="showFreeAccount" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Login as Free Account</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">Are you sure you want to login as Free Account?</p>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="deleteRecord()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showChangeCategory" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)"
  tabindex="-1" aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">Documents Added</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-0">Category and subcategory records have already been added. Do you want to redirect this record?
        </p>
      </div>
      <div class="modal-footer">
        <button class="bttn-primary bttn-icon" (click)="deleteRecord()">Yes</button>
        <button class="bttn-secoundry bttn-icon" (click)="hide()">No</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showHRCVideo" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)" tabindex="-1"
  aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">How it works</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="embed-responsive embed-responsive-16by9 playvideo ">
          <iframe src="https://player.vimeo.com/video/546619368?autoplay=1&amp;loop=1"
            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" frameborder="0"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showHROVideo" class="modal fade show" id="clonepopup" appOutside (click)="ClickedOut($event)" tabindex="-1"
  aria-labelledby="clonepopuptilte" style="display: block;">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="h2 modal-title text-blue" id="clonepopuptilte">How it works</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="embed-responsive embed-responsive-16by9 playvideo ">
          <iframe src="https://player.vimeo.com/video/546621700?autoplay=1&amp;loop=1"
            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" frameborder="0"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>