import { prop,propObject,propArray,required,maxLength,range, numeric, NumericValueType  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class DocumentPlanBase {

//#region documentPlanId Prop
        @prop()
        documentPlanId : number;
//#endregion documentPlanId Prop


//#region planAmount Prop
        @prop()
        @required()
        @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })
        @range({ minimumNumber: 1, maximumNumber: 10000000 })
        planAmount : number;
//#endregion planAmount Prop


//#region planDetails Prop
        @prop()
        planDetails : string;
//#endregion planDetails Prop


//#region planName Prop
        @maxLength({value:50})
        planName : string;
//#endregion planName Prop

}