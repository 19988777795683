import { DatePipe } from '@angular/common';
export class StorageKeys {
    public static Authentication = 'auth';
    public static Request_identity = 'request_identity';
    public static Username = 'userName';
    public static RedirectFrom = 'redirectFrom';
    public static UserRegisteredForm = 'userRegisteredForm';
    public static OrcidId = 'orcidId';
    public static ApplicationObjects = 'applicationObjects';
    public static UserId = 'userId';
    public static UserRole = 'role';
    public static IsLoggedIn = 'isLoggedIn';
    public static LastRequestOn = 'lastRequestOn';
    public static CurrentService = 'currentService';
    public static ProfilePicture = 'profilePic';
    public static Email = 'email';
    public static USACountryId = 1;
    public static GuestHRCDashboard = 'dashboardInItCount';
}


export class RoutingKeys {
    public static Login = 'login';
    public static AddUser = 'user/add';
    public static Dashboard = 'dashboard';
    public static HRODashboard = 'hro-dashboard';
    public static RolesPermission = 'roles';
    public static AddRole = 'roles/add';
    public static Country = 'country';
    public static AddCountry = 'country/add';
    public static Expertize = 'expertise';
    public static AddExpertize = 'expertise/add';
    public static ExpertizeSubCategory = 'expertise-sub-category';
    public static AddExpertizeSubCategory = 'expertise-sub-category/add';
    public static EmailTemplate = 'emailtemplates';
    public static YearsOfExperience = 'years-of-experience';
    public static AddYearsOfExperience = 'years-of-experience/add';
    public static MonthlyOption = 'monthly-retained-options';
    public static AddMonthlyOption = 'monthly-retained-options/add';
    public static MonthlyService = 'monthly-retained-services';
    public static AddMonthlyService = 'monthly-retained-services/add';
    public static State = 'state';
    public static AddState = 'state/add';
    public static City = 'city';
    public static AddCity = 'city/add';
    public static ResetPassword = 'login/resetPassword/';
    public static Question = 'questions';
    public static StaticPage = 'static-page';
    public static StaticPagePreview = 'staticPage/';
    public static CompanySize = 'company-size';
    public static AddCompanySize = 'company-size/add';
    public static SubscriptionPlan = 'subscription-plans';
    public static AddSubscriptionPlan = 'subscription-plans/add';
    public static SubscriptionType = 'subscription-type';
    public static AddSubscriptionType = 'subscription-type/add';
    public static LinkedInlogin = 'linkedInlogin/';
    public static Testimonials = "testimonials";
    public static AddTestimonials = "testimonials/add";
    public static FeedBacks="feedbacks";
    public static AddFeedBacks="feedbacks/add";
    public static HRO = 'hro-management';
    public static HRODetails = 'hro-management/details';
    public static HRConsultant = 'hrc';
    public static HRConsultantDetails = 'hrc/details';
    public static ProjectHRConsultantDetails = 'hrc/projectDetailsHRC';
    public static Contract = 'contracts';   
    public static Project='projects';
    public static AddProject='projects/add';
    public static AddHRO='hro-management/add';
    public static AddHRC='hrc/add';
    public static FeedbackView = 'feedbacks/view';
    public static User ='user';
    public static AddContract = 'contracts/add';
    public static ProjectRFQAdmin = 'project-r-f-q-admin';
    public static ProjectRFQHRC = 'project-r-f-q-response';
    public static Messages="messages";
    public static AddMessage="messages/add";
    public static ProjectRFQHRO = 'quote-message';
    public static PlanDetails = 'hrc/planDetails';
    public static MatchingHRC = 'projects/matchingHRC';
    public static UploadAttachments = 'us-compliance';
    public static PublicRFP = 'public-r-f-p';
    public static AddPublicRFP = 'public-r-f-p/add';
    public static DocumentPlan = 'us-compliance/plan';
    public static ContractView = 'contracts/view';
    //public static ProjectRFQResponse = 'project-r-f-q-response';
    public static AddDocument = 'us-compliance/add';
    public static Payment ='payment';
    public static HRCDashboard = 'hrc-dashboard';
    public static HRCProjectDetails = 'hrc/hrcProjectDetails';
    public static HRCProjectList = 'hrc/hrcProjectList';
    public static GuestHRCDashboard = 'guest-hrc-dashboard';
    public static HrcAccount = 'hrc/hrcAccount';
    public static PaymentReport='payment-report';
    public static PaymentResponse='payment-response';
}

export class CommonConstants {
    public static DateFormat = 'MMM d, y, h:mm:ss a';
    public static SuperAdminRoleName = 'super admin';
}

export class PipeConstants {
    public static datePipe = new DatePipe('en-US');
}

export class RequestHeaderKeys {
    public static Authorization = 'Authorization';
    public static Request_identity = 'request_identity';
}

export class Extension {
    public static Image = '.jpg,.jpeg,.png';
}

export class ModelDefaultvalue {
    public static Id = 0;
    public static IdStatic = 1;
    public static applicationLocaleId = 144;
    public static applicationTimeZoneId = 362;
    public static languageCode = 'en';
    public static loginBlocked = false;
    public static CreatedBy = 1;
}

export class UserRoles {
    public static Admin = 1;
    public static HRO = 2;
    public static HRConsultant = 3;
    public static GuestHRC = 4;

}

export class ToolTipText{
    public static EditProject="Edit Project";
    public static DeleteProject="Delete Project";
    public static ViewMatchingHRC="View matching HRC List";
    public static MessageHRC="Message HRC";
    public static MessageHRO="Message HRO";
    public static ApproveRFQ="Approve Project RFQ Request";
    public static RejectRFQ="Reject Project RFQ Request";
    public static DeleteRFQ="Delete Project RFQ Request";
    public static ViewMessage="View Message";
    public static EditFeedback="Edit Feedback";
    public static DeleteFeedback="Delete Feedback";
    public static ViewFeedback="View Feedback Details";
    public static EditContract="Edit Contract";
    public static DeleteContract="Delete Contract";
    public static AcceptContract="Accept Contract";
    public static RejectContract="Reject Contract";
    public static ViewContract="View Contract Details";
    public static EditTestimonial="Edit Testimonial";
    public static DeleteTestimonial="Delete Testimonial";
    public static EditCity="Edit City";
    public static DeleteCity="Delete City";
    public static DeleteCompanySize="Delete Company Size";
    public static EditCompanySize="Edit Company Size";
    public static EditCountry="Edit Country";
    public static DeleteCountry="Delete Country";
    public static DeleteEmailNotification="Delete Email";
    public static ViewEmailNotification="View Email Details";
    public static EditEmailTemplate="Edit Email Template";
    public static EditExpertise="Edit Expertise";
    public static DeleteExpertise="Delete Expertise";
    public static EditExpertiseSubCategory="Edit Expertise Sub-Category";
    public static DeleteExpertiseSubCategory="Delete Expertise Sub-Category";
    public static EditMonthlyRetainedOption="Edit Monthly Retained Option";
    public static DeleteMonthlyRetainedOption="Delete Monthly Retained Service";
    public static EditMonthlyRetainedService="Edit Monthly Retained Service";
    public static DeleteMonthlyRetainedService="Delete Monthly Retained Service";
    public static EditPublicRFP="Edit Public RFP";
    public static DeletePublicRFP="Delete Public RFP";
    public static VisitPublicRFPUrl="Visit Public RFP link";
    public static EditQuestion="Edit Question";
    public static DeleteQuestion="Delete Question";
    public static EditState="Edit State";
    public static DeleteState="Delete State";
    public static EditStaticPage="Edit Static Page details";
    public static EditSubscriptionPlan="Edit Subscription Plan";
    public static DeleteSubscriptionPlan="Delete Subscription Plan";
    public static EditSubscriptionType="Edit Subscription Type";
    public static DeleteSubscriptionType="Delete Subscription Type";
    public static EditYearsOfExperience="Edit Years Of Experience";
    public static DeleteYearsOfExperience="Delete Years Of Experience";
    public static EditHRC="Edit HRC Details";
    public static DeleteHRC="Delete HRC";
    public static ViewHRC="View HRC Details";
    public static EditHRO="Edit HRO Details";
    public static DeleteHRO="Delete HRO";
    public static ViewHRO="View HRO Details";
    public static EditRole="Edit Role Permissions";
    public static EditUSCompliance="Edit HR Compliance";
    public static DeleteUSCompliance="Delete HR Compliance";
    public static VisitContractList="See Contracts";
    public static VisitProjectRFQList="See Project RFQs";
    public static ViewProjectDetails="View Project Details";
    public static ViewExpertiseDetails:"View Expertise Details";
    public static InterestedRFQ="Interested in Project";
    public static NotInterstedRFQ="Not Interested in Project";
}