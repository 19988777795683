import { prop,propObject,propArray,required,maxLength,range ,numeric, NumericValueType } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SubscriptionPlanBase {

//#region subscriptionPlanId Prop
        @prop()
        subscriptionPlanId : number;
//#endregion subscriptionPlanId Prop


//#region subscriptionPlanName Prop
        @required()
        @maxLength({ value: 100 })
        subscriptionPlanName : string;
//#endregion subscriptionPlanName Prop


//#region subscriptionDetails Prop
        @maxLength({ value: 500 })
        subscriptionDetails : string;
//#endregion subscriptionDetails Prop


//#region subscriptionTypeId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        subscriptionTypeId : number;
//#endregion subscriptionTypeId Prop


//#region subscriptionPrice Prop
        @required()
        @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true })
        subscriptionPrice : number;
//#endregion subscriptionPrice Prop


//#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop





}