// @ts-nocheck
import { ElementConfig, GridDesigner } from "@rxweb/grid"
import { dbAccessModule } from './security/authorization-resolver';

export class AppGrid extends GridDesigner {

  constructor(source: any[], model: Function, gridConfiguration?: any) {
    super(source, model, gridConfiguration);
    this.allowSorting = true;
    this.designClass.tableClass = "display table nowrap table-striped table-hover dataTable".split(' ');
    this.designClass.cellClass = ["sorting"];
    this.designClass.ascendingClass = ["feather".split(" "), "icon-arrow-up".split(" "),];
    this.designClass.descendingClass = ["feather".split(" "), "icon-arrow-down".split(" "),];
    this.designClass.rowClass = [function () { return "gradeA even".split(" ") }];
    this.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex align-items-center flex-wrap".split(' ');
    this.footerDesignClass.dropDownTemplateClass.rootClass = "dataTables_length mb-0 mb-lg-0".split(' ');
    this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm mx-2 gridDropdown".split(" ");
    this.footerDesignClass.textTemplateClass.rootClass = "dataTables_info mb-0 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.rootClass = "pagination-noborder mb-0 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination mb-0 mb-lg-0".split(" ");;
    this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
    this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
    this.footerDesignClass.dropDownTemplateClass.labelClass = "d-flex align-items-center mb-0".split(' ');
    this.maxPerPage = 10;
    this.pagingSource = [10, 25, 50, 100];
    this.startNodeName = 'div'
  }

  clearGrid(elementId) {
    const element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  authorize(authorizeConfig: { [key: string]: any }) {
    if (dbAccessModule)
      return dbAccessModule[authorizeConfig.accessLevel][authorizeConfig.action];
    else
      return false;
  }

  get tableElementConfig(): ElementConfig {
    return {
      class: ["table-responsive"],
      childrens: [{
        table: {
          class: this.designClass.tableClass,
          childrens: [this.headerTemplate, this.bodyTemplate]
        }
      }]
    };
  }

}
