import {StaticPageImageBase} from '../database-models/static-page-image-base';
import {StaticPageBase} from '../database-models/static-page-base';
//Generated Imports
export class StaticPageImage extends StaticPageImageBase 
{




//#region Generated Reference Properties
//#region staticPage Prop
staticPage : StaticPageBase;
//#endregion staticPage Prop

//#endregion Generated Reference Properties







}