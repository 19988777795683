import {FeedbackBase} from '../database-models/feedback-base';
import {MonthlyRetainedOptionBase} from '../database-models/monthly-retained-option-base';
import {ProjectBase} from '../database-models/project-base';
import {UserBase} from '../database-models/user-base';
import {FeedbackDetailBase} from '../database-models/feedback-detail-base';
//Generated Imports
export class Feedback extends FeedbackBase 
{




//#region Generated Reference Properties
//#region monthlyRetainedOption Prop
monthlyRetainedOption : MonthlyRetainedOptionBase;
//#endregion monthlyRetainedOption Prop
//#region project Prop
project : ProjectBase;
//#endregion project Prop
//#region user Prop
user : UserBase;
//#endregion user Prop
//#region feedbackDetails Prop
feedbackDetails : FeedbackDetailBase[];
//#endregion feedbackDetails Prop

//#endregion Generated Reference Properties








































}