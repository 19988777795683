import {vHRCUserBase} from '../database-models/v-h-r-c-user-base';
//Generated Imports
export class vHRCUser extends vHRCUserBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties

}