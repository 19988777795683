import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { Subscription } from 'rxjs';
import { anonymous } from '@rxweb/angular-router'
import { ActivatedRoute } from '@angular/router';



@anonymous()
@Component({
    selector: "app-thank-you",
    templateUrl: './thank-you.component.html'
})

export class ThankYouComponent implements OnInit, OnDestroy {
    @Input() componentType:any;
    ResetPasswordError:boolean = false;
    subscription: Subscription;
    constructor(activatedRoute: ActivatedRoute) {
        activatedRoute.params.subscribe(t => {
            this.componentType=t['data'];
        })
    }

    ngOnInit(): void {
         if(this.componentType=="ResetPasswordError"){
            this.ResetPasswordError = true;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();

    }
}