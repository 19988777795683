import {HROLookupBase,} from '@app/database-models'
//Generated Imports
export class HROLookup extends HROLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties















































}