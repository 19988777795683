import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEmailSenderBase {

//#region userId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'userId', keyColumn: true})
        userId : number;
//#endregion userId Prop


//#region userName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'userName', keyColumn: false})
        userName : string;
//#endregion userName Prop


//#region userEmail Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'userEmail', keyColumn: false})
        userEmail : string;
//#endregion userEmail Prop


//#region projectId Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'projectId', keyColumn: false})
        projectId : any;
//#endregion projectId Prop


//#region projectName Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'projectName', keyColumn: false})
        projectName : string;
//#endregion projectName Prop


//#region hroId Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'hroId', keyColumn: false})
        hroId : any;
//#endregion hroId Prop


//#region hroName Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'hroName', keyColumn: false})
        hroName : string;
//#endregion hroName Prop


//#region hrcId Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'hrcId', keyColumn: false})
        hrcId : any;
//#endregion hrcId Prop


//#region hrcName Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'hrcName', keyColumn: false})
        hrcName : string;
//#endregion hrcName Prop


//#region hroCompanyName Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'hroCompanyName', keyColumn: false})
        hroCompanyName : string;
//#endregion hroCompanyName Prop

}