import {UserExpertizeDetailBase} from '../database-models/user-expertize-detail-base';
import {ExpertizeBase} from '../database-models/expertize-base';
import {ExpertizeSubCategoryBase} from '../database-models/expertize-sub-category-base';
import {UserBase} from '../database-models/user-base';
//Generated Imports
export class UserExpertizeDetail extends UserExpertizeDetailBase 
{




//#region Generated Reference Properties
//#region expertize Prop
expertize : ExpertizeBase;
//#endregion expertize Prop
//#region expertizeSubCategory Prop
expertizeSubCategory : ExpertizeSubCategoryBase;
//#endregion expertizeSubCategory Prop
//#region user Prop
user : UserBase;
//#endregion user Prop

//#endregion Generated Reference Properties


























}