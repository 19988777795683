import { Router } from '@angular/router';
import { ProjectDetail } from './../../../../models/extended-models/project-detail';
import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, numeric } from '@rxweb/reactive-form-validators';
import { ExpertizeLookup, ExpertizeSubCategoryLookup, Project, ProjectExpertizeDetail } from '@app/models';
import { AbstractProject } from '../domain/abstract-project';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { RoutingKeys } from 'src/app/components/shared/constant/commonConstants';
import { MasterLookupGroup } from 'src/app/viewModel/master-lookup-group';
import { MASTER_LOOKUPS } from 'src/app/uris/master-lookups.uri';
import { DomSanitizer } from '@angular/platform-browser';
import { StatusEnum } from 'src/app/enums/status.enum';
import { QuestionsEnum } from 'src/app/enums/questions.enum';
import { access } from '@rxweb/angular-router';
import { ApplicationModules, PermissionNames } from 'src/app/enums/application-modules.enum';
import { DatePipe } from '@angular/common';

@access({ accessLevel: ApplicationModules.MyProject, action: PermissionNames.Post})

@Component({
  selector: "app-project-add",
  templateUrl: './project-add.component.html'
})
export class ProjectAddComponent extends AbstractProject implements OnInit, OnDestroy {
  project: Project;
  subscription: Subscription;
  id: number;
  projectDetailFormGroup: IFormGroup<ProjectDetail>;
  projectQuestionOption: Array<any>;
  masterLookupGroup: MasterLookupGroup;
  expertizeSubCategoryLookup: ExpertizeSubCategoryLookup[] = [];
  expertizeLookup: ExpertizeLookup[] = [];
  expertizeId: number[];
  projectExpertizeDetailsArray: Array<ProjectExpertizeDetail>;
  projectExpertizeList: Array<ProjectExpertizeDetail>;
  subExpertizeArray: Array<SubExpertize>;
  isCategorySelected: boolean = false;
  selectcheckdAll: boolean = false;
  documentBase64textString: any = "";
  challengesBase64textString: any = "";
  fileName: any;
  fileType: string;
  binaryString: any;
  projectName: string = "";
  questionIdArray: Array<any>;
  projectDetailArray: Array<ProjectDetail>;
  optionArray: Array<any>;
  projectId: number = 0;
  imageQuestionId: number;
  imageQuestionType: number;
  questionEnum: any;
  statusEnum: any;
  employeeSource: Array<any>;
  stageSource: Array<any>;
  experienceSource: Array<any>;
  expertizeDetailsLength: number = 0;
  matchingProjectId: number;
  validationMessage: string;
  isValidMsg: boolean = false;
  requireMsg: string = CustomMessage.isRequiredField;
  textAreaMsg: string = CustomMessage.textLength;
  isBudget: boolean = true;
  budgetText: string;
  budgetInputValidation: string = '';
  budgetValue: string = '';
  budgetNumValue: number = 0;
  textArea1ErrMsg: string = '';
  textArea2ErrMsg: string = '';
  //documentImageMsg: string = "";
  //challengesImageMsg: string = "";
  isSingleOrEnterpriseMsg: string = "";
  isEmployeeMsg: string = "";
  isStageMsg: string = "";
  isExperienceMsg: string = "";
  isDeliverableMsg: string = "";
  isGoalsAndObjectiveMsg: string = "";
  isBudgetMsg: string = "";
  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  totalSize: number = 0;
  file1List: file1ViewModel[] = [];
  file2List: file2ViewModel[] = [];
  selectDateErrorMessage: string;
  showDateErrorMessage: boolean = false;
  selectedProjectRFQDeadlineDate: any;

  constructor(private formBuilder: RxFormBuilder, private route: Router, private sanitizer: DomSanitizer,private datePipe:DatePipe) {
    super();
  }

  ngOnInit(): void {
    this.spin = true;
    this.statusEnum = StatusEnum;
    this.questionEnum = QuestionsEnum;
    this.project = new Project();
    var projectDetailobj = new ProjectDetail();
    projectDetailobj.projectDetailsId = 0;
    projectDetailobj.projectId = 0;
    this.projectDetailArray = new Array<ProjectDetail>();
    this.expertizeId = [];
    this.subExpertizeArray = new Array<SubExpertize>();
    this.questionIdArray = new Array<any>();
    this.optionArray = new Array<any>();
    this.employeeSource = new Array<any>();
    this.stageSource = new Array<any>();
    this.experienceSource = new Array<any>();
    this.projectExpertizeDetailsArray = new Array<ProjectExpertizeDetail>();
    this.projectDetailFormGroup = this.formBuilder.formGroup(ProjectDetail, projectDetailobj) as IFormGroup<ProjectDetail>;
    this.projectFormGroup = this.formBuilder.formGroup(this.project) as IFormGroup<Project>;

    this.lookup<MasterLookupGroup>(
      [
        { path: MASTER_LOOKUPS.expertizeLookup, propName: 'expertizeLookup' },
        { path: MASTER_LOOKUPS.expertizeSubCategoryLookup, propName: 'expertizeSubCategoryLookup' }
      ]).subscribe((response: any) => {
        this.masterLookupGroup = response;
        this.expertizeLookup = this.masterLookupGroup.expertizeLookup;
        this.expertizeSubCategoryLookup = this.masterLookupGroup.expertizeSubCategoryLookup;

        this.get().subscribe((t: Array<any>) => {
          for (var i = 0; i < t.length; i++) {
            t[i].options = JSON.parse(t[i].options);
            let option = t[i].options;
            this.optionArray.push(option);
            if (t[i].questionId == QuestionsEnum.Question2) {
              this.employeeSource = option;
            }
            else if (t[i].questionId == QuestionsEnum.Question3) {
              this.stageSource = option;
            }
            else if (t[i].questionId == QuestionsEnum.Question6) {
              this.experienceSource = option;
            }
            let model = new Model();
            model.questionId = t[i].questionId;
            model.statusId = t[i].statusId;
            model.questionText = t[i].questionText;
            model.questionType = t[i].questionType;
            this.questionIdArray.push(model);
          }
          this.projectQuestionOption = [];
          this.projectQuestionOption = t;
          this.chkAllfieldSelected();

          this.showComponent = true;
          this.spin = false;
        })
      })
  }
  setDefaultValues() {
    this.projectFormGroup.patchValue({
      "statusId": 1,
      "createdBy": this.user.userId,
      "createdOn": new Date(),
      "projectId": 0,
      "userId": this.user.userId,
      "modifiedBy": this.user.userId,
      "modifiedOn": new Date(),
    });
  }


  deadlineDate(event) {
    if(event!=undefined){
      this.showDateErrorMessage = false;
      // let sdate = new Date(event);
      let sdate = this.datePipe.transform(new Date(event),'yyyy-MM-dd');
      let tdate = this.datePipe.transform(new Date,'yyyy-MM-dd');
      if (sdate < tdate || sdate === tdate) {
          this.selectDateErrorMessage = "Please select date greater than today.";
          this.showDateErrorMessage = true;
      }
      // var newDate = this.datePipe.transform(sdate, '');
      // else if()
    }
    else{
      this.selectDateErrorMessage="Please select a date";
      this.showDateErrorMessage=true;
    }
    
}
  setOptionValue(options, checked) {
    if (checked == true) {
      let projectDetail = new ProjectDetail();
      projectDetail.checked = checked;
      projectDetail.imageName = null;
      projectDetail.projectDetailsId = 0;
      projectDetail.projectOptionDetailDescription = null;
      projectDetail.projectOptionDetailId = options.optionDetailsId;
      projectDetail.projectQuestionId = options.questionId;
      if (QuestionsEnum.Question1 == options.questionId) {
        this.isSingleOrEnterpriseMsg = '';
      }
      projectDetail.projectQuestionType = options.questionType;
      this.projectDetailArray.push(projectDetail);
      this.projectDetailArray.forEach(element => {
        if (element.projectOptionDetailId != options.optionDetailsId && element.projectQuestionId == options.questionId) {
          let index = this.projectDetailArray.findIndex(x => x.projectOptionDetailId != options.optionDetailsId && x.projectQuestionId == options.questionId)
          this.projectDetailArray.splice(index, 1);
        }
      })
    }
  }
  setOptionDetailsValue(value, questionId) {
    if (value != 'Select') {
      this.optionArray.forEach(element => {
        if (element != null) {
          element.forEach(element1 => {
            if (value == element1.optionText) {
              let projectDetail = new ProjectDetail();
              projectDetail.checked = null;
              projectDetail.imageName = null;
              projectDetail.projectDetailsId = 0;
              projectDetail.projectOptionDetailDescription = null;

              projectDetail.projectOptionDetailId = element1.optionDetailsId;
              projectDetail.projectQuestionId = element1.questionId;
              projectDetail.projectQuestionType = element1.questionType;
              if (QuestionsEnum.Question2 == element1.questionId) {
                this.isEmployeeMsg = '';
              }
              else if (QuestionsEnum.Question3 == element1.questionId) {
                this.isStageMsg = '';
              }
              else if (QuestionsEnum.Question6 == questionId) {
                this.isExperienceMsg = '';
              }
              this.projectDetailArray.push(projectDetail);
              this.projectDetailArray.forEach(element2 => {
                if (element2.projectOptionDetailId != element1.optionDetailsId && element2.projectQuestionId == element1.questionId) {
                  let index = this.projectDetailArray.findIndex(x => x.projectOptionDetailId != element1.optionDetailsId && x.projectQuestionId == element1.questionId)
                  this.projectDetailArray.splice(index, 1);
                }
              })
            }
          });
        }

      })
    }
    else {
      if (QuestionsEnum.Question2 == questionId) {
        this.isEmployeeMsg = this.requireMsg;
      }
      else if (QuestionsEnum.Question3 == questionId) {
        this.isStageMsg = this.requireMsg;
      }
      else if (QuestionsEnum.Question6 == questionId) {
        this.isExperienceMsg = this.requireMsg;
      }
      this.projectDetailArray.forEach(element2 => {
        if (element2.projectQuestionId == questionId) {
          let index = this.projectDetailArray.findIndex(x => x.projectQuestionId == questionId);
          this.projectDetailArray.splice(index, 1);
        }
      })
    }
  }
  setOptionDescriptionValue(questionId, questionType, value) {
    if (value != '') {
      let projectDetail = new ProjectDetail();
      projectDetail.checked = null;
      projectDetail.imageName = null;
      projectDetail.projectDetailsId = 0;
      projectDetail.projectOptionDetailDescription = value;
      projectDetail.projectOptionDetailId = null;
      projectDetail.projectQuestionId = questionId;
      if (QuestionsEnum.Question4 == questionId) {
        if (value.length > 500) {
          this.textArea1ErrMsg = this.textAreaMsg;
          this.isDeliverableMsg = '';
        }
        else {
          this.textArea1ErrMsg = "";
          this.isDeliverableMsg = '';
        }
      }
      else if (QuestionsEnum.Question8 == questionId) {
        if (value.length > 500) {
          this.textArea2ErrMsg = this.textAreaMsg;
          this.isGoalsAndObjectiveMsg = '';
        }
        else {
          this.textArea2ErrMsg = "";
          this.isGoalsAndObjectiveMsg = '';
        }
      }
      projectDetail.projectQuestionType = questionType;
      this.projectDetailArray.forEach(element => {
        if (element.projectQuestionId == questionId) {
          let index = this.projectDetailArray.findIndex(x => x.projectOptionDetailDescription != value && x.projectQuestionId == questionId);
          this.projectDetailArray.splice(index, 1);
        }
      })
      this.projectDetailArray.push(projectDetail);
      // this.projectDetailArray.forEach(element => {
      //   if (element.projectOptionDetailDescription != value && element.projectQuestionId == questionId) {
      //     let index = this.projectDetailArray.findIndex(x => x.projectOptionDetailDescription != value && x.projectQuestionId == questionId);
      //     this.projectDetailArray.splice(index, 1);
      //   }
      // })
    }
    else {
      if (QuestionsEnum.Question4 == questionId) {
        this.textArea1ErrMsg = "";
        this.isDeliverableMsg = this.requireMsg;
      }
      else if (QuestionsEnum.Question8 == questionId) {
        this.textArea2ErrMsg = "";
        this.isGoalsAndObjectiveMsg = this.requireMsg;
      }
      this.projectDetailArray.forEach(element => {
        if (element.projectQuestionId == questionId) {
          let index = this.projectDetailArray.findIndex(x => x.projectQuestionId == questionId);
          this.projectDetailArray.splice(index, 1);
        }
      })
    }
  }

  checkImageValidation(questionId) {
    // if (QuestionsEnum.Question5 == questionId) {
    //   let index = this.projectDetailArray.findIndex(x => x.projectQuestionId == questionId);
    //   if (index > -1) {
    //     this.documentImageMsg = '';
    //   }
    //   else {
    //     this.documentImageMsg = this.requireMsg;
    //   }
    // }
    // else if (QuestionsEnum.Question9 == questionId) {
    //   let index = this.projectDetailArray.findIndex(x => x.projectQuestionId == questionId);
    //   if (index > -1) {
    //     this.challengesImageMsg = '';
    //   }
    //   else {
    //     this.challengesImageMsg = this.requireMsg;
    //   }
    // }
  }
  handleFileSelect(questionId, questionType, evt) {
    this.fileType = "";
    this.imageQuestionType = questionType;
    var selectedFiles = evt.target.files;
    if (selectedFiles.length > 0) {
      //this.documentImageMsg = '';
      var fileArray = this.upload(selectedFiles, questionId);
      if (fileArray.length > 0 || fileArray != undefined || fileArray != null) {
        if (QuestionsEnum.Question5 == questionId) {
          fileArray.forEach(element => {
            if (this.file1List.length == 0) {
              var selectedFilename = element.name;
              var selectedFileSize = element.size;
              var reader = new FileReader();
              reader.readAsDataURL(element);
              reader.onload = this._handleReaderLoaded.bind(this, selectedFilename, selectedFileSize, questionId);
            }
            this.file1List.forEach(element1 => {
              if (element.name != element1.fileName) {
                var selectedFilename = element.name;
                var selectedFileSize = element.size;
                var reader = new FileReader();
                reader.readAsDataURL(element);
                reader.onload = this._handleReaderLoaded.bind(this, selectedFilename, selectedFileSize, questionId);
              }
              else {
                this.file1ListCount--;
                this.toastr.error("This file is already added");
              }

            })
          })

        }
        else if (QuestionsEnum.Question9 == questionId) {
          fileArray.forEach(element => {
            if (this.file2List.length == 0) {
              var selectedFilename = element.name;
              var selectedFileSize = element.size;
              var reader = new FileReader();
              reader.readAsDataURL(element);
              reader.onload = this._handleReaderLoaded.bind(this, selectedFilename, selectedFileSize, questionId);
            }
            this.file2List.forEach(element1 => {
              if (element.name != element1.fileName) {
                var selectedFilename = element.name;
                var selectedFileSize = element.size;
                var reader = new FileReader();
                reader.readAsDataURL(element);
                reader.onload = this._handleReaderLoaded.bind(this, selectedFilename, selectedFileSize, questionId);
              }
              else {
                this.file2ListCount--;
                this.toastr.error("This file is already added");
              }
            })
          })

        }
      }
    }
    // else {
    //   if (QuestionsEnum.Question5 == questionId) {
    //     if (this.file1List.length == 0)
    //       this.documentImageMsg = this.requireMsg;
    //   }
    //   else if (QuestionsEnum.Question9 == questionId) {
    //     if (this.file2List.length == 0)
    //       this.challengesImageMsg = this.requireMsg;
    //   }
    // }
  }

  _handleReaderLoaded(fileName, fileSize, questionId, readerEvt) {
    if (QuestionsEnum.Question5 == questionId) {
      //this.documentImageMsg = '';
      var binaryString = readerEvt.target.result;
      var firstIndex = binaryString.indexOf("data:") + 5;
      var lastIndex = binaryString.indexOf(";base64,");
      var contentType = binaryString.substring(firstIndex, lastIndex);
      let index = this.file1List.findIndex(x => x.fileName == fileName);
      if (index < 0) {
        var file1Model = new file1ViewModel();
        file1Model.fileName = fileName;
        file1Model.fileSize = fileSize;
        file1Model.attachmentBase64String = binaryString.split(";base64,")[1];
        file1Model.questionId = questionId;
        file1Model.contentType = contentType;
        this.file1List.push(file1Model);
      }
      else {
        this.file1List[index].contentType = contentType;
        this.file1List[index].fileName = fileName;
        this.file1List[index].fileSize = fileSize;
        this.file1List[index].attachmentBase64String = binaryString.split(";base64,")[1];
      }
      let indexInArray = this.projectDetailArray.findIndex(x => x.imageName == fileName && x.projectQuestionId == questionId);
      if (indexInArray < 0) {
        let projectDetail = new ProjectDetail();
        projectDetail.checked = null;
        projectDetail.imageName = fileName;
        projectDetail.projectFileSize = fileSize;
        projectDetail.contentType = contentType;
        projectDetail.projectDetailsId = 0;
        projectDetail.projectOptionDetailDescription = binaryString.split(";base64,")[1];
        projectDetail.projectOptionDetailId = null;
        projectDetail.projectQuestionId = questionId
        projectDetail.projectQuestionType = this.imageQuestionType;
        this.projectDetailArray.push(projectDetail);
      }
      else {
        this.projectDetailArray[indexInArray].contentType = contentType;
        this.projectDetailArray[indexInArray].projectOptionDetailDescription = binaryString.split(";base64,")[1];
        this.projectDetailArray[indexInArray].projectQuestionId = questionId;
        this.projectDetailArray[indexInArray].projectFileSize = fileSize;

      }
    }
    else if (QuestionsEnum.Question9 == questionId) {
      //this.challengesImageMsg = ''
      var binaryString = readerEvt.target.result;
      var firstIndex = binaryString.indexOf("data:") + 5;
      var lastIndex = binaryString.indexOf(";base64,");
      var contentType = binaryString.substring(firstIndex, lastIndex);
      let index = this.file2List.findIndex(x => x.fileName == fileName);
      if (index < 0) {
        var file2Model = new file2ViewModel();
        file2Model.fileName = fileName;
        file2Model.fileSize = fileSize;
        file2Model.attachmentBase64String = binaryString.split(";base64,")[1];
        file2Model.questionId = questionId;
        file2Model.contentType = contentType;
        this.file2List.push(file2Model);
      }
      else {
        this.file2List[index].fileName = fileName;
        this.file2List[index].fileSize = fileSize;
        this.file2List[index].contentType = contentType;
        this.file2List[index].attachmentBase64String = binaryString.split(";base64,")[1];
      }
      let indexInArray = this.projectDetailArray.findIndex(x => x.imageName == fileName && x.projectQuestionId == questionId);
      if (indexInArray < 0) {
        let projectDetail = new ProjectDetail();
        projectDetail.checked = null;
        projectDetail.imageName = fileName;
        projectDetail.projectFileSize = fileSize;
        projectDetail.contentType = contentType;
        projectDetail.projectDetailsId = 0;
        projectDetail.projectOptionDetailDescription = binaryString.split(";base64,")[1];
        projectDetail.projectOptionDetailId = null;
        projectDetail.projectQuestionId = questionId
        projectDetail.projectQuestionType = this.imageQuestionType;
        this.projectDetailArray.push(projectDetail);
      }
      else {
        this.projectDetailArray[indexInArray].contentType = contentType;
        this.projectDetailArray[indexInArray].projectOptionDetailDescription = binaryString.split(";base64,")[1];
        this.projectDetailArray[indexInArray].projectQuestionId = questionId;
        this.projectDetailArray[indexInArray].projectFileSize = fileSize;
      }
    }
  }
  removeAttachment1(fileIndex: number) {
    var fileObject = this.file1List[fileIndex];
    var index = this.projectDetailArray.findIndex(x => x.projectQuestionId == fileObject.questionId && x.imageName == fileObject.fileName);
    this.projectDetailArray.splice(index, 1);
    this.file1List.splice(fileIndex, 1);
    this.file1ListCount = this.file1List.length;
    // if (this.file1List.length == 0) {
    //   this.documentImageMsg = this.requireMsg;
    // }
    // else {
    //   this.documentImageMsg = '';
    // }
  }
  removeAttachment2(fileIndex: number) {
    var fileObject = this.file2List[fileIndex];
    var index = this.projectDetailArray.findIndex(x => x.projectQuestionId == fileObject.questionId && x.imageName == fileObject.fileName);
    this.projectDetailArray.splice(index, 1);
    this.file2List.splice(fileIndex, 1);
    this.file2ListCount = this.file2List.length;
    // if (this.file2List.length == 0) {
    //   this.challengesImageMsg = this.requireMsg;
    // }
    // else {
    //   this.challengesImageMsg = '';
    // }
  }

  setExpertizeDetails(expertizeDetail: any, projectId) {
    this.projectExpertizeDetailsArray = [];
    if (expertizeDetail != null) {
      expertizeDetail.forEach(element => {
        this.setProjectExpertiseDetails(element.ProjectExpertizeDetailsId, element.ProjectExpertizeId, element.ProjectExpertizeSubCategoryId, projectId);
        this.expertizeLookup.find(p => p.expertizeId == element.ProjectExpertizeId).checked = true;
      });
      this.setProjectSubCategory(expertizeDetail);
    }
  }
  setProjectExpertiseDetails(ProjectExpertizeDetailsId, ProjectExpertizeId, ProjectExpertizeSubCategoryId, projectId) {
    let projectExpertizeDetails = new ProjectExpertizeDetail();
    projectExpertizeDetails.projectExpertizeDetailsId = 0;
    projectExpertizeDetails.projectExpertizeId = ProjectExpertizeId;
    projectExpertizeDetails.projectExpertizeSubCategoryId = ProjectExpertizeSubCategoryId;
    projectExpertizeDetails.projectId = projectId;
    this.projectExpertizeDetailsArray.push(projectExpertizeDetails);
  }
  setProjectSubCategory(expertizeDetail) {
    this.isCategorySelected = true;
    this.expertizeLookup.forEach(element => {
      if (element.checked) {
        let subCategory = new Array<SubExpertizeCategory>();
        let subExpertize = new SubExpertize();
        subExpertize.expertizeId = element.expertizeId;
        subExpertize.expertizeName = this.masterLookupGroup.expertizeLookup.find(t => t.expertizeId == element.expertizeId).expertizeName;

        this.masterLookupGroup.expertizeSubCategoryLookup.forEach(element1 => {
          if (element1.expertizeId == element.expertizeId)
            subCategory.push(element1);
        })
        subExpertize.subExpertizeCategory = subCategory;
        this.subExpertizeArray.push(subExpertize);
      }

    });
    expertizeDetail.forEach(element => {
      this.subExpertizeArray.forEach(ele => {
        ele.subExpertizeCategory.forEach(elem => {
          if (elem.expertizeSubCategoryId == element.ProjectExpertizeSubCategoryId) {
            elem.checked = true;
          }
        })
      })

    });
  }
  setselectall(checked) {
    this.selectcheckdAll = checked;
    this.subExpertizeArray = [];
    if (checked) {
      this.projectExpertizeDetailsArray.splice(0);
    }
    this.expertizeLookup.forEach(element => {
      element.checked = checked;
      this.setExpertiseSubCategory(element.expertizeId, checked, checked);
      if (checked) {
        this.subExpertizeArray.forEach(cat => {
          if (cat.expertizeId == element.expertizeId) {
            cat.subExpertizeCategory.forEach(subCat => {
              subCat.checked = checked;
              this.addCategory(subCat.expertizeSubCategoryId, checked);
            });
          }
        });
      }
    });

  }
  clickExpertise(expertizeId, checked) {
    this.setExpertiseSubCategory(expertizeId, checked)
    this.chkAllfieldSelected();
  }

  clickSubExpertise(value, checked) {
    this.addCategory(value, checked)
    this.chkAllfieldSelected();
  }

  chkAllfieldSelected() {
    if (this.masterLookupGroup.expertizeLookup.findIndex(p => !p.checked) > -1 || this.subExpertizeArray.findIndex(p => p.subExpertizeCategory.find(q => !q.checked)) > -1) {
      this.selectcheckdAll = false;
    }
    else {
      this.selectcheckdAll = true;
    }
  }
  setExpertiseSubCategory(expertizeId, checked, isSelectAll: boolean = false) {
    var category = this.masterLookupGroup.expertizeLookup.filter(t => t.expertizeId == expertizeId);
    category[0].checked = checked;

    if (checked) {
      var subExpertizeModal = new SubExpertize();
      var subCategoryArray = new Array<SubExpertizeCategory>();
      var filteredValue = this.masterLookupGroup.expertizeSubCategoryLookup.filter(t => t.expertizeId == expertizeId);
      filteredValue.forEach((element) => {
        let subCategory = new SubExpertizeCategory();
        subCategory.expertizeId = element.expertizeId;
        subCategory.expertizeSubCategoryId = element.expertizeSubCategoryId;
        subCategory.expertizeSubCategoryName = element.expertizeSubCategoryName;
        subCategory.checked = false;
        subCategoryArray.push(subCategory);
      });
      subExpertizeModal.subExpertizeCategory = subCategoryArray;
      subExpertizeModal.expertizeName = category[0].expertizeName;
      subExpertizeModal.expertizeId = category[0].expertizeId;

      this.subExpertizeArray.push(subExpertizeModal);

      this.isCategorySelected = true;
    }
    else {
      this.subExpertizeArray.forEach((element, index) => {
        if (element.expertizeId == expertizeId) {
          this.subExpertizeArray.splice(index, 1);
        }
      });
      if (this.projectExpertizeDetailsArray.length > 0) {
        for (var i = 0; i < this.projectExpertizeDetailsArray.length; i++) {
          if (this.projectExpertizeDetailsArray[i].projectExpertizeId == expertizeId) {
            this.projectExpertizeDetailsArray.splice(i, 1);
            i--;
          }
        }
      }
      //replace else if with if
      // else if (this.subExpertizeArray.length == 0) {
      //   this.isCategorySelected = false;
      // }
      if (this.subExpertizeArray.length == 0) {
        this.isCategorySelected = false;
      }
    }
  }
  addCategory(value: any, checked: any) {
    if (checked) {
      let expertize = new ProjectExpertizeDetail();
      expertize.projectExpertizeDetailsId = 0;
      expertize.projectId = this.projectId;
      expertize.projectExpertizeSubCategoryId = Number(value);
      this.subExpertizeArray.forEach((element, index) => {
        if (element.subExpertizeCategory.length > 0) {
          element.subExpertizeCategory.forEach((element, index) => {
            if (element.expertizeSubCategoryId == value) {
              expertize.projectExpertizeId = element.expertizeId;
              element.checked = true;
            }
          })
        }
      })
      let inArray = this.projectExpertizeDetailsArray.find(x => x.projectExpertizeSubCategoryId == value);
      if (!inArray) {
        this.projectExpertizeDetailsArray.push(expertize);
      }
      // this.projectExpertizeDetailsArray.push(expertize);
    }
    else {
      this.projectExpertizeDetailsArray.forEach((element, index) => {
        if (element.projectExpertizeSubCategoryId == value) {
          this.subExpertizeArray.find(p => p.expertizeId == element.projectExpertizeId).subExpertizeCategory.find(q => q.expertizeSubCategoryId == element.projectExpertizeSubCategoryId).checked = checked;
          this.projectExpertizeDetailsArray.splice(index, 1);
        }
      });
    }
  }
  onBudget(questionId, questionType, value) {
    let currency = parseInt(value);
    if (value != '') {
      if (!isNaN(currency)) {
        if (currency > 0 && currency <= 1000000) {
          let index = this.projectDetailArray.findIndex(x => x.projectQuestionId == questionId)
          if (index < 0) {
            this.isBudget = false;
            this.budgetInputValidation = "";
            this.budgetText = '';
            this.budgetNumValue = 0;
            let projectDetail = new ProjectDetail();
            projectDetail.checked = null;
            projectDetail.imageName = null;
            projectDetail.projectDetailsId = 0;
            projectDetail.projectOptionDetailDescription = currency.toString();
            projectDetail.projectOptionDetailId = null;
            projectDetail.projectQuestionId = questionId;
            this.isBudgetMsg = '';
            projectDetail.projectQuestionType = questionType;
            this.projectDetailArray.push(projectDetail);
          }
          else {
            this.isBudget = false;
            this.budgetText = '';
            this.isBudgetMsg = '';
            this.budgetInputValidation = "";
            this.budgetNumValue = 0;

            this.projectDetailArray[index].projectOptionDetailDescription = currency.toString();
          }
        }
        else {
          this.isBudget = true;
          this.budgetText = CustomMessage.validAmount;
          this.isBudgetMsg = '';
          this.budgetInputValidation = "";
          this.budgetNumValue = currency;
          this.budgetValue = '';

        }
      }
      else {
        this.budgetInputValidation = "Amount will be the numeric";
        this.budgetText = '';
        this.isBudgetMsg = '';
        this.budgetValue = value;
        this.budgetNumValue = 0;
      }
      // }
    }
    else {
      if (QuestionsEnum.Question7 == questionId) {
        this.isBudgetMsg = this.requireMsg;
        this.budgetText = '';
        this.budgetInputValidation = "";
        this.isBudget = true;

        this.budgetValue = '';

      }

    }


  }

  setValidationMessage() {
    this.questionIdArray.forEach(element => {
      if (QuestionsEnum.Question1 == element.questionId && (element.statusId == StatusEnum.InActive || element.statusId == StatusEnum.Deleted)) {
        this.isSingleOrEnterpriseMsg = '';
      }
      else if (QuestionsEnum.Question2 == element.questionId && (element.statusId == StatusEnum.InActive || element.statusId == StatusEnum.Deleted)) {
        this.isEmployeeMsg = '';
      }
      else if (QuestionsEnum.Question3 == element.questionId && (element.statusId == StatusEnum.InActive || element.statusId == StatusEnum.Deleted)) {
        this.isStageMsg = '';
      }
      else if (QuestionsEnum.Question4 == element.questionId && (element.statusId == StatusEnum.InActive || element.statusId == StatusEnum.Deleted)) {
        this.isDeliverableMsg = '';
        this.textArea1ErrMsg = '';
      }
      // else if (QuestionsEnum.Question5 == element.questionId && (element.statusId == StatusEnum.InActive || element.statusId == StatusEnum.Deleted)) {
      //   this.documentImageMsg = '';
      // }
      else if (QuestionsEnum.Question6 == element.questionId && (element.statusId == StatusEnum.InActive || element.statusId == StatusEnum.Deleted)) {
        this.isExperienceMsg = '';
      }
      else if (QuestionsEnum.Question7 == element.questionId && (element.statusId == StatusEnum.InActive || element.statusId == StatusEnum.Deleted)) {
        this.isBudgetMsg = '';
        this.isBudget = false;
        this.budgetText = '';
      }
      else if (QuestionsEnum.Question8 == element.questionId && (element.statusId == StatusEnum.InActive || element.statusId == StatusEnum.Deleted)) {
        this.isGoalsAndObjectiveMsg = '';
        this.textArea2ErrMsg = '';
      }
      // else if (QuestionsEnum.Question9 == element.questionId && (element.statusId == StatusEnum.InActive || element.statusId == StatusEnum.Deleted)) {
      //   this.challengesImageMsg = '';
      // }
    })
  }
  validateValues() {
    this.questionIdArray.forEach(element => {
      let index = this.projectDetailArray.findIndex(x => x.projectQuestionId == element.questionId);
      if (index > -1) {
        if (QuestionsEnum.Question1 == element.questionId) {
          this.isSingleOrEnterpriseMsg = "";
        }
        else if (QuestionsEnum.Question2 == element.questionId) {
          this.isEmployeeMsg = "";
        }
        else if (QuestionsEnum.Question3 == element.questionId) {
          this.isStageMsg = '';
        }
        else if (QuestionsEnum.Question4 == element.questionId) {
          this.isDeliverableMsg = '';
        }
        // else if (QuestionsEnum.Question5 == element.questionId) {
        //   this.documentImageMsg = '';
        // }
        else if (QuestionsEnum.Question6 == element.questionId) {
          this.isExperienceMsg = '';
        }
        else if (QuestionsEnum.Question7 == element.questionId) {
          this.isBudgetMsg = '';
        }
        else if (QuestionsEnum.Question8 == element.questionId) {
          this.isGoalsAndObjectiveMsg = '';
        }
        // else if (QuestionsEnum.Question9 == element.questionId) {
        //   this.challengesImageMsg = '';
        // }
      }
      else {
        if (QuestionsEnum.Question1 == element.questionId) {
          this.isSingleOrEnterpriseMsg = this.requireMsg;
        }
        else if (QuestionsEnum.Question2 == element.questionId) {
          this.isEmployeeMsg = this.requireMsg;
        }
        else if (QuestionsEnum.Question3 == element.questionId) {
          this.isStageMsg = this.requireMsg;
        }
        else if (QuestionsEnum.Question4 == element.questionId) {
          this.isDeliverableMsg = this.requireMsg;
          this.textArea1ErrMsg = '';
        }
        // else if (QuestionsEnum.Question5 == element.questionId) {
        //   this.documentImageMsg = this.requireMsg;
        // }
        else if (QuestionsEnum.Question6 == element.questionId) {
          this.isExperienceMsg = this.requireMsg;
        }
        else if (QuestionsEnum.Question7 == element.questionId) {
          // this.isBudgetMsg = this.requireMsg;
          if (this.budgetValue != '') {
            this.budgetInputValidation = "Amount will be the numeric";
          }
          else if (this.budgetNumValue != 0) {
            this.isBudget == false;
          }
          else {
            this.isBudgetMsg = this.requireMsg;
          }
        }
        else if (QuestionsEnum.Question8 == element.questionId) {
          this.isGoalsAndObjectiveMsg = this.requireMsg;
          this.textArea2ErrMsg = '';
        }
        // else if (QuestionsEnum.Question9 == element.questionId) {
        //   this.challengesImageMsg = this.requireMsg;
        // }
      }
    })
    this.setValidationMessage();
    if (this.isSingleOrEnterpriseMsg == '' && this.isEmployeeMsg == '' && this.isStageMsg == ''
      && this.isDeliverableMsg == '' && this.textArea1ErrMsg == '' && this.isExperienceMsg == ''
      && this.isBudgetMsg == '' && this.isBudget == false && this.budgetInputValidation == ''
      && this.isGoalsAndObjectiveMsg == '' && this.textArea2ErrMsg == '') {
      this.isValidMsg = true;
    }
    else {
      this.isValidMsg = false;
    }
  }
  onSubmit() {
    this.setDefaultValues();
    this.validateValues();
    if (this.projectFormGroup.valid && this.projectExpertizeDetailsArray.length > 0 && this.isValidMsg == true && !this.showDateErrorMessage) {
      this.projectFormGroup.value.projectDetails = this.projectDetailArray;
      this.projectFormGroup.value.projectExpertizeDetails = this.projectExpertizeDetailsArray;
      this.spin = true;
      this.post({ body: this.projectFormGroup.value }).subscribe((response: number) => {
        this.projectId = response;
        this.toastr.success(CustomMessage.dataAdded);
        this.spin = false;
        this.route.navigate([RoutingKeys.MatchingHRC, response]);
      });
    }
  }
  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
}
export class SubExpertize {
  subExpertizeCategory: Array<SubExpertizeCategory>;
  expertizeName: string;
  expertizeId: number;
}
export class SubExpertizeCategory {
  expertizeSubCategoryId: number;
  expertizeSubCategoryName: string;
  expertizeId: number;
  checked: boolean;
}

export class Model {
  questionId: string;
  statusId: string;
  questionText: string;
  questionType: string;
}
class file1ViewModel {
  fileName: string;
  attachmentBase64String: string;
  questionId: number;
  contentType: string;
  fileSize: number;
}
class file2ViewModel {
  fileName: string;
  attachmentBase64String: string;
  questionId: number;
  contentType: string;
  fileSize: number;
}