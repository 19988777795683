import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProjectRFQBase {

//#region projectRFQId Prop
        @prop()
        projectRFQId : number;
//#endregion projectRFQId Prop


//#region projectId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        projectId : number;
//#endregion projectId Prop


//#region hRConsultant Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        hRConsultant : number;
//#endregion hRConsultant Prop


//#region deadlineDate Prop
        @required()
        deadlineDate : Date;
//#endregion deadlineDate Prop


//#region projectRFQStatus Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        projectRFQStatus : number;
//#endregion projectRFQStatus Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop





}