export const API: { [key: string]: string } = {
    authorize: 'api/Authorize',
    authentication: 'api/authentication',
    staticPage: 'api/StaticPage',
    linkedInlogin: 'api/linkedInlogin',
    roleLookup: 'api/masterlookups/rolelookup',
    getLinkedInCredential: 'api/Authentication/GetLinkedInCredential',
    resetPassword: 'api/ResetPassword/ResetPassword',
    forgotPassword: 'api/Authentication/forgotPassword',
    searchPaymentDetails: 'api/SearchPaymentDetails',
    saveLinkedinUser: 'api/Authentication/SaveLinkedinUser',
    saveUser: 'api/Authentication/SaveUser',
    logout: 'api/authorize/logout',
    getAllApplicationObjects: 'api/applicationObjects/getAllApplicationObjects',
    authorizeDotNet:'api/StaticPage/AuthorizeDotNet',
    usCompPlan:'api/StaticPage/UsCompPlan',
    documentPayment:'api/SearchPlanDetails/DocumentPayment',
    paymentAccessKey: 'api/SearchUsers/PaymentAccessKey',
    addCredential:'api/SearchPlanDetails/AddCredential',
    activationKey: 'api/ResetPassword/ActivationKey',
    adminDashboard: 'api/SearchDashboard/adminDashboard',
    searchDashboard:'api/SearchDashboard',
    searchProjectRecordsCount:'api/SearchProjectRecordsCount',
    getExpertiseCount: 'api/Expertize/getExpertiseCount',
    getEmailNotifications: 'api/EmailNotification/getEmailNotifications',
    userDetails: 'api/Authorize/userDetails',
    markAsReadEmailNotification: 'api/EmailNotification/MarkAsReadEmailNotification',
    deleteAllEmailNotification: 'api/EmailNotification/DeleteAllEmailNotification',
    getRFP:'api/PublicRFP/GetRFP',
    contactUs:'api/StaticPage/ContactUs',
    searchContracts: 'api/SearchContracts',
    searchSubscriptionPlan: 'api/SearchSubscriptionPlan',
    planPrice: 'api/SearchPlanPrice/planPrice',
    category: 'api/UploadAttachments/category',
    searchComplianceCategory: 'api/SearchComplianceCategory',
    document: 'api/SearchComplianceCategory/Document',
    users :'api/Users',
    paymentReport: 'api/SearchUserReport/paymentReport',
    contractView: 'api/Contracts/contractView',
    services: 'api/Feedbacks/services',
    searchFeedback: 'api/SearchFeedback',
    feedbackView: 'api/Feedbacks/feedbackView',
    sendMessage: 'api/Messages/sendMessage',
    getMessages:'api/Messages/getMessages',
    searchProjectRFQ: 'api/SearchProjectRFQ',
    searchHRCMatching: 'api/SearchHRCMatching',
    createProjectRFQ: 'api/SearchHRCMatching/CreateProjectRFQ',
    reportInfo: 'api/SearchDashBoard/reportInfo',
    download: 'api/StaticPage/download',
    quoteMessageCount:'api/ProjectRFQ/QuoteMessageCount',
    projects:'api/Projects',
    checkDeadline: 'api/SearchProjectRFQ/checkDeadline',
    searchPaymentDetails_Id:'api/SearchPaymentDetails/Id',
    paidExpertise:'api/Users/PaidExpertise',
    notpaidExpertise: 'api/Users/NotpaidExpertise',
    cancelPlan:'api/SearchPlanDetails/CancelPlan',
    updateExpertisePlan:'api/SearchContracts/updateExpertisePlan',
    addNewUserExpertise: 'api/Expertize/addNewUserExpertise',
    getExpertiseAmount: 'api/SearchContracts/getExpertiseAmount',
    hrcAccount: 'api/SearchUsers/HrcAccount',
    projectRFQ: 'api/ProjectRFQ',
    allProjectList: 'api/SearchUsers/AllProjectList',
    searchUserData:'api/SearchUserData',
    searchUserReport:'api/SearchUserReport',
    access:'api/Authorize/access',
    searchComplianceCategory_USCompId:'api/SearchComplianceCategory/GetUSComplianceId',
    checkPaymentAccessKey: 'api/StaticPage/CheckPayment'
    
};