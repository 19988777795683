import {UserRoleBase,RoleBase,UserBase,} from '@app/database-models'
//Generated Imports
export class UserRole extends UserRoleBase 
{




//#region Generated Reference Properties
//#region role Prop
role : RoleBase;
//#endregion role Prop
//#region user Prop
user : UserBase;
//#endregion user Prop

//#endregion Generated Reference Properties


























}