import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vRoleBase {

//#region roleId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'roleId', keyColumn: true})
        roleId : number;
//#endregion roleId Prop


//#region roleName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'roleName', keyColumn: false,headerTitle: "Role"})
        roleName : string;
//#endregion roleName Prop


//#region totalCount Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'totalCount', keyColumn: false})
        totalCount : any;
//#endregion totalCount Prop

}