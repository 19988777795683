import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ExpertizeBase {

//#region expertizeId Prop
        @prop()
        expertizeId : number;
//#endregion expertizeId Prop


//#region expertizeName Prop
        @required()
        @maxLength({value:100})
        expertizeName : string;
//#endregion expertizeName Prop


//#region expertizeDetails Prop
        @maxLength({value:500})
        expertizeDetails : string;
//#endregion expertizeDetails Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop





}