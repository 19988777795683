import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProjectHrcMailSendBase {

//#region projectHRCMatchingId Prop
        @prop()
        projectHRCMatchingId : any;
//#endregion projectHRCMatchingId Prop


//#region projectId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        projectId : number;
//#endregion projectId Prop


//#region userId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        userId : number;
//#endregion userId Prop

}