import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({providedIn:'root'})
export class ApplicationBroadcaster {
    private loginSubject: Subject<boolean> = new Subject<boolean>();
    public loginSubscriber: Observable<boolean>;

    private userNameSubject: Subject<string> = new Subject<any>();
    public userNameSubscriber: Observable<any>;

    private switchServiceSubject: Subject<string> = new Subject<string>();
    public switchServiceSubscriber: Observable<string>;

    private emailNotificationsSubject: Subject<number> = new Subject<any>();
    public emailNotificationsSubscriber: Observable<any>;

    private quoteMessagesSubject: Subject<number> = new Subject<any>();
    public quoteMessagesSubscriber: Observable<any>;

    constructor() {
        this.loginSubscriber = this.loginSubject.asObservable();        
        this.userNameSubscriber = this.userNameSubject.asObservable();
        this.emailNotificationsSubscriber = this.emailNotificationsSubject.asObservable();
        this.quoteMessagesSubscriber = this.quoteMessagesSubject.asObservable();
    }

    loginBroadCast(value: boolean): void {
        this.loginSubject.next(value);
    }
    
    userNameBroadCast(value:string): void {
        this.userNameSubject.next(value);
    }

    switchServiceBroadCast(value: string): void{
        this.switchServiceSubject.next(value);
    }

    emailNotificationsCount(value : number) : void {
        this.emailNotificationsSubject.next(value);
    }

    quoteMessagesCount(value : number) : void {
        this.quoteMessagesSubject.next(value);
    }
}
