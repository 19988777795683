import { DatePipe } from '@angular/common';
export class StorageKeys {
    public static Authentication = 'auth';
    public static Username = 'userName';
    public static ProfilePicture = 'profilePicture';
    public static RedirectFrom = 'redirectFrom';
    public static UserRegisteredForm = 'userRegisteredForm';
    public static OrcidId = 'orcidId';
    public static ApplicationObjects = 'applicationObjects';
    public static UserId = 'userId';
    public static UserRole = 'role';
    public static IsLoggedIn = 'isLoggedIn';
    public static LastRequestOn = 'lastRequestOn';
}

export class RoutingKeys {
    public static Login = 'login';
    public static AddUser = 'user/add';
    public static Dashboard = 'dashboard';
    public static RolesPermission = 'roles';
    public static EditRole = 'roles/edit';
    public static Degree = 'degrees';
    public static Position = 'positions';
    public static University = 'universities';
    public static emailTemplateTtype = 'email-template-types';
    public static emailTemplate = 'email-templates';
    public static manageUser = 'manage-users';
    public static adminUser = 'admin-users';
    public static Profile = 'profile';
    public static manageJob = 'manage-job';
    public static thankYouResetPasswordError = "thank-you/ResetPasswordError";
}

export class CommonConstants {
    public static DateFormat = 'MMM d, y, h:mm:ss a';
    public static SuperAdminRoleName = 'super admin';
}

export class PipeConstants {
    public static datePipe = new DatePipe('en-US');
}

export class RequestHeaderKeys {
    public static Authorization = 'Authorization';
}

export class Extension {
    public static Image = '.jpg,.jpeg,.png';
}

export class ProjectRFQDeadlineDateText{
    public static DeadlineText='*Project RFQ Deadline Date will be closed at 23:59:59 PST.'
}

export class ProjectRFQDeadlineDateTextMatchingHRC{
    public static DeadlineText='*Project RFQ Deadline Date will be closed at deadlineDate 23:59:59 PST.'
}

export class ModelResponse{
    public static Yes ='yes'   
}

export class ModelComponentType{
    public static GuestHRC1stLogin ='guestHRC1stLogin';
    public static Roles ='roles';
    public static DocSuccess ='docSuccess';
    public static Success ='success';
    public static Delete ='delete';
    public static EmailNotificationModal ='emailNotificationModal';
    public static MarkAsRead ='markAsRead';
    public static DeleteAll ='deleteAll';
    public static ShowExpertiseDetails ='showExpertiseDetails';
    public static ShowSubExpertiseDetails ='showSubExpertiseDetails';
    public static PopupMessage ='popupMessage';
    public static Hrcomplianceformtips ='hrcomplianceformtips';
    public static ResponseDescription ='responseDescription';
    public static Approve ='approve';
    public static Reject ='reject';
    public static MessageModal ='messageModal';
    public static Interested ='interested';
    public static Notinterested ='notinterested';
    public static MatchingHRC ='matchingHRC';
    public static ChangeExpertiseTab ='changeExpertiseTab';
    public static CancelPlan ='cancelPlan';
    public static UserEdit ='userEdit';
    public static DisbaleEnableExpertise ='disbaleEnableExpertise';
    public static HrcPlanDetailModal ='hrcPlanDetailModal';
    public static TipsForExpertiseInHRCProfile ='tipsForExpertiseInHRCProfile';
    public static FreeAccount = 'freeAccount';
    public static ComplianceCategoryChange = 'ChangeCategory';
    public static HRCVideo ='HRCVideo';
    public static HROVideo ='HROVideo';
}