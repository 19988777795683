import {vHRCRecordBase} from '../database-models/v-h-r-c-record-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name: 'viewDetailsAction',
    allowSorting: false,
    configuredTemplate: { templateName: 'viewDetailsAction' }, columnIndex: 9, headerTitle: "Action", class: ["actionFild"], headerCellClass: ["pl-4"]
})
export class vHRCRecord extends vHRCRecordBase {




    //#region Generated Reference Properties

//#endregion Generated Reference Properties

























}