import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class AthorizeDotNetResponseCodeBase {

//#region athorizeDotNetResponseCodeId Prop
        @prop()
        athorizeDotNetResponseCodeId : number;
//#endregion athorizeDotNetResponseCodeId Prop


//#region responseCode Prop
        @required()
        @maxLength({value:10})
        responseCode : string;
//#endregion responseCode Prop


//#region responseDescription Prop
        @required()
        responseDescription : string;
//#endregion responseDescription Prop


//#region statusId Prop
        @prop()
        statusId : number;
//#endregion statusId Prop

}