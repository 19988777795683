import { TemplateConfig } from '@rxweb/grid';

export const EXPERTISE_ACTION_GRID_TEMPLATE: TemplateConfig = {
    div: {
        class: 'formAction list-unstyled mb-0'.split(' '),
        childrens: [{
            a: {
                class: 'gridEditbtn'.split(' '),
                authorize: 'put',
                event: {
                    click: 'onEdit'
                },
                childrens: [{
                    a: {
                        childrens: [{
                            i: { class: 'feather icon-edit'.split(' ') }
                        }],
                    }
                }]
            }
        },
        {
            a: {
                class: 'gridDeletebtn'.split(' '),
                authorize: 'delete',
                event: {
                    click: 'onDelete'
                },
                childrens: [{
                    a: {
                        childrens: [{
                            i: { class: 'feather icon-trash-2'.split(' ') }
                        }],
                    }
                }]
            }
        },
        {
            a: {
                class: 'gridinfobttn'.split(' '),
                authorize: 'get',
                event: {
                    click: 'viewDetails'
                },
                childrens: [{
                    a: {
                        childrens: [{
                            i: { class: 'fas fa-address-card'.split(' ') }
                        }],
                    }
                }]
            }
        }
        ]
    }
};
