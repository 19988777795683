import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractFeedback } from '../domain/abstract-feedback';
import { Feedback, vFeedbackDetail } from "@app/models";
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CommonHelperService } from 'src/app/components/shared/common-helpers/helper.service';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { RoutingKeys, StorageKeys, ToolTipText } from 'src/app/components/shared/constant/commonConstants';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { AppGrid } from 'src/app/domain/app-grid';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { vFeedbackDetailHRO } from 'src/app/viewModel/vFeedbackDetailHRO';
import { vFeedbackDetailHRC } from 'src/app/viewModel/vFeedbackDetailHRC';
import { RolesEnum } from 'src/app/enums/roles-enum';
import { access } from '@rxweb/angular-router';
import { ApplicationModules, PermissionNames } from 'src/app/enums/application-modules.enum';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { SpParameter, PageDataQuery, SearchQuery, Query } from 'src/app/viewModel/sp-parameter';
import { FeedbackAddComponent } from "../add/feedback-add.component";
import { ModelComponentType, ModelResponse } from "src/app/components/shared/constant/common-constants";
declare const $: any;

@access({ accessLevel: ApplicationModules.Feedbacks, action: PermissionNames.Get })

@Component({
    selector: "app-feedback-list",
    templateUrl: './feedback-list.component.html'
})

export class FeedbackListComponent extends AbstractFeedback implements OnInit, OnDestroy {
    feedbackAdd: any = FeedbackAddComponent;

    feedbacks: List<Feedback>;
    subscription: Subscription;
    feedbackGrid: AppGrid;
    feedbackGridColumns: any[];
    searchText = '';
    modalView: ModalView;
    queryString: string;
    loggedInUserRoleId: number;
    hRORoleId: number = RolesEnum.HRO;
    storageHelper: BrowserStorage;
    totalRecordsPerPage = 10;
    pageIndex = 1;
    orderByColumn = 'projectName';
    sortOrder = 'desc';
    isGridBound = true;
    showDescHRO: boolean = false;
    showDescHRC: boolean = false;


    constructor(
        private route: Router,
        private commonHelperService: CommonHelperService,
        modalView: ModalView
    ) {
        super();
        this.modalView = modalView;
        this.storageHelper = new BrowserStorage();
    }

    ngOnInit(): void {
        this.initializeGridforSP();
    }


    initializeGridforSP(page: Number = 0) {
        page = page == 0 ? 0 : this.pageIndex;
        const jsonToSend = this.getJsonToSend();
        // if (this.isGridBound && !this.commonHelperService.isObjectNullOrUndefined(this.feedbackGrid)) {
        //     this.feedbackGrid.clearGrid('feedbacks');
        // }
        this.spin = true;
        this.showComponent = true;
        let sendJson = new Query();
        sendJson.query = JSON.stringify(jsonToSend);
        this.subscription = this.getFeedbacks(sendJson).subscribe((feedbackList: any) => {
            this.spin = false;
            this.bindGrid(feedbackList, page);
        }, () => {
            this.spin = false;
        });
    }

    bindGrid(data: any, page: Number = 0) {
        const parsedData = JSON.parse(data)[0].result;
        const gridData = parsedData.data;
        const totalRecords = parsedData.footer[0].totalCount;
        this.loggedInUserRoleId = this.user.roleId;
        if (page == 0) {
            if (this.loggedInUserRoleId == RolesEnum.SuperAdmin) {
                this.feedbackGrid = new AppGrid(gridData, vFeedbackDetail
                    , {
                        actions: {
                            onEdit: this.onEdit.bind(this),
                            onDelete: this.onDelete.bind(this),
                            onHROLink: this.onHROLink.bind(this),
                            onHRCLink: this.onHRCLink.bind(this),
                            onProjectLink: this.onProjectLink.bind(this),
                            viewDetails: this.viewDetails.bind(this),
                        }
                    }
                );
            }
            else if (this.loggedInUserRoleId == RolesEnum.HRO) {
                this.feedbackGrid = new AppGrid(gridData, vFeedbackDetailHRO
                    , {
                        actions: {
                            onEdit: this.onEdit.bind(this),
                            onDelete: this.onDelete.bind(this),
                            onHRCLink: this.onHRCLink.bind(this),
                            onProjectLink: this.onProjectLink.bind(this),
                            viewDetails: this.viewDetails.bind(this),
                        }
                    }
                )
                this.showDescHRO = true;
            }
            else if (this.loggedInUserRoleId == RolesEnum.HRConsultant) {
                this.feedbackGrid = new AppGrid(gridData, vFeedbackDetailHRC
                    , {
                        actions: {
                            viewDetails: this.viewDetails.bind(this),
                            onHROLink: this.onHROLink.bind(this),
                            onProjectLink: this.onProjectLink.bind(this),
                        }
                    }
                );
                this.showDescHRC = true;
            }

            this.feedbackGrid.authorization = {
                'get': { accessLevel: ApplicationModules.Feedbacks, action: PermissionNames.Get },
                'put': { accessLevel: ApplicationModules.Feedbacks, action: PermissionNames.Put },
                'delete': { accessLevel: ApplicationModules.Feedbacks, action: PermissionNames.Delete }
            }
            this.feedbackGrid.storeProcedure = {
                length: totalRecords,
                onPageChanging: this.onPageChanging.bind(this),
                onPageSorting: this.onPageSorting.bind(this),
                nextPage: this.pageIndex
            };
            if (this.isGridBound) {
                this.feedbackGrid.design(document.getElementById('feedbacks'));
                this.isGridBound = false;
            }
        }
        else {
            if (this.isGridBound) {
                this.feedbackGrid.design(document.getElementById('feedbacks'));
                this.isGridBound = false;
            }
            this.feedbackGrid.storeProcedure = {
                length: totalRecords,
                onPageChanging: this.onPageChanging.bind(this),
                nextPage: this.pageIndex,
                onPageSorting: this.onPageSorting.bind(this)
            }
            this.feedbackGrid.updateSource(gridData);

        }
        this.addTooltip();
        //this.feedbackGrid.updateSource([]);            
    }

    onPageChanging(nextPage: number) {
        this.totalRecordsPerPage = this.feedbackGrid.maxPerPage;
        this.pageIndex = nextPage;
        //this.isGridBound = true;
        this.initializeGridforSP(this.pageIndex);
    }

    onPageSorting(columnName: string, isAscendingOrder: boolean, currentPage: number) {
        this.orderByColumn = columnName;
        this.sortOrder = isAscendingOrder ? 'asc' : 'desc';
        //this.isGridBound = true;
        this.pageIndex = 1;
        this.initializeGridforSP(1);
    }

    search(value) {
        this.searchText = value;
        this.pageIndex = 1;
        this.initializeGridforSP(this.pageIndex);
    }

    getJsonToSend(): SpParameter {
        const pageQuery = new PageDataQuery();
        pageQuery.PageRow = this.totalRecordsPerPage;
        pageQuery.PageIndex = this.pageIndex;
        pageQuery.OrderColumn = this.orderByColumn;
        pageQuery.OrderType = this.sortOrder;

        const jsonObject = new SpParameter();
        jsonObject.searchQuery = new SearchQuery();
        jsonObject.searchQuery.searchText = this.searchText;
        jsonObject.pageQuery = JSON.stringify(pageQuery);
        jsonObject.searchQuery = JSON.stringify(jsonObject.searchQuery);
        return jsonObject;
    }

    onEdit(feedback: Feedback) {
        this.spin = true;
        this.route.navigate([RoutingKeys.FeedBacks, feedback.feedbackId]);
    }

    onDelete(feedback: Feedback) {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Delete }).then(t => {
            if (t == ModelResponse.Yes) {
                this.spin = true;
                this.delete({ params: [feedback.feedbackId], body: null }).subscribe(response => {
                    this.spin = false;
                    this.toastr.success(CustomMessage.dataDeleted);
                    this.initializeGridforSP(this.pageIndex);
                });
            }
        });
    }

    onHROLink(feedback: any) {
        this.route.navigateByUrl("hro-management/feedbackHRODetails/" + feedback.hroId);
    }

    onHRCLink(feedback: any) {
        this.route.navigateByUrl("hrc/feedbackHRCDetails/" + feedback.hrcId);
    }

    onProjectLink(feedback: any) {
        this.route.navigateByUrl("projects/feedbackProjectDetails/" + feedback.projectId);
    }

    viewDetails(feedback: Feedback) {
        
        this.route.navigate([RoutingKeys.FeedbackView, feedback.feedbackId]);
    }

    addFeedback() {
        this.route.navigate([RoutingKeys.AddFeedBacks]);
    }

    addTooltip() {
        $(document).ready(function () {
            $(".gridEditbtn").hover(function () {
                $(this).attr("title", ToolTipText.EditFeedback);
            })
            $(".gridDeletebtn").hover(function () {
                $(this).attr("title", ToolTipText.DeleteFeedback);
            })
            $(".gridinfobttn").hover(function () {
                $(this).attr("title", ToolTipText.ViewFeedback);
            })
        })
    }

    openTips() {
        let msg = new Array<any>();
        msg[0] = 'Please rate your human resources consultant (HRC) here, feedback is a valuable part of the consultant process.'
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.PopupMessage, params: msg }).then(t => {
        })
    }
    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}
