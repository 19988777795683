import {vQuestionRecordBase} from '../database-models/v-question-record-base';
//Generated Imports
export class vQuestionRecord extends vQuestionRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties














































































}