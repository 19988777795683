import {CompanySizeLookupBase} from '../database-models/company-size-lookup-base';
//Generated Imports
export class CompanySizeLookup extends CompanySizeLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




























}