import { TemplateConfig } from '@rxweb/grid';
import { ContractsEnum } from 'src/app/enums/contracts.enum';

export const CONTRACT_STATUS_GRID_TEMPLATE: TemplateConfig = {
    span: {
        class: [function (e) {
            if (this[e.name] == "Approved")
                return this[e.name] = 'Approved', "label f-12 text-white greenbadge-bg".split(" ");

            else if (this[e.name] == "Pending")
                return this[e.name] = 'Pending', "label f-12 text-white pendingbadge-bg".split(" ");

            else if (this[e.name] == "Completed")
                return this[e.name] = 'Completed', "label f-12 text-white completedbadge-bg".split(" ");

            else if (this[e.name] == "Rejected")
                return this[e.name] = 'Rejected', "label f-12 text-white radbadge-bg".split(" ");
        }],
        childrens: [{
            text: {
                text: function (e) {
                    if (this[e.name] == "Approved")
                        return this[e.name] = 'Approved'

                    else if (this[e.name] == "Pending")
                        return this[e.name] = 'Pending'

                    else if (this[e.name] == "Completed")
                        return this[e.name] = 'Completed'

                    else if (this[e.name] == "Rejected")
                        return this[e.name] = 'Rejected'
                }
            }
        }]
    }
}
