import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ExpertizeSubCategoryBase {

//#region expertizeSubCategoryId Prop
        @prop()
        expertizeSubCategoryId : number;
//#endregion expertizeSubCategoryId Prop


//#region expertizeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        expertizeId : number;
//#endregion expertizeId Prop


//#region expertizeSubCategoryName Prop
        @required()
        @maxLength({value:100})
        expertizeSubCategoryName : string;
//#endregion expertizeSubCategoryName Prop


//#region expertizeSubCategoryDescription Prop
        @maxLength({value:100})
        expertizeSubCategoryDescription : string;
//#endregion expertizeSubCategoryDescription Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop





}