import {vSelectedSubscriptionPlanBase} from '../database-models/v-selected-subscription-plan-base';
//Generated Imports
export class vSelectedSubscriptionPlan extends vSelectedSubscriptionPlanBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}