import { TemplateConfig } from '@rxweb/grid';
import { PipeConstants } from 'src/app/components/shared/constant/commonConstants';


export const CONTRACT_DETAILS_ACTION_GRID_TEMPLATE: TemplateConfig = {
    div: {
        class: 'formAction list-unstyled mb-0'.split(' '),
        childrens: [
            {
                a: {
                    class:[
                        function (e) {
                            if (this.contractStatus != "Pending") {
                                return "disabledgridbtn";
                            }
                            else{
                                return "gridEditbtn";
                            }
                        }
                    ],                
                    authorize: 'put',
                    event: {
                        click: 'onEdit'
                    },
                    childrens: [{
                        a: {                        
                            childrens: [{
                                i: { class: 'feather icon-edit'.split(' ') }
                            }],
                        }
                    }]
                }
            },{
            a: {
                class: 'gridinfobttn'.split(' '),
                authorize: 'get',
                event: {
                    click: 'viewDetails'
                },
                childrens: [{
                    a: {
                        childrens: [{
                            i: { class: 'fas fa-address-card'.split(' ') }
                        }],
                    }
                }]
            }
        }
        ]
    }
};
