import {HRComplianceBase} from '../database-models/h-r-compliance-base';
import {USComplianceBase} from '../database-models/u-s-compliance-base';
//Generated Imports
export class HRCompliance extends HRComplianceBase 
{




//#region Generated Reference Properties
//#region uSCompliance Prop
uSCompliance : USComplianceBase[];
//#endregion uSCompliance Prop

//#endregion Generated Reference Properties


}