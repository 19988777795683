import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vMonthlyRetainedServiceBase {

//#region status Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'status', keyColumn: false,headerTitle: "Status",  configuredTemplate: { templateName: "recordActive" }})
        status : string;
//#endregion status Prop


//#region applicationObjectName Prop
        @gridColumn({visible: false, columnIndex:4, allowSorting: true, headerKey: 'applicationObjectName', keyColumn: false,headerTitle: "Service Type"})
        applicationObjectName : string;
//#endregion applicationObjectName Prop


//#region monthlyRetainedOptionName Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'monthlyRetainedOptionName', keyColumn: false,headerTitle: "Option"})
        monthlyRetainedOptionName : string;
//#endregion monthlyRetainedOptionName Prop


//#region monthlyRetainedServiceName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'monthlyRetainedServiceName', keyColumn: false,headerTitle: "Service"})
        monthlyRetainedServiceName : string;
//#endregion monthlyRetainedServiceName Prop


//#region monthlyRetainedServiceId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'monthlyRetainedServiceId', keyColumn: true})
        monthlyRetainedServiceId : number;
//#endregion monthlyRetainedServiceId Prop

}