import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { Contract } from '@app/models';
import { CoreComponent } from '@rxweb/angular-router';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { ContractViewModel } from 'src/app/viewModel/contract-view-model';
import { SpParameter, Query } from 'src/app/viewModel/sp-parameter';
import { RolesEnum } from 'src/app/enums/roles-enum';
import { API } from "src/app/components/shared/constant/administrationAPIContants";

@http({
    path: "api/Contracts",
})

export class AbstractContract extends CoreComponent {
    contractFormGroup: IFormGroup<ContractViewModel>
    toastr: BaseToastr;
    spin = false;
    breadCrumb: BreadCrumb;
    dialog: BaseDialog;
    showComponent: boolean = false;
    rxHttp: RxHttp;


    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.badRequest;
        this.rxHttp = new RxHttp();
    }

    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home"

        var RoleId = this.user.roleId;

        if (RoleId == RolesEnum.SuperAdmin) {
            this.breadCrumb.title = 'Contracts Management'
            this.breadCrumb.pageName = 'Contracts Management'
        }
        else {
            this.breadCrumb.title = 'My Contracts'
            this.breadCrumb.pageName = 'My Contracts'
        }      
        this.breadCrumb.pageRedirect = "contracts";
    }
    badRequest = (data) => {
        this.spin = false;
        let messages = JSON.parse(data);
        this.toastr.warning(messages[0]);
    }

    getContracts(searchParams: Query) {
        return this.rxHttp.post({ body: searchParams, path: API.searchContracts });
    }
}
