import {vDetailsSubscriptionPlanBase} from '../database-models/v-details-subscription-plan-base';
//Generated Imports
export class vDetailsSubscriptionPlan extends vDetailsSubscriptionPlanBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}