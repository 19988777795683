import {SubscriptionPlanBase} from '../database-models/subscription-plan-base';
import {SubscriptionTypeBase} from '../database-models/subscription-type-base';
import {UserDetailBase} from '../database-models/user-detail-base';
//Generated Imports
export class SubscriptionPlan extends SubscriptionPlanBase 
{




//#region Generated Reference Properties
//#region subscriptionType Prop
subscriptionType : SubscriptionTypeBase;
//#endregion subscriptionType Prop
//#region userDetails Prop
userDetails : UserDetailBase[];
//#endregion userDetails Prop

//#endregion Generated Reference Properties






























































}