import {ModuleMasterBase} from '../database-models/module-master-base';
import {ApplicationModuleBase} from '../database-models/application-module-base';
//Generated Imports
export class ModuleMaster extends ModuleMasterBase 
{




//#region Generated Reference Properties
//#region applicationModules Prop
applicationModules : ApplicationModuleBase[];
//#endregion applicationModules Prop

//#endregion Generated Reference Properties





































}