import {UserDetailBase} from '../database-models/user-detail-base';
import {SubscriptionPlanBase} from '../database-models/subscription-plan-base';
import {UserBase} from '../database-models/user-base';
import {YearsOfExperienceDetailBase} from '../database-models/years-of-experience-detail-base';
//Generated Imports
export class UserDetail extends UserDetailBase 
{




//#region Generated Reference Properties
//#region subscriptionPlan Prop
subscriptionPlan : SubscriptionPlanBase;
//#endregion subscriptionPlan Prop
//#region user Prop
user : UserBase;
//#endregion user Prop
//#region yearsOfExperienceDetail Prop
yearsOfExperienceDetail : YearsOfExperienceDetailBase;
//#endregion yearsOfExperienceDetail Prop
uploadedImage:string;
contentType:string;
//#endregion Generated Reference Properties





















































}