import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class MessageBase {

        //#region messageId Prop
        @prop()
        @gridColumn({ visible: false, allowSearch: false, columnIndex: 0, allowSorting: true, headerKey: 'messageId', keyColumn: false })
        messageId: number;
        //#endregion messageId Prop


        //#region messageFrom Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        messageFrom: number;
        //#endregion messageFrom Prop


        //#region messageTo Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        messageTo: number;
        //#endregion messageTo Prop


        //#region projectRFQId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        projectRFQId: number;
        //#endregion projectRFQId Prop


        //#region subject Prop

        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'subject', keyColumn: false, headerTitle: "From" })
        messageFromName: string;
        //#endregion subject Prop

        //#region subject Prop
        @required()
        @maxLength({ value: 100 })
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'subject', keyColumn: false, headerTitle: "Subject" })
        subject: string;
        //#endregion subject Prop


        //#region messageDetails Prop
        @required()
        @maxLength({ value: 500 })
        messageDetails: string;
        //#endregion messageDetails Prop


        //#region attachmentURL Prop
        @prop()
        attachmentURL: string;
        //#endregion attachmentURL Prop


        //#region isPreview Prop
        @prop()
        isPreview: boolean;
        //#endregion isPreview Prop


        //#region createdBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        createdBy: number;
        //#endregion createdBy Prop


        //#region createdOn Prop
        @required()
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'createdOn', keyColumn: false, headerTitle: "Date & Time", configuredTemplate: { templateName: "dateTimeFormat" } })
        createdOn: any;
        //#endregion createdOn Prop


        //#region modifiedBy Prop
        @prop()
        modifiedBy: number;
        //#endregion modifiedBy Prop


        //#region modifiedOn Prop
        @prop()
        modifiedOn: any;
        //#endregion modifiedOn Prop

}