import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDetailsSubscriptionPlanBase {

//#region subscriptionPlanId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'subscriptionPlanId', keyColumn: true})
        subscriptionPlanId : number;
//#endregion subscriptionPlanId Prop


//#region subscriptionPlanName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'subscriptionPlanName', keyColumn: false})
        subscriptionPlanName : string;
//#endregion subscriptionPlanName Prop


//#region subscriptionDetails Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'subscriptionDetails', keyColumn: false})
        subscriptionDetails : string;
//#endregion subscriptionDetails Prop


//#region subscriptionPrice Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'subscriptionPrice', keyColumn: false})
        subscriptionPrice : number;
//#endregion subscriptionPrice Prop


//#region status Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'status', keyColumn: false})
        status : string;
//#endregion status Prop


//#region subscriptionTypeName Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'subscriptionTypeName', keyColumn: false})
        subscriptionTypeName : string;
//#endregion subscriptionTypeName Prop


//#region validMonths Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'validMonths', keyColumn: false})
        validMonths : any;
//#endregion validMonths Prop

}