import { BlobTypeEnum } from "src/app/enums/blob-type.enum";

import { http, RxHttp } from "@rxweb/http";
import { DownloadDocumentViewModel } from "src/app/viewModel/download-document-viewModel";
import { CoreComponent } from "@rxweb/angular-router";
import { BaseToastr } from "src/app/domain/customize-design/toastr";
import { CustomMessage } from "./custom-message";
import { rejects } from "assert";
import { API } from "./administrationAPIContants";

export class DownloadFile {
    rxHttp: RxHttp;
    // count: number = 0;
    result: any = null;
    toastr: BaseToastr;
    fileType: Array<string> = ['doc', 'docx', 'pdf', 'xls', 'xlsx', 'ppt', 'pptx', 'jpg', 'jpeg', 'png']
    onlyImageType: Array<string> = ['jpg', 'jpeg', 'png']
    max_size = 3e+6; //size filter in bytes

    constructor() {
        this.rxHttp = new RxHttp();
        this.toastr = new BaseToastr();
    }

    fileValidation(file, isOnlyImage: boolean = false) {
        // const max_size = 3e+6; //size filter in bytes
        let ext = file.name.toLowerCase().split('.').pop()
        if (isOnlyImage && (this.onlyImageType.indexOf(ext) == -1)) {
            // if (this.onlyImageType.indexOf(ext) == -1) {
            this.toastr.error(CustomMessage.uploadImageErrorMsg);
            return false;
            // }
        }
        else {
            if (this.fileType.indexOf(ext) == -1) {
                this.toastr.error(CustomMessage.uploadFileErrorMsg);
                return false;
            }
        }
        if (file.size > this.max_size) {
            this.toastr.error(CustomMessage.fileSizeValidation3mb);
            return false;
        }
        return true;
    }

    mutipleFileValidation(selectedFiles, fileListLength: number = 0) {
        if (selectedFiles.length > 0 && (fileListLength + selectedFiles.length) >= 6) {
            this.toastr.error(CustomMessage.fileCountValidation);
            return false;
        }
        for (var index = 0; index < selectedFiles.length; index++) {
            let ext = selectedFiles[index].name.toLowerCase().split('.').pop()
            if (this.fileType.indexOf(ext) == -1) {
                this.toastr.error(CustomMessage.uploadFileErrorMsg);
                return false;
            }
            // totalSize = selectedFilesSize + selectedFiles[index].size;
            if (selectedFiles[index].size > this.max_size) {
                this.toastr.error(CustomMessage.fileSizeValidation3mb);
                return false;
            }
        }
        return true;
    }


    downloadFileFromAzure(downloadDocumentViewModel: DownloadDocumentViewModel, fileObj: any) {
        var promise = new Promise((resolve, reject) => {
            this.rxHttp.post({ path: API.download, body: downloadDocumentViewModel }).subscribe((res: any) => {
                fileObj.attachmentBase64String = res;
                this.result = this.download(fileObj);
                resolve(true);
            }, () => {
                reject(false)
            });
        });
        return promise;
    }

    download(fileObj: any) {
        var binaryString = window.atob(fileObj.attachmentBase64String);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var link = document.createElement('a');
        var blob = new Blob([bytes], { type: fileObj.fileType });
        link.href = window.URL.createObjectURL(blob);
        link.download = fileObj.fileName;
        document.body.appendChild(link);
        link.click();
    }
}