import { vPublicRFPBase } from '../database-models/v-public-r-f-p-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'action' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"], headerCellClass: ["pl-4"]
})
export class vPublicRFP extends vPublicRFPBase {




    //#region Generated Reference Properties

    //#endregion Generated Reference Properties






}