import { actionColumn } from '@rxweb/grid';
import {vStaticPageBase} from '../database-models/v-static-page-base';
//Generated Imports
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'editAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vStaticPage extends vStaticPageBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




}