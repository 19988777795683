import { prop, propArray, required, disable, range } from '@rxweb/reactive-form-validators';
import { HRCompliance } from '../models/extended-models/h-r-compliance';
import { State } from '../models/extended-models/state';
import { USComplianceDocument } from '../models/extended-models/u-s-compliance-document';
import { AbstractControl } from '@angular/forms';



export class USComplianceViewModel {

  //#region uSComplianceId Prop
  @prop()
  uSComplianceId: number;
  //#endregion uSComplianceId Prop


  //#region categoryId Prop
  @prop()
  @required()
  categoryId: number;
  //#endregion categoryId Prop


  //#region hRComplianceId Prop
  @prop()
  @required({ conditionalExpression: function (control: AbstractControl) { return this.categoryId == 20; } })
  hrComplianceId: number;
  //#endregion hRComplianceId Prop


  //#region stateId Prop
  @prop()
  @required({ conditionalExpression: function (control: AbstractControl) { return this.categoryId == 21; } })
  stateId: number;
  //#endregion stateId Prop

  //#region uSComplianceDocument Prop
  @propArray(USComplianceDocument)
  uSComplianceDocument: USComplianceDocument[];
  //#endregion uSComplianceDocument Prop  


  //#region statusId Prop
  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  statusId: number;
  //#endregion statusId Prop



  //#region hRCompliance Prop
  //  @prop()
  //hRCompliance: HRCompliance;
  //#endregion hRCompliance Prop


  //#region state Prop
  //  @propArray(State)
  //  state: State[];
  //#endregion state Prop


}