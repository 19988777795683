import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectionHeaderComponent } from './selection-header';


@NgModule({
    declarations: [SelectionHeaderComponent],
    imports: [
        CommonModule
    ],
    exports: [SelectionHeaderComponent]
})
export class SelectionHeaderComponentModule { }
