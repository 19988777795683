import { Injectable } from '@angular/core';
import { CoreComponent } from '@rxweb/angular-router';
import { http, RxHttp } from '@rxweb/http';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { UserAuthenticationViewModel } from 'src/app/models/client-models/login.classes';
import { LinkedInConfiguration } from 'src/app/models/extended-models/linked-in-configuration';
import { ForgotPasswordViewModel } from 'src/app/viewModel/forgot-password-view-model';
import { ResetPasswordModel } from 'src/app/viewModel/reset-password-model';
import { ResetPasswordResponseModel } from 'src/app/viewModel/reset-password-response-model';
import { UserCredentialModel } from 'src/app/viewModel/user-credential-model';
import { API } from '../shared/constant/administrationAPIContants';
import { StorageKeys } from '../shared/constant/commonConstants';

@Injectable({
  providedIn: 'root'
})

@http({
  path: 'api'
})
export class LoginService extends CoreComponent {
  storageHelper: BrowserStorage;

  constructor(private rxHttp: RxHttp) {
    super();
    this.storageHelper = new BrowserStorage();
    this.rxHttp.badRequest = () => {
    };
  }

  login(userCredentialModel: UserCredentialModel) {
    return this.rxHttp.post<UserAuthenticationViewModel>({ body: userCredentialModel, path: API.authentication });
  }

  getAnonymousToken() {
    return this.rxHttp.get<string>({ path: API.authentication });
  }

  forgotPassword(forgotPasswordViewModel:ForgotPasswordViewModel) {
    return this.rxHttp.post<UserAuthenticationViewModel>({ body: forgotPasswordViewModel, path: API.forgotPassword, });
  }

  loginWithLinkedin(code: string, state: string, error: string, error_description: string) {
    return this.rxHttp.get<UserAuthenticationViewModel>({ path: API.saveLinkedinUser, queryParams: { code, state, error, error_description } });
  }

  createUser(user) {
    return this.rxHttp.post<UserAuthenticationViewModel>({  body: user, path: API.saveUser });
  }

  logout() {
    return this.rxHttp.post<void>({ body: {}, path: API.logout });
  }

  getApplicationObjects() {
    return this.rxHttp.get<any>({ path: API.getAllApplicationObjects });
  }

  resetPassword(resetPasswordModel: ResetPasswordModel) {
    return this.rxHttp.post<ResetPasswordResponseModel>({ path: API.resetPassword, body: resetPasswordModel });
  }

  getLinkedInCredential(){
    return this.rxHttp.get<LinkedInConfiguration>({ path: API.getLinkedInCredential });
  }

  getUrl(data){
    return "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=" + data.clientID + "&redirect_uri=" + data.redirectUrl + "&state=DCeEFWf45A53sdfKef424&scope=r_liteprofile%20r_emailaddress";
  }

  setLocalstorage(response){
    this.storageHelper.local.save(StorageKeys.Request_identity, response.key, false);
    this.storageHelper.local.save(StorageKeys.Authentication, response.token, false);
    this.storageHelper.local.save(StorageKeys.Username, response.fullName);
    this.storageHelper.local.save(StorageKeys.ProfilePicture, response.profilePictureUrl, false);
    this.storageHelper.local.save(StorageKeys.UserId, response.userId);
    this.storageHelper.local.save(StorageKeys.UserRole, response.roleId);
    this.storageHelper.local.save(StorageKeys.IsLoggedIn, true);
  }

}
