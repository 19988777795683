import { TemplateConfig } from '@rxweb/grid';
import { PipeConstants } from 'src/app/components/shared/constant/commonConstants';


export const HRC_ACTION_RFQ: TemplateConfig = {
    div: {
        class: 'formAction list-unstyled mb-0'.split(' '),
        childrens: [
            {
                a: {
                    class: [function (e) {
                        if (this[e.name] != 0 && this[e.name] != "") {
                            return "message_notify";
                        }
                        else{
                            return "gridBluebtn";
                        }
                    }],
                    event: {
                        click: 'onMesaageView'
                    },
                    childrens: [{
                        i: { class: 'fas fa-comment-dots'.split(' ') },
                        span:{
                          childrens:[{
                            text: {
                              text: function (e) {               
                                return (this[e.name] == 0 ? "" : this[e.name]);
                              }}
                          }]
                        }
                    },
                    ]
                }
            },
            {
                a: {
                    class: [
                        function (e) {
                            if (this.projectRFQStatus != "Pending") {
                                return "disabledgridbtn";
                            }
                            else {
                                return "gridEditbtn";
                            }
                        }
                    ],
                    // authorize: 'put',
                    event: {
                        click: 'onApprove'
                    },
                    childrens: [{
                        a: {

                            childrens: [{
                                i: { class: 'fas fa-thumbs-up'.split(' ') }
                            }],
                        }
                    },
                    ]
                }
            },
            {
                a: {
                    class: [
                        function (e) {
                            if (this.projectRFQStatus != "Pending") {
                                return "disabledgridbtn";
                            }
                            else {
                                return "gridDeletebtn rejectRFQ".split(" ");
                            }
                        }
                    ],
                    // authorize: 'delete',
                    event: {
                        click: 'onReject'
                    },
                    childrens: [{
                        a: {
                            childrens: [{
                                i: { class: 'fas fa-thumbs-down'.split(' ') }
                            }],
                        }
                    }]
                }

            }
        ]
    }
};
