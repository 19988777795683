import {vHRORecordBase} from '../database-models/v-h-r-o-record-base';
//Generated Imports
export class vHRORecord extends vHRORecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






































}