import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class PublicRFPCategoryBase {

//#region publicRFPCategoryId Prop
        @prop()
        publicRFPCategoryId : number;
//#endregion publicRFPCategoryId Prop


//#region publicRFPId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        publicRFPId : number;
//#endregion publicRFPId Prop


//#region categoryId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        categoryId : number;
//#endregion categoryId Prop


//#region statusId Prop
        @prop()
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @prop()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop





}