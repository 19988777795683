import {PublicRFPCategoryBase} from '../database-models/public-r-f-p-category-base';
import {PublicRFPBase} from '../database-models/public-r-f-p-base';
import {ExpertizeBase} from '../database-models/expertize-base';
//Generated Imports
export class PublicRFPCategory extends PublicRFPCategoryBase 
{




//#region Generated Reference Properties
//#region publicRFP Prop
publicRFP : PublicRFPBase;
//#endregion publicRFP Prop
//#region expertize Prop
expertize : ExpertizeBase;
//#endregion expertize Prop

//#endregion Generated Reference Properties




}