import { NgModule } from '@angular/core';
import { RxTranslateModule} from "@rxweb/translate"
import { routerModule } from '@rxweb/angular-router';
import { AuthResolver } from './domain/security/authentication-resolver';
import { AuthorizationResolver } from './domain/security/authorization-resolver';
import { GlobalMultilingualContentResolver } from './domain/localization/global-multilingual-content.resolver';
import { ComponentMultilingualContentResolver } from './domain/localization/component-multilingual-content.resolver';

@routerModule({
    authentication: AuthResolver,
    authorization: AuthorizationResolver,
    middlewares: [GlobalMultilingualContentResolver, ComponentMultilingualContentResolver],
    urlEncryption:false
})

@NgModule({
    imports: [
        RxTranslateModule.forRoot({
            cacheLanguageWiseObject: true,
            globalFilePath: "assets/i18n/{{language-code}}/global.{{language-code}}.json",
            filePath: "assets/i18n/{{language-code}}/{{translation-name}}.{{language-code}}.json"
        })
    ],
    // providers: [{
    //         cacheLanguageWiseObject: true,
    //         globalFilePath: "assets/i18n/{{language-code}}/global.{{language-code}}.json",
    //         filePath: "assets/i18n/{{language-code}}/{{translation-name}}.{{language-code}}.json"
    // }],
    exports: [RxTranslateModule],
})
export class RxWebModule { }

