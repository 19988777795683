import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCompanySizeDetailBase {

//#region companySize Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'companySize', keyColumn: false,headerTitle: "Company Size"})
        companySize : string;
//#endregion companySize Prop


//#region status Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'status', keyColumn: false, headerTitle: "Status", configuredTemplate: { templateName: "recordActive" }})
        status : string;
//#endregion status Prop


//#region companySizeId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'companySizeId', keyColumn: true})
        companySizeId : number;
//#endregion companySizeId Prop

}