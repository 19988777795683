import { TemplateConfig } from '@rxweb/grid';
import { REDIRECT_HRC_LINK_GRID_TEMPLATE } from 'src/app/custom-templates/grid/redirect-hrc-link';
import { ACTIVE_LABEL_BACKGROUND } from 'src/app/custom-templates/grid/active-label';
import { SORTING_BY_HEADER_GRID_TEMPLATE } from 'src/app/custom-templates/grid/sorting-by-header';
import { NUMBER_FORMAT_GRID_TEMPLATE } from 'src/app/custom-templates/grid/grid-numberFormat';
import { TEXT_FORMAT_GRID_TEMPLATE } from 'src/app/custom-templates/grid/grid-textFormat';
import { NUMBER_REDIRECT_GRID_TEMPLATE } from 'src/app/custom-templates/grid/grid-redirect-link-number';
import { DUE_DATE_LABEL_BACKGROUND } from 'src/app/custom-templates/grid/due-date';
import { FOLLOWUP_DATE_LABEL_BACKGROUND } from 'src/app/custom-templates/grid/followup-date';
import { HEADER_NAME_BACKGROUND } from 'src/app/custom-templates/grid/header-name';
import { ACTION_GRID_TEMPLATE } from './action';
import { RECORD_ACTIVE_GRID_TEMPLATE } from './record-active';
import { EDIT_ACTION_GRID_TEMPLATE } from './edit-action';
import { DETAILS_ACTION_GRID_TEMPLATE } from './action-details';
import { FEEDBACK_VIEW_GRID_TEMPLATE } from './feedback-detail';
import { ACCEPT_ACTION_GRID_TEMPLATE } from './action-accept';
import { DATE_FORMAT_GRID_TEMPLATE } from './grid-dateformat';
import { REDIRECT_PROJECT_LINK_GRID_TEMPLATE } from './redirect-project-link';
import { NUMBER_DISPLAY } from './number-display';
import { CONTRACT_STATUS_GRID_TEMPLATE } from './contract-status';
import { REDIRECT_HRO_LINK_GRID_TEMPLATE } from './redirect-hro-link';
import { CONTRACT_ADMIN_ACTION_GRID_TEMPLATE } from './contract-admin-action';
import { USER_TYPE_GRID_TEMPLATE } from './userType';
import { EMAIL_NOTIFICATION_GRID_TEMPLATE } from './email-notification-action';
import { MESSAGE_GRID_TEMPLATE } from './message-preview-action';
import { ACCEPT_ACTION_RFQ_GRID_TEMPLATE } from './action-accept-rfq';
import { RFQ_ADMIN_ACTION_GRID_TEMPLATE } from './r-f-q-admin-action';
import { MESSAGE_VIEW_ACTION_GRID_TEMPLATE } from './action-message-view';
import { RFQ_STATUS_GRID_TEMPLATE } from './r-f-q-status';
import { CHECKBOX_TEMPLATE_GRID_TEMPLATE } from './checkbox-template';
import { DATE_TIME_FORMAT_GRID_TEMPLATE } from './grid-date-time-format';
import { ACTION_PROJECTLIST_GRID_TEMPLATE } from './action-project-list';
import { REDIRECT_SUBJECT_LINK_GRID_TEMPLATE } from './redirect-subject-link';
import { RATINGS_GRID_TEMPLATE } from './ratings';
import { MATCHING_HRC_RATINGS_GRID_TEMPLATE } from './matching-hrc-ratings';
import { REDIRECT_TESTIMONIAL_LINK_GRID_TEMPLATE } from './redirect-testimonial-link';
import { REGISTERED_USER_GRID_TEMPLATE } from './registered-user';
import { REDIRECT_PUBLIC_RFP_LINK_GRID_TEMPLATE } from './redirect-public-r-f-p-link';
import { PUBLIC_RFP_ACTION_GRID_TEMPLATE } from './public-rfp-action';
import { REDIRECT_CATEGORY_LINK_GRID_TEMPLATE } from './redirect-category-link';
import { DOWNLOAD_DOC_LINK_GRID_TEMPLATE } from './downloadDoc-link';
import { URL_LINK_GRID_TEMPLATE } from './urlLink';
import { PAYMENT_REPORT_ACTION_GRID_TEMPLATE } from './report-action';
import { CONTRACT_DETAILS_ACTION_GRID_TEMPLATE } from './action-contract-detail';
import { ROLE_GRID_TEMPLATE } from './role';
import { PAYMENT_GRID_TEMPLATE } from './payment';
import { USER_PAYMENT_GRID_TEMPLATE } from './user-payment';
import { SUBSCRIPTION_PLAN_GRID_TEMPLATE } from './subscription-plan';
import { EXPERTISE_ACTION_GRID_TEMPLATE } from './expertise-action';
import { DEADLINE_DATE_GRID_TEMPLATE } from './grid-deadlineDate-status';
import { USER_TYPE_TEXT_GRID_TEMPLATE } from './userType-text';
import { REDIRECT_LINK_GRID_TEMPLATE } from './redirect-link';
import { HRC_ACTION_RFQ } from './hrc-action-rfq';
import { FEEDBACK_ACTION_GRID_TEMPLATE } from './feedback-action';



export const GRID_CUSTOM_TEMPLATES: { [key: string]: TemplateConfig } = {
    action: ACTION_GRID_TEMPLATE,
    recordActive: RECORD_ACTIVE_GRID_TEMPLATE,
    redirectHRCLink: REDIRECT_HRC_LINK_GRID_TEMPLATE,
    activeLabel : ACTIVE_LABEL_BACKGROUND,
    sortingByHeader: SORTING_BY_HEADER_GRID_TEMPLATE,
    dateFormat :DATE_FORMAT_GRID_TEMPLATE,
    numberFormat:NUMBER_FORMAT_GRID_TEMPLATE,
    textFormat:TEXT_FORMAT_GRID_TEMPLATE,
    redirectLinkNumber:NUMBER_REDIRECT_GRID_TEMPLATE,
    dueDate:DUE_DATE_LABEL_BACKGROUND,
    followupDate: FOLLOWUP_DATE_LABEL_BACKGROUND,
    headerName: HEADER_NAME_BACKGROUND,
    editAction:EDIT_ACTION_GRID_TEMPLATE, 
    viewDetailsAction:DETAILS_ACTION_GRID_TEMPLATE,
    feedbackViewAction:FEEDBACK_VIEW_GRID_TEMPLATE,
    accept:ACCEPT_ACTION_GRID_TEMPLATE,
    redirectProjectLink: REDIRECT_PROJECT_LINK_GRID_TEMPLATE,
    numberDisplay:  NUMBER_DISPLAY,
    contractStatus: CONTRACT_STATUS_GRID_TEMPLATE,
    redirectHROLink: REDIRECT_HRO_LINK_GRID_TEMPLATE,
    contractAdminAction: CONTRACT_ADMIN_ACTION_GRID_TEMPLATE,
    feedbackAction: FEEDBACK_ACTION_GRID_TEMPLATE,
    userType:USER_TYPE_GRID_TEMPLATE,
    userTypeText:USER_TYPE_TEXT_GRID_TEMPLATE,
    messagePreviewAction:MESSAGE_GRID_TEMPLATE,
    emailNotificationAction:EMAIL_NOTIFICATION_GRID_TEMPLATE,
    actionAcceptRFQ: ACCEPT_ACTION_RFQ_GRID_TEMPLATE,
    rfqAdminAction: RFQ_ADMIN_ACTION_GRID_TEMPLATE,
    messageView: MESSAGE_VIEW_ACTION_GRID_TEMPLATE,
    rfqStatus:RFQ_STATUS_GRID_TEMPLATE,
    chckboxTemplate: CHECKBOX_TEMPLATE_GRID_TEMPLATE,
    dateTimeFormat:DATE_TIME_FORMAT_GRID_TEMPLATE,
    actionProjectList: ACTION_PROJECTLIST_GRID_TEMPLATE,
    redirectSubjectLink: REDIRECT_SUBJECT_LINK_GRID_TEMPLATE,
    ratings:RATINGS_GRID_TEMPLATE,
    matchingHRCRatings:MATCHING_HRC_RATINGS_GRID_TEMPLATE,
    redirectTestimonialLink:REDIRECT_TESTIMONIAL_LINK_GRID_TEMPLATE,
    registeredUser:REGISTERED_USER_GRID_TEMPLATE,
    publicRFPRedirectLink:REDIRECT_PUBLIC_RFP_LINK_GRID_TEMPLATE,
    publicRFPAction:PUBLIC_RFP_ACTION_GRID_TEMPLATE,
    redirectCategoryLink:REDIRECT_CATEGORY_LINK_GRID_TEMPLATE,
    downloadDoc:DOWNLOAD_DOC_LINK_GRID_TEMPLATE,
    urlLink:URL_LINK_GRID_TEMPLATE,
    paymentReportAction: PAYMENT_REPORT_ACTION_GRID_TEMPLATE,
    contractDetails:CONTRACT_DETAILS_ACTION_GRID_TEMPLATE,
    role:ROLE_GRID_TEMPLATE,
    payment:PAYMENT_GRID_TEMPLATE,
    userPayment:USER_PAYMENT_GRID_TEMPLATE,
    subscriptionPlan:SUBSCRIPTION_PLAN_GRID_TEMPLATE,
    expertiseAction:EXPERTISE_ACTION_GRID_TEMPLATE,
    deadlineDateStatus:DEADLINE_DATE_GRID_TEMPLATE,
    linkRedirect:REDIRECT_LINK_GRID_TEMPLATE,
    hrcRFQAction:HRC_ACTION_RFQ
}

