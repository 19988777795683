import { TemplateConfig } from '@rxweb/grid';
import { PipeConstants } from 'src/app/components/shared/constant/commonConstants';


export const ACCEPT_ACTION_GRID_TEMPLATE: TemplateConfig = {
    div: {
        class: 'formAction list-unstyled mb-0'.split(' '),
        childrens: [{
            a: {
                class: [
                    function (e) {
                        if (this.contractStatus != "Pending") {
                            return "disabledgridbtn";
                        }
                        else{
                            return "gridEditbtn acceptContract".split(" ");
                        }
                    }
                ],
                // authorize: 'put',
                event: {
                    click: 'onApprove'
                },
                childrens: [{
                    a: {                        
                        childrens: [{
                            i: { class: 'fas fa-check'.split(' ') }
                        }],
                    }
                },
                ]
            }

        },
        {
            a: {
                class: [
                    function (e) {
                        if (this.contractStatus != "Pending") {
                            return "disabledgridbtn";
                        }
                        else{
                            return "gridDeletebtn rejectContract".split(" ");
                        }
                    }
                ],
                // authorize: 'delete',
                event: {
                    click: 'onReject'
                },
                childrens: [{
                    a: {
                        childrens: [{
                            i: { class: 'fas fa-times'.split(' ') }
                        }],
                    }
                }]
            }

        },
        
        {
            a: {
                class: 'gridinfobttn'.split(' '),
                authorize: 'get',
                event: {
                    click: 'viewDetails'
                },
                childrens: [{
                    a: {
                        childrens: [{
                            i: { class: 'fas fa-address-card'.split(' ') }
                            // text: {
                            //     text: "View Details"
                            // }
                        }],
                    }
                }]
            }

        }
        ]
    }
};
