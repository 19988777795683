import {SubscriptionTypeLookupBase} from '../database-models/subscription-type-lookup-base';
//Generated Imports
export class SubscriptionTypeLookup extends SubscriptionTypeLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties















}