import {YearsOfExperienceDetailBase} from '../database-models/years-of-experience-detail-base';
import {UserDetailBase} from '../database-models/user-detail-base';
//Generated Imports
export class YearsOfExperienceDetail extends YearsOfExperienceDetailBase 
{




//#region Generated Reference Properties
//#region userDetails Prop
userDetails : UserDetailBase[];
//#endregion userDetails Prop

//#endregion Generated Reference Properties





















}