import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vExpertisePlanBase {

//#region expertisePlanId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'expertisePlanId', keyColumn: true})
        expertisePlanId : number;
//#endregion expertisePlanId Prop


//#region expertiseId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'expertiseId', keyColumn: false})
        expertiseId : number;
//#endregion expertiseId Prop


//#region userId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'userId', keyColumn: false})
        userId : number;
//#endregion userId Prop


//#region isPlanCancel Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'isPlanCancel', keyColumn: false})
        isPlanCancel : boolean;
//#endregion isPlanCancel Prop


//#region expertizeName Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'expertizeName', keyColumn: false})
        expertizeName : string;
//#endregion expertizeName Prop

}