import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ExpertisePlanBase {

//#region expertisePlanId Prop
        @prop()
        expertisePlanId : number;
//#endregion expertisePlanId Prop


//#region userId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        userId : number;
//#endregion userId Prop


//#region expertiseId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        expertiseId : number;
//#endregion expertiseId Prop


//#region isPlanCancel Prop
        @required()
        isPlanCancel : boolean;
//#endregion isPlanCancel Prop


//#region cancelledAt Prop
        @prop()
        cancelledAt : any;
//#endregion cancelledAt Prop

}