import {SubscriptionPlanLookupBase} from '../database-models/subscription-plan-lookup-base';
//Generated Imports
export class SubscriptionPlanLookup extends SubscriptionPlanLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties















}