import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { CoreComponent, anonymous, middleware } from '@rxweb/angular-router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { MasterLookupGroup } from 'src/app/viewModel/master-lookup-group';
import { MASTER_LOOKUPS } from 'src/app/uris/master-lookups.uri';
import { http } from '@rxweb/http';
import { LoggedInMiddleware } from 'src/app/domain/security/middleware/logged-in.middleware';
import { ModelComponentType } from "../constant/common-constants";


@anonymous()

@Component({
    templateUrl: './confirmation-modal.component.html'
})

export class ConfirmationModalComponent extends CoreComponent implements OnInit {
    @Input() componentType: any;
    @Input() hide: Function;
    @Input() amount: number;
    @Input() subscriptionPlan: string;
    @Input() expertiseDetails: string;
    @Input() expertise: any;
    @Input() params: string[];

    showDeleteComponent: boolean;
    showRolesComponent: boolean;
    showApproveComponent: boolean;
    showRejectComponent: boolean;
    selectedValue: any;
    masterLookupGroup: MasterLookupGroup;
    roleLookup: any;
    showFeedbackOptionChangeComponent: boolean;
    showPlanCancelComponent: boolean;
    showMarkAllAsReadComponent: boolean;
    showDeleteAllComponent: boolean;
    showMatchingHrc: boolean;
    showSuccessComponent: boolean;
    showDocSuccessComponent: boolean;
    showCategoryChangeComponent: boolean;
    showHrcomplianceformtips: boolean;
    showInterestedComponent:boolean;
    showNotInterestedComponent:boolean;
    paymentResponse: any;
    plan: string;
    price: number;
    showResponseDescription: boolean;
    expertiseName: Array<string>;
    category: any;
    showExpertise: boolean = false;
    showDisableEnableExpertiseComponent: boolean;
    showChangeExpertiseTabComponent: boolean;
    showHrcAccountComponent: boolean;
    enableOrDisbale: string;
    showUserEditComponent: boolean;
    showGuestHRCComponent: boolean;
    showExpertiseDetailsComponent: boolean;
    showSubExpertiseDetailsComponent: boolean;
    showTipsForExpertiseInHRCProfileComponent: boolean;
    planExpirydate: any;
    showPopupMessage: boolean;
    showFreeAccount: boolean = false;
    showChangeCategory: boolean = false;
    showHRCVideo: boolean = false;
    showHROVideo: boolean = false;

    ngOnInit(): void {
        if (this.componentType == "delete") {
            this.showDeleteComponent = true;
        }
        else if (this.componentType == "roles") {
            this.lookup<MasterLookupGroup>(
                [
                    { path: MASTER_LOOKUPS.roleLookup, propName: 'roleLookup' },
                ]).subscribe((response: any) => {
                    this.masterLookupGroup = response;
                    this.showRolesComponent = true;
                })
            // this.roleLookup=[{"roleId":2,"roleName":"HRO"},{"roleId":3,"roleName":"HRC"}]
            // this.showRolesComponent = true;
            // this.showDeleteComponent=false;
        }
        else if (this.componentType == "feedbackOptionChange") {
            this.showFeedbackOptionChangeComponent = true;
        }
        else if (this.componentType == "interested") {
            this.showInterestedComponent = true;
        }
        else if (this.componentType == "notinterested") {
            this.showNotInterestedComponent = true;
        }
        else if (this.componentType == "cancelPlan") {
            this.showPlanCancelComponent = true;
        }
        else if (this.componentType == "approve") {
            this.showApproveComponent = true;
        }
        else if (this.componentType == "reject") {
            this.showRejectComponent = true;
        }
        else if (this.componentType == "markAsRead") {
            this.showMarkAllAsReadComponent = true;
        }
        else if (this.componentType == "deleteAll") {
            this.showDeleteAllComponent = true;
        }
        else if (this.componentType == "matchingHRC") {
            this.showMatchingHrc = true;
        }
        else if (this.componentType == "success") {
            this.plan = this.subscriptionPlan;
            this.price = this.amount;
            this.showSuccessComponent = true;
        }
        else if (this.componentType == "responseDescription") {
            this.lookup<MasterLookupGroup>(
                [
                    { path: MASTER_LOOKUPS.expertizeLookup, propName: 'expertizeLookup' }
                ]).subscribe((response: any) => {
                    this.masterLookupGroup = response;
                    this.expertiseName = [];
                    this.category = JSON.parse(this.expertise);
                    if (this.category != null && this.category.length > 0) {
                        this.showExpertise = true;
                        this.masterLookupGroup.expertizeLookup.forEach(x => {
                            this.category.forEach(c => {
                                if (x.expertizeId == c.userExpertizeId) {
                                    if (!this.expertiseName.includes(x.expertizeName))
                                        this.expertiseName.push(x.expertizeName);
                                }
                            })
                        });
                    }
                });
            this.showResponseDescription = true;
        }
        else if (this.componentType == "changeCategory") {
            this.showCategoryChangeComponent = true;
        }
        else if (this.componentType == "docSuccess") {
            this.showDocSuccessComponent = true;
        }
        else if (this.componentType == "hrcomplianceformtips") {
            this.showHrcomplianceformtips = true;
        }
        else if (this.componentType == "disbaleEnableExpertise") {
            this.enableOrDisbale = this.params[0];
            this.showDisableEnableExpertiseComponent = true;
        }
        else if (this.componentType == "changeExpertiseTab") {
            this.showChangeExpertiseTabComponent = true;
        }
        else if (this.componentType == "hrcAccount") {
            this.showHrcAccountComponent = true;
        }
        else if (this.componentType == "userEdit") {
            this.showUserEditComponent = true;
        }
        else if (this.componentType == "guestHRC1stLogin") {
            this.showGuestHRCComponent = true;
        }
        else if (this.componentType == "showExpertiseDetails") {
            this.showExpertiseDetailsComponent = true;
        }
        else if (this.componentType == "showSubExpertiseDetails") {
            this.showSubExpertiseDetailsComponent = true;
        }
        else if (this.componentType == "tipsForExpertiseInHRCProfile") {
            this.showTipsForExpertiseInHRCProfileComponent = true;
        }
        else if (this.componentType == "popupMessage") {
            this.showPopupMessage = true;
        }
        else if (this.componentType == "freeAccount") {
            this.showFreeAccount = true;
        }
        else if (this.componentType == "ChangeCategory") {
            this.showChangeCategory = true;
        }
        else if(this.componentType == ModelComponentType.HRCVideo ){
            this.showHRCVideo = true;
        }
        else if(this.componentType == ModelComponentType.HROVideo ){
            this.showHROVideo = true;
        }
    }

    register(): any {
        this.hide(this.selectedValue);
    }

    deleteRecord(): any {
        this.hide('yes');
    }

    markAsRead(): any {
        this.hide('yes');
    }
    hrcAccount(value?: string) {
        this.hide(value);
    }

    ClickedOut(event){
        
        if(event.target.id === "clonepopup") {
            this.hide()
          } 
    }
    ngOnDestroy(): void {

    }
}
