import { TemplateConfig } from '@rxweb/grid';

export const MESSAGE_VIEW_ACTION_GRID_TEMPLATE: TemplateConfig = {
  div: {
    class: 'formAction list-unstyled mb-0'.split(' '),
    childrens: [{
      a: {
        class: [function (e) {
          if (this[e.name] != 0 && this[e.name] != "") {
              return "message_notify";
          }
          else{
              return "gridBluebtn";
          }
      }],
        authorize: 'put',
        event: {
          click: 'onMesaageView'
        },
        childrens: [{
          a: {
            childrens: [{
               i: { class: 'fas fa-comment-dots'.split(' ') },
              span:{
                childrens:[{
                  text: {
                    text: function (e) {               
                      return (this[e.name] == 0 ? "" : this[e.name]);
                    }}
                }]
              }
              

            }],
          }
        },
        ]
      }
    }
    ]
  }
};
