import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vQuoteMessageListBase {

//#region projectRFQId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'projectRFQId', keyColumn: true})
        projectRFQId : number;
//#endregion projectRFQId Prop


//#region projectName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'projectName', keyColumn: false})
        projectName : string;
//#endregion projectName Prop


//#region hROName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'hROName', keyColumn: false})
        hROName : string;
//#endregion hROName Prop


//#region hRCName Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'hRCName', keyColumn: false})
        hRCName : string;
//#endregion hRCName Prop


//#region projectRFQStatus Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'projectRFQStatus', keyColumn: false})
        projectRFQStatus : string;
//#endregion projectRFQStatus Prop

}