<!-- [ navigation menu ] start -->
<nav class="pcoded-navbar">
    <div class="navbar-wrapper">
        <div class="navbar-brand header-logo">
            <a href="javascript:void(0)" class="b-brand">
                <img src="../../../../assets/images/hr-doorway-icon.png" width="42" height="42" alt="Hr DoorWay"
                    class="logo-icon" />
                <img src="../../../../assets/images/hr-doorway-text.png" width="148" height="18" alt="Hr DoorWay"
                    class="logo-text" />
            </a>
            <a class="mobile-menu" id="mobile-collapse"><span></span></a>
        </div>

        <div class="navbar-content scroll-div ps ps--active-y">
            <ul *ngIf="isSuperAdmin" class="nav pcoded-inner-navbar">
                <li data-username="Dashboard" class="nav-item" [class.active]="activeMenu == 1"
                    (click)="activeMenuItem(1)">
                    <a routerLinkActive="active" routerLink="dashboard" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-home"></i></span>
                        <span class="pcoded-mtext">Dashboard</span>
                    </a>
                </li>

                <li data-username="User Management" class="nav-item pcoded-hasmenu" [class.active]="activeMenu == 2"
                    (click)="activeMenuItem(2)">
                    <a class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-user-cog"></i></span>
                        <span class="pcoded-mtext">User Management</span>
                    </a>
                    <ul class="pcoded-submenu">
                        <li [class.active]="activeSubMenu == 21"
                        (click)="activeSubMenuItem(21)">
                            <a href="" routerLink="hro-management">
                                HRO Management
                            </a>
                        </li>
                        <li [class.active]="activeSubMenu == 22"
                        (click)="activeSubMenuItem(22)">
                            <a href="" class="" routerLink="hrc">
                                HR Consultant Management
                            </a>
                        </li>
                        <!-- <li>
                            <a class="" routerLink="dashboard">
                                Staff Management
                            </a>
                        </li> -->
                        <li [class.active]="activeSubMenu == 23"
                        (click)="activeSubMenuItem(23)">
                            <a href="" class="" routerLink="roles">
                                Role Management
                            </a>
                        </li>
                    </ul>
                </li>

                <li data-username="Project RFQ Request Response Management" class="nav-item"
                    [class.active]="activeMenu == 3" (click)="activeMenuItem(3)">
                    <a routerLinkActive="active" routerLink="project-r-f-q-admin" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-question"></i></span>
                        <span class="pcoded-mtext">Project RFQ Request Response Management</span>
                    </a>
                </li>

                <li data-username="Contracts Management" class="nav-item" [class.active]="activeMenu == 4"
                    (click)="activeMenuItem(4)">
                    <a routerLinkActive="active" routerLink="contracts" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-edit"></i></span>
                        <span class="pcoded-mtext">Contracts Management</span>
                    </a>
                </li>

                <li data-username="Feedback Management" class="nav-item" [class.active]="activeMenu == 5"
                    (click)="activeMenuItem(5)">
                    <a routerLinkActive="active" routerLink="feedbacks" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-thumbs-up"></i></span>
                        <span class="pcoded-mtext">Feedback Management</span>
                    </a>
                </li>

                <!-- <li data-username="Subscription Plan Management" class="nav-item" [class.active]="activeMenu == 6"
                    (click)="activeMenuItem(6)">
                    <a routerLinkActive="active" routerLink="subscription-plans" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-calendar-check"></i></span>
                        <span class="pcoded-mtext">Subscription Plan Management</span>
                    </a>
                </li> -->

                <li data-username="Subscription Plan Cancellation Responses" class="nav-item"
                    [class.active]="activeMenu == 7" (click)="activeMenuItem(7)">
                    <a routerLinkActive="active" routerLink="plan-cancellation" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-calendar-times"></i></span>
                        <span class="pcoded-mtext">Subscription Plan Cancellation Responses</span>
                    </a>
                </li>

                <li data-username="User List" class="nav-item" [class.active]="activeMenu == 13"
                    (click)="activeMenuItem(13)">
                    <a routerLinkActive="active" routerLink="user" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-list-alt"></i></span>
                        <span class="pcoded-mtext">User List</span>
                    </a>
                </li>

                <li  *rxAuthorize=[hrComplianceList] data-username="User List" class="nav-item" [class.active]="activeMenu == 14"
                    (click)="activeMenuItem(14)">
                    <a routerLinkActive="active" routerLink="us-compliance" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-book"></i></span>
                        <span class="pcoded-mtext">HR Compliance Forms</span>
                    </a>
                </li>

                <li data-username="Payment Response – Subscriptions" class="nav-item" [class.active]="activeMenu == 8"
                    (click)="activeMenuItem(8)">
                    <a routerLinkActive="active" routerLink="payment-response" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-money-bill-alt"></i></span>
                        <span class="pcoded-mtext">Payment Response – Subscriptions</span>
                    </a>
                </li>

                <li data-username="Master Management" class="nav-item pcoded-hasmenu" [class.active]="activeMenu == 9"
                    (click)="activeMenuItem(9)">
                    <a class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-user-cog"></i></span>
                        <span class="pcoded-mtext">Master Management</span>
                    </a>
                    <ul class="pcoded-submenu">
                        <li [class.active]="activeSubMenu == 31"
                        (click)="activeSubMenuItem(31)">
                            <a href="" class="" routerLink="expertise">
                                Expertise Category Management
                            </a>
                        </li>
                        <li [class.active]="activeSubMenu == 32"
                        (click)="activeSubMenuItem(32)">
                            <a href="" class="" routerLink="expertise-sub-category">
                                Expertise Sub Category Management
                            </a>
                        </li>
                        <li [class.active]="activeSubMenu == 33"
                        (click)="activeSubMenuItem(33)">
                            <a href="" class="" routerLink="questions">
                                Questions Management
                            </a>
                        </li>
                        <!-- <li>
                            <a href="" routerLink="subscription-type">
                                Subscription Type Management
                            </a>
                        </li> -->
                        <li [class.active]="activeSubMenu == 34"
                        (click)="activeSubMenuItem(34)">
                            <a href="" routerLink="years-of-experience">
                                Years of Experience Management
                            </a>
                        </li>
                        <li [class.active]="activeSubMenu == 35"
                        (click)="activeSubMenuItem(35)">
                            <a href="" class="" routerLink="city">
                                City Management
                            </a>
                        </li>
                        <li [class.active]="activeSubMenu == 36"
                        (click)="activeSubMenuItem(36)">
                            <a href="" class="" routerLink="state">
                                State Management
                            </a>
                        </li>
                        <li [class.active]="activeSubMenu == 37"
                        (click)="activeSubMenuItem(37)">
                            <a href="" class="" routerLink="country">
                                Country Management
                            </a>
                        </li>
                        <li [class.active]="activeSubMenu == 38"
                        (click)="activeSubMenuItem(38)">
                            <a href="" class="" routerLink="company-size">
                                Company Size Management
                            </a>
                        </li>
                        <!-- <li>
                            <a href="" class="" routerLink="monthly-retained-options">
                                Monthly Retained Service Option Management
                            </a>
                        </li> -->
                        <li [class.active]="activeSubMenu == 39"
                        (click)="activeSubMenuItem(39)">
                            <a href="" class="" routerLink="monthly-retained-services">
                                Monthly Retained Service Management
                            </a>
                        </li>
                        <li [class.active]="activeSubMenu == 40"
                        (click)="activeSubMenuItem(40)">
                            <a href="" class="" routerLink="emailtemplates">
                                Email Template Management
                            </a>
                        </li>
                    </ul>
                </li>

                <li data-username="Reports" class="nav-item pcoded-hasmenu" [class.active]="activeMenu == 15"
                    (click)="activeMenuItem(15)">
                    <a class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-file-alt"></i></span>
                        <span class="pcoded-mtext">Reports</span>
                    </a>
                    <ul class="pcoded-submenu">
                        <li [class.active]="activeSubMenu == 41"
                        (click)="activeSubMenuItem(41)">
                            <a href="" class="" routerLink="user-report">
                                User Reports
                            </a>
                        </li>
                        <li [class.active]="activeSubMenu == 42"
                        (click)="activeSubMenuItem(42)">
                            <a href="" routerLink="payment-report">
                                Payment Reports
                            </a>
                        </li>
                    </ul>
                </li>

                <li data-username="Public RFP" class="nav-item" [class.active]="activeMenu == 11"
                    (click)="activeMenuItem(11)">
                    <a routerLinkActive="active" routerLink="public-r-f-p" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-link"></i></span>
                        <span class="pcoded-mtext">Public RFPs</span>
                    </a>
                </li>

                <!-- <li data-username="Static Page Management" class="nav-item" [class.active]="activeMenu == 10"
                (click)="activeMenuItem(10)">
                    <a routerLinkActive="active" routerLink="static-page" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-file"></i></span>
                        <span class="pcoded-mtext">Static Page Management</span>
                    </a>
                </li> -->

                <li data-username="System Settings" class="nav-item" [class.active]="activeMenu == 12"
                    (click)="activeMenuItem(12)">
                    <a routerLinkActive="active" routerLink="system-setting" class="nav-link">
                        <span class="pcoded-micon"><i class="feather icon-settings"></i></span>
                        <span class="pcoded-mtext">System Settings</span>
                    </a>
                </li>
            </ul>


            <ul *ngIf="isHRO" class="nav pcoded-inner-navbar">
                <li data-username="Dashboard" class="nav-item" [class.active]="activeMenu == 1"
                    (click)="activeMenuItem(1)">
                    <a routerLinkActive="active" routerLink="dashboard" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-home"></i></span>
                        <span class="pcoded-mtext">Dashboard</span>
                    </a>
                </li>
                <li *rxAuthorize="[projectList]" data-username="My Projects" class="nav-item" [class.active]="activeMenu == 2"
                    (click)="activeMenuItem(2)">
                    <a routerLinkActive="active" routerLink="projects" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-edit"></i></span>
                        <span class="pcoded-mtext">My Projects</span>
                    </a>
                </li>
                <li *rxAuthorize="[quoteMessageList]" data-username="Quote Message" class="nav-item" [class.active]="activeMenu == 3"
                    (click)="activeMenuItem(3)">
                    <a routerLinkActive="active" routerLink="quote-message" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-comments"></i></span>
                        <span class="pcoded-mtext">Quote Messages</span>
                        <span class="pcoded-badge label label-info"
                            *ngIf="quoteMessagesCount>0?true:false">{{quoteMessagesCount}}</span>
                    </a>
                </li>
                <li *rxAuthorize="[contractList]" data-username="My Contracts" class="nav-item" [class.active]="activeMenu == 4"
                    (click)="activeMenuItem(4)">
                    <a routerLinkActive="active" routerLink="contracts" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-edit"></i></span>
                        <span class="pcoded-mtext">My Contracts</span>
                    </a>
                </li>
                <li *rxAuthorize="[feedbackList]" data-username="Feedbacks" class="nav-item" [class.active]="activeMenu == 5"
                    (click)="activeMenuItem(5)">
                    <a routerLinkActive="active" routerLink="feedbacks" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-thumbs-up"></i></span>
                        <span class="pcoded-mtext">Feedback</span>
                    </a>
                </li>
                <li *rxAuthorize="[hrComplianceList]" data-username="User List" class="nav-item" [class.active]="activeMenu == 6"
                    (click)="activeMenuItem(6)">
                    <a routerLinkActive="active" routerLink="us-compliance" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-book"></i></span>
                        <!-- <span class="pcoded-mtext">US Compliance</span> -->
                        <span class="pcoded-mtext">HR Compliance Forms</span>
                    </a>
                </li>
            </ul>
            <ul *ngIf="isHRC" class="nav pcoded-inner-navbar">
                <li data-username="Dashboard" class="nav-item" [class.active]="activeMenu == 1"
                    (click)="activeMenuItem(1)">
                    <a routerLinkActive="active" routerLink="hrc-dashboard" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-home"></i></span>
                        <span class="pcoded-mtext">Dashboard</span>
                    </a>
                </li>
                <li *rxAuthorize=[allProjectList] data-username="All Projects" class="nav-item" [class.active]="activeMenu == 7"
                    (click)="activeMenuItem(7)">
                    <a routerLinkActive="active" routerLink="hrc/hrcProjectList" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-folder"></i></span>
                        <span class="pcoded-mtext">My Projects</span>
                    </a>
                </li>
                <li *rxAuthorize=[pRFQResponseList] data-username="Project RFQ Response" class="nav-item" [class.active]="activeMenu == 2"
                    (click)="activeMenuItem(2)">
                    <a routerLinkActive="active" routerLink="project-r-f-q-response" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-comments"></i></span>
                        <span class="pcoded-mtext">My RFQ Response</span>
                        <span class="pcoded-badge label label-info" style="right: 10px;"
                            *ngIf="quoteMessagesCount>0?true:false">{{quoteMessagesCount}}</span>
                    </a>
                </li>
                <li *rxAuthorize=[contractList] data-username="My Contracts" class="nav-item" [class.active]="activeMenu == 3"
                    (click)="activeMenuItem(3)">
                    <a routerLinkActive="active" routerLink="contracts" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-edit"></i></span>
                        <span class="pcoded-mtext">My Contracts</span>
                    </a>
                </li>
                <li *rxAuthorize=[feedbackList] data-username="Feebacks" class="nav-item" [class.active]="activeMenu == 4"
                    (click)="activeMenuItem(4)">
                    <a routerLinkActive="active" routerLink="feedbacks" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-thumbs-up"></i></span>
                        <span class="pcoded-mtext">Feedback</span>
                    </a>
                </li>
                <li *rxAuthorize=[testimonialList] data-username="My Testimonials" class="nav-item" [class.active]="activeMenu == 5"
                    (click)="activeMenuItem(5)">
                    <a routerLinkActive="active" routerLink="testimonials" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-hand-holding-heart"></i></span>
                        <span class="pcoded-mtext">My Testimonials</span>
                    </a>
                </li>
                <li *rxAuthorize=[publicRFPList] data-username="Public RFP" class="nav-item" [class.active]="activeMenu == 6"
                    (click)="activeMenuItem(6)">
                    <a routerLinkActive="active" routerLink="public-r-f-p" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-link"></i></span>
                        <span class="pcoded-mtext">Public RFPs</span>
                    </a>
                </li>
                <li *rxAuthorize=[hrComplianceList] data-username="User List" class="nav-item" [class.active]="activeMenu == 14"
                    (click)="activeMenuItem(14)">
                    <a routerLinkActive="active" routerLink="us-compliance" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-book"></i></span>
                        <span class="pcoded-mtext">HR Compliance Forms</span>
                    </a>
                </li>
            </ul>
            <ul *ngIf="isGuestHRC" class="nav pcoded-inner-navbar">
                <li data-username="Dashboard" class="nav-item" [class.active]="activeMenu == 1"
                    (click)="activeMenuItem(1)">
                    <a routerLinkActive="active" routerLink="guest-hrc-dashboard" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-home"></i></span>
                        <span class="pcoded-mtext">Dashboard</span>
                    </a>
                </li>
                <li *rxAuthorize=[allProjectList] data-username="All Projects" class="nav-item" [class.active]="activeMenu == 7"
                    (click)="activeMenuItem(7)">
                    <a routerLinkActive="active" routerLink="hrc/hrcProjectList" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-folder"></i></span>
                        <span class="pcoded-mtext">My Projects</span>
                    </a>
                </li>
                <li  *rxAuthorize=[hrComplianceList] data-username="User List" class="nav-item" [class.active]="activeMenu == 14"
                    (click)="activeMenuItem(14)">
                    <a routerLinkActive="active" routerLink="us-compliance" class="nav-link">
                        <span class="pcoded-micon"><i class="fas fa-book"></i></span>
                        <span class="pcoded-mtext">HR Compliance Forms</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<!-- [ navigation menu ] end -->