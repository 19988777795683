import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CoreComponent } from '@rxweb/angular-router';
import { GridCustomTemplate } from '@rxweb/grid';
import { HttpClientConfig, HttpResponse } from '@rxweb/http';
import { ErrorMessageBindingStrategy, ReactiveFormConfig } from '@rxweb/reactive-form-validators';
import { RoutingKeys, StorageKeys } from 'src/app/components/shared/constant/commonConstants';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { ApplicationBroadcaster } from 'src/app/broadcast-services/application-broadcaster';
import { AuthFilter } from 'src/app/broadcast-services/auth-filter';
import { GRID_CUSTOM_TEMPLATES } from '../../custom-templates/grid';
import { CustomMessage } from '../shared/constant/custom-message';
import { RolesEnum } from 'src/app/enums/roles-enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends CoreComponent {
  toastr: BaseToastr;
  isLoggedIn = false;
  isShowDashboard = false;
  storageHelper: BrowserStorage;
  constructor(
    private browserStorage: BrowserStorage,
    private router: Router,
    private applicationBroadCaster: ApplicationBroadcaster,
  ) {
    super();
    this.toastr = new BaseToastr();
    this.applicationBroadCaster.loginSubscriber.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if (!isLoggedIn) {
        this.browserStorage.local.clearAll();
        this.browserStorage.session.clearAll();
      }
    });

  }

  ngOnInit(): void {

    GridCustomTemplate.register(GRID_CUSTOM_TEMPLATES);

    HttpClientConfig.register({
      hostURIs: [{
        name: 'server',
        default: false,
        uri: 'https://localhost:5001'
      },
        // {
        //   name: 'local',
        //   default: true,
        //   uri: 'https://localhost:44352'
        // }
        // {
        //   name: 'local',
        //   default: true,
        //   uri: 'http://hrdoorway-api.live1.dev.radixweb.net'  
        // }
        // {
        //   name: 'local',
        //   default: true,
        //   uri: 'https://2hrdoorway-api.live1.dev.radixweb.net'  
        // }
        // {
        //   name: 'local',
        //   default: true,
        //   uri: 'https://hrdoorwayqa-api.azurewebsites.net'
        // }
        {
          name: 'local',
          default: true,
          uri: 'https://alliance-api.hrdoorway.com'  
        }
      ],
      filters: [{ model: AuthFilter }],
      onError: (response: HttpResponse) => {
        if (response.statusCode === 401 || response.statusCode === 0
        ) {
          this.browserStorage.local.clearAll();
          this.browserStorage.session.clearAll();
          this.applicationBroadCaster.loginBroadCast(false);
          this.isLoggedIn = false;
          this.toastr.error(CustomMessage.SessionExpired);
          this.router.navigate([RoutingKeys.Login]);
        }
      }
    });

    const isActivateUserUrl = window.location.href.indexOf('login/activateuser?ActivationKey=') !== -1;
    const isResetPasswordUrl = location.pathname.includes('login/resetPassword');
    const isLoggedIn = this.browserStorage.local.get(StorageKeys.IsLoggedIn);
    const isStaticPage = window.location.href.indexOf('/staticPage') !== -1;
    const isplanDetails = window.location.href.indexOf('/hrc/planDetails') !== -1;
    const isLoginWithLinkedin = window.location.href.indexOf('/linkedInlogin') !== -1;
    const isPaymentSpinner = window.location.href.indexOf('/payment-spinner') !== -1;
    const isPaymentcancel = window.location.href.indexOf('/ISCancel=true') !== -1;
    
    if(isPaymentcancel){
      window.localStorage.setItem('cancelPopup', "true");
    }

    if (isLoggedIn) {
      if (isActivateUserUrl) {
        this.toastr.error('Please logout first, then try to activate user');
        this.redirectToDashboard();

      } else if (isResetPasswordUrl) {
        this.toastr.error('Please logout first, then try to reset password');
        this.redirectToDashboard();
      }
      else if (isStaticPage) {
        this.redirectToDashboard()
      }
      else {
        this.isLoggedIn = true;
      }

    } else {
      if (isResetPasswordUrl) {
        var key = window.location.href.split('?key=');
        this.router.navigate([RoutingKeys.ResetPassword, key[1]]);
      }
      if (isLoginWithLinkedin) {
        this.browserStorage.local.clearAll();
      }
      else if (isPaymentSpinner) {
        this.isLoggedIn = false;
      }
      else if (!isActivateUserUrl && !isResetPasswordUrl && !isplanDetails) {
        this.browserStorage.local.clearAll();
        this.router.navigate([RoutingKeys.Login]);
      }
    }
    ReactiveFormConfig.set({
      "validationMessage": {
        "required": "You can't leave this empty.",
        "unique": "Enter unique value in the input",
        "minLength": "Minimum {{1}}  characters required.",
        "maxLength": "More than {{1}} characters are not permitted.",
        "pattern": "The specified input format is not recognized.",
        "compare": "The specified values of new password and confirm password must be the same.",
        "contains": "The specified value must ' in the input",
        "alpha": "You can use letters only.",
        "alphaNumeric": "You can use letters, numbers and periods only",
        "range": "You need to select appropriate value in this field",
        "maxNumber": "You can not enter value more than #n#",
        "numeric": "Only number required",
        "email": "Please enter valid email address.",
        "latitude": "Please enter a valid latitude.",
        "longitude": "Please enter a valid longitude",
        "onlypassword": "Password must contains mini. 8 & max. 15 chars including upper/lowercase, alphanumeric, special chars and numbers.",
      }, "reactiveForm": { "errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnTouchedOrSubmit }
    });
  }

  redirectToDashboard() {
    if (this.storageHelper.local.get(StorageKeys.UserRole) == RolesEnum.HRO) {
      this.router.navigate([RoutingKeys.HRODashboard]);
    }
    else if (this.storageHelper.local.get(StorageKeys.UserRole) == RolesEnum.HRConsultant) {
      this.router.navigate([RoutingKeys.HRCDashboard]);
    }
    else if (this.storageHelper.local.get(StorageKeys.UserRole) == RolesEnum.GuestHRC) {
      this.router.navigate([RoutingKeys.GuestHRCDashboard]);
    }
    else {
      this.router.navigate([RoutingKeys.Dashboard]);
    }
  }

}
