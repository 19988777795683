import {USComplianceDocumentBase} from '../database-models/u-s-compliance-document-base';
import {USComplianceBase} from '../database-models/u-s-compliance-base';
//Generated Imports
export class USComplianceDocument extends USComplianceDocumentBase 
{




//#region Generated Reference Properties
//#region uSCompliance Prop
uSCompliance : USComplianceBase;
//#endregion uSCompliance Prop

attachmentBase64String: string;

isDocumentUpdated: boolean;

contentType: string;

//#endregion Generated Reference Properties










}