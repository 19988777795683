import { TemplateConfig } from '@rxweb/grid';
import { RolesEnum } from 'src/app/enums/roles-enum';

export const USER_TYPE_GRID_TEMPLATE: TemplateConfig = {
    span: {        
        class: [function (e) {
            return this.roleId == RolesEnum.GuestHRC ? "label f-12 text-white guestUserbadge-bg".split(" ") : (this[e.name] == 'Yes' ? "label f-12 text-white linkedInUserbadge-bg".split(" ") : 'label f-12 text-white inactivebadge-bg'.split(" "));
        }],
        childrens: [{
            text: {
                text: function (e) { 
                    return this.roleId == RolesEnum.GuestHRC ? "Guest" : (this[e.name] == 'Yes' ? "LinkedIn" : "Application") }
            }
        }]
    }
}