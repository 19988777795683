import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class UserExpertizeDetailBase {

//#region userExpertizeDetailsId Prop
        @prop()
        userExpertizeDetailsId : number;
//#endregion userExpertizeDetailsId Prop


//#region userId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        userId : number;
//#endregion userId Prop


//#region userExpertizeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        userExpertizeId : number;
//#endregion userExpertizeId Prop


//#region userExpertizeSubCategoryId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        userExpertizeSubCategoryId : number;
//#endregion userExpertizeSubCategoryId Prop







}