export class SpParameter {
    searchQuery: any;
    pageQuery: any;
}

export class Query {
    query: any;
    }

export class SearchQuery {
    searchText: string;
   // isIncludeAllUsers: boolean;
    roleId: number | string;
   // journalId: number;
   yearsOfExperienceId:number;
   cityId: number;
   stateId: number;
   countryId: number;
   ratings: number;
   linkedInUser: boolean;
}

export class PageDataQuery {
    PageIndex: number;
    PageRow: number;
    OrderColumn: string;
    OrderType: string;
}
