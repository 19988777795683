import {vRFQRecordBase} from '../database-models/v-r-f-q-record-base';
//Generated Imports
export class vRFQRecord extends vRFQRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}