import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TransectionDetailBase {

//#region transectionDetailId Prop
        @prop()
        transectionDetailId : any;
//#endregion transectionDetailId Prop


//#region transectionKey Prop
        @required()
        transectionKey : string;
//#endregion transectionKey Prop


//#region userId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        userId : number;
//#endregion userId Prop


//#region createdDate Prop
        @required()
        createdDate : Date;
//#endregion createdDate Prop


//#region transectionId Prop
        @prop()
        transectionId : string;
//#endregion transectionId Prop


//#region customerPaymentProfileId Prop
        @prop()
        customerPaymentProfileId : string;
//#endregion customerPaymentProfileId Prop


//#region modifiedDate Prop
        @prop()
        modifiedDate : Date;
//#endregion modifiedDate Prop


//#region responseCode Prop
        @maxLength({value:50})
        responseCode : string;
//#endregion responseCode Prop


//#region responseMsg Prop
        @prop()
        responseMsg : string;
//#endregion responseMsg Prop


//#region amount Prop
        @prop()
        amount : any;
//#endregion amount Prop


//#region isPaymentRecived Prop
        @required()
        isPaymentRecived : boolean;
//#endregion isPaymentRecived Prop


//#region subscriptionPlanId Prop
        @prop()
        subscriptionPlanId : number;
//#endregion subscriptionPlanId Prop


//#region isUSCompliance Prop
        @required()
        isUSCompliance : boolean;
//#endregion isUSCompliance Prop


//#region payNum Prop
        @prop()
        payNum : number;
//#endregion payNum Prop

}