import { Session } from 'protractor';

export const CustomMessage: { [key: string]: string } = {
    dataAdded: 'Data Added Successfully.',
    dataUpdated: 'Data Updated Successfully.',
    dataDeleted: 'Data Deleted Successfully',
    register: 'Your registration has been successfully done.',
    errorOccurred: "Error occurred.",
    selectOne: 'Please select atleast one.',
    passwordChanged: 'Password changed successfully.',
    problemDownloadExcel: 'There was some problem downloading the Excel.',
    oldPassworRequired: "Old password is required.",
    newPassworRequired: "New password is required.",
    confirmPassworRequired: "Confirm New Password is required.",
    newPassworNotValid: "New password isn't valid.",
    confirmPassworNotValid: "Confirm New Password isn't valid.",
    OldNewPasswordNotSame: "The old password and the new password can't have the same values specified.",
    YearOfExperienceValidation: "Invalid Experience!",
    forgotPasswordMail: "Mail sent successfully.",
    loginSuccessful: "Login Successful.",
    logoutSuccessful: "Logout Successful.",
    SessionExpired: "Session Expired. Please try again.",
    isRequiredField: "You can't leave this empty.",
    contractApproved: "Contract approved successfully",
    contractRejected: "Contract rejected successfully",
    CompanySizeValidation: "Invalid Expression!",
    InvalidStartDate: "Please select start date greater than today",
    InvalidEndDate: "Please select end date greater than today",
    ValidateEndDate: "End date should be greater than start date",
    MarkAllNotificationAsRead: "Email notifications marked as read successfully.",
    DeleteAll: "All email notifications deleted successfully.",
    rfqApproved: "Data approved successfully",
    rfqRejected: "Data rejected  successfully",
    PlanCancel: "Subscription Plan Cancelled Successfully",
    RequestSendSuccessfully: "Request Proposal sent successfully.",
    paymentSuccessful: "Payment Successful",
    paymentFailed: "Payment Failed",
    MsgSent: "Message sent successfully.",
    invalidEmail: "Please enter valid email",
    fileSizeValidation: "Maximum total file size allowed is 5MB",
    // fileSizeValidation10mb: "Maximum total file size allowed is 10MB",
    textLength: "More than 500 characters are not permitted.",
    uploadFileErrorMsg: "We support only .doc, .docx, .pdf, .pptx, .ppt, .xlsx, .xls, .jpg, .jpeg, .png format.",
    uploadImageErrorMsg: "We support only .jpg, .png, .jpeg format",
    validAmount: "Please enter valid amount",
    projectCreate: "Project is created successfully",
    OptionAdded: "Option already added.",
    fileCountValidation: "Maximum 5 files is allowed to upload",
    duplicateFileValidation: "This file is already added",
    atleastOne: "You have to add atleast one.",
    EmailChangeSuccessfully: "Email has been changed successfully.",
    fileSizeValidation3mb: "Maximum file size allowed is 3MB",
    fileSizeValidation15mb: "Maximum total file size allowed is 15MB",
    categoryAdded: "This category is already added.If you need to add more URL please go and edit in Additional Online Forms And Posters Library Category.",
    ProjectRFQDeadlineDateRequired: "Please select valid 'Request Proposal' deadline date.",
    FeedbackDetailValidation:"Please select at least one feedback detail.",
    ChangeProjectDeadlineDate:"Please change the deadline date from edit screen or going one step back, date should be greater than today",
    rfqInterested:"Data Interested successfully",
    rfqNotInterested:"Data Not Interested successfully"
}