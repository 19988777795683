import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vAdminRecordBase {

//#region userId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'userId', keyColumn: true})
        userId : number;
//#endregion userId Prop


//#region applicationLocaleId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'applicationLocaleId', keyColumn: false})
        applicationLocaleId : number;
//#endregion applicationLocaleId Prop


//#region applicationTimeZoneId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'applicationTimeZoneId', keyColumn: false})
        applicationTimeZoneId : number;
//#endregion applicationTimeZoneId Prop


//#region languageCode Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'languageCode', keyColumn: false})
        languageCode : string;
//#endregion languageCode Prop


//#region roleId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'roleId', keyColumn: false})
        roleId : any;
//#endregion roleId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region createdBy Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'createdBy', keyColumn: false})
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop

}