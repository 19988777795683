import {StateBase} from '../database-models/state-base';
import {CountryBase} from '../database-models/country-base';
import {CityBase} from '../database-models/city-base';
import {UserBase} from '../database-models/user-base';
//Generated Imports
export class State extends StateBase 
{




//#region Generated Reference Properties
//#region country Prop
country : CountryBase;
//#endregion country Prop
//#region city Prop
city : CityBase[];
//#endregion city Prop
//#region users Prop
users : UserBase[];
//#endregion users Prop

//#endregion Generated Reference Properties



























}