import { TemplateConfig } from '@rxweb/grid';

export const EMAIL_NOTIFICATION_GRID_TEMPLATE: TemplateConfig = {
    div: {
        class: 'formAction list-unstyled mb-0'.split(' '),
        childrens: [{
            a: {
                class:[function (e) {
                    return this.isPreview ? "gridinfobttn" : "gridEditbtn";
                }],
                //  'gridEditbtn'.split(' '),
                event: {
                    click: 'onView'
                },
                childrens: [{
                    a: {
                        childrens: [{
                            i: { class: 'fa fa-eye'.split(' ') }
                        }],
                    }
                }]
            }
        },
        {
            a: {
                class: 'gridDeletebtn'.split(' '),
                authorize: 'delete',
                event: {
                    click: 'onDelete'
                },
                childrens: [{
                    a: {
                        childrens: [{
                            i: { class: 'feather icon-trash-2'.split(' ') }
                            // text:{ 
                            //     text : "Delete"
                            // }
                        }],
                    }
                }]
            }

        }
        ]
    }
  
}