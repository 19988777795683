import {vCountryRecordBase} from '../database-models/v-country-record-base';
//Generated Imports
export class vCountryRecord extends vCountryRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





























}