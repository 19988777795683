import { http, RxHttp } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { Message } from '@app/models';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { CoreComponent } from '@rxweb/angular-router';
import { RolesEnum } from 'src/app/enums/roles-enum';
import { Query } from 'src/app/viewModel/sp-parameter';
import { API } from "src/app/components/shared/constant/administrationAPIContants";

@http({
    path: "api/Messages",
})

export class AbstractMessage extends CoreComponent {
    messageFormGroup: IFormGroup<Message>
    toastr: BaseToastr;
    spin = false;
    breadCrumb: BreadCrumb;
    dialog: BaseDialog;
    showComponent: boolean = false;
    rxHttp: RxHttp;
    rfqId: number;
    tipsMsg: any = new Array<any>();;

    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.rxHttp = new RxHttp();
        this.badRequest;
    }

    bindBreadCrumb() {
        let isMatchingHRCPage = window.location.href.indexOf('/add/') !== -1;
        if (isMatchingHRCPage) {
            let rfq = location.pathname.split("/");
            // this.rfqId =7;
            this.rfqId = parseInt(rfq[3]);
        }
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";
        var url = location.pathname.split("/");
        var mId = url[2];
        var RoleId = this.user.roleId;

        if (RoleId == RolesEnum.SuperAdmin) {
            this.breadCrumb.name2 = "Project RFQ Response Request Management";
            this.breadCrumb.link2 = 'project-r-f-q-admin';
        }
        else if (RoleId == RolesEnum.HRConsultant) {
            this.breadCrumb.name2 = "My RFQ Response";
            this.breadCrumb.link2 = 'project-r-f-q-response';
            this.tipsMsg[0] = "Select send message the HRO and/or submit your proposal.";
        }
        else if (RoleId == RolesEnum.HRO) {
            this.breadCrumb.name2 = "Quote Messages";
            this.breadCrumb.link2 = 'quote-message';
            this.tipsMsg[0] = "Proposal submissions and HRC RFQ messages are seen here.";
        }

        this.breadCrumb.title = 'Messages';
        this.breadCrumb.pageName = 'Messages';
        if (this.rfqId)
            this.breadCrumb.pageRedirect = "messages/" + this.rfqId;
        else
            this.breadCrumb.pageRedirect = "messages/" + mId;
    }

    badRequest = (data) => {
        this.spin = false;
        let messages = JSON.parse(data);
        this.toastr.warning(messages[0]);
    }
    getMessage(searchParams: Query) {
        return this.rxHttp.post({ body: searchParams, path: API.getMessages });
    }
}
