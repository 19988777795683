import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreComponent } from '@rxweb/angular-router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { ApplicationBroadcaster } from 'src/app/broadcast-services/application-broadcaster';
import { LoginService } from '../../authentication/login.service';
//import { UserAdminListComponent } from '../../user-admin/user-admin/list/user-admin-list.component';
import { CommonHelperService } from '../common-helpers/helper.service';
import { CommonConstants, RoutingKeys, StorageKeys } from '../constant/commonConstants';
import { RolesEnum } from 'src/app/enums/roles-enum';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { CustomMessage } from '../constant/custom-message';
import { EmailNotificationListComponent } from '../../master/email-notification/list/email-notification-list.component';
import { RxHttp } from '@rxweb/http';
import { UserAuthenticateData } from 'src/app/models/client-models/login.classes';
import { MasterLookupGroup } from 'src/app/viewModel/master-lookup-group';
import { MASTER_LOOKUPS } from 'src/app/uris/master-lookups.uri';
import { RoleNameEnum } from 'src/app/enums/role-name.enum';
import { API } from '../constant/administrationAPIContants';
declare const $: any;

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.css'],
    providers: [CommonHelperService]
})
export class TopBarComponent extends CoreComponent implements OnInit {
    spin = false;
    isSideBarActive = false;
    loginUserName: string;
    loggedInUserId: number;
    modalView: ModalView;
    isUserLoggedIn = false;
    storageHelper: BrowserStorage;
    userFullName = '';
    userId: number;
    userRole: string;
    isProfilePictureAvailable = false;
    profilePicture: string;
    isSidebarExpanded = true;
    isSuperAdmin = false;
    toastr: BaseToastr;
    //adminUserList: any = UserAdminListComponent;
    emailNotificationsCount: number;
    accessEmailNotification: any = EmailNotificationListComponent
    loggedInRoleName: string;
    masterLookupGroup: MasterLookupGroup;

    constructor(
        private http: RxHttp,
        private storagedata: BrowserStorage,
        private modelView: ModalView,
        private router: Router,
        private applicationBroadCaster: ApplicationBroadcaster,
        private commonHelperService: CommonHelperService,
        private loginService: LoginService) {
        super();
        this.storageHelper = new BrowserStorage();
        this.toastr = new BaseToastr();
        this.modalView = modelView;
        this.applicationBroadCaster.loginSubscriber.subscribe((isLogin: boolean) => {
            if (isLogin) {
                this.checkUserIsLoggedIn();
            } else {
                this.clearStorage();
            }
        });
        this.applicationBroadCaster.emailNotificationsSubscriber.subscribe(t => {
            this.emailNotificationsCount = t;
        });
    }
    
    ngOnInit() {
        if (!this.isUserLoggedIn) {
            this.http.get<UserAuthenticateData>({ path: API.userDetails }).subscribe(response => {
                this.applicationBroadCaster.emailNotificationsCount(response.emailNotificationCount);
            });
            this.checkUserIsLoggedIn();
        }
    }

    checkUserIsLoggedIn() {
        const isLoggedIn = this.storageHelper.local.get(StorageKeys.IsLoggedIn) as boolean;
        if (isLoggedIn) {
            this.userFullName = this.storageHelper.local.get(StorageKeys.Username);
            this.userId = Number.parseInt(this.storageHelper.local.get(StorageKeys.UserId));
            this.isUserLoggedIn = true;
            const profilePicByte = this.storageHelper.local.get(StorageKeys.ProfilePicture, false) as string;
            if (!this.commonHelperService.isStringNullEmptyOrWhiteSpace(profilePicByte)) {
                if (this.profilePicture !== profilePicByte) {
                    this.spin = true;
                }
                this.profilePicture = profilePicByte;
                this.isProfilePictureAvailable = true;
            } else {
                this.isProfilePictureAvailable = false;
                this.profilePicture = null;
            }
            this.userRole = this.storageHelper.local.get(StorageKeys.UserRole) as string;
            this.setRoloName();
            this.isSuperAdmin = (!this.commonHelperService.isStringNullEmptyOrWhiteSpace(this.userRole)
                && CommonConstants.SuperAdminRoleName === this.userRole.toLowerCase());
        } else {
            this.isUserLoggedIn = false;
            this.userFullName = '';
        }
    }

    setRoloName() {
        this.lookup<MasterLookupGroup>(
            [
                { path: MASTER_LOOKUPS.roleNameLookup, propName: 'roleNameLookup' },
            ]).subscribe((response: any) => {
                this.masterLookupGroup = response;
                if (parseInt(this.userRole) == RolesEnum.HRO)
                    this.loggedInRoleName = this.masterLookupGroup.roleNameLookup.find(t => t.applicationObjectId == RoleNameEnum.HumanResourcesOutsourcingOpenParenthesisHROCloseParenthesis).roleName;
                else if (parseInt(this.userRole) == RolesEnum.HRConsultant)
                    this.loggedInRoleName = this.masterLookupGroup.roleNameLookup.find(t => t.applicationObjectId == RoleNameEnum.HumanResourcesConsultantOpenParenthesisHRCCloseParenthesis).roleName;
                setTimeout(() => {
                    var navigationHeight = $('header').outerHeight();
                    $('.pcoded-main-container').css('padding-top',navigationHeight+15);    
                }, 50);
                
            })
    }

    

    clearStorage() {
        this.storageHelper.local.clearAll();
        this.storageHelper.session.clearAll();
        this.router.navigate([RoutingKeys.Login]);
        // this.router.navigate([RoutingKeys.StaticPagePreview]);
        this.checkUserIsLoggedIn();
    }

    logoutUser() {
        this.post({ path: API.logout, body: { 'AudienceType': 'web' } }).subscribe(() => {
            this.clearStorage();
            this.toastr.success(CustomMessage.logoutSuccessful);
            this.applicationBroadCaster.loginBroadCast(false);
        });
    }

    // toggleSideBar() {
    //     this.isSidebarExpanded = !this.isSidebarExpanded;
    //     const element = document.getElementById('saklaiBody');
    //     if (this.isSidebarExpanded) {
    //         element.classList.remove('sidebar-collapsed');
    //     } else {
    //         element.classList.add('sidebar-collapsed');
    //     }
    // }

    imageLoaded() {
        this.spin = false;
    }

    imageLoadError(event) {
        this.spin = false;
    }

    onClick() {
        if (parseInt(this.userRole) === RolesEnum.HRConsultant || parseInt(this.userRole) === RolesEnum.GuestHRC) {
            this.router.navigate([RoutingKeys.HRConsultant, this.userId]);
        }
        else if (parseInt(this.userRole) === RolesEnum.HRO) {
            this.router.navigate([RoutingKeys.HRO, this.userId]);
        }
        else if (parseInt(this.userRole) === RolesEnum.SuperAdmin) {
            //this.route.navigate([RoutingKeys.HRConsultant, user.userId]);
            this.router.navigate([RoutingKeys.User, this.userId]);
        }
    }
}
