import { vPublicRfpHrcBase } from '../database-models/v-public-rfp-hrc-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'publicRFPAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vPublicRfpHrc extends vPublicRfpHrcBase {




    //#region Generated Reference Properties

    //#endregion Generated Reference Properties








}