import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPublicRFPSendMailBase {

//#region userId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'userId', keyColumn: false})
        userId : any;
//#endregion userId Prop


//#region userExpertizeId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'userExpertizeId', keyColumn: true})
        userExpertizeId : number;
//#endregion userExpertizeId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: true})
        name : string;
//#endregion name Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'email', keyColumn: true})
        email : string;
//#endregion email Prop

}