import { TemplateConfig } from '@rxweb/grid';

export const NUMBER_REDIRECT_GRID_TEMPLATE: TemplateConfig = {
    // div: {
    //     childrens: [{
    //         a: {
    //             event: {
    //                 click: "onLink"
    //             },
    //             childrens: [{
    //                 div: {
    //                     style: {
    //                         "cursor": "pointer",
    //                     },
    //                     class: ["pull-right"],
    //                     childrens: [{
    //                         text: {
    //                             text: function (e) {
    //                                 if (this[e.name] != null) {
    //                                     return this[e.name];
    //                                 } else {
    //                                     return ''
    //                                 }
    //                             }
    //                         }
    //                     }]
    //                 }
    //             }]
    //         }
    //     }],
    // }

    div: {
        class: [
            function (e) {
                return "divTableCell".split(" ");
            }],
        attributes: {
            'data-head': function (e) { return e.headerTitle; }
        }
        ,
        childrens: [{
            div: {
                childrens: [{
                    a: {
                        event: {
                            click: "onLink"
                        },
                        childrens: [{
                            div: {
                                style: {
                                    "cursor": "pointer",
                                },
                                class: ["pull-right"],
                                childrens: [{
                                    text: {
                                        text: function (e) {
                                            if (this[e.name] != null) {
                                                return this[e.name];
                                            } else {
                                                return ''
                                            }
                                        }
                                    }
                                }]
                            }
                        }]
                    }
                }],
            }
        }]
    }
};

