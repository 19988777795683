import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSubscriptionTypeRecordBase {

//#region subscriptionTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'subscriptionTypeId', keyColumn: true})
        subscriptionTypeId : number;
//#endregion subscriptionTypeId Prop


//#region subscriptionTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'subscriptionTypeName', keyColumn: false})
        subscriptionTypeName : string;
//#endregion subscriptionTypeName Prop


//#region subscriptionTypeDescription Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'subscriptionTypeDescription', keyColumn: false})
        subscriptionTypeDescription : string;
//#endregion subscriptionTypeDescription Prop


//#region validMonths Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'validMonths', keyColumn: false})
        validMonths : any;
//#endregion validMonths Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'createdBy', keyColumn: false})
        createdBy : any;
//#endregion createdBy Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop

}