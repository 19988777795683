import {MonthlyRetainedOptionLookupBase} from '../database-models/monthly-retained-option-lookup-base';
//Generated Imports
export class MonthlyRetainedOptionLookup extends MonthlyRetainedOptionLookupBase 
{

//#region isSelected prop
isSelected:boolean
//#endregion isSelected prop


//#region Generated Reference Properties

//#endregion Generated Reference Properties














































































}