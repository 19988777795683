import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vContractHRCBase {

//#region contractId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'contractId', keyColumn: true})
        contractId : number;
//#endregion contractId Prop


//#region hRConsultant Prop
        @gridColumn({visible: false, columnIndex:1, allowSorting: true, headerKey: 'hRConsultant', keyColumn: false,headerTitle: "HR Consultant"})
        hRConsultant : string;
//#endregion hRConsultant Prop


//#region hROName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'hROName', keyColumn: false,headerTitle: "HRO", configuredTemplate: { templateName: "redirectHROLink" }})
        hROName : string;
//#endregion hROName Prop


//#region projectId Prop
        @gridColumn({visible: false, columnIndex:3, allowSorting: true, headerKey: 'projectId', keyColumn: false})
        projectId : number;
//#endregion projectId Prop


//#region projectName Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'projectName', keyColumn: false,headerTitle: "Project", configuredTemplate: { templateName: "redirectProjectLink" }})
        projectName : string;
//#endregion projectName Prop


//#region contractDetails Prop
        @gridColumn({visible: false, columnIndex:5, allowSorting: true, headerKey: 'contractDetails', keyColumn: false,headerTitle: "Details"})
        contractDetails : string;
//#endregion contractDetails Prop


//#region contractStartDate Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'contractStartDate', keyColumn: false,headerTitle: "Contract Start Date", configuredTemplate: { templateName: "dateFormat" }})
        contractStartDate : any;
//#endregion contractStartDate Prop


//#region contractEndDate Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, isAscending: true, headerKey: 'contractEndDate', keyColumn: false,headerTitle: "Contract End Date", configuredTemplate: { templateName: "dateFormat" }})
        contractEndDate : any;
//#endregion contractEndDate Prop


//#region pricing Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'pricing', keyColumn: false,headerTitle: "Pricing", configuredTemplate:{templateName: "numberDisplay"}})
        pricing : any;
//#endregion pricing Prop


//#region createdBy Prop
        @gridColumn({visible: false, columnIndex:11, allowSorting: true, headerKey: 'createdBy', keyColumn: false})
        createdBy : any;
//#endregion createdBy Prop


//#region createdOn Prop
        @gridColumn({visible: false, columnIndex:12, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop


//#region contractStatus Prop
       @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'contractStatus', keyColumn: false,headerTitle: "Status", configuredTemplate: { templateName: "contractStatus"}})
       contractStatus : string;
//#endregion contractStatus Prop



}