import { PublicRFPBase } from '../database-models/public-r-f-p-base';
import { PublicRFPCategoryBase } from '../database-models/public-r-f-p-category-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'action' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"], headerCellClass: ["pl-4"]
})
export class PublicRFP extends PublicRFPBase {
    //#region Generated Reference Properties
    //#region publicRFPCategories Prop
    publicRFPCategories: PublicRFPCategoryBase[];
    //#endregion publicRFPCategories Prop
    //#endregion Generated Reference Properties
}