import { TemplateConfig } from '@rxweb/grid';

export const ACTIVE_LABEL_BACKGROUND: TemplateConfig = {
    // label: {

    //     class:[
    //             function (e) {
    //                 return this[e.name] ? "mb-0 badge sucess".split(" ") : 'mb-0 badge sucess'.split(" ");
    //     }],

    //     childrens: [{
    //             text: {
    //                 text: function (e) { return this[e.name] }
    //                 }
    //         }]
    // }

    div: {
        class: [
            function (e) {
                return "divTableCell".split(" ");
            }],
        attributes: {
            'data-head': function (e) { return e.headerTitle; }
        }
        ,
        childrens: [{
            label: {
                class: [
                    function (e) {
                        return this[e.name] ? "mb-0 badge sucess".split(" ") : 'mb-0 badge sucess'.split(" ");
                    }],
                childrens: [{
                    text: {
                        text: function (e) { return this[e.name] }
                    }
                }]
            }
        }]
    }

}
