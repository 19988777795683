import {ExpertizeBase,ProjectExpertizeDetailBase,UserExpertizeDetailBase,} from '@app/database-models'
//Generated Imports
export class Expertize extends ExpertizeBase 
{




//#region Generated Reference Properties
//#region projectExpertizeDetails Prop
projectExpertizeDetails : ProjectExpertizeDetailBase[];
//#endregion projectExpertizeDetails Prop
//#region userExpertizeDetails Prop
userExpertizeDetails : UserExpertizeDetailBase[];
//#endregion userExpertizeDetails Prop

//#endregion Generated Reference Properties





























}