import {FeedbackDetailBase} from '../database-models/feedback-detail-base';
import {FeedbackBase} from '../database-models/feedback-base';
import {MonthlyRetainedServiceBase} from '../database-models/monthly-retained-service-base';
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class FeedbackDetail extends FeedbackDetailBase {




    //#region Generated Reference Properties
//#region feedback Prop
feedback: FeedbackBase;
//#endregion feedback Prop
//#region monthlyRetainedService Prop
monthlyRetainedService: MonthlyRetainedServiceBase;
//#endregion monthlyRetainedService Prop
isSelected:boolean;
//#endregion Generated Reference Properties
















    //#region arrayIndex Prop
    @prop()
    arrayIndex: number;
    //#endregion arrayIndex Prop
    //#region questionText Prop
    @prop()
    questionText:string;
    //#endregion questionText Prop
    //#region isRequired Prop
    @prop()
    isRequired:boolean;
    //#endregion isRequired Prop

    checked:boolean;





















}