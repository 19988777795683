import {RoleBase} from '../database-models/role-base';
import {RolePermissionBase} from '../database-models/role-permission-base';
import {UserRoleBase} from '../database-models/user-role-base';
import {UserBase} from '../database-models/user-base';
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class Role extends RoleBase {




    //#region Generated Reference Properties
//#region rolePermissions Prop
@prop()
    rolePermissions: RolePermissionBase[];
//#endregion rolePermissions Prop
//#region userRoles Prop
userRoles: UserRoleBase[];
//#endregion userRoles Prop
//#region users Prop
users: UserBase[];
//#endregion users Prop

//#endregion Generated Reference Properties
























}