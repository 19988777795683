import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUSComplianceHRBase {

//#region title Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, isAscending:true, headerKey: 'title', keyColumn: false , headerTitle: "Title"})
        title : string;
//#endregion title Prop


//#region documentName Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'documentName', keyColumn: false, headerTitle:"Document Name",configuredTemplate: { templateName: "downloadDoc" }})
        documentName : string;
//#endregion documentName Prop

//#region category Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'category', keyColumn: false , headerTitle:'Category'})
        category : string;
//#endregion category Prop


//#region hrCompliance Prop
        @gridColumn({visible:true, columnIndex:2, allowSorting: true, headerKey: 'hrCompliance', keyColumn: false, headerTitle : 'HR Compliance'})
        hrCompliance : string;
//#endregion hrCompliance Prop


//#region stateName Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'stateName', keyColumn: false,headerTitle:'State'})
        stateName : string;
//#endregion stateName Prop


//#region uRLLink Prop
        @gridColumn({visible: false, columnIndex:5, allowSorting: false, headerKey: 'urlLink', keyColumn: false , headerTitle:'URL Link'})
        urlLink : string;
//#endregion uRLLink Prop


//#region uSComplianceId Prop
        @gridColumn({visible: false, columnIndex:6, allowSorting: true, headerKey: 'uSComplianceId', keyColumn: true})
        uSComplianceId : number;
//#endregion uSComplianceId Prop

}