import {vHrcProjectDetailBase} from '../database-models/v-hrc-project-detail-base';
//Generated Imports
export class vHrcProjectDetail extends vHrcProjectDetailBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





}