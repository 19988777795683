import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProjectRFQHROBase {

        //#region createdBy Prop
        @gridColumn({ visible: true, columnIndex: 0, headerTitle: "Message", allowSorting: false, headerKey: 'messageCount', keyColumn: false, configuredTemplate: { templateName: "actionAcceptRFQ" } })
        messageCount: number;
        //#endregion createdBy Prop

        //#region projectRFQId Prop
        @gridColumn({ visible: false, columnIndex: 1, allowSorting: true, headerKey: 'projectRFQId', keyColumn: true })
        projectRFQId: number;
        //#endregion projectRFQId Prop


        //#region projectId Prop
        @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerKey: 'projectId', keyColumn: false })
        projectId: number;
        //#endregion projectId Prop


        //#region projectName Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'projectName', keyColumn: false, headerTitle: "Project", configuredTemplate: { templateName: "redirectProjectLink" } })
        projectName: string;
        //#endregion projectName Prop


        //#region hRConsultant Prop
        @gridColumn({ visible: false, columnIndex: 4, allowSorting: true, headerKey: 'hRConsultant', keyColumn: false })
        hRConsultant: number;
        //#endregion hRConsultant Prop


        //#region hRConsultantName Prop
        @gridColumn({ visible: true, columnIndex: 5, allowSorting: true, headerKey: 'hRConsultantName', keyColumn: false, headerTitle: "HR Consultant", configuredTemplate: { templateName: "redirectHRCLink" } })
        hRConsultantName: string;
        //#endregion hRConsultantName Prop


        //#region hROName Prop
        @gridColumn({ visible: false, columnIndex: 6, allowSorting: true, headerKey: 'hROName', keyColumn: false })
        hROName: string;
        //#endregion hROName Prop

        //#region deadlineDate Prop
        @gridColumn({ visible: true, columnIndex: 7, allowSorting: true, headerKey: 'deadlineDate', keyColumn: false, headerTitle: "Deadline Date", configuredTemplate: { templateName: "dateFormat" } })
        deadlineDate: any;
        //#endregion deadlineDate Prop


        //#region projectRFQStatus Prop
        @gridColumn({ visible: true, columnIndex: 9, allowSorting: true, headerKey: 'projectRFQStatus', keyColumn: false, headerTitle: "RFQ Status", configuredTemplate: { templateName: "rfqStatus" } })
        projectRFQStatus: number;
        //#endregion projectRFQStatus Prop


        //#region createdOn Prop
        @gridColumn({ visible: false, columnIndex: 9, allowSorting: true, headerKey: 'createdOn', keyColumn: false, headerTitle: "RFQ Sent date", configuredTemplate: { templateName: "dateTimeFormat" } })
        createdOn: any;
        //#endregion createdOn Prop


        //#region createdBy Prop
        @gridColumn({ visible: false, columnIndex: 10, allowSorting: true, headerKey: 'createdBy', keyColumn: false })
        createdBy: number;
        //#endregion createdBy Prop

        //#region createdBy Prop
        @gridColumn({ visible: true, columnIndex: 8, allowSorting: true, headerKey: 'RFQ CreatedBy', keyColumn: false, headerTitle: "RFQ Created By" })
        rfqCreatedBy: string;
        //#endregion createdBy Prop





}