import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vMonthlyRetainedOptionRecordBase {

//#region monthlyRetainedOptionId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'monthlyRetainedOptionId', keyColumn: true})
        monthlyRetainedOptionId : number;
//#endregion monthlyRetainedOptionId Prop


//#region monthlyRetainedOptionName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true,isAscending:true, headerKey: 'monthlyRetainedOptionName', keyColumn: false})
        monthlyRetainedOptionName : string;
//#endregion monthlyRetainedOptionName Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'createdBy', keyColumn: false})
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop

}