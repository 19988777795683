import {EmailDetailBase} from '../database-models/email-detail-base';
import {EmailNotificationBase} from '../database-models/email-notification-base';
//Generated Imports
export class EmailDetail extends EmailDetailBase 
{




//#region Generated Reference Properties
//#region emailNotifications Prop
emailNotifications : EmailNotificationBase[];
//#endregion emailNotifications Prop

//#endregion Generated Reference Properties





}