import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class AttachmentBase {

//#region attachmentId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        attachmentId : number;
//#endregion attachmentId Prop


//#region attachments Prop
        @required()
        attachments : string;
//#endregion attachments Prop

}