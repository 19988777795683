import {USComplianceBase} from '../database-models/u-s-compliance-base';
import {HRComplianceBase} from '../database-models/h-r-compliance-base';
import {StateBase} from '../database-models/state-base';
import {USComplianceDocumentBase} from '../database-models/u-s-compliance-document-base';
//Generated Imports
export class USCompliance extends USComplianceBase 
{




//#region Generated Reference Properties
//#region hRCompliance Prop
hRCompliance : HRComplianceBase;
//#endregion hRCompliance Prop
//#region state Prop
state : StateBase;
//#endregion state Prop
//#region uSComplianceDocument Prop
uSComplianceDocument : USComplianceDocumentBase[];
//#endregion uSComplianceDocument Prop

//#endregion Generated Reference Properties


}