import {vCancelledCategoryBase} from '../database-models/v-cancelled-category-base';
//Generated Imports
export class vCancelledCategory extends vCancelledCategoryBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}