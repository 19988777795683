import {vSubscriptionTypeRecordBase} from '../database-models/v-subscription-type-record-base';
//Generated Imports
export class vSubscriptionTypeRecord extends vSubscriptionTypeRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties


















}