import {ExpertizeSubCategoryBase,UserExpertizeDetailBase,ProjectExpertizeDetailBase,} from '@app/database-models'
//Generated Imports
export class ExpertizeSubCategory extends ExpertizeSubCategoryBase 
{




//#region Generated Reference Properties
//#region userExpertizeDetails Prop
userExpertizeDetails : UserExpertizeDetailBase[];
//#endregion userExpertizeDetails Prop
//#region projectExpertizeDetails Prop
projectExpertizeDetails : ProjectExpertizeDetailBase[];
//#endregion projectExpertizeDetails Prop

//#endregion Generated Reference Properties
















































}