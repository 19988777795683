export class BreadCrumb {
    title: string;
    pageName: string;
    pageRedirect : string;
    name1: string;
    link1: string;
    name2: string;
    link2: string;
    name3: string;
    link3: string;
    name4: string;
    link4: string;
}