import {ExpertizeSubCategoryLookupBase,} from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class ExpertizeSubCategoryLookup extends ExpertizeSubCategoryLookupBase 
{




//#region Generated Reference Properties
//#region modifiedOn Prop
@prop()
checked : boolean = false;
//#endregion modifiedOn Prop
//#endregion Generated Reference Properties























}