import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSubscriptionPlanCancelListBase {

//#region userId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'userId', keyColumn: true })
        userId : number;
//#endregion userId Prop


//#region name Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'name', keyColumn: false, headerTitle: 'Name' })
        name : string;
//#endregion name Prop


//#region roleId Prop
        @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerKey: 'roleId', keyColumn: false })
        roleId : any;
//#endregion roleId Prop


//#region companyName Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'companyName', keyColumn: false, headerTitle: 'Company Name' })
        companyName : string;
//#endregion companyName Prop


//#region email Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'email', keyColumn: false, headerTitle: 'Email Id' })
        email : string;
//#endregion email Prop


//#region isPlanCancelled Prop
        @gridColumn({ visible: false, columnIndex: 9, allowSorting: true, headerKey: 'isPlanCancelled', keyColumn: false })
        isPlanCancelled : any;
//#endregion isPlanCancelled Prop


//#region subscriptionPlanName Prop
        @gridColumn({ visible: false, columnIndex: 6, allowSorting: true, headerKey: 'subscriptionPlanName', keyColumn: false, headerTitle: "Subscription Plan" })
        subscriptionPlanName : string;
//#endregion subscriptionPlanName Prop


//#region subscriptionTypeName Prop
        @gridColumn({ visible: true, columnIndex: 7, allowSorting: true, headerKey: 'subscriptionTypeName', keyColumn: false, headerTitle: 'Subscription Type' })
        subscriptionTypeName : string;
//#endregion subscriptionTypeName Prop


//#region startDate Prop
        @gridColumn({ visible: true, columnIndex: 8, allowSorting: true, headerKey: 'startDate', keyColumn: false, headerTitle: "Start Date", configuredTemplate: { templateName: "dateFormat" } })
        startDate : any;
//#endregion startDate Prop


//#region endDate Prop
        @gridColumn({ visible: true, columnIndex: 9, allowSorting: true, headerKey: 'endDate', keyColumn: false, headerTitle: "End Date", configuredTemplate: { templateName: "dateFormat" }})
        endDate : any;
//#endregion endDate Prop

}