
//Generated Imports
import { actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';
import { vProjectRFQHRCBase } from '../database-models/v-project-r-f-q-hrc-base';

// @actionColumn({
//     name: 'hrcRFQAction',
//     allowSorting: false,
//     configuredTemplate: { templateName: 'hrcRFQAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"], headerCellClass: ["pl-4"]
// })
export class vProjectRFQHRC extends vProjectRFQHRCBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties
















}