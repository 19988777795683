import {ProjectExpertizeDetailBase} from '../database-models/project-expertize-detail-base';
import {ExpertizeBase} from '../database-models/expertize-base';
import {ExpertizeSubCategoryBase} from '../database-models/expertize-sub-category-base';
import {ProjectBase} from '../database-models/project-base';
//Generated Imports
export class ProjectExpertizeDetail extends ProjectExpertizeDetailBase 
{




//#region Generated Reference Properties
//#region expertize Prop
expertize : ExpertizeBase;
//#endregion expertize Prop
//#region expertizeSubCategory Prop
expertizeSubCategory : ExpertizeSubCategoryBase;
//#endregion expertizeSubCategory Prop
//#region project Prop
project : ProjectBase;
//#endregion project Prop

//#endregion Generated Reference Properties















































}