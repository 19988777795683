import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class StaticPageBase {

//#region staticPageId Prop
        @prop()
        staticPageId : number;
//#endregion staticPageId Prop


//#region staticPageName Prop
        @required()
        @maxLength({value:20})
        staticPageName : string;
//#endregion staticPageName Prop


//#region staticPageText Prop
        @required()
        staticPageText : string;
//#endregion staticPageText Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @prop()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop



}