
//Generated Imports
import { actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';
import { vProjectRFQHROBase } from '../database-models/v-project-r-f-q-hro-base';

// @actionColumn({
//     name: 'messageView',
//     allowSorting: false,
//     configuredTemplate: { templateName: 'messageView' }, columnIndex: 0, headerTitle: "Message", class: ["actionFild"], headerCellClass: ["pl-4"]
// })
export class vProjectRFQHRO extends vProjectRFQHROBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties
















}