import {MessageBase} from '../database-models/message-base';
import {MessageAttachmentBase} from '../database-models/message-attachment-base';
import { actionColumn } from '@rxweb/grid';
//Generated Imports
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'messagePreviewAction' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class Message extends MessageBase 
{




//#region Generated Reference Properties

//#region messageAttachments Prop
        messageAttachments : MessageAttachmentBase[];
//#endregion messageAttachments Prop
//#endregion Generated Reference Properties



































}