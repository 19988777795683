import {AttachmentBase} from '../database-models/attachment-base';
//Generated Imports
export class Attachment extends AttachmentBase 
{


//#region Generated Reference Properties
attachmentBase64String: string;
contentType:string;
//#endregion Generated Reference Properties

}