import {vCityRecordBase} from '../database-models/v-city-record-base';
//Generated Imports
export class vCityRecord extends vCityRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












































}