import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractTestimonial } from '../domain/abstract-testimonial';
import { Testimonial } from "@app/models";
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CommonHelperService } from 'src/app/components/shared/common-helpers/helper.service';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { AppGrid } from 'src/app/domain/app-grid';
import { RoutingKeys, ToolTipText } from 'src/app/components/shared/constant/commonConstants';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { access } from "@rxweb/angular-router";
import { ApplicationModules, PermissionNames } from "src/app/enums/application-modules.enum";
import { TestimonialAddComponent } from "../add/testimonial-add.component";
import { ModelComponentType, ModelResponse } from "src/app/components/shared/constant/common-constants";
declare const $: any;

@access({ accessLevel: ApplicationModules.MyTestimonials, action: PermissionNames.Get })

@Component({
    selector: "app-testimonial-list",
    templateUrl: './testimonial-list.component.html'
})
export class TestimonialListComponent extends AbstractTestimonial implements OnInit, OnDestroy {
    testimonialAdd:any=TestimonialAddComponent;
    
    testimonials: List<Testimonial>;
    subscription: Subscription;
    testimonialGrid: AppGrid;
    testimonialGridColumns: any[];
    searchText = '';
    modalView: ModalView;

    constructor(
        private route: Router,
        private commonHelperService: CommonHelperService,
        modalView: ModalView
    ) {
        super();
        this.modalView = modalView;
    }
    ngOnInit(): void {
        this.initializeGrid();
    }

    initializeGrid() {
        if (!this.commonHelperService.isObjectNullOrUndefined(this.testimonialGrid)) {
            this.testimonialGrid.clearGrid('testimonials');
        }
        this.spin = true;
        this.showComponent = true;
        this.subscription = this.get({ queryParams: { 'UserId': [this.user.userId], 'TestimonialId': 0 } }).subscribe((testimonialList: any) => {
            this.spin = false;
            this.testimonialGrid = new AppGrid(testimonialList, Testimonial
                , {
                    actions: {
                        onEdit: this.onEdit.bind(this),
                        onDelete: this.onDelete.bind(this),
                    }
                }
            );
            this.testimonialGrid.authorization = {
                'put': { accessLevel: ApplicationModules.MyTestimonials, action: PermissionNames.Put },
                'delete': { accessLevel: ApplicationModules.MyTestimonials, action: PermissionNames.Delete }
            };
            this.testimonialGrid.design(document.getElementById('testimonials'));
            this.testimonialGridColumns = this.testimonialGrid.gridColumns.filter(x => x.keyColumn === false);
            this.testimonialGrid.search = this.searchText;
            this.addTooltip();
        });
    }

    onEdit(testimonial: Testimonial) {
        this.route.navigate([RoutingKeys.Testimonials, testimonial.testimonialId]);
    }

    onDelete(testimonial: Testimonial) {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Delete }).then(t => {
            if (t == ModelResponse.Yes) {
                this.spin = true;
                this.delete({ params: [testimonial.testimonialId], queryParams: { 'UserId': [this.user.userId], 'TestimonialId': testimonial.testimonialId }, body: null }).subscribe(response => {
                    this.spin = false;
                    this.toastr.success(CustomMessage.dataDeleted);
                    this.initializeGrid();
                });
            }
        });
    }

    search(value) {
        this.testimonialGrid.search = value;
        this.searchText = this.testimonialGrid.search;
    }

    addTestimonial() {
        this.route.navigate([RoutingKeys.AddTestimonials]);
    }

    addTooltip() {
        $(document).ready(function () {
            $(".gridEditbtn").hover(function () {
                $(this).attr("title", ToolTipText.EditTestimonial);
            })
            $(".gridDeletebtn").hover(function () {
                $(this).attr("title", ToolTipText.DeleteTestimonial);
            })
        })
    }

    openTips() {
        let msg = new Array<any>();
        msg[0] = 'Please add your testimonials here.'
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.PopupMessage, params: msg }).then(t => {
        })
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

}
