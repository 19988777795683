import {UserBase} from '../database-models/user-base';
import {CityBase} from '../database-models/city-base';
import {CompanySizeDetailBase} from '../database-models/company-size-detail-base';
import {CountryBase} from '../database-models/country-base';
import {RoleBase} from '../database-models/role-base';
import {StateBase} from '../database-models/state-base';
import {UserDetailBase} from '../database-models/user-detail-base';
import {UserExpertizeDetailBase} from '../database-models/user-expertize-detail-base';
import {TestimonialBase} from '../database-models/testimonial-base';
import {UserSubscriptionDetailBase} from '../database-models/user-subscription-detail-base';
import {ApplicationUserTokenBase} from '../database-models/application-user-token-base';
import {UserRoleBase} from '../database-models/user-role-base';
import {ContractBase} from '../database-models/contract-base';
import {FeedbackBase} from '../database-models/feedback-base';
import {ProjectRFQBase} from '../database-models/project-r-f-q-base';
import { propArray } from '@rxweb/reactive-form-validators';
import { UserDetail } from './user-detail';

//Generated Imports
export class User extends UserBase 
{




//#region Generated Reference Properties
//#region city Prop
city : CityBase;
//#endregion city Prop
//#region companySizeDetail Prop
companySizeDetail : CompanySizeDetailBase;
//#endregion companySizeDetail Prop
//#region country Prop
country : CountryBase;
//#endregion country Prop
//#region role Prop
role : RoleBase;
//#endregion role Prop
//#region state Prop
state : StateBase;
//#endregion state Prop
//#region userDetails Prop
@propArray(UserDetail)
userDetails : UserDetail[];
//#endregion userDetails Prop
//#region userExpertizeDetails Prop
@propArray(UserExpertizeDetailBase)
userExpertizeDetails : UserExpertizeDetailBase[];
//#endregion userExpertizeDetails Prop
//#region testimonials Prop
testimonials : TestimonialBase[];
//#endregion testimonials Prop
//#region userSubscriptionDetails Prop
userSubscriptionDetails : UserSubscriptionDetailBase[];
//#endregion userSubscriptionDetails Prop
//#region applicationUserTokens Prop
applicationUserTokens : ApplicationUserTokenBase[];
//#endregion applicationUserTokens Prop
//#region userRoles Prop
userRoles : UserRoleBase[];
//#endregion userRoles Prop
//#region contracts Prop
contracts : ContractBase[];
//#endregion contracts Prop
//#region feedback Prop
feedback : FeedbackBase[];
//#endregion feedback Prop
//#region projectRFQ Prop
projectRFQ : ProjectRFQBase[];
//#endregion projectRFQ Prop

//#endregion Generated Reference Properties





































}