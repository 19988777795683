import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class HRComplianceBase {

//#region hRComplianceId Prop
        @prop()
        hrComplianceId : number;
//#endregion hRComplianceId Prop


//#region hRComplianceName Prop
        @maxLength({value:50})
        hrComplianceName : string;
//#endregion hRComplianceName Prop



}