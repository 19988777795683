import {SMTPConfigurationBase} from '../database-models/s-m-t-p-configuration-base';
//Generated Imports
export class SMTPConfiguration extends SMTPConfigurationBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties









}