import {MonthlyRetainedOptionBase} from '../database-models/monthly-retained-option-base';
import {FeedbackBase} from '../database-models/feedback-base';
import {MonthlyRetainedServiceBase} from '../database-models/monthly-retained-service-base';
//Generated Imports
export class MonthlyRetainedOption extends MonthlyRetainedOptionBase 
{




//#region Generated Reference Properties
//#region feedback Prop
feedback : FeedbackBase[];
//#endregion feedback Prop
//#region monthlyRetainedService Prop
monthlyRetainedService : MonthlyRetainedServiceBase[];
//#endregion monthlyRetainedService Prop

//#endregion Generated Reference Properties














































































}