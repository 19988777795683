import {ProjectBase} from '../database-models/project-base';
import {ProjectDetailBase} from '../database-models/project-detail-base';
import {ProjectExpertizeDetailBase} from '../database-models/project-expertize-detail-base';
import {ContractBase} from '../database-models/contract-base';
import {FeedbackBase} from '../database-models/feedback-base';
import {ProjectRFQBase} from '../database-models/project-r-f-q-base';
//Generated Imports
export class Project extends ProjectBase 
{




//#region Generated Reference Properties
//#region projectDetails Prop
projectDetails : ProjectDetailBase[];
//#endregion projectDetails Prop
//#region projectExpertizeDetails Prop
projectExpertizeDetails : ProjectExpertizeDetailBase[];
//#endregion projectExpertizeDetails Prop
//#region contracts Prop
contracts : ContractBase[];
//#endregion contracts Prop
//#region feedback Prop
feedback : FeedbackBase[];
//#endregion feedback Prop
//#region projectRFQ Prop
projectRFQ : ProjectRFQBase[];
//#endregion projectRFQ Prop

//#endregion Generated Reference Properties















































}