import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SubscriptionTypeLookupBase {

//#region subscriptionTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'subscriptionTypeId', keyColumn: true})
        subscriptionTypeId : number;
//#endregion subscriptionTypeId Prop


//#region subscriptionTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'subscriptionTypeName', keyColumn: false})
        subscriptionTypeName : string;
//#endregion subscriptionTypeName Prop

}