import {SubscriptionTypeBase} from '../database-models/subscription-type-base';
import {SubscriptionPlanBase} from '../database-models/subscription-plan-base';
//Generated Imports
export class SubscriptionType extends SubscriptionTypeBase 
{




//#region Generated Reference Properties
//#region subscriptionPlan Prop
subscriptionPlan : SubscriptionPlanBase[];
//#endregion subscriptionPlan Prop

//#endregion Generated Reference Properties







































}