import {vContractRecordBase} from '../database-models/v-contract-record-base';
//Generated Imports
export class vContractRecord extends vContractRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





















}