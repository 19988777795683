<div *ngIf="showDeleteComponent" class="modal fade show" id="clonepopup" tabindex="-1" aria-labelledby="clonepopuptilte"
  style="display: block;">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div class="card">
        <div class="card-body text-center">
          <div class="mb-4">
            <img class="authpage-logo" src="assets/images/hr-doorway-logo.png" width="292" height="174" alt="HR DoorWay" />
          </div>
          <h3 class="mb-4"><b>Reset Password</b></h3>
          <form [formGroup]="forgotPasswordFormGroup" (keyup.enter)="forgotPassword($event)">
           
            <div class="input-group  mb-3">
              <input type="email" class="form-control focus-effect w-100" id="email-address" aria-describedby="emailHelp"
                formControlName="email" placeholder="Email" (keyup)="isExist()"><br>
              <small class="form-text text-danger"
                *ngIf="forgotPasswordFormGroup.controls.email.errors">{{forgotPasswordFormGroup.controls.email.errorMessage}}</small>
            </div>
           
          </form>
          <div class="form-group login-btn mb-0">
            <button class="btn btn-primary mb-4 shadow-2 " (click)="forgotPassword($event)">Send</button>
          </div>
          <a (click)="hide()" class="underline  mt-2" style="font-weight: bold;">Login?</a>
          <small *ngIf="userNotExistMsg!=''" class="form-text text-danger"><b>{{userNotExistMsg}}</b></small>

        </div>
      </div>
    </div>
  </div>
</div>
<div [rxSpinner]="spin">
</div>