import { TemplateConfig } from '@rxweb/grid';
import { formatDate } from '@angular/common';
import { pipe } from 'rxjs';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';

export const DUE_DATE_LABEL_BACKGROUND: TemplateConfig = {

    // span: {
    //     class: ["label", function (e) {
    //         return this.islate == 1 ? "text-red" : '';
    //     }],
    //     childrens: [{
    //         text: {
    //             text: function (e) {
    //                 if (this.DueDate != null) {
    //                     var date = new Date(this.DueDate);
    //                     var storageHelper = new BrowserStorage();
    //                     return formatDate(date, storageHelper.local.get('dateFormat', false), 'en-US');
    //                 } else {
    //                     return ''
    //                 }
    //             }
    //         }
    //     }]
    // }
    div: {
        class: [
            function (e) {
                return "divTableCell".split(" ");
            }],
        attributes: {
            'data-head': function (e) { return e.headerTitle; }
        }
        ,
        childrens: [{
            span: {
                class: ["label", function (e) {
                    return this.islate == 1 ? "text-red" : '';
                }],
                childrens: [{
                    text: {
                        text: function (e) {
                            if (this.DueDate != null) {
                                var date = new Date(this.DueDate);
                                var storageHelper = new BrowserStorage();
                                return formatDate(date, storageHelper.local.get('dateFormat', false), 'en-US');
                            } else {
                                return ''
                            }
                        }
                    }
                }]
            }
        }]
    }
}

