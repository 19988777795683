import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractProjectRFQHRC } from '../domain/abstract-project-r-f-q-hrc';
import { ProjectRFQ } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { Query, SpParameter, PageDataQuery, SearchQuery } from 'src/app/viewModel/sp-parameter';
import { vProjectRFQHRC } from 'src/app/models/extended-models/v-project-r-f-q-hrc';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { RoutingKeys, ToolTipText } from 'src/app/components/shared/constant/commonConstants';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { Router } from '@angular/router';
import { CommonHelperService } from 'src/app/components/shared/common-helpers/helper.service';
import { ProjectRFQStatusEnum } from 'src/app/enums/project-r-f-q-status.enum';
import { StatusEnum } from 'src/app/enums/status.enum';
import { access } from "@rxweb/angular-router";
import { ApplicationModules, PermissionNames } from "src/app/enums/application-modules.enum";
import { ModelComponentType, ModelResponse, ProjectRFQDeadlineDateText } from "src/app/components/shared/constant/common-constants";
declare const $: any;

@access({ accessLevel: ApplicationModules.ProjectRFQResponse, action: PermissionNames.Get })

@Component({
    selector: "app-project-r-f-q-hrc-list",
    templateUrl: './project-r-f-q-hrc-list.component.html'
})
export class ProjectRFQHRCListComponent extends AbstractProjectRFQHRC implements OnInit, OnDestroy {
    projectRFQHRC: List<ProjectRFQ>;
    subscription: Subscription;
    projectRFQHRCGrid: AppGrid;
    modalView: ModalView;
    searchText = '';
    totalRecordsPerPage = 10;
    pageIndex = 1;
    orderByColumn = 'projectRFQId';
    sortOrder = 'desc';
    isGridBound = true;
    deadlineText = ProjectRFQDeadlineDateText.DeadlineText;

    constructor(
        private route: Router,
        private commonHelperService: CommonHelperService,
        modalView: ModalView

    ) {
        super();
        this.modalView = modalView;
    }

    ngOnInit(): void {
        this.initializeGridforSP();
    }

    initializeGridforSP() {
        const jsonToSend = this.getJsonToSend();
        if (this.isGridBound && !this.commonHelperService.isObjectNullOrUndefined(this.projectRFQHRCGrid)) {
            this.projectRFQHRCGrid.clearGrid('projectRFQHRC');
        }
        this.spin = true;
        this.showComponent = true;
        let sendJson = new Query();
        sendJson.query = JSON.stringify(jsonToSend);
        this.subscription = this.getProjectRFQList(sendJson).subscribe((projectRFQHRCList: any) => {
            this.spin = false;
            this.bindGrid(projectRFQHRCList);
        }, () => {
            this.spin = false;
        });
    }

    getJsonToSend(): SpParameter {
        const pageQuery = new PageDataQuery();
        pageQuery.PageRow = this.totalRecordsPerPage;
        pageQuery.PageIndex = this.pageIndex;
        pageQuery.OrderColumn = this.orderByColumn;
        pageQuery.OrderType = this.sortOrder;

        const jsonObject = new SpParameter();
        jsonObject.searchQuery = new SearchQuery();
        jsonObject.searchQuery.searchText = this.searchText;
        jsonObject.pageQuery = JSON.stringify(pageQuery);
        jsonObject.searchQuery = JSON.stringify(jsonObject.searchQuery);
        return jsonObject;
    }

    bindGrid(data: any) {
        const parsedData = JSON.parse(data)[0].result;
        const gridData = parsedData.data;
        const totalRecords = parsedData.footer[0].totalCount;
        if (this.isGridBound) {
            this.projectRFQHRCGrid = new AppGrid(gridData, vProjectRFQHRC
                , {
                    actions: {
                        onMesaageView: this.onMesaageView.bind(this),
                        onApprove: this.onApprove.bind(this),
                        onReject: this.onReject.bind(this),
                        onHROLink: this.onHROLink.bind(this),
                        onProjectLink: this.onProjectLink.bind(this),
                    }
                }
            )
        }
        this.projectRFQHRCGrid.authorization = {
            'get': { accessLevel: ApplicationModules.ProjectRFQResponse, action: PermissionNames.Get }
        }
        this.projectRFQHRCGrid.storeProcedure = {
            length: totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            onPageSorting: this.onPageSorting.bind(this),
            nextPage: this.pageIndex
        };

        this.projectRFQHRCGrid.updateSource([]);

        if (this.isGridBound) {
            this.projectRFQHRCGrid.design(document.getElementById('projectRFQHRC'));
            this.isGridBound = false;
        }
        this.projectRFQHRCGrid.updateSource(gridData);
        this.addTooltip();
    }

    onMesaageView(projectRFQHRC: any) {
        this.route.navigate([RoutingKeys.Messages, projectRFQHRC.projectRFQId]);
    }

    onHROLink(projectRFQHRC: any) {
        //if (projectRFQHRC.projectRFQStatus == "Approved" || projectRFQHRC.projectRFQStatus=="Interested")
            this.route.navigateByUrl("hro-management/rfqHRODetails/" + projectRFQHRC.hROId);
    }

    onProjectLink(projectRFQHRC: any) {
        //if(projectRFQHRC.projectRFQStatus == "Approved")
        this.route.navigateByUrl("projects/projectDetailsHRC/" + projectRFQHRC.projectId);
    }

    onApprove(projectRFQHRC: ProjectRFQ) {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Interested }).then(t => {
            if (t == ModelResponse.Yes) {
                this.spin = true;
                this.get({ params: [projectRFQHRC.projectRFQId] }).subscribe((p: ProjectRFQ) => {
                    p.projectRFQStatus = ProjectRFQStatusEnum.Interested;
                    p.statusId = StatusEnum.Active;
                    this.put({ params: [projectRFQHRC.projectRFQId], body: p }).subscribe(response => {
                        this.spin = false;
                        this.toastr.success(CustomMessage.rfqInterested);
                        this.initializeGridforSP();
                    })
                })
            }
        });
    }

    onReject(projectRFQHRC: ProjectRFQ) {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Notinterested}).then(t => {
            if (t == ModelResponse.Yes) {
                this.spin = true;
                this.get({ params: [projectRFQHRC.projectRFQId] }).subscribe((p: ProjectRFQ) => {
                    p.projectRFQStatus = ProjectRFQStatusEnum.NotInterested;
                    p.statusId = StatusEnum.Active;
                    this.put({ params: [projectRFQHRC.projectRFQId], body: p }).subscribe(response => {
                        this.spin = false;
                        this.toastr.success(CustomMessage.rfqNotInterested);
                        this.initializeGridforSP();
                    })
                })
            }
        });
    }

    onPageChanging(nextPage: number) {
        this.totalRecordsPerPage = this.projectRFQHRCGrid.maxPerPage;
        this.pageIndex = nextPage;
        this.initializeGridforSP();
    }

    onPageSorting(columnName: string, isAscendingOrder: boolean, currentPage: number) {
        this.orderByColumn = columnName;
        this.sortOrder = isAscendingOrder ? 'asc' : 'desc';
        this.pageIndex = 1;
        this.initializeGridforSP();
    }

    search(value) {
        this.searchText = value;
        this.pageIndex = 1;
        this.initializeGridforSP();
    }

    addTooltip() {
        $(document).ready(function () {
            $(".message_notify").hover(function () {
                $(this).attr("title", ToolTipText.MessageHRO);
            })
            $(".gridBluebtn").hover(function () {
                $(this).attr("title", ToolTipText.MessageHRO);
            })
            $(".gridEditbtn").hover(function () {
                $(this).attr("title", ToolTipText.InterestedRFQ);
            })
            $(".gridDeletebtn").hover(function () {
                $(this).attr("title", ToolTipText.NotInterstedRFQ);
            })
        })
    }

    openTips() {
        let msg = new Array<any>();
        msg[0] = 'Please prepare and upload your proposal by selecting the message icon. Select an action to proceed or message the HRO with questions.'
        msg[1] = 'Once your proposal has been reviewed, you will receive a message regarding the next step.'
        msg[2] = this.deadlineText;
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.PopupMessage, params: msg }).then(t => {
        })
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}
