import {ContractBase} from '../database-models/contract-base';
import {ProjectBase} from '../database-models/project-base';
import {UserBase} from '../database-models/user-base';
//Generated Imports
export class Contract extends ContractBase 
{




//#region Generated Reference Properties
//#region project Prop
project : ProjectBase[];
//#endregion project Prop
//#region user Prop
users : UserBase[];
//#endregion user Prop

//#endregion Generated Reference Properties














}