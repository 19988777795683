import { http, RxHttp } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { UploadAttachment, USCompliance, USComplianceDocument } from '@app/models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { CoreComponent } from '@rxweb/angular-router';
import { USComplianceViewModel } from 'src/app/viewModel/us-compliance-view-model';
import { Query } from 'src/app/viewModel/sp-parameter';
import { FormGroup } from '@angular/forms';
import { API } from "src/app/components/shared/constant/administrationAPIContants";

@http({
    path: "api/UploadAttachments",
})

export class AbstractUploadAttachment extends CoreComponent {
    usComplianceFormGroup: FormGroup;
    uploadAttachmentFormGroup: FormGroup;
    toastr: BaseToastr;
    spin = false;
    breadCrumb: BreadCrumb;
    dialog: BaseDialog;
    showComponent: boolean = false;
    rxHttp: RxHttp;

    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.badRequest;
        this.rxHttp = new RxHttp();
    }
    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard'
        this.breadCrumb.name1 = "Home"
        // this.breadCrumb.title = 'US Compliance'
        // this.breadCrumb.pageName = 'US Compliance'
        this.breadCrumb.title = 'HR Compliance Forms'
        this.breadCrumb.pageName = 'HR Compliance Forms'
        this.breadCrumb.pageRedirect = "us-compliance";
    }

    badRequest = (data) => {
        this.spin = false;
        let messages = JSON.parse(data);
        this.toastr.warning(messages[0]);
    }

    getUserData(searchParams: Query) {
        return this.rxHttp.post({ body: searchParams, path: API.searchComplianceCategory });
    }

    getDocument(searchParams: Query) {
        return this.rxHttp.post({ body: searchParams, path: API.document });
    }

    getUSComplianceId(categoryId, subCategoryId) {
        return this.rxHttp.get({ queryParams: { 'categoryId': categoryId, 'subCategoryId': subCategoryId }, path: API.searchComplianceCategory_USCompId });
    }
} 