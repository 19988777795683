import {CityLookupBase} from '../database-models/city-lookup-base';
//Generated Imports
export class CityLookup extends CityLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties



























}