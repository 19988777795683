import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { State, Attachment, USCompliance, USComplianceDocument } from '@app/models';
import { AbstractUploadAttachment } from '../domain/abstract-upload-attachment';
import { MasterLookupGroup } from 'src/app/viewModel/master-lookup-group';
import { MASTER_LOOKUPS } from 'src/app/uris/master-lookups.uri';
import { Router } from '@angular/router';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { RoutingKeys, StorageKeys } from 'src/app/components/shared/constant/commonConstants';
import { USComplianceViewModel } from 'src/app/viewModel/us-compliance-view-model';
import { USComplianceDocumentBase } from '@app/database-models';
import { FormArray } from '@angular/forms';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { StatusEnum } from 'src/app/enums/status.enum';
import { USComplianceCategoryEnum } from 'src/app/enums/u-s-compliance-category.enum';
import { DownloadFile } from "src/app/components/shared/constant/download-file";
import { access } from "@rxweb/angular-router";
import { ApplicationModules, PermissionNames } from "src/app/enums/application-modules.enum";
import { API } from "src/app/components/shared/constant/administrationAPIContants";
import { ModelComponentType, ModelResponse } from "src/app/components/shared/constant/common-constants";
import { Parser } from "@angular/compiler/src/ml_parser/parser";

@access({ accessLevel: ApplicationModules.HRComplianceForms, action: PermissionNames.Post })

@Component({
    selector: "app-upload-attachment-add",
    templateUrl: './upload-attachment-add.component.html'
})
export class UploadAttachmentAddComponent extends AbstractUploadAttachment implements OnInit, OnDestroy {
    usCompliance: USComplianceViewModel;
    subscription: Subscription;
    masterLookupGroup: MasterLookupGroup;
    fileType: string;
    isCategorySelected: boolean = false;
    attachmentArray: USComplianceDocument[] = [];
    isComplianceLookup: boolean = false;
    isStateLookup: boolean = false;
    isTitle: boolean = false;
    uSComplianceDocument: any;
    isDocument: boolean = false;
    isURL: boolean = false;
    modalView: ModalView;
    addButton: boolean = false;
    selectedFilename: string;
    backupUSComplianceDocument;
    usComplianceId: number = 0;
    currentFormGroup: any;

    subCategory: string;
    category: string;
    isrecordDeleted: boolean = false;

    constructor(private formBuilder: RxFormBuilder, private route: Router, modalView: ModalView) {
        super();
        this.modalView = modalView;
    }

    ngOnInit(): void {
        this.spin = false;
        this.usCompliance = new USComplianceViewModel();
        this.attachmentArray = new Array<USComplianceDocument>();
        this.lookup<MasterLookupGroup>(
            [
                { path: MASTER_LOOKUPS.complianceCategoryLookup, propName: 'complianceCategoryLookup' },
                { path: MASTER_LOOKUPS.hRLookup, propName: 'hRLookup' },
                { path: MASTER_LOOKUPS.stateLookup, propName: 'stateLookup' }
            ]).subscribe((response: any) => {
                this.usCompliance.uSComplianceDocument = new Array<USComplianceDocument>();
                this.uSComplianceDocument = new USComplianceDocument();
                //  this.usCompliance.uSComplianceDocument.push(this.uSComplianceDocument);
                this.usComplianceFormGroup = this.formBuilder.formGroup(this.usCompliance);
                this.masterLookupGroup = response;
                this.setDefaultValues();
                this.showComponent = true;
                this.spin = false;
            })
    }

    addUSComplianceDocument(isaddNewDocument: boolean = false) {
        var compliance = new USComplianceDocument();
        compliance.isDocument = this.isDocument;
        if (this.backupUSComplianceDocument && Object.keys(this.backupUSComplianceDocument).length != 0) {
            compliance.attachmentBase64String = this.backupUSComplianceDocument.attachmentBase64String;
            compliance.contentType = this.backupUSComplianceDocument.contentType;
            compliance.documentName = this.backupUSComplianceDocument.documentName;
            compliance.documentUrl = this.backupUSComplianceDocument.documentUrl;
            compliance.isDocumentUpdated = this.backupUSComplianceDocument.isDocumentUpdated;
            compliance.title = this.backupUSComplianceDocument.title;
            compliance.urlLink = this.backupUSComplianceDocument.urlLink;
            compliance.usComplianceDocumentId = this.backupUSComplianceDocument.usComplianceDocumentId;
            compliance.usComplianceId = this.backupUSComplianceDocument.usComplianceId;
        }
        var fomrGroup = this.formBuilder.formGroup(compliance);
        this.uSComplianceDocument = <FormArray>this.usComplianceFormGroup.controls.uSComplianceDocument;
        this.isURL = this.isURL;

        //this.backupUSComplianceDocument
        if (isaddNewDocument || (this.backupUSComplianceDocument && Object.keys(this.backupUSComplianceDocument).length != 0)) {
            this.uSComplianceDocument.push(fomrGroup);
            this.backupUSComplianceDocument = new USComplianceDocument();
        }
    }

    addNewDocument(isaddNewDocument: boolean = false) {
        if (this.usComplianceFormGroup.controls.uSComplianceDocument.value.length == 1 && this.usComplianceId == 0) {
            this.addUploadAttachments(isaddNewDocument);
        }
        else if (this.usComplianceFormGroup.controls.uSComplianceDocument.value.length == 0) {
            var compliance = new USComplianceDocument();
            compliance.isDocument = this.isDocument;
            var fomrGroup = this.formBuilder.formGroup(compliance);
            this.uSComplianceDocument = <FormArray>this.usComplianceFormGroup.controls.uSComplianceDocument;
            this.isURL = this.isURL;
            this.uSComplianceDocument.push(fomrGroup);
        }
        else {
            this.editUploadAttachments(isaddNewDocument);
        }
    }

    removeUSComplianceDocument(index) {
        let isnewobject = (this.uSComplianceDocument.value[index].usComplianceDocumentId == null || this.uSComplianceDocument.value[index].usComplianceDocumentId == 0) ? true : false;
        let chkobj = this.uSComplianceDocument.value.filter(p => p.usComplianceDocumentId != null && p.usComplianceDocumentId != 0);
        if (chkobj.length > 1 || isnewobject) {
            this.uSComplianceDocument.removeAt(index, 1);
            this.backupUSComplianceDocument = this.attachmentArray.find(p => p.usComplianceDocumentId == 0);
            this.attachmentArray.splice(index, 1);
            this.isrecordDeleted = true;
            let newRecindex = this.uSComplianceDocument.value.findIndex(p => p.usComplianceDocumentId == null);
            if (newRecindex > -1)
                this.uSComplianceDocument.removeAt(newRecindex, 1);
            this.editUploadAttachments();
        }
        else{
            this.toastr.error(CustomMessage.atleastOne);
        }
    }

    handleFileSelect(evt, i) {
        this.fileType = "";
        this.selectedFilename = this.uSComplianceDocument.controls[i].value.documentName.split('\\')[2]
        this.uSComplianceDocument.controls[i].controls.documentName.setValue(this.selectedFilename);
        var selectedFile = evt.target.files[0];

        this.selectedFilename = selectedFile.name
        let ext = selectedFile.name.split('.').pop(),
            fileType = selectedFile.type;

        var downloadFileObj = new DownloadFile();
        var isFilesValid = downloadFileObj.fileValidation(selectedFile);
        if (selectedFile && isFilesValid) {
            this.fileType = ext;
            var reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onload = this._handleReaderLoaded.bind(this, this.selectedFilename, i);
        }
        else {
            this.uSComplianceDocument.controls[i].controls.documentName.setValue(null);
            this.uSComplianceDocument.controls[i].controls.documentName.setValidators([RxwebValidators.required()]);
            this.uSComplianceDocument.controls[i].controls.documentName.updateValueAndValidity();
        }
    }

    _handleReaderLoaded(fileName, i, readerEvt) {
        var binaryString = readerEvt.target.result;
        this.currentFormGroup = this.attachmentArray[i];
        var firstIndex = binaryString.indexOf("data:") + 5;
        var lastIndex = binaryString.indexOf(";base64,");
        var contentType = binaryString.substring(firstIndex, lastIndex);



        if (this.currentFormGroup && this.currentFormGroup.usComplianceDocumentId > 0) {
            this.attachmentArray[i].attachmentBase64String = binaryString.split(";base64,")[1];
            this.attachmentArray[i].documentName = fileName;
            this.attachmentArray[i].isDocumentUpdated = true;
            this.attachmentArray[i].contentType = contentType;
        }
        else {
            var fileModel = new USComplianceDocument();
            fileModel.contentType = contentType;
            fileModel.usComplianceDocumentId = 0;
            fileModel.documentName = fileName;
            fileModel.attachmentBase64String = binaryString.split(";base64,")[1];
            if (this.attachmentArray.length == 0)
                this.attachmentArray[i] = fileModel;

            this.attachmentArray[i].usComplianceDocumentId = 0;
            this.attachmentArray[i].documentName = fileName;
            this.attachmentArray[i].usComplianceId = this.usComplianceId;
            this.attachmentArray[i].attachmentBase64String = binaryString.split(";base64,")[1];
            this.attachmentArray[i].isDocumentUpdated = false;
            this.attachmentArray[i].contentType = contentType;
        }

    }

    setDefaultValues() {
        this.usComplianceFormGroup.patchValue({
            "statusId": StatusEnum.Active,
            "createdOn": new Date(),
            "createdBy": this.user.userId,
            "uSComplianceId": 0
        });
    }

    addUploadAttachments(isaddNewDocument: boolean = false) {
        this.spin = true;
        if (this.usComplianceFormGroup.value.uSComplianceDocument.length == 0) {
            this.spin = false;
            this.toastr.error(CustomMessage.atleastOne);
        }
        else {
            if (this.attachmentArray.length > 0) {
                for (let index = 0; index < this.attachmentArray.length; index++) {
                    // this.uSComplianceDocument.controls[index].controls.documentName.value = this.attachmentArray[index].attachments;
                    this.uSComplianceDocument.value[index].documentName = this.attachmentArray[index].documentName;
                    this.uSComplianceDocument.value[index].contentType = this.attachmentArray[index].contentType;
                    this.uSComplianceDocument.value[index].isDocument = this.isDocument;
                    this.uSComplianceDocument.value[index].usComplianceDocumentId = 0;
                    this.uSComplianceDocument.value[index].usComplianceId = 0;
                    this.uSComplianceDocument.value[index].attachmentBase64String = this.attachmentArray[index].attachmentBase64String;
                }
            }

            if (this.uSComplianceDocument.length > 0) {
                for (let index = 0; index < this.uSComplianceDocument.length; index++) {
                    this.uSComplianceDocument.value[index].isDocument = this.isDocument;
                    this.uSComplianceDocument.value[index].usComplianceDocumentId = 0;
                    this.uSComplianceDocument.value[index].usComplianceId = 0;
                }
            }

            if (this.usComplianceFormGroup.valid) {
                this.post({ body: this.usComplianceFormGroup.value }).subscribe((response: number) => {
                    this.usComplianceId = response;
                    this.spin = false;
                    this.toastr.success(CustomMessage.dataAdded);
                    this.getCoplianceList(isaddNewDocument)
                })
            }
            else {
                this.spin = false;
                this.usComplianceFormGroup.markAllAsTouched();
            }
        }
    }

    getCoplianceList(isaddNewDocument: boolean = false) {
        this.get({ params: [this.usComplianceId] }).subscribe((t: any) => {

            let viewModel = new USComplianceViewModel();
            this.setValues(t, viewModel);
            this.usComplianceFormGroup = this.formBuilder.formGroup(USComplianceViewModel, viewModel) as IFormGroup<USComplianceViewModel>;
            this.usComplianceFormGroup.patchValue({
                "statusId": StatusEnum.Active,
            });
            this.addUSComplianceDocument(isaddNewDocument);

            this.getCategoryValue();
            this.getSubCategoryValue(t);
            this.spin = false;
            this.showComponent = true;
        })
    }

    getSubCategoryValue(t) {
        if (t.stateId != null) {
            this.masterLookupGroup.stateLookup.forEach(x => {
                if (x.stateId == this.usComplianceFormGroup.controls.stateId.value) {
                    this.subCategory = x.stateName;
                }
            });
        }
        else if (t.hrComplianceId != null) {
            this.masterLookupGroup.hRLookup.forEach(x => {
                if (x.hrComplianceId == this.usComplianceFormGroup.controls.hrComplianceId.value) {
                    this.subCategory = x.hrComplianceName;
                }
            });
        }
    }

    getCategoryValue() {
        this.masterLookupGroup.complianceCategoryLookup.forEach(x => {
            if (x.applicationObjectId == this.usComplianceFormGroup.controls.categoryId.value) {
                this.category = x.category;
            }
        });
    }

    setValues(t, viewModel) {
        // this.uSComplianceDocument = t.usComplianceDocument;
        this.attachmentArray = t.usComplianceDocument;
        viewModel.uSComplianceDocument = t.usComplianceDocument
        viewModel.uSComplianceId = t.usComplianceId;
        viewModel.categoryId = t.categoryId;
        viewModel.hrComplianceId = t.hrComplianceId;
        viewModel.stateId = t.stateId;

        if (t.hrComplianceId != null) {
            this.isDocument = true;
            this.isComplianceLookup = true;
            this.isStateLookup = false;

        }
        else if (t.stateId != null) {
            this.isDocument = true;
            this.isComplianceLookup = false;
            this.isStateLookup = true;
        }
        else if (t.usComplianceDocument[0].urlLink != null) {
            this.isDocument = false;
            this.isComplianceLookup = false;
            this.isStateLookup = false;
            this.isURL = true;
        }
    }

    editUploadAttachments(isaddNewDocument: boolean = false) {
        this.spin = true;
        if (this.usComplianceFormGroup.value.uSComplianceDocument.length == 0) {
            this.spin = false;
            this.toastr.error(CustomMessage.atleastOne);
        }
        else {
            if (this.attachmentArray.length > 0) {
                for (let index = 0; index < this.attachmentArray.length; index++) {
                    this.uSComplianceDocument.value[index].documentName = this.attachmentArray[index].documentName;
                    this.uSComplianceDocument.value[index].isDocument = this.isDocument;
                    this.uSComplianceDocument.value[index].usComplianceDocumentId = this.attachmentArray[index].usComplianceDocumentId;
                    this.uSComplianceDocument.value[index].usComplianceId = this.usComplianceId;
                    this.uSComplianceDocument.value[index].attachmentBase64String = this.attachmentArray[index].attachmentBase64String;
                    this.uSComplianceDocument.value[index].isDocumentUpdated = this.attachmentArray[index].usComplianceDocumentId > 0 ? true : false;
                    this.uSComplianceDocument.value[index].contentType = this.attachmentArray[index].contentType;
                }
            }

            if (this.usComplianceFormGroup.valid) {
                this.put({ params: [this.usComplianceId], body: this.usComplianceFormGroup.value }).subscribe(response => {
                    this.spin = false;
                    if (!this.isrecordDeleted)
                        this.toastr.success(CustomMessage.dataAdded);
                    else
                        this.toastr.success(CustomMessage.dataDeleted);

                    this.getCoplianceList(isaddNewDocument);
                    this.isrecordDeleted = false;
                })
            }
            else {
                this.usComplianceFormGroup.markAllAsTouched();
            }
            this.spin = false;
        }
    }

    selectCategory(value) {
        this.addButton = true;
        this.usComplianceFormGroup.controls.uSComplianceDocument.reset();
        this.attachmentArray = new Array<USComplianceDocument>();

        var length = this.uSComplianceDocument.length;
        for (var index = 0; index < length; index++) {
            this.uSComplianceDocument.removeAt(0);
        }

        if (value.sourceItem.applicationObjectId == USComplianceCategoryEnum.HumanResourceComplianceFormFiles) {
            this.isStateLookup = false;
            this.isComplianceLookup = true;
            this.isURL = false;
            this.isDocument = true;
            this.usComplianceFormGroup.value.categoryId = value.sourceItem.applicationObjectId;
        }
        else if (value.sourceItem.applicationObjectId == USComplianceCategoryEnum.StateLawAndForms) {
            this.isComplianceLookup = false;
            this.isStateLookup = true;
            this.isURL = false;
            this.isDocument = true;
            this.usComplianceFormGroup.value.categoryId = value.sourceItem.applicationObjectId;
            this.masterLookupGroup.stateLookup = this.masterLookupGroup.stateLookup.filter(t => t.countryId == StorageKeys.USACountryId);
        }
        else if (value.sourceItem.applicationObjectId == USComplianceCategoryEnum.AdditionalOnlineFormsAndPostersLibrary) {
            // this.get({ path: API.category }).subscribe(response => {
            //     if (response == true) {
            //         //this.uSComplianceDocument.controls.title.setValue(null);
            //         this.usComplianceFormGroup.controls.categoryId.setValue(null);
            //         this.usComplianceFormGroup.controls.categoryId.updateValueAndValidity();
            //         this.addButton = false;
            //         this.toastr.warning(CustomMessage.categoryAdded);
            //     }
            //     else {
            //         this.isStateLookup = false;
            //         this.isComplianceLookup = false;
            //         this.isDocument = false;
            //         this.isURL = true;
            //     }
            // })
            this.showConformationPopup(this.usComplianceFormGroup.value.categoryId, 0);
        }
    }

    selectCompliance(value) {
        this.usComplianceFormGroup.value.hRComplianceId = value.sourceItem.hrComplianceId;
        this.usComplianceFormGroup.controls.stateId.setValue(null);
        this.showConformationPopup(this.usComplianceFormGroup.value.categoryId, this.usComplianceFormGroup.value.hrComplianceId);
    }

    selectState(value) {
        this.usComplianceFormGroup.value.stateId = value.sourceItem.stateId;
        this.usComplianceFormGroup.controls.hrComplianceId.setValue(null);
        this.showConformationPopup(this.usComplianceFormGroup.value.categoryId, this.usComplianceFormGroup.value.stateId);
    }

    showConformationPopup(categoryId, subCategoryId) {
        this.getUSComplianceId(categoryId, subCategoryId).subscribe((USComplianceId: number) => {
            this.spin = false;
            if (USComplianceId > 0) {
                this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.ComplianceCategoryChange }).then(t => {
                    if (t == ModelResponse.Yes) {
                        this.spin = true;
                        this.route.navigate([RoutingKeys.UploadAttachments, USComplianceId]);
                    }
                    else {
                        this.addButton = false;
                    }
                });
            }
            else {
                this.addButton = true;
                if (categoryId == USComplianceCategoryEnum.AdditionalOnlineFormsAndPostersLibrary) {
                    this.isStateLookup = false;
                    this.isComplianceLookup = false;
                    this.isDocument = false;
                    this.isURL = true;
                }
            }
        }, () => {
            this.spin = false;
        });
    }



    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}