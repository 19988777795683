//import {vQuoteMessageListBase} from '../database-models/v-quote-message-list-base';
//Generated Imports
export class vQuoteMessageList 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




}