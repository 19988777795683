import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHRCRecordBase {

//#region userId Prop
        @gridColumn({visible: true, columnIndex:15, allowSorting: true, headerKey: 'userId', keyColumn: true})
        userId : number;
//#endregion userId Prop


//#region roleId Prop
        @gridColumn({visible: true, columnIndex:14, allowSorting: true, headerKey: 'roleId', keyColumn: false})
        roleId : any;
//#endregion roleId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:13, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region companyName Prop
        @gridColumn({visible: true, columnIndex:12, allowSorting: true, headerKey: 'companyName', keyColumn: false})
        companyName : string;
//#endregion companyName Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:11, allowSorting: true, headerKey: 'email', keyColumn: false})
        email : string;
//#endregion email Prop


//#region isPlanCancelled Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'isPlanCancelled', keyColumn: false})
        isPlanCancelled : any;
//#endregion isPlanCancelled Prop


//#region linkedInUser Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'linkedInUser', keyColumn: false})
        linkedInUser : any;
//#endregion linkedInUser Prop


//#region expertize Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'expertize', keyColumn: false})
        expertize : string;
//#endregion expertize Prop


//#region userDetailsId Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'userDetailsId', keyColumn: false})
        userDetailsId : any;
//#endregion userDetailsId Prop


//#region headline Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'headline', keyColumn: false})
        headline : string;
//#endregion headline Prop


//#region photo Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'photo', keyColumn: false})
        photo : string;
//#endregion photo Prop


//#region summary Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'summary', keyColumn: false})
        summary : string;
//#endregion summary Prop


//#region achievements Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'achievements', keyColumn: false})
        achievements : string;
//#endregion achievements Prop


//#region photoUrl Prop
        @gridColumn({visible: true, columnIndex:13, allowSorting: true, headerKey: 'photoUrl', keyColumn: false})
        photoUrl : string;
//#endregion photoUrl Prop


//#region cityId Prop
        @gridColumn({visible: true, columnIndex:12, allowSorting: true, headerKey: 'cityId', keyColumn: false})
        cityId : any;
//#endregion cityId Prop


//#region stateId Prop
        @gridColumn({visible: true, columnIndex:13, allowSorting: true, headerKey: 'stateId', keyColumn: false})
        stateId : any;
//#endregion stateId Prop


//#region countryId Prop
        @gridColumn({visible: true, columnIndex:14, allowSorting: true, headerKey: 'countryId', keyColumn: false})
        countryId : any;
//#endregion countryId Prop


//#region applicationLocaleId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'applicationLocaleId', keyColumn: false})
        applicationLocaleId : number;
//#endregion applicationLocaleId Prop


//#region applicationTimeZoneId Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'applicationTimeZoneId', keyColumn: false})
        applicationTimeZoneId : number;
//#endregion applicationTimeZoneId Prop


//#region languageCode Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'languageCode', keyColumn: false})
        languageCode : string;
//#endregion languageCode Prop


//#region createdBy Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'createdBy', keyColumn: true})
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop


//#region companySizeId Prop
        @gridColumn({visible: true, columnIndex:19, allowSorting: true, headerKey: 'companySizeId', keyColumn: false})
        companySizeId : any;
//#endregion companySizeId Prop


//#region yearsOfExperienceId Prop
        @gridColumn({visible: true, columnIndex:20, allowSorting: true, headerKey: 'yearsOfExperienceId', keyColumn: false})
        yearsOfExperienceId : any;
//#endregion yearsOfExperienceId Prop


//#region subscriptionPlanId Prop
        @gridColumn({visible: true, columnIndex:22, allowSorting: true, headerKey: 'subscriptionPlanId', keyColumn: false})
        subscriptionPlanId : any;
//#endregion subscriptionPlanId Prop


//#region startDate Prop
        @gridColumn({visible: true, columnIndex:23, allowSorting: true, headerKey: 'startDate', keyColumn: false})
        startDate : any;
//#endregion startDate Prop


//#region endDate Prop
        @gridColumn({visible: true, columnIndex:24, allowSorting: true, headerKey: 'endDate', keyColumn: false})
        endDate : any;
//#endregion endDate Prop


//#region paymentStatusId Prop
        @gridColumn({visible: true, columnIndex:26, allowSorting: true, headerKey: 'paymentStatusId', keyColumn: false})
        paymentStatusId : any;
//#endregion paymentStatusId Prop

}