import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class MonthlyRetainedOptionLookupBase {

//#region monthlyRetainedOptionId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'monthlyRetainedOptionId', keyColumn: true})
        monthlyRetainedOptionId : number;
//#endregion monthlyRetainedOptionId Prop


//#region monthlyRetainedOptionName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'monthlyRetainedOptionName', keyColumn: false})
        monthlyRetainedOptionName : string;
//#endregion monthlyRetainedOptionName Prop

}