import { RolesEnum } from 'src/app/enums/roles-enum';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { ProjectDetail } from './../../../../models/extended-models/project-detail';
import { Component, OnInit, OnDestroy } from "@angular/core";
import { AbstractProject } from '../domain/abstract-project';
import { OptionDetail, Project, vProjectList } from "@app/models";
import { Subscription } from 'rxjs';
import { RoutingKeys, StorageKeys, ToolTipText } from 'src/app/components/shared/constant/commonConstants';
import { Router } from '@angular/router';
import { AppGrid } from 'src/app/domain/app-grid';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { CommonHelperService } from 'src/app/components/shared/common-helpers/helper.service';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { ApplicationModules, PermissionNames } from 'src/app/enums/application-modules.enum';
import { access } from '@rxweb/angular-router';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { ProjectAddComponent } from '../add/project-add.component';
import { ModelComponentType, ModelResponse } from 'src/app/components/shared/constant/common-constants';
declare const $: any;

@access({ accessLevel: ApplicationModules.MyProject, action: PermissionNames.Get })

@Component({
    selector: "app-project-list",
    templateUrl: './project-list.component.html'
})
export class ProjectListComponent extends AbstractProject implements OnInit, OnDestroy {
    projectAdd: any = ProjectAddComponent;

    projects: ProjectDetail[];
    subscription: Subscription;
    optionArray: OptionDetail[];
    projectGrid: AppGrid;
    projectGridColumns: any[];
    searchText = '';
    modalView: ModalView;
    roleId: number;
    storage: BrowserStorage;
    isRole: boolean = true;
    constructor(private route: Router,
        private commonHelperService: CommonHelperService,
        modalView: ModalView) {
        super()
        this.modalView = modalView
        this.storage = new BrowserStorage();
    }

    ngOnInit(): void {
        this.roleId = this.storage.local.get(StorageKeys.UserRole);
        if (RolesEnum.HRO == this.roleId) {
            this.isRole = false;
        }
        this.initializeGrid();
    }
    initializeGrid() {
        if (!this.commonHelperService.isObjectNullOrUndefined(this.projectGrid)) {
            this.projectGrid.clearGrid('project');
        }
        this.spin = true;
        this.showComponent = true;
        this.subscription = this.get({ queryParams: { 'Page': ["listPage"] } }).subscribe((projectList: any) => {
            this.projectGrid = new AppGrid(projectList, vProjectList
                , {
                    actions: {
                        onEdit: this.onEdit.bind(this),
                        onDelete: this.onDelete.bind(this),
                        onProjectLink: this.onProjectLink.bind(this),
                        onLink: this.onLink.bind(this)
                    }
                }
            );

            this.projectGrid.authorization = {
                'put': { accessLevel: ApplicationModules.MyProject, action: PermissionNames.Put },
                'delete': { accessLevel: ApplicationModules.MyProject, action: PermissionNames.Delete },
                'get': { accessLevel: ApplicationModules.MatchingHRCList, action: PermissionNames.Get }
            }

            this.projectGrid.design(document.getElementById('project'));
            this.projectGridColumns = this.projectGrid.gridColumns.filter(x => x.keyColumn === false);
            this.projectGrid.search = this.searchText;
            this.spin = false;
            this.addTooltip();
        });
    }


    search(value) {
        this.projectGrid.search = value;
        this.searchText = this.projectGrid.search;
    }

    addTooltip() {
        $(document).ready(function () {

            $(".gridEditbtn").hover(function () {
                $(this).attr("title", ToolTipText.EditProject);
            })
            $(".gridDeletebtn").hover(function () {
                $(this).attr("title", ToolTipText.DeleteProject);
            })
            $(".gridPinkbtn").hover(function () {
                $(this).attr("title", ToolTipText.ViewMatchingHRC);
            })
        })
    }

    onEdit(project: Project) {
        this.route.navigate([RoutingKeys.Project, project.projectId]);
    }
    onDelete(project: Project) {
        this.modalView.show(ConfirmationModalComponent, { componentType: ModelComponentType.Delete }).then(t => {
            if (t == ModelResponse.Yes) {
                this.spin = true;
                this.delete({ params: [project.projectId], body: null }).subscribe(response => {
                    this.spin = false;
                    this.toastr.success(CustomMessage.dataDeleted);
                    this.initializeGrid();
                });
            }
        });
    }
    onProjectLink(project: any) {
        this.route.navigateByUrl("projects/details/" + project.projectId)
    }

    onLink(project: any) {
        this.route.navigateByUrl("projects/matchingHRC/" + project.projectId)
    }

    addProject() {
        this.route.navigate([RoutingKeys.AddProject]);
    }


    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

}
