import { prop,propObject,propArray,required,maxLength,range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class PublicRFPBase {

//#region publicRFPId Prop
        @prop()
        publicRFPId : number;
//#endregion publicRFPId Prop


//#region publicRFPTitle Prop
        @required()
        @maxLength({ value: 100 })
        @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'publicRFPTitle', keyColumn: false, headerTitle: "Title" })
        publicRFPTitle : string;
//#endregion publicRFPTitle Prop


//#region url Prop
        @required()
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'url', keyColumn: false, headerTitle: "Url" })
        url : string;
//#endregion url Prop


//#region statusId Prop
        @prop()
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @prop()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop


//#region deadlineDate Prop
        @prop()
        deadlineDate : any;
//#endregion deadlineDate Prop



}