import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vMonthlyRetainedOptionBase {

//#region applicationObjectName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'applicationObjectName', keyColumn: false,headerTitle: "Status", configuredTemplate: { templateName: "recordActive" }})
        applicationObjectName : string;
//#endregion applicationObjectName Prop


//#region monthlyRetainedOptionName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true,isAscending:true, headerKey: 'monthlyRetainedOptionName', keyColumn: false,headerTitle: "Option"})
        monthlyRetainedOptionName : string;
//#endregion monthlyRetainedOptionName Prop


//#region monthlyRetainedOptionId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'monthlyRetainedOptionId', keyColumn: true})
        monthlyRetainedOptionId : number;
//#endregion monthlyRetainedOptionId Prop

}