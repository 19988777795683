import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class FeedbackBase {

//#region feedbackId Prop
        @prop()
        feedbackId : number;
//#endregion feedbackId Prop


//#region hRConsultantId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        hRConsultantId : number;
//#endregion hRConsultantId Prop


//#region projectId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        projectId : number;
//#endregion projectId Prop


//#region feedbackOptionId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        feedbackOptionId : number;
//#endregion feedbackOptionId Prop


//#region userId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        userId : number;
//#endregion userId Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop


//#region comments Prop
        @required()
        @maxLength({value:500})
        comments : string;
//#endregion comments Prop


//#region ratings Prop
        @prop()
        ratings : number;
//#endregion ratings Prop









}