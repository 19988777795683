import { actionColumn } from '@rxweb/grid';
import {TestimonialBase} from '../database-models/testimonial-base';
import {UserBase} from '../database-models/user-base';
//Generated Imports
@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'action' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class Testimonial extends TestimonialBase 
{




//#region Generated Reference Properties
//#region user Prop
user : UserBase;
//#endregion user Prop

//#endregion Generated Reference Properties

























}