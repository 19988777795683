import { prop,propObject,propArray,required,maxLength,range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TestimonialBase {

//#region testimonialId Prop
        @prop()
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'testimonialId', keyColumn: true })
        testimonialId : number;
//#endregion testimonialId Prop


//#region testimonialTitle Prop
        @required()
        @gridColumn({ visible: true,  columnIndex: 1, allowSorting: true, headerKey: 'testimonialDescription', keyColumn: false, headerTitle: "Title" })
        @maxLength({value:100})
        testimonialTitle : string;
//#endregion testimonialTitle Prop


//#region testimonialDescription Prop
        @maxLength({ value: 500 })
        // @gridColumn({ visible: true, class: ["word-wrap-cust"], columnIndex: 2, allowSorting: true, headerKey: 'testimonialDescription', keyColumn: false, headerTitle: "Description" })
        testimonialDescription : string;
//#endregion testimonialDescription Prop


//#region userId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        userId : number;
//#endregion userId Prop


//#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId : number;
//#endregion statusId Prop

//to show status in grid
@gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'status', keyColumn: false,headerTitle:"Status",configuredTemplate: { templateName: "recordActive" }})
status:string

//#region createdBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @required()
        createdOn : any;
//#endregion createdOn Prop


//#region modifiedBy Prop
        @prop()
        modifiedBy : number;
//#endregion modifiedBy Prop


//#region modifiedOn Prop
        @prop()
        modifiedOn : any;
//#endregion modifiedOn Prop



}