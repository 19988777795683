import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vYearOfExperienceBase {

//#region yearsOfExperienceId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'yearsOfExperienceId', keyColumn: true})
        yearsOfExperienceId : number;
//#endregion yearsOfExperienceId Prop


//#region yearsOfExperience Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'yearsOfExperience', keyColumn: false,headerTitle:"Years Of Experience"})
        yearsOfExperience : string;
//#endregion yearsOfExperience Prop


//#region status Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'status', keyColumn: false,headerTitle:"Status",configuredTemplate: { templateName: "recordActive" }})
        status : string;
//#endregion status Prop

}