import {vExpertizeSubCategoryRecordBase} from '../database-models/v-expertize-sub-category-record-base';
//Generated Imports
export class vExpertizeSubCategoryRecord extends vExpertizeSubCategoryRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties













}