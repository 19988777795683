import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { RxWebModule } from './rxweb.module'
import { AppComponent } from './components/start/app.component';
import { ROUTES } from './components/start/routing'
import { RxHttp } from '@rxweb/http';
import { BaseToastr } from './domain/customize-design/toastr';
import { ModalView } from './domain/customize-design/modal';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { MultiLingualData } from '@rxweb/localization';
import { LoginService } from './components/authentication/login.service';
import { ApplicationBroadcaster } from './broadcast-services/application-broadcaster';
import { HttpClientModule } from '@angular/common/http';
import { RxRoutingModule } from '@rxweb/angular-router';
import { SharedModule } from './components/shared/shared.module';
import { LoginGuard } from './components/authentication/guard/login-guard';
import { RxSelectModule } from '@rxweb/angular/select';
import { RxTranslateModule } from '@rxweb/translate';
import { TopBarComponent } from './components/shared/top-bar/top-bar.component';
import { FooterBarComponent } from './components/shared/footer-bar/footer-bar.component';
import { SideBarComponent } from './components/shared/side-bar/side-bar.component';
import { ConfirmationModalModule } from './components/shared/confirmation-modal/confirmation-modal.module';
import { AppPopupComponent } from './components/shared/app-popup/app-popup.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { ForgotPasswordModule } from './components/authentication/forgot-password/forgot-password.module';
import { ThankyouModule } from './components/master/thank-you/thank-you.module';
import { MessageSharedModule } from './components/project/messages/message-shared.module';


const route = RouterModule.forRoot(ROUTES, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' });
MultiLingualData.addOrUpdate('global', {
  internationalization: {
    date: {
      format: 'mdy',
      separator: '/'
    }
  }
});

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    FooterBarComponent,
    SideBarComponent,
    AppPopupComponent,
  ],

  imports: [
    BrowserModule, route, RxWebModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule, RxRoutingModule, SharedModule, RxSelectModule, CKEditorModule, ConfirmationModalModule,
    ThankyouModule    //, HttpClientModule
  ],
  providers: [RxHttp, Title, BaseToastr, ModalView, LoginService, ApplicationBroadcaster, LoginGuard],
  bootstrap: [AppComponent],
  exports: [RouterModule, ForgotPasswordModule,MessageSharedModule],
  //schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
