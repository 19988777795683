import { http, RxHttp } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { Role } from '@app/models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BreadCrumb } from 'src/app/viewModel/bread-crumb';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { CoreComponent } from '@rxweb/angular-router';


@http({
    path: "api/Roles",
})


export class AbstractRole extends CoreComponent {
    roleFormGroup: IFormGroup<Role>
    toastr: BaseToastr;
    spin :boolean;
    breadCrumb: BreadCrumb;
    dialog: BaseDialog;
    showComponent: boolean = false;

    constructor() {
        super();
        this.bindBreadCrumb();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.badRequest;
    }
    bindBreadCrumb() {
        this.breadCrumb = new BreadCrumb();
        this.breadCrumb.link1 = 'dashboard';
        this.breadCrumb.name1 = "Home";
        this.breadCrumb.name2="User Management";
        this.breadCrumb.title = 'Role Management';
        this.breadCrumb.pageName = 'Role Management';
        this.breadCrumb.pageRedirect = "roles";
        this.breadCrumb.title='Role Management';
    }
    badRequest = (data) => {
        this.spin = false;
        let messages = JSON.parse(data);
        this.toastr.warning(messages[0]);
    }

}
