
//Generated Imports
import { actionColumn } from '@rxweb/grid';
import { vContractHROBase } from '../database-models/v-contract-hro-base';

@actionColumn({
    name: 'action',
    allowSorting: false,
    configuredTemplate: { templateName: 'contractDetails' }, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vContractHRO extends vContractHROBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




}