import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';

import { Testimonial } from '@app/models';
import { AbstractTestimonial } from '../domain/abstract-testimonial';
import { Router } from '@angular/router';
import { RoutingKeys } from 'src/app/components/shared/constant/commonConstants';
import { CustomMessage } from 'src/app/components/shared/constant/custom-message';
import { StatusEnum } from 'src/app/enums/status.enum';
import { access } from "@rxweb/angular-router";
import { ApplicationModules, PermissionNames } from "src/app/enums/application-modules.enum";

@access({ accessLevel: ApplicationModules.MyTestimonials, action: PermissionNames.Post})

@Component({
    selector: "app-testimonial-add",
    templateUrl: './testimonial-add.component.html'
})
export class TestimonialAddComponent extends AbstractTestimonial implements OnInit, OnDestroy {
    testimonial: Testimonial;
    subscription: Subscription;
    statusOfTestimonial: boolean = true;

    constructor(private formBuilder: RxFormBuilder, private route: Router) {
        super();
    }

    ngOnInit(): void { 
        
        this.testimonial = new Testimonial();
        this.testimonialFormGroup = this.formBuilder.formGroup(this.testimonial) as IFormGroup<Testimonial>;
        this.setDefaultValues();
        this.showComponent = true;
    }

    setDefaultValues() {
        this.testimonialFormGroup.patchValue({
            "createdBy": this.user.userId,
            "createdOn": new Date(),
            "testimonialId": 0,
            "userId":this.user.userId,
            "statusId":StatusEnum.Active
        });
    }

    changeStatus() {
        this.statusOfTestimonial = !this.statusOfTestimonial;
    }

    setStatusValue() {
        if (this.statusOfTestimonial) {
            this.testimonialFormGroup.controls.statusId.setValue(StatusEnum.Active);
        }
        else {
            this.testimonialFormGroup.controls.statusId.setValue(StatusEnum.InActive);
        }
    }

    addTestimonial() {
        this.spin = true;
        this.setStatusValue();
        if (this.testimonialFormGroup.valid) {
            this.post({ body: this.testimonialFormGroup.value }).subscribe(response => {
                this.spin = false;
                this.toastr.success(CustomMessage.dataAdded);
                this.route.navigate([RoutingKeys.Testimonials]);
            })
        }
    }

    backToList() {
        this.route.navigate([RoutingKeys.Testimonials]);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

}
