import {ProjectRFQBase} from '../database-models/project-r-f-q-base';
import {ProjectBase} from '../database-models/project-base';
import {UserBase} from '../database-models/user-base';
//Generated Imports
export class ProjectRFQ extends ProjectRFQBase 
{




//#region Generated Reference Properties
//#region project Prop
project : ProjectBase;
//#endregion project Prop
//#region user Prop
user : UserBase;
//#endregion user Prop

//#region user Prop
fromMatchingPage : boolean;
//#endregion user Prop

//#endregion Generated Reference Properties




































}