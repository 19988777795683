import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vExpertizeRecordBase {

//#region expertizeId Prop
        @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'expertizeId', keyColumn: true })
        expertizeId : number;
//#endregion expertizeId Prop


//#region expertizeName Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'expertizeName', keyColumn: false })
        expertizeName : string;
//#endregion expertizeName Prop


//#region expertizeDetails Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'expertizeDetails', keyColumn: false })
        expertizeDetails : string;
//#endregion expertizeDetails Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region createdBy Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'createdBy', keyColumn: false})
        createdBy : number;
//#endregion createdBy Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop

}