import {StateLookupBase} from '../database-models/state-lookup-base';
//Generated Imports
export class StateLookup extends StateLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties



























}