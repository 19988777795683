import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { anonymous, middleware } from '@rxweb/angular-router'
import { CoreComponent } from '@rxweb/angular-router';
import { LoggedInMiddleware } from '../../../domain/security/middleware/logged-in.middleware';
import { IFormGroup, RxFormBuilder } from "@rxweb/reactive-form-validators";
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { LoginService } from '../login.service';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { Subscription } from 'rxjs';
import { ForgotPasswordViewModel } from 'src/app/viewModel/forgot-password-view-model';

@middleware([LoggedInMiddleware])
@anonymous()
@Component({
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent extends CoreComponent implements OnInit, OnDestroy {

    userCredentialForgotPasswordModel: ForgotPasswordViewModel;
    forgotPasswordFormGroup: IFormGroup<ForgotPasswordViewModel>;
    storageHelper: BrowserStorage;
    toastr: BaseToastr;
    loginSubscription: Subscription;
    showDeleteComponent: boolean;
    @Input() hide: Function;
    spin: boolean = false;
    userNotExistMsg:string;
    
    constructor(
        private loginService: LoginService,
        private formBuilder: RxFormBuilder,) {
        super();
        this.storageHelper = new BrowserStorage();
        this.toastr = new BaseToastr();
    }



    ngOnInit() {
        this.userCredentialForgotPasswordModel = new ForgotPasswordViewModel();
        this.forgotPasswordFormGroup = this.formBuilder.formGroup(this.userCredentialForgotPasswordModel) as IFormGroup<ForgotPasswordViewModel>;
        this.spin = false;
        this.showDeleteComponent = true;
    }

    forgotPassword(): void {
        this.forgotPasswordFormGroup.submitted = true;
        if (this.forgotPasswordFormGroup.valid) {
              this.spin = true;
            this.sendForgetPasswordMail();
        }
    }
    isExist(){
        this.userNotExistMsg='';
    }
    sendForgetPasswordMail() {
        this.loginService.forgotPassword(this.forgotPasswordFormGroup.value).subscribe(response => {
            if (response.failedLogin) {
                this.userNotExistMsg=response.validationMessage;
            } else {
                this.hide('yes');
            }
              this.spin = false;
            }
            , () => {
              this.spin = false;
        });
    }

    login() {
        this.hide();
    }

    ngOnDestroy() {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
    }
}
