import { TemplateConfig } from '@rxweb/grid';
import { formatDate } from '@angular/common';
import { pipe } from 'rxjs';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';


export const DATE_FORMAT_GRID_TEMPLATE: TemplateConfig = {
    span: {
        childrens: [{
            text: {
                text: function (e) {
                    debugger;
                    if (this[e.name] != null) { 
                        debugger;                     
                        var date = new Date(this[e.name]);                       
                        return (date.toLocaleString('default', { month: 'short' })) + ' ' + ('0' + date.getDate()).slice(-2) + ', ' + date.getFullYear();
                    } else {
                        return 'NA'
                    }
                }
            }
        }]
    }
}
