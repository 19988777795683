import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProjectBase {

        //#region projectId Prop
        @prop()
        projectId: number;
        //#endregion projectId Prop


        //#region projectName Prop
        @required()
        @maxLength({ value: 100 })
        projectName: string;
        //#endregion projectName Prop


        //#region userId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        userId: number;
        //#endregion userId Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop


        //#region createdBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        createdBy: number;
        //#endregion createdBy Prop


        //#region createdOn Prop
        @required()
        createdOn: any;
        //#endregion createdOn Prop


        //#region modifiedBy Prop
        @prop()
        modifiedBy: number;
        //#endregion modifiedBy Prop


        //#region modifiedOn Prop
        @prop()
        modifiedOn: any;
        //#endregion modifiedOn Prop

        //#region projectDeadlineDate Prop
        @required()
        @prop()
        projectDeadlineDate: any;
        //#endregion projectDeadlineDate Prop











}