import { Feedback } from '../models/extended-models/feedback';
import { HRConsultantLookup } from '../models/extended-models/h-r-consultant-lookup';
import { MonthlyRetainedOptionLookup } from '../models/extended-models/monthly-retained-option-lookup';
import { ProjectLookup } from '../models/extended-models/project-lookup';

export class FeedbackLookupGroup {
    hrConsultantLookup: HRConsultantLookup[];
    projectLookup: ProjectLookup[];
    monthlyRetainedOptionLookup: MonthlyRetainedOptionLookup[];
    feedbackRecord:any;
}