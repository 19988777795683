<style>
    .thankyou_page {
        min-height: 100vh;
        height: 100%;
        padding: 15px;
    }

    .thankyou_page h1 {
        font-size: 65px;
        line-height: 65px;
        /* position: relative; */
    }

    .thankyou_page h1 span {
        font-size: 45px;
        color: #a09e9e;
    }

    .thankyou_page h1:before {
        position: absolute;
        left: 0;
        height: 70%;
        width: 1px;
        background: #e8e8e8;
        content: "";
        transform: translateY(15%);
    }

    .thankyou_page h1.thankyou_msg {
        font-size: 16px;
        max-width: 500px;
        line-height: 24px;
        /* color: #a09e9e; */
    }

    .emailStyle{
        text-decoration: underline;
         color: #399ed8;
    }

    @media only screen and (max-width:767px) {
        .thankyou_page h1:before {
            display: none;
        }

        .thankyou_page h1 {
            font-size: 45px;
            line-height: 45px;
        }

        .thankyou_page h1 span {
            font-size: 25px;
            color: #a09e9e;
        }

        .thankyou_page h1.thankyou_msg {
            font-size: 16px;
            ;
        }

        .thankyou_page img {
            max-width: 200px;
        }
    }
</style>


<div class="thankyou_page d-flex justify-content-center align-items-center flex-column" *ngIf="ResetPasswordError">
    <div class="mb-4 text-center">
        <img src="assets/images/hr-doorway-logo.png" width="292"
          height="174" alt="HR DoorWay" />
        <h1 class="thankyou_msg mt-4 text-center ">Sorry the link provided is no longer valid.</h1>
    </div>
</div>