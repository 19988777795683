import {vMonthlyRetainedServiceRecordBase} from '../database-models/v-monthly-retained-service-record-base';
//Generated Imports
export class vMonthlyRetainedServiceRecord extends vMonthlyRetainedServiceRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties































































}