import { TemplateConfig } from '@rxweb/grid';

export const CHECKBOX_TEMPLATE_GRID_TEMPLATE: TemplateConfig = {
  div: {
    authorize: "add",
    class:["checkbox", "d-inline"],
    childrens: [
      {
        input: {
            event: {
              input: "onCheckBoxSelect"
          },
          attributes: {
            id:function (e) {
              return "hrc-"+ this.UserId;
            },
            type: "checkbox",
            checked: function (e) {
              return this.isChecked;
            },
            value: function (e) {
              return this.isChecked;
            },
          }
        },
        label:{
          attributes:{
            for:function (e) {
              return "hrc-"+ this.UserId;
            },
          },
          class:["cr"]
        },
      }
    ]
  },
};
