import {vHROBase} from '../database-models/v-h-r-o-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name: 'viewDetailsAction',
    allowSorting: false,
    configuredTemplate: { templateName: 'viewDetailsAction'}, columnIndex: 0, headerTitle: "Action", class: ["actionFild"],headerCellClass:["pl-4"]
})
export class vHRO extends vHROBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties







































}