import {vCompanySizeRecordBase} from '../database-models/v-company-size-record-base';
//Generated Imports
export class vCompanySizeRecord extends vCompanySizeRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties














}