import { TemplateConfig } from '@rxweb/grid';
import { ContractsEnum } from 'src/app/enums/contracts.enum';

export const ROLE_GRID_TEMPLATE: TemplateConfig = {
    span: {
        class: [function (e) {
            if (this[e.name] == "HRO")
                return this[e.name] = 'HRO', "subscriptionPayment".split(" ");

            else if (this[e.name] == "HRC")
                return this[e.name] = 'HRC', "user-payment".split(" ");

            else
                return "guestuser".split(" ");

        }],
        childrens: [{
            text: {
                text: function (e) {
                    if (this[e.name] == "HRO")
                        return this[e.name] = 'HRO'

                    else if (this[e.name] == "HRC")
                        return this[e.name] = 'HRC'

                    else if (this[e.name] == "Guest")
                        return this[e.name] = 'Guest'
                }
            }
        }]
    }
}
