import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class RoleNameLookupBase {

//#region applicationObjectId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'applicationObjectId', keyColumn: true})
        applicationObjectId : number;
//#endregion applicationObjectId Prop


//#region roleName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'roleName', keyColumn: false})
        roleName : string;
//#endregion roleName Prop

}