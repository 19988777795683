import {AthorizeDotNetResponseCodeBase} from '../database-models/athorize-dot-net-response-code-base';
//Generated Imports
export class AthorizeDotNetResponseCode extends AthorizeDotNetResponseCodeBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties


}