import { TemplateConfig } from '@rxweb/grid';
import { PipeConstants } from 'src/app/components/shared/constant/commonConstants';


export const EDIT_ACTION_GRID_TEMPLATE: TemplateConfig = {
    div: {
        class: 'formAction list-unstyled mb-0'.split(' '),
        childrens: [{
            a: {
                class:'gridEditbtn'.split(' '),
                authorize: 'put',
                event: {
                    click: 'onEdit'
                },
                childrens: [{
                    a: {
                        childrens: [{
                            i: { class: 'feather icon-edit'.split(' ') }
                        }],
                    }
                }]
            }
        },
        
        ]
    }
};
