import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProjectRecordBase {

//#region projectId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'projectId', keyColumn: true})
        projectId : number;
//#endregion projectId Prop


//#region projectName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'projectName', keyColumn: false})
        projectName : string;
//#endregion projectName Prop


//#region questionId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'questionId', keyColumn: false})
        questionId : number;
//#endregion questionId Prop


//#region questionType Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'questionType', keyColumn: false})
        questionType : number;
//#endregion questionType Prop


//#region questionText Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'questionText', keyColumn: false})
        questionText : string;
//#endregion questionText Prop


//#region projectOptionDetailId Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'projectOptionDetailId', keyColumn: false})
        projectOptionDetailId : number;
//#endregion projectOptionDetailId Prop


//#region projectOptionDetailDescription Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'projectOptionDetailDescription', keyColumn: false})
        projectOptionDetailDescription : string;
//#endregion projectOptionDetailDescription Prop


//#region expertizeId Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'expertizeId', keyColumn: false})
        expertizeId : number;
//#endregion expertizeId Prop


//#region expertizeName Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'expertizeName', keyColumn: false})
        expertizeName : string;
//#endregion expertizeName Prop


//#region expertizeSubCategoryId Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'expertizeSubCategoryId', keyColumn: false})
        expertizeSubCategoryId : number;
//#endregion expertizeSubCategoryId Prop


//#region expertizeSubCategoryName Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'expertizeSubCategoryName', keyColumn: false})
        expertizeSubCategoryName : string;
//#endregion expertizeSubCategoryName Prop


//#region expertizeSubCategoryDescription Prop
        @gridColumn({visible: true, columnIndex:11, allowSorting: true, headerKey: 'expertizeSubCategoryDescription', keyColumn: false})
        expertizeSubCategoryDescription : string;
//#endregion expertizeSubCategoryDescription Prop

}