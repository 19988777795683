import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RoutingKeys, StorageKeys } from 'src/app/components/shared/constant/commonConstants';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { ApplicationBroadcaster } from 'src/app/broadcast-services/application-broadcaster';
import { CommonHelperService } from '../../shared/common-helpers/helper.service';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {

    storageHelper: BrowserStorage;
    constructor(
        private commonHelperService: CommonHelperService,
        private router: Router,
        private applicationBroadCaster: ApplicationBroadcaster
    ) {
        this.storageHelper = new BrowserStorage();
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const token = this.storageHelper.local.get(StorageKeys.Authentication, false);
        if (this.commonHelperService.isStringNullEmptyOrWhiteSpace(token)) {
            this.applicationBroadCaster.loginBroadCast(false);
            this.router.navigate([RoutingKeys.Login]);
            return false;
        }
        return true;
    }
}
