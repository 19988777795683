import {vProjectEditRecordBase} from '../database-models/v-project-edit-record-base';
//Generated Imports
export class vProjectEditRecord extends vProjectEditRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties













}